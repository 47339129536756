<template>
  <div class="main-frame">
    <div class="modal-cover" v-show="isShowCompleteModal || isShowOutModal||isToolsRevise||isToolsSelect"></div>
    <div class="top-headline">
      <h1 class="title">평가하기</h1>
      <button class="btn_complete_evaluation" @click="isShowCompleteModal = true" v-if="!isEvaluationEdit">평가 완료 처리</button>
    </div>
    <h1 class="sub-title" v-if="!isDocIdDataNull">환자 차트 확인</h1>
    <h1 class="title error" v-else>{{ warningText }}</h1>
    <div class="chart-confirm-container">
      <div class="chart-confirm" v-if="!isDocIdDataNull">
        <img src="@/assets/images/IA/evaluation_text_1.png" alt="icon_1">
        <div class="chart-container">
          <p class="chart-title-2">환자명</p>
          <p class="chart-value-1 user-name">{{ fbDataEvaluation.patientInfo.name }}</p>
        </div>
        <div class="chart-container">
          <p class="chart-title-2">평가자</p>
          <p class="chart-value-1">{{ fbDataEvaluation.therapistInfo.name }}</p>
        </div>
        <div class="chart-container">
          <p class="chart-title-2">평가 회차</p>
          <p class="chart-value-2">{{ fbDataEvaluation.round }}차</p>
        </div>
        <div class="chart-container">
          <p class="chart-title-2">평가 생성 날짜 </p>
          <p class="chart-value-2"> {{ fbDataEvaluation.regDate }}</p>
        </div>
      </div>
      <div class="divider" v-if="!isDocIdDataNull"></div>
      <div class="chart-confirm" v-if="!isDocIdDataNull">
        <img src="@/assets/images/IA/evaluation_text_2.png" alt="icon_2">
        <div class="chart-container">
          <p class="chart-title-4">진단명</p>
          <p class="chart-value-3">{{ fbDataEvaluation.diseaseInfo.diagnosis }}
            <strong v-if="fbDataEvaluation.diseaseInfo.description !== ''"
                    class="diseaseInfo_desc">({{ fbDataEvaluation.diseaseInfo.description }})</strong>
          </p>
        </div>
        <div class="chart-container">
          <p class="chart-title-4">손상 발생일</p>
          <p class="chart-value-3">{{ fbDataEvaluation.diseaseInfo.injuredDate }}</p>
        </div>
        <div class="chart-container">
          <p class="chart-title-4">손상 부위 / 손상측</p>
          <p class="chart-value-4" style="white-space: nowrap">{{ fbDataEvaluation.diseaseInfo.injuredPart }} /
            {{ fbDataEvaluation.diseaseInfo.injuredSide }}</p>
        </div>
        <div class="chart-container">
          <p class="chart-title-4">손잡이</p>
          <p class="chart-value-4">{{ fbDataEvaluation.diseaseInfo.usedHand }}</p>
        </div>
      </div>
    </div>
    <h1 class="sub-title">평가도구 안내</h1>
    <div class="tools-select-container">
      <div class="tools-guide-flex">
        <img src="@/assets/images/IA/check-circle.png" alt="circle">
        <p>평가하기 영역에서 진행하고자 하는 평가도구를 선택해 진행합니다.</p>
      </div>
      <div class="tools-guide-flex">
        <img src="@/assets/images/IA/check-circle.png" alt="circle">
        <p>생성한 평가도구는 변경 할 수 있습니다. 단, 평가 결과 저장이 완료된 평가는 변경할 수 없으며 임시저장된 평가는 이전데이터가 모두 삭제됩니다. </p>
      </div>
      <div class="tools-guide-flex">
        <img src="@/assets/images/IA/check-circle.png" alt="circle">
        <p>평가를 완료하지 않고 서비스를 종료할 경우 이후에 접속시 이어 할 수 있습니다.</p>
      </div>
      <div class="tools-guide-flex">
        <img src="@/assets/images/IA/check-circle.png" alt="circle">
        <p>‘평가 완료 처리’ 버튼을 누를 경우, 평가 결과 저장이 완료된 것만 클라우드 저장 후 평가가 종료됩니다.</p>
      </div>
      <div class="tools-guide-flex">
        <img src="@/assets/images/IA/check-circle.png" alt="circle">
        <p>평가 값 수정은 완료 처리후에도 환자 상세보기페이지에서 수정 가능합니다.</p>
      </div>
    </div>
    <div class="bottom-headline">
      <h1 class="sub-title">평가하기</h1>
      <div class="button-box">
        <button class="evaluation-edit" v-if="!isEvaluationEdit" @click="isToolsRevise=true">평가도구 변경</button>
        <button class="evaluation-select" @click="isToolsSelect=true">진행할 평가도구 선택</button>
      </div>
    </div>
    <div class="evaluation-container">
      <div class="non-select-tools" v-if="selPagerName===''"> 평가도구를 선택해주세요.</div>
      <!-- 상지 -->
      <FuglMyer v-if="selPagerName==='fm'" :fbDocId="fbDocId" :fm="fbDataEvaluation.tools.fm" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <BoxAndBlocks v-if="selPagerName==='bb'" :fbDocId="fbDocId" :bb="fbDataEvaluation.tools.bb" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <WolfMotorFunction v-if="selPagerName==='wmft'" :fbDocId="fbDocId" :wmft="fbDataEvaluation.tools.wmft" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <StrokeImpactScale v-if="selPagerName==='sis'" :fbDocId="fbDocId" :sis="fbDataEvaluation.tools.sis" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <ActualAmountOfUse v-if="selPagerName==='aaut'" :fbDocId="fbDocId" :aaut="fbDataEvaluation.tools.aaut" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <MotorActivityLog v-if="selPagerName==='mal'" :fbDocId="fbDocId" :mal="fbDataEvaluation.tools.mal" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <!-- 하지 -->
      <ABCscale v-if="selPagerName==='abc'" :fbDocId="fbDocId" :abc="fbDataEvaluation.tools.abc" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <DynamicGaitIndex v-if="selPagerName==='dgi'" :fbDocId="fbDocId" :dgi="fbDataEvaluation.tools.dgi" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <SixMinuteWalkingTest v-if="selPagerName==='smwt'" :smwt="fbDataEvaluation.tools.smwt" :fbDocId="fbDocId" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <TenMeterWalkTest v-if="selPagerName==='tmwt'" :fbDocId="fbDocId" :tmwt="fbDataEvaluation.tools.tmwt" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <FuglMyerLower v-if="selPagerName==='fm_l'" :fbDocId="fbDocId" :fm_l="fbDataEvaluation.tools.fm_l" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <FiveTimesSitToStandTest v-if="selPagerName==='ftsts'" :fbDocId="fbDocId" :ftsts="fbDataEvaluation.tools.ftsts" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <TimeUpAndGo v-if="selPagerName==='tug'" :fbDocId="fbDocId" :tug="fbDataEvaluation.tools.tug" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <BergBalanceScale v-if="selPagerName==='bbs'" :fbDocId="fbDocId" :bbs="fbDataEvaluation.tools.bbs" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <!-- BASIC -->
      <BasicBalance v-if="selPagerName==='balance'" :fbDocId="fbDocId" :balance="fbDataEvaluation.tools.balance" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <ModifiedAshowrthScale v-if="selPagerName==='mas'" :fbDocId="fbDocId" :mas="fbDataEvaluation.tools.mas" @refresh="refresh" :isEdit="isEvaluationEdit"/>
      <RangeOfMotion_ManualMuscleTest v-if="selPagerName==='rom_mmt'" :fbDocId="fbDocId" :rom_mmt="fbDataEvaluation.tools.rom_mmt" @refresh="refresh" :isEdit="isEvaluationEdit"/>

    </div>
    <EvaluationCompleteDialog v-if="isShowCompleteModal" @callBackModal="callBackModal" :fbDocId="fbDocId"/>
    <EvaluationOutDialog v-if="isShowOutModal" @chooseToolCallback="chooseToolCallback" :selToolName="selToolName"/>
    <EvaluationToolsReviseDialog v-if="isToolsRevise" @callBackModal="callBackModal" :tools="fbDataEvaluation.tools"/>
    <EvaluationToolsSelectDialog v-if="isToolsSelect" @callBackModal="callBackModal" :tools="fbDataEvaluation.tools"/>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import StrokeImpactScale from "./paper/sis/StrokeImpactScale";
import FuglMyer from "@/views/therapist/menu/doEvaluation/paper/fm/FuglMyer";
import BoxAndBlocks from "@/views/therapist/menu/doEvaluation/paper/bb/BoxAndBlocks";
import ActualAmountOfUse from "@/views/therapist/menu/doEvaluation/paper/aaut/ActualAmountOfUse";
import WolfMotorFunction from "@/views/therapist/menu/doEvaluation/paper/wmft/WolfMotorFunction";
import MotorActivityLog from "@/views/therapist/menu/doEvaluation/paper/mal/MotorActivityLog";
import EvaluationCompleteDialog from "./EvaluationCompleteDialog";
import EvaluationOutDialog from "@/views/therapist/menu/doEvaluation/EvaluationOutDialog";
import EvaluationToolsReviseDialog from "@/views/therapist/menu/doEvaluation/EvaluationToolsReviseDialog";
import EvaluationToolsSelectDialog from "@/views/therapist/menu/doEvaluation/EvaluationToolsSelectDialog";
import FuglMyerLower from "@/views/therapist/menu/doEvaluation/paper/fm_l/FuglMyerLower";
import ABCscale from "@/views/therapist/menu/doEvaluation/paper/abc/ABCscale";
import TimeUpAndGo from "@/views/therapist/menu/doEvaluation/paper/tug/TimeUpAndGo";
import FiveTimesSitToStandTest from "@/views/therapist/menu/doEvaluation/paper/ftsts/FiveTimesSitToStandTest";
import TenMeterWalkTest from "@/views/therapist/menu/doEvaluation/paper/tmwt/TenMeterWalkTest";
import DynamicGaitIndex from "@/views/therapist/menu/doEvaluation/paper/dgi/DynamicGaitIndex";
import BergBalanceScale from "@/views/therapist/menu/doEvaluation/paper/bbs/BergBalanceScale";
import SixMinuteWalkingTest from "@/views/therapist/menu/doEvaluation/paper/smwt/SixMinuteWalkingTest";
import BasicBalance from "@/views/therapist/menu/doEvaluation/paper/basic/BasicBalance";
import ModifiedAshowrthScale from "@/views/therapist/menu/doEvaluation/paper/basic/ModifiedAshowrthScale";
import RangeOfMotion_ManualMuscleTest from "@/views/therapist/menu/doEvaluation/paper/basic/RangeOfMotion_ManualMuscleTest";

export default {
  name: "EvaluationDetailPage",
  components: {
    FuglMyerLower, ABCscale, TimeUpAndGo, FiveTimesSitToStandTest, TenMeterWalkTest, DynamicGaitIndex, BergBalanceScale, SixMinuteWalkingTest, RangeOfMotion_ManualMuscleTest,
    EvaluationOutDialog,
    EvaluationCompleteDialog,
    MotorActivityLog, WolfMotorFunction, ActualAmountOfUse, BoxAndBlocks, FuglMyer,
    BasicBalance,
    ModifiedAshowrthScale,
    StrokeImpactScale, EvaluationToolsReviseDialog, EvaluationToolsSelectDialog,
    // SlideUpDown
  },
  data() {
    return {
      fbCollection: 'evaluations',
      fbDocId: this.$route.params.id.toString(),
      fbDataEvaluation: {
        patientInfo: {},
        therapistInfo: {},
        diseaseInfo: {},
        tools: {
          bm: {name: 'bm', checked: false, state: 0,},
          rom_mmt: {
            name: 'rom_mmt', checked: false, state: 0,
            rom: {
              lt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              },
              rt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              }
            },
            mmt: {
              lt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              },
              rt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              }
            }
          },
          mas: {
            name: 'mas', checked: false, state: 0,
            lt: {
              shoulder: {flexion: null, extension: null, abduction: null, adduction: null},
              elbow: {flexion: null, extension: null,},
              wrist: {flexion: null, extension: null,},
              hip: {flexion: null, extension: null, abduction: null, adduction: null},
              knee: {flexion: null,},
              ankle: {dorsi_flexion: null, plantar_flexion: null}
            },
            rt: {
              shoulder: {flexion: null, extension: null, abduction: null, adduction: null},
              elbow: {flexion: null, extension: null,},
              wrist: {flexion: null, extension: null,},
              hip: {flexion: null, extension: null, abduction: null, adduction: null},
              knee: {flexion: null,},
              ankle: {dorsi_flexion: null, plantar_flexion: null}
            }
          },
          balance: {
            name: 'balance', checked: false, state: 0,
            sitting: {static: null, dynamic: null},
            standing: {static: null, dynamic: null}
          },
          fm: {
            name: 'Fugl-Meyer(상)', checked: false, state: 0,
            a_1_1: null, a_1_2: null, a_2_1: null, a_2_2: null, a_2_3: null, a_2_4: null, a_2_5: null, a_2_6: null, a_2_7: null,
            a_2_8: null, a_2_9: null, a_3_1: null, a_3_2: null, a_3_3: null, a_4_1: null, a_4_2: null, a_4_3: null, a_5_1: null,
            b_1_1: null, b_1_2: null, b_1_3: null, b_1_4: null, b_1_5: null,
            c_1_1: null, c_1_2: null, c_1_3: null, c_1_4: null, c_1_5: null, c_1_6: null, c_1_7: null,
            d_1_1: null, d_1_2: null, d_1_3: null,
          },
          bb: {
            name: 'BB', checked: false, state: 0,
            ma: null, la: null
          },
          wmft: {
            name: 'WMFT', checked: false, state: 0,
            ma: {
              ma_1: null, ma_2: null, ma_3: null, ma_4: null, ma_5: null, ma_6: null, ma_7: null, ma_8: null, ma_9: null, ma_10: null,
              ma_11: null, ma_12: null, ma_13: null, ma_14: null, ma_15: null,
              kg: {kg_1: null, kg_2: null, kg_3: null,},
              pw: {pw_1: null, pw_2: null, pw_3: null,},
            },
            la: {
              la_1: null, la_2: null, la_3: null, la_4: null, la_5: null, la_6: null, la_7: null, la_8: null, la_9: null, la_10: null,
              la_11: null, la_12: null, la_13: null, la_14: null, la_15: null,
              kg: {kg_1: null, kg_2: null, kg_3: null,},
              pw: {pw_1: null, pw_2: null, pw_3: null,},
            },
          },
          sis: {
            name: 'SIS', checked: false, state: 0,
            a_1_1: null, a_1_2: null, a_1_3: null, a_1_4: null,
            a_2_1: null, a_2_2: null, a_2_3: null, a_2_4: null, a_2_5: null, a_2_6: null, a_2_7: null,
            a_3_1: null, a_3_2: null, a_3_3: null, a_3_4: null, a_3_5: null, a_3_6: null, a_3_7: null, a_3_8: null, a_3_9: null,
            a_4_1: null, a_4_2: null, a_4_3: null, a_4_4: null, a_4_5: null, a_4_6: null, a_4_7: null,
            a_5_1: null, a_5_2: null, a_5_3: null, a_5_4: null, a_5_5: null, a_5_6: null, a_5_7: null, a_5_8: null, a_5_9: null, a_5_10: null,
            a_6_1: null, a_6_2: null, a_6_3: null, a_6_4: null, a_6_5: null, a_6_6: null, a_6_7: null, a_6_8: null, a_6_9: null,
            a_7_1: null, a_7_2: null, a_7_3: null, a_7_4: null, a_7_5: null,
            a_8_1: null, a_8_2: null, a_8_3: null, a_8_4: null, a_8_5: null, a_8_6: null, a_8_7: null, a_8_8: null,
            a_9_1: null,
          },
          aaut: {
            name: 'AAUT', checked: false, state: 0,
            aou: {
              aou_1: null, aou_2: null, aou_3: null, aou_4: null, aou_5: null, aou_6: null, aou_7: null,
              aou_8: null, aou_9: null, aou_10: null, aou_11: null, aou_12: null, aou_13: null, aou_14: null,
            },
            qom: {
              qom_1: null, qom_2: null, qom_3: null, qom_4: null, qom_5: null, qom_6: null, qom_7: null,
              qom_8: null, qom_9: null, qom_10: null, qom_11: null, qom_12: null, qom_13: null, qom_14: null,
            },
          },
          mal: {
            name: 'MAL', checked: false, state: 0,
            aou: {
              aou_1: null, aou_2: null, aou_3: null, aou_4: null, aou_5: null, aou_6: null, aou_7: null, aou_8: null, aou_9: null, aou_10: null,
              aou_11: null, aou_12: null, aou_13: null, aou_14: null, aou_15: null, aou_16: null, aou_17: null, aou_18: null, aou_19: null,
              aou_20: null, aou_21: null, aou_22: null, aou_23: null, aou_24: null, aou_25: null, aou_26: null, aou_27: null, aou_28: null,
            },
            qom: {
              qom_1: null, qom_2: null, qom_3: null, qom_4: null, qom_5: null, qom_6: null, qom_7: null, qom_8: null, qom_9: null, qom_10: null,
              qom_11: null, qom_12: null, qom_13: null, qom_14: null, qom_15: null, qom_16: null, qom_17: null, qom_18: null, qom_19: null,
              qom_20: null, qom_21: null, qom_22: null, qom_23: null, qom_24: null, qom_25: null, qom_26: null, qom_27: null, qom_28: null,
            },
          },
          bbs: {
            name: 'BBS', checked: false, state: 0,
            bbs_1: null, bbs_2: null, bbs_3: null, bbs_4: null, bbs_5: null, bbs_6: null, bbs_7: null,
            bbs_8: null, bbs_9: null, bbs_10: null, bbs_11: null, bbs_12: null, bbs_13: null, bbs_14: null,
          },
          tug: {
            name: 'TUG', checked: false, state: 0,
            time: null
          },
          ftsts: {
            name: 'FTSTS', checked: false, state: 0,
            time: null
          },
          fm_l: {
            name: 'Fugl-Meyer(하)', checked: false, state: 0,
            e_1_1: null, e_1_2: null,
            e_2_1: null, e_2_2: null, e_2_3: null, e_2_4: null, e_2_5: null, e_2_6: null, e_2_7: null,
            e_3_1: null, e_3_2: null,
            e_4_1: null, e_4_2: null,
            e_5_1: null,
            f_1_1: null, f_1_2: null, f_1_3: null,
          },
          tmwt: {
            name: '10MWT', checked: false, state: 0,
            assistive_device: null, assist: null,
            self_selected_speed: {trial_1: null, trial_2: null, trial_3: null, average_time: null, average_self_selected: null},
            fast_speed: {trial_1: null, trial_2: null, trial_3: null, average_time: null, average_self_selected: null}
          },
          smwt: {
            name: '6MWT', checked: false, state: 0, assist: null,
            pre_rest: null, pre_1: null, pre_2: null, pre_3: null, pre_4: null, pre_5: null, pre_6: null,
            distance_rest: null, distance_1: null, distance_2: null, distance_3: null, distance_4: null, distance_5: null, distance_6: null,
            comments_rest: null, comments_1: null, comments_2: null, comments_3: null, comments_4: null, comments_5: null, comments_6: null,
          },
          dgi: {
            name: 'DGI', checked: false, state: 0,
            dgi_1: null, dgi_2: null, dgi_3: null, dgi_4: null, dgi_5: null, dgi_6: null, dgi_7: null, dgi_8: null,
          },
          abc: {
            name: 'ABCscale', checked: false, state: 0,
            abc_1: null, abc_2: null, abc_3: null, abc_4: null,
            abc_5: null, abc_6: null, abc_7: null, abc_8: null,
            abc_9: null, abc_10: null, abc_11: null, abc_12: null,
            abc_13: null, abc_14: null, abc_15: null, abc_16: null
          }
        },
      },
      isEvaluationEdit: false,
      isShowOutModal: false,
      isDocIdDataNull: false,
      isToolsRevise: false,
      isToolsSelect: false,
      warningText: '평가 정보를 찾을 수 없습니다.',
      isSelPaper: false,
      selPagerName: '',
      selToolName: '',
      isShowCompleteModal: false,
      active: {
        tool_1: true,
        tool_2: true,
        tool_3: true,
      },
      tool_1: {
        tool_sub_1: true,
      },
      tool_2: {
        tool_sub_1: true,
        tool_sub_2: true,
      },
      tool_3: {
        tool_sub_1: true,
        tool_sub_2: true,
        tool_sub_3: true,
      },
      oneToolsOption: [
        {
          text: 'Body Function & Structure',
          value: 'Body Function & Structure',
          selected: true,
        }
      ],
      twoToolsOption: [
        {
          text: 'Activity',
          value: 'Activity',
          selected: true,
        }
      ],
      threeToolsOption: [
        {
          text: 'Participation',
          value: 'Participation',
          selected: true,
        }
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getDataEvaluationInfo()
      this.checkIsEditPage()
    },
    checkIsEditPage() {
      const self = this;
      if (localStorage.isEvaluationEdit) {
        self.isEvaluationEdit = true;
      }
    },
    async getDataEvaluationInfo() {
      const self = this;
      await firebase.firestore().collection(self.fbCollection)
          .doc(self.fbDocId).get().then(async (snapshot) => {
            const _data = snapshot.data();
            if (_data == null) {
              self.isDocIdDataNull = true;
              return
            }
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            if (_data.diseaseInfo.description.length > 8) {
              _data.diseaseInfo.description = _data.diseaseInfo.description.substring(0, 8) + '...'
            }
            self.fbDataEvaluation = _data
          });
    },

    callBackModal: function (index, isShowModal, data) {
      const self = this;
      self.isShowCompleteModal = false
      self.isToolsRevise = false
      self.isToolsSelect = false
      if (index === 0) {
        if (isShowModal) {
          self.$router.push('/therapist/doEvaluation/');
        }
      }
      if (index === 2) {
        self.fbDataEvaluation.tools = data
        self.selPagerName = ''
      }
      if (index === 4) {
        const toolName = data

        if (self.selPagerName !== toolName && self.selPagerName !== '') {
          self.isShowOutModal = true;
          self.selToolName = toolName;
        } else {
          self.selPagerName = toolName;
        }
      }
    },

    chooseToolCallback(state, toolName) {
      const self = this;
      self.isShowOutModal = false
      self.selToolName = ''
      if (state) {
        self.selPagerName = toolName;
        this.getDataEvaluationInfo()
      }
    },

    refresh: function (toolName, state) {
      const self = this;
      if (state === 1) {
        alert('임시저장 성공!');
      } else {
        self.selPagerName = '';
        alert('결과저장 성공!');

        const now = new Date();
        const lastToolName = {}
        const nowDate = getDate(now)
        lastToolName['last' + toolName] = nowDate
        firebase.firestore().collection('patients').doc(self.fbDataEvaluation.patientInfo.patientKey).set(lastToolName, {merge: true}).then(() => {
          if (self.isEvaluationEdit) {
            self.$router.replace(`/therapist/patientsManage/${localStorage.evaluationId}`);
          }

          delete localStorage.evaluationId
          delete localStorage.isEvaluationEdit
        })
      }
    },
  }
}
</script>


<style scoped>
.top-headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.title {
  font-size: 34px;
  font-weight: 700;
}

.main-frame {
  padding: 10px 15px;
  min-width: 1140px;
  background-color: #F8F8FA;
}

.sub-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 30px 0 10px 0;
}

.chart-confirm-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
  display: flex;
  flex-direction: row;
}

.chart-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.user-name {
  color: #0069CA !important;
}

.chart-container p strong {
  color: #000000;
  margin-left: 20px;
}

.chart-item {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.chart-confirm:last-child {
  margin-left: 20px !important;
}

.chart-confirm img {
  width: 92px;
  height: 28px;
  margin-bottom: 10px;
}

.tb-data {
  margin-top: 10px;
}

.bottom-headline {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 30px 0 10px 0;
  height: 40px;
}

.bottom-headline .sub-title {
  margin: 0;
}

.button-box {
  display: flex;
  justify-content: flex-start;
}

.button-box button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  width: 134px;
  height: 40px;
  background: rgb(248, 248, 250);
  white-space: nowrap;
  border: 1px solid #0069CA;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;

  letter-spacing: 0.0125em;
  color: #0069CA;
}

.button-box .evaluation-select {
  margin-left: 12px;
  width: 182px;
}

.button-box .evaluation-edit {
  border: 1px solid #8D8D8D;
  color: #8D8D8D;
}

.evaluation-container {
  padding: 23px 20px;
  width: 100%;
  min-height: 210px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 30px;
  position: relative;
}

.non-select-tools {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chart-value-2 {
  width: 122px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #000000;
  margin: 0;
}

.chart-value-4 {
  width: 93px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #000000;
  margin: 0;
}

.tools-guide-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
}

.tools-guide-flex img {
  width: 18px;
  height: 18px;
}

.tools-guide-flex p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #0A1120;
  margin: 0 0 0 5px;
}

.select-tools-box {
  margin-top: 14px;
}

.row-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.search-select {
  flex: 1;
  background-color: #ffffff;
  margin-right: 10px;
}

.search-select:last-child {
  margin-right: 0 !important;
}

/deep/ .search-select .md-form.md-outline {
  width: 100% !important;
  margin: 0 !important;
  height: 40px;
}

/deep/ .select-dropdown {
  margin: 0 !important;
}

/deep/ .search-se.main-frame {
  padding: 10px 15px;
  min-width: 1140px;
}

.chart-confirm:last-child {
  margin-left: 40px;
}

.tools-select-container {
  width: 100%;
  height: 158px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 23px;
}

.divider {
  margin: 0 40px;
  height: 150px;
  background: #E1E3E6;
  width: 1px;
}

.chart-title-1 {
  width: 45px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #999999;
  margin: 0 20px 0 0;
}

.chart-value-1 {
  width: 96px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #000000;
}

.chart-title-2 {
  width: 100px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #999999;
  margin: 0 40px 0 0;
}

.chart-title-3 {
  width: 85px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #999999;
  margin: 0 20px 0 0;
}

.chart-value-3 {
  width: 234px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #000000;
  margin: 0;
}

.chart-title-4 {
  width: 131px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #999999;
  margin: 0 40px 0 0;
}

/deep/ .search-box-item .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
  height: 40px !important;
}

/deep/ .search-select .md-form {
  width: 145px;
  margin: 0 10px 0 0;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .form-control {
  margin: 0 !important;
}

/deep/ .caret {
  display: none;
}

.btn_complete_evaluation {
  padding: 11px 20px;
  width: 137px;
  height: 40px;
  color: #ffffff;
  background: #0A1120;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.custom-select-box {
  flex: 1;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.0025em;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  margin-right: 10px;
  cursor: pointer;
}

.custom-select-box p {
  margin: 0;
  align-items: center;
}

.custom-select-box img {
  width: 18px;
  height: 18px;
  margin-left: auto;
}

.custom-select-item {
  flex: 1;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  padding: 0 8px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 10;
}

.item-tool {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.custom-select-item h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.custom-select-item p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin-bottom: 0;
  margin-left: auto;
}

.active-item {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #E14F4E !important;
  margin-bottom: 0;
  margin-left: auto;
}

.error {
  color: red;
  text-align: center;
}

.diseaseInfo_desc {
  color: #8D8D8D !important;
  margin: 0 !important;
}

.icon-check img {
  width: 18px;
}

</style>
