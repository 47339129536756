export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'bbs_1',
        field: 'bbs_1',
    },
    {
        label: 'bbs_2',
        field: 'bbs_2',
    },
    {
        label: 'bbs_3',
        field: 'bbs_3',
    },
    {
        label: 'bbs_4',
        field: 'bbs_4',
    },
    {
        label: 'bbs_5',
        field: 'bbs_5',
    },
    {
        label: 'bbs_6',
        field: 'bbs_6',
    },
    {
        label: 'bbs_7',
        field: 'bbs_7',
    },
    {
        label: 'bbs_8',
        field: 'bbs_8',
    },
    {
        label: 'bbs_9',
        field: 'bbs_9',
    },
    {
        label: 'bbs_10',
        field: 'bbs_10',
    },
    {
        label: 'bbs_11',
        field: 'bbs_11',
    },
    {
        label: 'bbs_12',
        field: 'bbs_12',
    },
    {
        label: 'bbs_13',
        field: 'bbs_13',
    },
    {
        label: 'bbs_14',
        field: 'bbs_14',
    },

];
