<template>
  <div class="sub-frame">
    <div class="modal-cover" v-show="isShowToolsModal||isShowContinueModal"></div>
    <h1 class="title">평가하기</h1>
    <div class="search-frame">
      <p class="search-count">총 환자({{ patientCount }})</p>
      <div class="search-box">
        <mdb-select
            :scroll=false
            outline
            v-model="basicOptions"
            placeholder="환자 번호"
            @getValue="getSelectMainValue"
            class="search-select "/>
        <div class="basic-search">
          <div class="icon-input">
            <img src="@/assets/images/IA/icon_search.png" alt="search">
            <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
          </div>
          <button class="custom-btn-box search-btn">검색</button>
        </div>
      </div>
    </div>
    <div class="table-frame">
      <mdb-datatable-2
          v-model="data"
          class="custom-table"
          borderless
          hover
          fullPagination
          :searching="{value: search, field: fieldName} "
          noFoundMessage="조회된 환자가 없습니다."/>
      <div class="data-null" v-show="!isDataNull">
        <img src="@/assets/images/IA/img_null.png" alt="null">
        <p>등록된 환자가 없습니다.</p>
      </div>
    </div>

    <EvaluationToolsAddDialog v-if="isShowToolsModal" @callBackModal="callBackModal" :selectPatientInfo="selectPatientInfo"/>
    <EvaluationContinueDialog v-if="isShowContinueModal" @callBackModal="callBackModal" :selectPatientInfo="selectPatientInfo"/>
  </div>
</template>

<script>
import {mdbDatatable2, mdbSelect} from "mdbvue";
import evaluation_columns from "@/assets/data/evaluation_columns";
import EvaluationToolsAddDialog from "@/views/therapist/menu/doEvaluation/EvaluationToolsAddDialog";
import selectOptions from "@/assets/data/selectOption_data";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import EvaluationContinueDialog from "@/views/therapist/menu/doEvaluation/EvaluationContinueDialog";

export default {
  name: "EvaluationPage",
  components: {
    EvaluationContinueDialog,
    EvaluationToolsAddDialog,
    mdbDatatable2,
    mdbSelect,
  },
  data() {
    return {
      fbCollection: 'patients',
      fbTherapistUid: this.$store.state.user.uid,
      basicOptions: selectOptions.basicOptionsNoDiagnosis,
      diagnosisOptions: selectOptions.diagnosis,
      data: {
        columns: evaluation_columns,
        rows: []
      },
      search: '',
      fieldName: 'patientNo',
      isShowToolsModal: false,
      isShowContinueModal: false,
      isDataNull: false,
      isDiagnosis: false,
      patientCount: 0,
      selectPatientInfo: {},
      fbDocId: '',
    }
  },
  created() {
    window.addEvaluationTools = this.addEvaluationTools;
    window.continueEvaluationTools = this.continueEvaluationTools;
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    dataInit() {
      this.storageReset()
      this.getDataPatientTable()
      this.setNullDataDesign()
    },
    storageReset() {
      delete localStorage.evaluationId
      delete localStorage.isEvaluationEdit
    },
    setNullDataDesign() {
      const self = this;
      if (!self.isDataNull) {
        const _td = document.getElementsByClassName('dataTable').item(0)
            .children.item(1).childNodes.item(0).childNodes.item(0)
        if (self.data.rows.length === 0) {
          _td.style.height = '384px'
          _td.style.opacity = 0
        }
      }
    },
    getDataPatientTable() {
      const self = this;
      const db = firebase.firestore();
      self.data.rows.splice(0)
      db.collection(self.fbCollection)
          .where('therapistUid', '==', self.fbTherapistUid)
          .orderBy("regDate", "desc")
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.size === 0) {
              self.isDataNull = false
              return
            }
            self.patientCount = querySnapshot.size;
            self.isDataNull = true
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              _data["docId"] = doc.id;
              const date = new Date(_data.regDate.seconds * 1000);
              _data.regDate = getDate(date);
              if (_data.lastDate != null) {
                const lastDate = new Date(_data.lastDate.seconds * 1000);
                _data.lastDate = getDate(lastDate);
              }
              _data.name = _data.userInfo.name
              _data.visitCount2 = _data.visitCount
              _data.visitCount = '<p class="blue-color">' + _data.visitCount + "회차" + '</p>'
              _data.id = _data.userInfo.id
              if (!_data.isProgress) {
                _data.action = '<img src="/images/btn_evaluation_tools_add.png" alt="img" class="action-btn" onclick="addEvaluationTools(\'' + doc.id + '\')">'
              } else {
                _data.action = '<img src="/images/btn_evaluating.png" alt="img" class="action-btn" onclick="continueEvaluationTools(\'' + doc.id + '\')">'

              }
              self.data.rows.push(_data);
            });
          })
    },
    addEvaluationTools(value) {
      const self = this;
      const selData = self.data.rows.find((element) => element.docId === value)
      self.selectPatientInfo = {
        patientKey: selData.docId,
        name: selData.userInfo.name,
        uid: selData.userInfo.uid,
        patientNo: selData.patientNo,
        id: selData.userInfo.id,
        visitCount: selData.visitCount2
      }
      self.isShowToolsModal = true
    },

    continueEvaluationTools(value) {
      const self = this;
      const selData = self.data.rows.find((element) => element.docId === value)
      self.selectPatientInfo = {
        patientKey: selData.docId,
        fbDocId: selData.evaluationId
      }
      self.isShowContinueModal = true;
    },

    callBackModal: function (index, isShowModal, key) {
      const self = this;
      if (index === 2) {
        self.isShowToolsModal = false
        if (isShowModal) {
          self.goDetailChart(key)
        }
      }
      if (index === 10) {
        self.isShowContinueModal = false
        if (isShowModal) {
          this.getDataPatientTable();
        }
      }
    },

    getSelectMainValue(value) {
      const self = this;
      self.fieldName = value
    },
    goDetailChart(key) {
      this.$router.push({path: `/therapist/doEvaluation/${key}`});
    },
  }
}
</script>

<style scoped>
.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.sub-frame {
  padding: 40px 15px;
}

.search-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px 20px;
  width: 100%;
  height: 116px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 12px;
}

/deep/ .mdb-datatable thead th {
  font-size: 16px !important;
}

.table-frame {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  background: #fff;
}

.search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.basic-search {
  display: flex;
  flex-direction: row;
}

.diagnosis-search {
  display: flex;
  flex-direction: row;
}

/deep/ .diagnosis-search .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
}

/deep/ .diagnosis-search .form-control::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #B1B1B1 !important;
}


.search-count {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 10px;
}

/deep/ .search-select .md-form {
  width: 145px;
  margin: 0 10px 0 0;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .diagnosis-select .md-form {
  width: 268px;
  margin: 0;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

/deep/ .dropdown-content li > span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.search-input {
  width: 282px;
  padding-left: 30px;
  background: #EDEDF1;
  font-size: 16px;
}

.icon-input {
  position: relative;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-btn {
  width: 78px;
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
}

.add-patient {
  width: 145px;
  background-color: #24C299;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-left: auto;
  cursor: pointer;
}

.add-patient img {
  width: 18px;
  height: 18px;
}

.data-null {
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}

.data-null img {
  width: 131px;
  height: 105px;
  margin-bottom: 20px;
}

.data-null p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}

/deep/ .mdb-datatable thead th {
  font-size: 16px;
  font-weight: 700;
}

/deep/ .mdb-datatable tbody td:nth-child(3n) {
  color: #8D8D8D;
  font-weight: 400;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F1F6FD;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(227, 238, 252, 1);
}


/deep/ .action-btn {
  width: 145px;
  height: 40px;
  cursor: pointer;
  z-index: 100;

}

/deep/ .blue-color {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 0;
}


/deep/ td {
  vertical-align: middle;
  font-size: 16px !important;
}

</style>
