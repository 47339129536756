<template>
  <div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <table class="paper-table">
          <thead class="fixedHeader">
          <tr>
            <td colspan="6" class="td-task td-weight">Task</td>
            <td colspan="6" class="td-answer td-weight">QOM</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="6" class="td-task">1. 테이블에서 의자를 당겨 꺼내기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_1" v-model="qom.qom_1" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_1" v-model="qom.qom_1" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_1" v-model="qom.qom_1" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_1" v-model="qom.qom_1" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_1" v-model="qom.qom_1" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_1" v-model="qom.qom_1" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_1" v-model="qom.qom_1" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">2. 신문 펼치기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_2" v-model="qom.qom_2" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_2" v-model="qom.qom_2" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_2" v-model="qom.qom_2" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_2" v-model="qom.qom_2" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_2" v-model="qom.qom_2" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_2" v-model="qom.qom_2" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_2" v-model="qom.qom_2" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">3. 신문 넘겨서 원하는 쪽수 찾기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_3" v-model="qom.qom_3" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_3" v-model="qom.qom_3" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_3" v-model="qom.qom_3" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_3" v-model="qom.qom_3" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_3" v-model="qom.qom_3" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_3" v-model="qom.qom_3" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_3" v-model="qom.qom_3" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">4. 파일 폴더 열기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_4" v-model="qom.qom_4" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_4" v-model="qom.qom_4" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_4" v-model="qom.qom_4" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_4" v-model="qom.qom_4" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_4" v-model="qom.qom_4" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_4" v-model="qom.qom_4" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_4" v-model="qom.qom_4" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">5. 파일 넘기기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_5" v-model="qom.qom_5" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_5" v-model="qom.qom_5" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_5" v-model="qom.qom_5" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_5" v-model="qom.qom_5" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_5" v-model="qom.qom_5" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_5" v-model="qom.qom_5" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_5" v-model="qom.qom_5" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">6. 양식 작성</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_6" v-model="qom.qom_6" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_6" v-model="qom.qom_6" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_6" v-model="qom.qom_6" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_6" v-model="qom.qom_6" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_6" v-model="qom.qom_6" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_6" v-model="qom.qom_6" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_6" v-model="qom.qom_6" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">7. 종이 접기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_7" v-model="qom.qom_7" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_7" v-model="qom.qom_7" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_7" v-model="qom.qom_7" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_7" v-model="qom.qom_7" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_7" v-model="qom.qom_7" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_7" v-model="qom.qom_7" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_7" v-model="qom.qom_7" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">8. 종이 봉투에 넣기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_8" v-model="qom.qom_8" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_8" v-model="qom.qom_8" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_8" v-model="qom.qom_8" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_8" v-model="qom.qom_8" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_8" v-model="qom.qom_8" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_8" v-model="qom.qom_8" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_8" v-model="qom.qom_8" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">9. 명함 케이스 열기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_9" v-model="qom.qom_9" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_9" v-model="qom.qom_9" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_9" v-model="qom.qom_9" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_9" v-model="qom.qom_9" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_9" v-model="qom.qom_9" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_9" v-model="qom.qom_9" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_9" v-model="qom.qom_9" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">10. 명함 꺼내기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_10" v-model="qom.qom_10" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_10" v-model="qom.qom_10" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_10" v-model="qom.qom_10" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_10" v-model="qom.qom_10" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_10" v-model="qom.qom_10" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_10" v-model="qom.qom_10" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_10" v-model="qom.qom_10" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">11. 앨범 받기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_11" v-model="qom.qom_11" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_11" v-model="qom.qom_11" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_11" v-model="qom.qom_11" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_11" v-model="qom.qom_11" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_11" v-model="qom.qom_11" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_11" v-model="qom.qom_11" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_11" v-model="qom.qom_11" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">12. 앨범 쪽수 넘기기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_12" v-model="qom.qom_12" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_12" v-model="qom.qom_12" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_12" v-model="qom.qom_12" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_12" v-model="qom.qom_12" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_12" v-model="qom.qom_12" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_12" v-model="qom.qom_12" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_12" v-model="qom.qom_12" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">13. 앨범에 사진 끼워 넣기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_13" v-model="qom.qom_13" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_13" v-model="qom.qom_13" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_13" v-model="qom.qom_13" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_13" v-model="qom.qom_13" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_13" v-model="qom.qom_13" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_13" v-model="qom.qom_13" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_13" v-model="qom.qom_13" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td-task">14. 앨범을 검사자에게 돌려주기</td>
            <td colspan="6">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="qom_14" v-model="qom.qom_14" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_14" v-model="qom.qom_14" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_14" v-model="qom.qom_14" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_14" v-model="qom.qom_14" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_14" v-model="qom.qom_14" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="qom_14" v-model="qom.qom_14" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="qom_14" v-model="qom.qom_14" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </mdb-scrollbar>
  </div>
</template>

<script>
import {mdbScrollbar} from "mdbvue";

export default {
  name: "AautQom",
  components: {
    mdbScrollbar
  },
  props: {
    qom: Object,
  }
}
</script>

<style scoped>

.paper-frame {
  overflow: auto;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 62px;
  font-size: 16px;
}

tr > td:nth-child(2) {
  padding-left: 65px;
}

thead {
  background: #F8F8FA;
  color: #272833;
  font-weight: 500 !important;
}

.fixedHeader {
  position: sticky;
  top: 0;
}

.td-task {
  height: 48px !important;
  text-align: left;
  padding-left: 20px;
  font-weight: 400;
}

.td-total {
  text-align: center !important;
  color: #0069CA;
  font-weight: 500;
}

.td-answer {
  height: 48px !important;
  text-align: center;
}

.td-weight {
  font-weight: 500;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 5px 10px;
}

.info-container > input {
  text-align: right;
  font-size: 18px;
  color: #000;
}

.info-container > input::placeholder {
  color: #B1B1B1;
  font-size: 18px;
}

.info-container > p {
  font-size: 16px;
  font-weight: 400;
  color: #8D8D8D;
  margin-left: 5px;
  margin-right: 3px;
}

.radio-flex {
  display: flex;
  align-items: center;
}

/*radio custom*/

.radio-group {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url('../../../../../../assets/images/IA/img_divided_xs_line.png') no-repeat center;
}

.container {
  cursor: pointer;
  width: 46px;
  font-size: 18px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px 0 0;
  padding: 0 !important;
}

.container:last-child {
  margin-right: 0;
}


/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #F8F8FA;
  border-radius: 100px;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
}

.nt-mark {
  width: 86px !important;
  height: 38px !important;
  background-color: #fff;
  border-radius: 4px !important;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
  font-size: 16px !important;
  font-weight: 400;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0069CA;
  color: #fff;
  border: 2px solid #0069CA;
}

.divided-line {
  background: #E1E3E6;
  width: 2px;
  height: 46px;
  margin: 0 20px 0 10px;
}


</style>