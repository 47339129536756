<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Balance</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-guide-frame">
      <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
      <p class="paper-guide">Balance에 대한 설명 <span class="blue-btn" @click="isPopUp = true">더보기</span></p>
      <div class="pop-up" v-if="isPopUp">
        <div class="text">
          <div class="text-group">
            <div class="w106">Normal</div>
            <div class="w106 color-gray">
              <p>Static</p>
              <p>Dynamic</p>
            </div>
            <div class="w446">
              <p>도움이나 지지 없이 균형을 꾸준히 유지할 수 있다.</p>
              <p style="white-space: nowrap">최대저항을 이겨낼 수 있으며, 모든 방향으로 체중이동을 할 수 있다.</p>
            </div>
          </div>
          <div class="text-group">
            <div class="w106">Good</div>
            <div class="w106 color-gray">
              <p>Static</p>
              <p>Dynamic</p>
            </div>
            <div class="w446">
              <p>도움이나 지지 없이 균형을 유지할 수 있다.</p>
              <p>중등도의 저항을 이겨낼 수 있다: 땅에 떨어진 물건을 집는 동안 균형을 유지할 수 있다.</p>
            </div>
          </div>
          <div class="text-group">
            <div class="w106">Fair</div>
            <div class="w106 color-gray">
              <p>Static</p>
              <p>Dynamic</p>
            </div>
            <div class="w446">
              <p>무엇인가를 붙잡은 상태에서 균형을 유지할 수 있다.</p>
              <p>최소한의 저항을 이겨낸다: 머리나 몸통이 회전하는 동안 균형을 유지할 수 있다.</p>
            </div>
          </div>
          <div class="text-group">
            <div class="w106">Poor</div>
            <div class="w106 color-gray">
              <p>Static</p>
              <p>Dynamic</p>
            </div>
            <div class="w446">
              <p>무엇인가 잡거나 보조를 필요로 한다.</p>
              <p>어떠한 외부 저항도 이겨낼 수 없거나 균형을 바로 잃는다.</p>
            </div>
          </div>
        </div>
        <img src="@/assets/images/IA/btn_close.png" alt="" @click="isPopUp= false">
      </div>
    </div>
    <mdb-scrollbar class="scroll-frame" width="100%">
      <div class="paper-frame">
        <table class="paper-table">
          <tbody>
          <tr>
            <td colspan="6" class="paper-topic">Sitting(앉은 자세의 균형)</td>
            <td colspan="6" class="paper-topic">Standing(선 자세의 균형)</td>
          </tr>
          <tr>
            <td colspan="3" class="paper-item">Static</td>
            <td colspan="3" class="paper-item">Dynamic</td>
            <td colspan="3" class="paper-item">Static</td>
            <td colspan="3" class="paper-item">Dynamic</td>
          </tr>
          <tr>
            <td colspan="3" class="btn-box">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="balance.sitting.static" v-model="balance.sitting.static" :value="'P'">
                  <span class="checkmark">P</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.sitting.static" v-model="balance.sitting.static" :value="'F'">
                  <span class="checkmark">F</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.sitting.static" v-model="balance.sitting.static" :value="'G'">
                  <span class="checkmark">G</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.sitting.static" v-model="balance.sitting.static" :value="'N'">
                  <span class="checkmark">N</span>
                </label>
              </div>
            </td>
            <td colspan="3" class="btn-box">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="balance.sitting.dynamic" v-model="balance.sitting.dynamic" :value="'P'">
                  <span class="checkmark">P</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.sitting.dynamic" v-model="balance.sitting.dynamic" :value="'F'">
                  <span class="checkmark">F</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.sitting.dynamic" v-model="balance.sitting.dynamic" :value="'G'">
                  <span class="checkmark">G</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.sitting.dynamic" v-model="balance.sitting.dynamic" :value="'N'">
                  <span class="checkmark">N</span>
                </label>
              </div>
            </td>
            <td colspan="3" class="btn-box">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="balance.standing.static" v-model="balance.standing.static" :value="'P'">
                  <span class="checkmark">P</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.standing.static" v-model="balance.standing.static" :value="'F'">
                  <span class="checkmark">F</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.standing.static" v-model="balance.standing.static" :value="'G'">
                  <span class="checkmark">G</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.standing.static" v-model="balance.standing.static" :value="'N'">
                  <span class="checkmark">N</span>
                </label>
              </div>
            </td>
            <td colspan="3" class="btn-box">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="balance.standing.dynamic" v-model="balance.standing.dynamic" :value="'P'">
                  <span class="checkmark">P</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.standing.dynamic" v-model="balance.standing.dynamic" :value="'F'">
                  <span class="checkmark">F</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.standing.dynamic" v-model="balance.standing.dynamic" :value="'G'">
                  <span class="checkmark">G</span>
                </label>
                <label class="container">
                  <input type="radio" name="balance.standing.dynamic" v-model="balance.standing.dynamic" :value="'N'">
                  <span class="checkmark">N</span>
                </label>
              </div>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
  </div>
</template>

<script>

import {mdbScrollbar} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "BasicBalance",
  components: {
    mdbScrollbar
  },
  props: {
    fbDocId: String,
    balance: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      tools: {
        balance: {}
      },
      isShowModal: false,
      isPopUp: false,
    };
  },
  methods: {
    async tempSave() {
      const self = this;
      self.tools.balance = self.balance;
      self.tools.balance['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'balance', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (JSON.stringify(self.balance).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      self.btnConfirm()
    },
    btnConfirm() {
      const self = this;
      self.tools.balance = self.balance;
      self.tools.balance['state'] = 2;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'balance', 2)
          });
    },
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}
.blue-btn {
  color: #0069CA;
  cursor: pointer;
}
.pop-up {
  padding: 23px 20px;
  position: absolute;
  width: 732px;
  height: 328px;
  left: 167px;
  transform: translateY(55%);
  background: #FFFFFF;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 50;
  display: flex;
}

.pop-up .text {
  width: 658px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.text-group {
  display: flex;
  /*align-items: center;*/
  margin-top: 10px;
}
.text-group > :first-child {
  height: 100%;
  margin-top: 12px;
}
.w106 {
  width: 106px;
}
.w446 {
  width: 446px;
}
.color-gray {
  color: #8D8D8D;
}
.pop-up .text h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.pop-up img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}
.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 56px;
  font-size: 16px;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}


.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}


.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
  font-size: 14px;
}

.paper-item {
  padding: 6px 20px;
  font-weight: 500;
  background: #F8F8FA;
}

td {
  height: 48px;
  text-align: center;
}

/*radio custom*/
.btn-box {
  height: 78px;
}
.btn-box:last-child {
  border-right: 0;
}
.radio-group {
  height: 48px;
  width: 261px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-right: 2px solid #D4D4D8;
}
td:last-child .radio-group {
  border: 0;
}
.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
  width: 53px;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #B1B1B1;
  width: 53px;
  height: 48px;
  background: #F1F6FD;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  color: #0069CA;
  background: #FFFFFF;
  border: 2px solid #0069CA;
}

</style>
