<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        <span style="color: #0069CA">Fugl-Meyer</span> 표준화 평가 결과
      </h5>
    </div>
    <div class="modal-center">
      <div class="data-group">
        <p class="data-label">총 점수</p>
        <div class="data-disabled">
          <p class="result-p result-a">{{ totalScore }}점</p>
        </div>
        <p class="data-label label-right">/&nbsp;128만점</p>
      </div>
      <div class="data-group">
        <p class="data-label">합격률</p>
        <div class="data-disabled">
          <p class="result-p result-b">{{ totalPro }}%</p>
        </div>
        <p class="data-label label-right">/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;100%</p>
      </div>

      <div class="p-guide">
        <strong style="color: #E14F4E;" v-if="totalPro < 90">표준화를 완료하지 못했습니다.<br/>
          다시한번 도전해주세요.</strong>
        <strong style="color: #0069CA;" v-else>표준화를 완료했습니다.<br/>
          이제 환자를 평가할 수 있습니다.</strong>
        <p>※ 합격 기준 : 128점 만점에서 116점으로 90%이상</p>
      </div>

    </div>
    <div class="modal-footer">
      <!--      <button class="custom-btn-box-white btn-short" @click="btnCancel">닫기</button>-->
      <button class="primary-blue custom-btn-box btn-restart" @click="btnConfirm(1)" v-if="totalPro < 90">재도전</button>
      <button class="primary-blue custom-btn-box btn-confirm" @click="btnConfirm(2)" v-else>결과 저장</button>
    </div>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "FuglMeyerEvaluationFailDialog",
  props: {
    totalPartAScore: Number,
    totalPartBScore: Number,
    paperData: Object,
    paperIndex: Number,
    paperAllData: Object,
  },
  data() {
    return {
      fbCollection: 'standardization',
      fbUid: this.$store.state.user.uid,
      totalScore: 0,
      totalPro: 0,
      fm: {}
    }
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    dataInit() {
      const self = this;
      self.totalScore = this.totalPartAScore + this.totalPartBScore;
      self.totalPro = ((self.totalScore / 128) * 100).toFixed(1);
    },
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 0)
    },
    btnConfirm: async function (state) {
      const self = this;
      self.paperAllData.fm.index = self.paperIndex + 1;
      self.paperAllData.fm.state = state;
      self.paperAllData.fm.papers[self.paperIndex] = self.paperData;
      self.paperAllData.fm.papers[self.paperIndex]['score'] = self.totalScore;
      self.paperAllData.fm.papers[self.paperIndex]['pro'] = self.totalPro;
      self.paperAllData.fm.papers[self.paperIndex]['state'] = 1;

      if(state === 1){
        self.paperAllData.fm.papers[self.paperIndex + 1] = {
          fm_001: null, fm_002: null, fm_003: null, fm_004: null, fm_005: null, fm_006: null, fm_007: null, fm_008: null, fm_009: null, fm_010: null,
          fm_011: null, fm_012: null, fm_013: null, fm_014: null, fm_015: null, fm_016: null, fm_017: null, fm_018: null, fm_019: null, fm_020: null,
          fm_021: null, fm_022: null, fm_023: null, fm_024: null, fm_025: null, fm_026: null, fm_027: null, fm_028: null, fm_029: null, fm_030: null,
          fm_031: null, fm_032: null, fm_033: null, fm_034: null, fm_035: null, fm_036: null, fm_037: null, fm_038: null, fm_039: null, fm_040: null,
          fm_041: null, fm_042: null, fm_043: null, fm_044: null, fm_045: null, fm_046: null, fm_047: null, fm_048: null, fm_049: null, fm_050: null,
          fm_051: null, fm_052: null, fm_053: null, fm_054: null, fm_055: null, fm_056: null, fm_057: null, fm_058: null, fm_059: null, fm_060: null,
          fm_061: null, fm_062: null, fm_063: null, fm_064: null, fm_065: null, fm_066: null, fm_067: null, fm_068: null, fm_069: null, fm_070: null,
          fm_071: null, fm_072: null, fm_073: null, fm_074: null, fm_075: null, fm_076: null, fm_077: null, fm_078: null, fm_079: null, fm_080: null,
          fm_081: null, fm_082: null, fm_083: null, fm_084: null, fm_085: null, fm_086: null, fm_087: null, fm_088: null, fm_089: null, fm_090: null,
          fm_091: null, fm_092: null, fm_093: null, fm_094: null, fm_095: null, fm_096: null, fm_097: null, fm_098: null, fm_099: null, fm_100: null,
          fm_101: null, fm_102: null, fm_103: null, fm_104: null, fm_105: null, fm_106: null, fm_107: null, fm_108: null, fm_109: null, fm_110: null,
          fm_111: null, fm_112: null, fm_113: null, fm_114: null, fm_115: null, fm_116: null, fm_117: null, fm_118: null, fm_119: null, fm_120: null,
          fm_121: null, fm_122: null, fm_123: null, fm_124: null, fm_125: null, fm_126: null, fm_127: null, fm_128: null,
          score: 0,
          pass: 0,
          state: 0,
        };
      }

      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbUid)
          .set(self.paperAllData, {merge: true})
          .then(async () => {
            if (state === 1) {
              alert('재도전합니다.');
            }
            self.$emit('callBackModal', 1)
          });
    },
  }
}
</script>

<style scoped>

button, strong {
  font-size: 16px !important;
}

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

/deep/ .modal-header {
  border-bottom: 0;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 60px;
  background: #F8F8FA;
}

.data-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.data-total {
  border: 2px solid #0069CA;
  background: #fff !important;
}

.data-group > p:nth-child(1) {
  width: 120px;
}

.data-label {
  color: #8d8d8d;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.black-label {
  color: #000;
}

.label-right {
  width: 75px;
  margin-left: 10px;
  text-align: right;
}

.data-disabled {
  width: 245px;
  height: 40px;
  color: #000;
  font-weight: 400;
  background: #EDEDF1;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.result-p {
  width: 274px;
  font-size: 16px;
  text-align: right;
}

.p-guide {
  margin-top: 20px;
}

.p-guide > p {
  color: #E14F4E;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 14px;
  line-height: 36px;
  background-color: white;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0125em;
  color: rgba(24, 24, 25, 0.9);
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #E14F4E;
  margin-bottom: 20px;
  margin-top: 8px;
}

.btn-confirm {
  width: 84px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}

.btn-restart {
  background: #E14F4E !important;
  width: 84px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}


</style>
