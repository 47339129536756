<template>
  <div class="my-frame">
    <div class="user-info-frame">
      <div class="modal-cover" v-show="isShowModal"></div>
      <h5 class="title">회원정보</h5>
      <p class="guide">‘ <strong>*</strong> ’ 항목은 입력 필수 항목입니다.</p>
      <div class="line"></div>
      <div class="info-container">
        <h5>아이디<strong>*</strong></h5>
        <input type="text" v-model="userInfo.email" placeholder="이메일 입력" class="custom-input-box w444 disabled" disabled/>
      </div>
      <div class="info-container">
        <h5>휴대폰번호<strong>*</strong></h5>
        <input type="tel" v-model="userInfo.mobile" placeholder="‘-’없이 번호 입력" maxlength="11" class="custom-input-box w444"/>
      </div>
      <div class="info-container">
        <h5>비밀번호</h5>
        <button class="password-btn" @click="onChangePw">비밀번호 변경</button>
      </div>
      <div class="info-container">
        <h5>이름<strong>*</strong></h5>
        <input type="text" v-model="userInfo.name" placeholder="이름 입력" class="custom-input-box w444"/>
      </div>
      <div class="info-container">
        <h5>생년월일<strong>*</strong></h5>
        <mdb-date-picker-2 class="input-box bg-white w444 search-select2 " outline placeholder="날짜 선택" type="text" size="lg" v-model="userInfo.birth" title="생년월일 선택" :options="options"/>
      </div>
      <div class="info-container">
        <h5>성별<strong>*</strong></h5>
        <mdb-form-inline style="width: 444px">
          <mdb-input type="radio" id="gender-1" name="groupOfMaterialRadios2" radioValue="남성" v-model="userInfo.gender"
                     label="남성"/>
          <mdb-input type="radio" id="gender-2" name="groupOfMaterialRadios2" radioValue="여성" v-model="userInfo.gender"
                     label="여성"/>
        </mdb-form-inline>
      </div>
      <div class="info-container">
        <h5>소속병원 이름</h5>
        <input type="text" v-model="userInfo.hospitalInfo.name" placeholder="병원 이름 입력" class="custom-input-box w444"/>
      </div>
      <div class="info-container">
        <h5>소속병원 형태<strong>*</strong></h5>
        <mdb-select
            :scroll=false
            outline
            v-model="hospitalTypeOptions"
            placeholder="선택"
            @getValue="getHospitalTypeValue"
            class="search-select-width"/>
      </div>
      <div class="info-container">
        <h5>소속병원 위치<strong>*</strong></h5>
        <mdb-select
            :scroll=false
            outline
            v-model="hospitalLocationOptions"
            placeholder="선택"
            @getValue="getHospitalLocationValue"
            class="search-select-width"/>
      </div>
      <div class="info-container">
        <h5>직업</h5>
        <mdb-select
            :scroll=false
            outline
            v-model="jobOptions"
            placeholder="선택"
            @getValue="getJobValue"
            class="search-select-width"/>
      </div>
      <div class="info-container">
        <h5>근무년수</h5>
        <mdb-select
            :scroll=false
            outline
            v-model="workingYearOptions"
            placeholder="선택"
            @getValue="getWorkingYearValue"
            class="search-select-width"/>
      </div>
      <div class="line-thin"></div>
      <div class="btn-line">
        <button class="next-btn" @click="updateUserInfo">회원정보 변경</button>
        <button class="withdrawal-btn" @click="withdrawal">회원탈퇴</button>
      </div>

      <TherapistInfoEditConfirmDialog v-if="isShowModal" @callBackModal="callBackModal"/>
    </div>
  </div>
</template>

<script>

import {mdbDatePicker2, mdbFormInline, mdbInput, mdbSelect} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import TherapistInfoEditConfirmDialog from "./TherapisInfoEditConfirmDialog";

export default {
  name: "UserInfoEditPage",
  components: {
    TherapistInfoEditConfirmDialog,
    mdbInput,
    mdbFormInline,
    mdbDatePicker2,
    mdbSelect
  },
  data() {
    return {
      fbCollection: 'therapists',
      fbUid: this.$store.state.user.uid,
      yearOptions: [],
      monthOptions: [],
      dayOptions: [],
      hospitalTypeOptions: [],
      hospitalLocationOptions: [],
      jobOptions: [],
      workingYearOptions: [],
      isShowModal: false,
      birthYear: '',
      birthMonth: '',
      birthDay: '',
      userInfo: {
        birth: '',
        email: '',
        gender: '',
        hospitalInfo: {
          job: '',
          location: '',
          name: '',
          type: '',
          workingYear: ''
        },
        mobile: '',
        name: '',
      },
      userName: '',
      options: {
        month: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월'
        ],
        week: ['일', '월', '화', '수', '목', '금', '토'],
        color: {
          header: 'info',
          headerText: 'white',
          checkedDay: 'info'
        },
        buttons: {
          ok: '확인',
          cancel: '취소',
          clear: '초기화'
        },
      },
    }
  },
  async mounted() {
    this.initData();
  },
  methods: {
    async updateUserInfo() {
      const self = this
      const db = firebase.firestore();
      const firebaseCurrentUser = firebase.auth().currentUser

      const defRef = db.collection(self.fbCollection);
      await defRef.doc(self.fbUid)
          .update(self.userInfo)
          .then(() => {
            if (self.userName !== self.userInfo.name) {
              firebaseCurrentUser.updateProfile({
                displayName: self.userInfo.name
              })
                  .then(() => {
                    document.getElementsByClassName('user-name')[0].innerHTML = self.userInfo.name
                    self.isShowModal = true;
                  })
                  .catch((e) => {
                    console.log('error 발생 1', e)
                  })
            }
          })
          .catch((e) => {
            console.log('error 발생 2', e)
          })

      //TODO 각 콜랙션마다 존재하는 이름도 찾아서 변경
      if (self.userName !== self.userInfo.name) {
        const qs = await db.collection('evaluations').where('therapistInfo.uid', '==', self.fbUid).get()
        if (!qs.size) return

        console.log('qs', qs.docs)
        const promises = qs.docs.map(async (doc) => {
          await db.collection('evaluations')
              .doc(doc.id)
              .set({therapistInfo: {name: self.userInfo.name}}, {merge: true})
        })
        await Promise.all(promises)

        const qs2 = await db.collection('patients').where('therapistUid', '==', self.fbUid).get()
        if (!qs2.size) return

        const promises2 = qs2.docs.map(async (doc) => {
          await db.collection('evaluations')
              .doc(doc.id)
              .set({therapistName: self.userInfo.name}, {merge: true})
        })
        await Promise.all(promises2)

      }
    },
    initData() {
      const self = this
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      defRef.doc(self.fbUid)
          .get()
          .then((querySnapshot) => {
            self.userInfo = querySnapshot.data();
            self.userName = self.userInfo.name
            self.compareSelect()
          });
    },
    compareSelect() {
      const self = this
      const hosOp = [
        "1차 병원(의원급)",
        "2차 병원(재활전문병원 및 요양병원급)",
        "3차 병원(종합병원급)"
      ];
      const type = self.userInfo.hospitalInfo.type
      for (let i = 0; i <= hosOp.length; i++) {
        const demo = {}
        demo['value'] = hosOp[i];
        demo['text'] = hosOp[i];
        if (hosOp[i] === type) {
          demo['selected'] = true
        } else {
          demo['selected'] = false
        }
        self.hospitalTypeOptions.push(demo);
      }

      const hosOp2 = [
        "서울 및 경기도",
        "광역시",
        "경상도",
        "전라도",
        "충청도",
        "제주도"
      ];
      const type2 = self.userInfo.hospitalInfo.location
      for (let i = 0; i <= hosOp2.length; i++) {
        const demo = {}
        demo['value'] = hosOp2[i];
        demo['text'] = hosOp2[i];
        if (hosOp2[i] === type2) {
          demo['selected'] = true
        } else {
          demo['selected'] = false
        }
        self.hospitalLocationOptions.push(demo);
      }

      const hosOp3 = [
        "물리치료사",
        "작업치료사"
      ];
      const type3 = self.userInfo.hospitalInfo.job
      for (let i = 0; i <= hosOp3.length; i++) {
        const demo = {}
        demo['value'] = hosOp3[i];
        demo['text'] = hosOp3[i];
        if (hosOp3[i] === type3) {
          demo['selected'] = true
        } else {
          demo['selected'] = false
        }
        self.jobOptions.push(demo);
      }

      const hosOp4 = [
        "1년 미만",
        "1~3년",
        "3~5년",
        "5~10년",
        "10년 이상"
      ];
      const type4 = self.userInfo.hospitalInfo.workingYear
      for (let i = 0; i <= hosOp4.length; i++) {
        const demo = {}
        demo['value'] = hosOp4[i];
        demo['text'] = hosOp4[i];
        if (hosOp4[i] === type4) {
          demo['selected'] = true
        } else {
          demo['selected'] = false
        }
        self.workingYearOptions.push(demo);
      }

    },

    checkValidate() {
      const self = this;
      if (self.userInfo.mobile.trim().length === 0) {
        alert('휴대폰번호를 입력하세요.')
        return false
      } else if (self.userInfo.name.trim().length === 0) {
        alert('이름을 입력하세요.')
        return false
      }

      return true
    },
    getHospitalTypeValue(value) {
      const self = this;
      self.userInfo.hospitalInfo.type = value
    },
    getHospitalLocationValue(value) {
      const self = this;
      self.userInfo.hospitalInfo.location = value
    },
    getJobValue(value) {
      const self = this;
      self.userInfo.hospitalInfo.job = value
    },
    getWorkingYearValue(value) {
      const self = this;
      self.userInfo.hospitalInfo.workingYear = value
    },
    withdrawal() {
      this.$router.push('/therapist/withdrawal')
    },
    onChangePw() {
      this.$router.push('/therapist/rePassword')
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
      }
    },
  }
}
</script>

<style scoped>
.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0 0 30px 0;
}

.my-frame {
  width: 100%;
  background: #fff !important;
}

.user-info-frame {
  padding: 40px 0 60px;
  width: 805px;
  margin: 0 auto;
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
}

strong {
  color: #E14F4E;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #000000;
  margin: 40px 0 35px;
}

.line-thin {
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin: 0 auto;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.info-container input {
  font-size: 16px;
}

.info-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 150px;
  margin-right: 10px;
}

.w444 {
  width: 444px;
}

.w350 {
  width: 350px;
}


/deep/ .search-select .md-form {
  width: 141px;
  margin: 0 10px 0 0;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select-width .md-form {
  width: 444px !important;
  margin: 0;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select:last-child .md-form {
  width: 141px;
  margin: 0;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select2 .md-form {
  margin: 0 !important;
}


/deep/ .search-select2 .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
  height: 40px;
}


/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
  height: 40px;
}

/deep/ .diagnosis-search .form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

.info-container /deep/ .mr-5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-right: 10px !important;
  padding-left: 30px !important;
}

.btn-line {
  text-align: center;
  margin-top: 30px;
  position: relative;
}

.next-btn {
  width: 128px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.prev-btn {
  width: 128px;
  height: 40px;
  margin-right: 10px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.check-double {
  width: 84px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  margin-left: 10px;
}

.disabled {
  background: #F8F8FA;
}

.withdrawal-btn {
  width: 86px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #8D8D8D;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #8D8D8D;
  position: absolute;
  right: 0;
}

.password-btn {
  width: 444px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
}
</style>

