export default [
    {
        label: '번호',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '구분',
        field: 'type',
        sort: true
    },
    {
        label: '제목',
        field: 'title',
        sort: true
    },
    {
        label: '등록일',
        field: 'regDate',
        sort: false
    },
    {
        label: '다운로드 수',
        field: 'download',
        sort: false
    },
];
