<template>
  <div>
    <div class="nav-tools-bar">
      <div class="view-layout">
        <hr>
        <div>
          <div class="nav-tools-item" @click="active.tool_tab_1 = !active.tool_tab_1">
            <p v-html="selectedTool.tool_tab_1"></p>
            <img src="@/assets/images/IA/icon_down_xl.png" alt="down">
          </div>
          <div class="nav-tool-bar-sub">
            <slide-up-down :active="active.tool_tab_1" :duration="300">
              <div class="tool-tab" @click="firstTab('환자 평가 기록')">
                <p v-html="subTabName.patients.name"></p>
              </div>
              <div class="tool-tab" @click="firstTab('자료실')">
                <p v-html="subTabName.reference.name"></p>
              </div>
            </slide-up-down>
          </div>
        </div>
        <hr>
        <div v-show="selectedTool.tool_tab_1 !== '자료실'">
          <div style="display: flex;">
            <div class="nav-tools-item" @click="active.tool_tab_2 = !active.tool_tab_2">
              <p v-html="selectedTool.tool_tab_2"></p>
              <img src="@/assets/images/IA/icon_down_xl.png" alt="down">
            </div>
            <hr>
          </div>
          <div class="nav-tool-bar-sub">
            <slide-up-down :active="active.tool_tab_2" :duration="300">
              <div class="tool-tab" @click="secondTab('평가 도구 별 점수')">
                <p v-html="subTabName.patients.types.all.name"></p>
              </div>
              <div class="tool-tab" @click="secondTab('평가 도구 별 평균')">
                <p v-html="subTabName.patients.types.tool.name"></p>
              </div>
            </slide-up-down>
          </div>
        </div>
      </div>
    </div>
    <!--    자료실-->
    <div class="sub-frame view-layout">
      <h1 class="title" v-show="!isReferenceDetail" v-html="toolName"></h1>
      <div v-if="isReference && this.$route.params.id === 'ref'">
        <div class="search-frame">
          <div class="search-box">
            <div class="space"></div>
            <mdb-select
                :scroll=false
                outline
                v-model="basicOptions"
                placeholder="제목"
                @getValue="getSelectMainValue"
                noFoundMessage="자료가 없습니다."
                class="search-select "/>
            <div class="basic-search">
              <div class="icon-input">
                <img src="@/assets/images/IA/icon_search.png" alt="search">
                <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
              </div>
              <button class="custom-btn-box search-btn">검색</button>
            </div>
          </div>
        </div>
        <div class="table-container">
          <mdb-datatable-2
              class="text-center"
              borderless
              hover
              fullPagination
              @selected="onSelected($event)"
              noFoundMessage="자료가 없습니다."
              :searching="{value: search, field: fieldName} "
              v-model="data"/>
        </div>
      </div>

      <div v-if="isReferenceDetail && this.$route.params.id === 'ref'">
        <div class="search-container">
          <div style="margin-left: auto">
            <button class="table-btn" @click="prevBoard">
              <img src="@/assets/images/IA/icon_prev_post.png" alt="list">
              이전글
            </button>
            <button class="table-btn" @click="nextBoard">
              <img src="@/assets/images/IA/icon_next_post.png" alt="list">다음글
            </button>
            <button class="table-btn" @click="refList">
              <img src="@/assets/images/IA/icon_list_view.png" alt="list">목록
            </button>
          </div>
        </div>
        <div class="table-container">
          <div class="ref-title-box">
            <p class="ref-map">데이터 다운로드 〉자료실</p>
            <h4 class="ref-title">{{ refTitle }}</h4>
            <div class="ref-container">
              <h4 class="ref-date">{{ refDate }}</h4>
              <h4 class="ref-download">다운로드 수 {{ refDownload.toLocaleString('ko-KR') }}</h4>
            </div>

          </div>
          <div class="ref-file">
            <h5 class="ref-file-title">첨부파일 다운로드</h5>
            <img src="@/assets/images/IA/paper-clip.png" alt="clip">
            <p><a :href="refUrl" target="_blank" @click="download">{{ refFile }}</a></p>
          </div>
          <div class="ref-desc-box">
            <mdb-input type="textarea" id="editor" class="textarea-desc" outline v-model="refDesc" readOnly :rows="10"/>
          </div>
        </div>
      </div>
      <DataUserRecord v-if="this.$route.params.id === 'all'"/>
      <DataToolRecord v-if="this.$route.params.id === 'tool'"/>
    </div>
    <!--    자료실-->
  </div>
</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import {mdbDatatable2, mdbInput, mdbSelect} from "mdbvue";
import selectOptions from "@/assets/data/selectOption_data";
import dataDownload_columns from "@/assets/data/dataDownload_columns";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate, getDateTime} from "@/lib/prettyDate";
import DataUserRecord from "@/views/therapist/menu/dataDownload/DataUserRecord";
import DataToolRecord from "@/views/therapist/menu/dataDownload/DataToolRecord";

export default {
  name: "dataReferenceRoom",
  components: {
    DataToolRecord,
    DataUserRecord,
    mdbSelect,
    mdbDatatable2,
    SlideUpDown,
    mdbInput
  },
  data() {
    return {
      urlId: this.$route.params.id,
      selectedTool: {
        tool_tab_1: '자료실',
        tool_tab_2: '환자 평가 기록',
      },
      subTabName: {
        reference: {
          name: '자료실',
        },
        patients: {
          name: '환자 평가 기록',
          types: {
            all: {
              name: '평가 도구 별 점수',
            },
            tool: {
              name: '평가 도구 별 평균',
            }
          }
        }
      },
      active: {
        tool_tab_1: false,
        tool_tab_2: false,
      },
      fbCollection: 'reference',
      basicOptions: [
        {text: "제목", value: "title"},
        {text: "구분", value: "type"},
      ],
      divisionOptions: selectOptions.division,
      isVideoModal: false,
      search: '',
      selected: null,
      data: {
        columns: dataDownload_columns,
        rows: []
      },
      fieldName: '',
      isShowToolsModal: false,
      toolName: '평가 도구 별 점수',
      isReference: true,
      isReferenceDetail: false,

      refNo: 0,
      refTitle: '',
      refDate: '',
      refDesc: '',
      refFile: '',
      refUrl: '',
      refDownload: 0,
    }
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    download() {
      const self = this;
      const db = firebase.firestore();
      db.collection('reference')
          .doc(self.refDocId)
          .set({download: firebase.firestore.FieldValue.increment(+1)}, {merge: true})
          .then(() => {
            self.refDownload++
            self.selected.download = self.refDownload
          })
    },
    dataInit() {
      const self = this;
      if (self.$route.params.id === 'ref') {
        self.selectedTool.tool_tab_1 = self.subTabName.reference.name
        self.toolName = '자료실'
        self.getReferenceData()
      } else if (self.$route.params.id === 'all') {
        self.selectedTool.tool_tab_1 = self.subTabName.patients.name
        self.selectedTool.tool_tab_2 = self.subTabName.patients.types.all.name
        self.toolName = '평가 도구 별 점수'
      } else if (self.$route.params.id === 'tool') {
        self.selectedTool.tool_tab_1 = self.subTabName.patients.name
        self.selectedTool.tool_tab_2 = self.subTabName.patients.types.tool.name
        self.toolName = '평가 도구 별 평균'
      } else {
        self.selectedTool.tool_tab_1 = self.subTabName.reference.name
        self.toolsDescription = '준비중'
        self.toolName = '데이터가 없습니다.'
      }
    },

    getReferenceData() {
      const self = this;
      self.data.rows.splice(0)
      const db = firebase.firestore();
      let count = 0;
      db.collection(self.fbCollection).orderBy("regDate", "desc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          return
        }
        self.patientCount = querySnapshot.size;
        self.isDataNull = true
        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          count += 1;
          // console.log(_data);
          _data["docId"] = doc.id;
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDate(date);
          const dateTime = new Date(_data.regDate.seconds * 1000);
          _data.regDateTime = getDateTime(dateTime);
          _data.orderNumber = count;
          self.data.rows.push(_data);
        });
      })
    },


    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 2) {
        if (!isShowModal) {
          // const _body = document.getElementsByTagName('body').item(0)
          self.isShowToolsModal = isShowModal
          if (self.isShowToolsModal) {
            // _body.style.overflow = 'hidden';
            window.scrollTo(0, 0);
          } else {
            // _body.style.overflow = 'unset';
          }
        } else {
          self.isShowToolsModal = !isShowModal
          self.goDetailChart()
        }
      }
    },

    showToolsModal() {
      const self = this;
      // const _body = document.getElementsByTagName('body').item(0)
      self.isShowToolsModal = !self.isShowToolsModal
      if (self.isShowToolsModal) {
        // _body.style.overflow = 'hidden';
        window.scrollTo(0, 0);
      } else {
        // _body.style.overflow = 'unset';
      }
    },
    getSelectMainValue(value) {
      this.fieldName = value
    },
    goDetailChart() {
      this.$router.push('/therapist/doEvaluation/qerfadfaa').catch((err) => {
        console.log(err)
      })
    },
    async firstTab(name) {
      const self = this;
      let type = 'ref';
      if (name === '자료실') {
        self.toolName = '자료실'
        self.selectedTool.tool_tab_1 = self.subTabName.reference.name
        self.active.tool_tab_2 = false;
        self.isReference = true
        self.isReferenceDetail = false
        self.getReferenceData()
        if (self.$route.path !== '/therapist/dataReferenceRoom/' + type) {
          await self.$router.push({path: '/therapist/dataReferenceRoom/' + type});
        }
      } else {
        self.selectedTool.tool_tab_1 = self.subTabName.patients.name
        self.selectedTool.tool_tab_2 = self.subTabName.patients.types.all.name
        self.active.tool_tab_2 = true;
      }
      self.active.tool_tab_1 = false;
    },
    async secondTab(name) {
      let type = 'ref';
      const self = this;
      if (name === '평가 도구 별 점수') {
        type = 'all'
        self.toolName = '평가 도구 별 점수'
        self.selectedTool.tool_tab_2 = self.subTabName.patients.types.all.name
        self.isReference = false
        self.isReferenceDetail = false
      } else {
        type = 'tool'
        self.toolName = '평가 도구 별 평균'
        self.selectedTool.tool_tab_2 = self.subTabName.patients.types.tool.name
        self.isReference = false
        self.isReferenceDetail = false
      }
      self.active.tool_tab_1 = false;
      self.active.tool_tab_2 = false;
      if (self.$route.path !== '/therapist/dataReferenceRoom/' + type) {
        await self.$router.push({path: '/therapist/dataReferenceRoom/' + type});
      }
    },

    onSelected(value) {
      const self = this;
      self.isReference = false;
      this.selected = value;
      if (this.selected != null) {
        self.refDocId = self.selected.docId;
        self.refNo = self.selected.orderNumber;
        self.refTitle = self.selected.title;
        self.refDate = self.selected.regDate;
        self.refDesc = self.selected.desc;
        self.refDownload = self.selected.download;
        self.refFile = self.selected.fileName;
        self.refUrl = self.selected.file;
        self.isReferenceDetail = true;
      }
    },

    refList() {
      const self = this;
      self.isReference = true;
      self.isReferenceDetail = false;
    },

    prevBoard() {
      const self = this;
      const ref = self.data.rows;
      let res = ref.filter(it => it.orderNumber === self.refNo - 1);
      if (res.length !== 0) {
        self.refDocId = res[0].selected.docId;
        self.refNo = res[0].orderNumber;
        self.refTitle = res[0].title;
        self.refDate = res[0].regDate;
        self.refDesc = res[0].desc;
        self.refDownload = res[0].download;
        self.refUrl = res[0].file;
        self.refFile = res[0].fileName;
      } else {
        alert('처음 글입니다.');
      }
    },

    nextBoard() {
      const self = this;
      const ref = self.data.rows;
      let res = ref.filter(it => it.orderNumber === self.refNo + 1);
      if (res.length !== 0) {
        self.refDocId = res[0].docId;
        self.refNo = res[0].orderNumber;
        self.refTitle = res[0].title;
        self.refDate = res[0].regDate;
        self.refDesc = res[0].desc;
        self.refDownload = res[0].download;
        self.refUrl = res[0].file;
        self.refFile = res[0].fileName;
      } else {
        alert('마지막 글입니다.');
      }
    },
  }
}
</script>

<style scoped>
.nav-tools-bar {
  background-color: #0078AF;

}

hr {
  width: 1px;
  height: 50px;
  background: #0990CD;
  margin: 0;
  padding: 0;
  border: 0;
}

.nav-tools-bar .view-layout {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 17px;
}

.nav-tools-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 12px;
  position: static;
  width: 250px;
  height: 50px;
  cursor: pointer;
  text-align: center;
}

.nav-tools-item p {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.nav-tools-item img {
  width: 18px;
  height: 18px;
}

.sub-frame {
  padding: 40px 15px;
  background-color: #F8F8FA;
}

.search-frame {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.space {
  width: 585px;
}

.basic-search {
  display: flex;
  flex-direction: row;
}

.division-search {
  display: flex;
  flex-direction: row;
}

/deep/ .division-search .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
}

/deep/ .division-search .form-control::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #B1B1B1 !important;
}

/deep/ .search-select .md-form {
  width: 145px;
  margin: 0 10px 0 0;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .division-select .md-form {
  width: 282px;
  margin: 0;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

/deep/ .dropdown-content li > span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.search-input {
  width: 282px;
  padding-left: 30px;
  background: #EDEDF1;
}

/deep/ .mdb-datatable thead th {
  font-size: 16px !important;
}

/deep/ td {
  vertical-align: middle;
  font-size: 16px !important;
}

.icon-input {
  position: relative;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-btn {
  width: 78px;
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
}

.table-container {
  margin-top: 20px;
  width: 100%;
  background: #fff;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

/deep/ .mdb-datatable td:nth-child(2) {
  text-align: left;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F1F6FD;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(227, 238, 252, 1);
}

/deep/ .mdb-datatable th {
  padding: 0;
  height: 48px;
  vertical-align: middle;
}

/deep/ .mdb-datatable td {
  padding: 0;
  height: 48px;
  vertical-align: middle;
}

/deep/ .mdb-datatable-footer {
  padding: 5px 0 0 0;
  height: 64px;
  vertical-align: middle;
  align-items: center;
}

.tool-tab {
  width: 250px;
  background-color: white;
  text-align: center;
  padding: 8px 12px;
  border: 1px solid #E1E3E6;
  border-top: none;
  cursor: pointer;
  z-index: 100;
  position: relative;
}

.tool-tab p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}


.search-container {
  display: flex;
  flex-direction: row;
}

.table-btn {
  width: 86px;
  height: 40px;
  background: #EDEDF1;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  border: 0;
  margin-left: 10px;
}

.table-btn img {
  text-align: left;
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  margin-right: 4px;
}

.ref-map {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #0069CA;
}

.ref-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #272833;
}

.ref-title-box {
  padding: 23px 32px;
  background: #FFFFFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ref-desc-box {
  background: #F8F8FA;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ref-date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.ref-download {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin-left: 26px;
}

.ref-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #8D8D8D;
  margin: 0;
}

.ref-file {
  background: #F1F6FD;
  padding: 20px 32px;
  display: flex;
  flex-direction: row;
}

.ref-file img {
  width: 18px;
  height: 18px;
  margin-left: 24px;
  margin-right: 8px;
}

.ref-file-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0;
}

.ref-container {
  display: flex;
  flex-direction: row;
}

/deep/ .textarea-desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/deep/ .textarea-desc textarea {
  padding: 23px 32px !important;
  border: 0 !important;
  resize: none;
  margin: 0;
  color: #272833 !important;
  -webkit-text-fill-color: #272833;
  -webkit-opacity: 1;
  overflow: overlay;
}

</style>
