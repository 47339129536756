<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        환자 차트 등록
      </h5>
    </div>
    <div class="modal-center">
      <p class="sub-title">총 환자({{ patientCount }})</p>
      <div class="search-box">
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input type="text" v-model="search" placeholder="환자 이름 혹은 아이디로 검색" class="custom-input-box search-input"
                 v-on:keypress.enter.prevent="getSearchPatient"/>
        </div>
        <button class="custom-btn-box btn-short" @click="getSearchPatient">검색</button>
      </div>
      <p class="guide">
        환자의 직접 가입 또는 치료사의 대리 가입을 본 서비스에 해야 검색이 가능합니다.
      </p>
      <div class="patient-info-line">
        <p class="sub-title">환자 번호 등록</p>
        <p class="patient-name-id">{{ selectedUser }}</p>
      </div>
      <input type="text" disabled class="custom-input-box guide-nick" placeholder="하단 정보를 입력하면 환자 번호가 생성됩니다(예: 평가년월_병원 명_등록순번)"
             v-model="patientInfo.patientNo">
      <div class="search-box">
        <mdb-select outline v-model="yearOptions" placeholder="평가 년도" @getValue="getSelectYearValue" class="search-select "/>
        <mdb-select outline v-model="monthOptions" placeholder="평가 월" @getValue="getSelectMonthValue" class="search-select "/>
      </div>
      <div class="hospital-box">
        <input type="text" class="custom-input-box hospital-input" placeholder="병원명" v-model="patientNoParts.center"
               v-on:keypress.enter.prevent="makeNumber">
        <button class="custom-btn-box btn-short" @click="makeNumber">번호 생성</button>
      </div>
    </div>
    <div class="modal-footer">
      <button class=" custom-btn-box-white btn-short" @click="btnCancel">취소</button>
      <button class="primary-blue custom-btn-box btn-short" @click="btnConfirm">확인</button>
    </div>
    <div class="search-result-frame" v-show="isSearchResult">
      <SearchListView :searchUser="searchUsers[i]" v-for="(a,i) in searchUsers" :key="i" @setUserId="setUserId"/>
    </div>
  </div>
</template>

<script>
import {mdbSelect} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import SearchListView from "@/views/therapist/menu/patientsManage/components/SearchListView";
import selectOption_data from "@/assets/data/selectOption_data";
import {firebaseError} from "@/lib/firebaseError";

export default {
  name: "PatientsAddDialog",
  components: {
    SearchListView,
    mdbSelect,
  },
  props: {
    patientCount: Number,
  },
  data() {
    return {
      fbCollection: 'users',
      fbUid: this.$store.state.user.uid,
      search: '',
      userInfo: {
        id: '',
        name: '',
        uid: '',
      },
      patientInfo: {
        patientNo: '',
        therapistUid: this.$store.state.user.uid,
        visitCount: 0,
      },
      searchUsers: [],
      isSearchResult: false,
      yearOptions: [],
      monthOptions: [
        {text: "01", value: "01"},
        {text: "02", value: "02"},
        {text: "03", value: "03"},
        {text: "04", value: "04"},
        {text: "05", value: "05"},
        {text: "06", value: "06"},
        {text: "07", value: "07"},
        {text: "08", value: "08"},
        {text: "09", value: "09"},
        {text: "10", value: "10"},
        {text: "11", value: "11"},
        {text: "12", value: "12"},
      ],
      selectedUser: '',
      hospitalName: '',
      therapistName: '',
      patientNoParts: {
        year: '',
        month: '',
        center: '',
        no: 0,
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this;
      const now = new Date();	// 현재 날짜 및 시간
      const year = now.getFullYear();	// 연도
      for (let i = year; i >= 2021; i--) {
        let yearData = {}
        yearData['value'] = i - 2000;
        yearData['text'] = i
        self.yearOptions.push(yearData);
      }

      self.getPatientCount()
    },
    getSelectYearValue(value) {
      const self = this;
      if (value !== null) {
        self.patientNoParts.year = value
      }
    },
    getSelectMonthValue(value) {
      const self = this;
      if (value !== null) {
        self.patientNoParts.month = value
      }
    },
    async getSearchPatient_old() {
      const self = this;
      if (self.search === '') {
        alert('검색어를 입력하세요.')
        return
      }
      self.searchUsers.splice(0)

      const db = firebase.firestore();

      const defRef_1 = db.collection(self.fbCollection).where('name', '==', self.search);
      await defRef_1
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size !== 0) {
              querySnapshot.forEach((doc) => {
                const _userInfo = {id: '', name: '', uid: ''};
                _userInfo.id = doc.data().id;
                _userInfo.name = doc.data().name;
                _userInfo.uid = doc.id;
                self.searchUsers.push(_userInfo);
              });
            }
          });

      const defRef_2 = db.collection(self.fbCollection).where('id', '==', self.search);
      await defRef_2
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size !== 0) {
              querySnapshot.forEach((doc) => {
                const _userInfo = {id: '', name: '', uid: ''};
                _userInfo["id"] = doc.data().id;
                _userInfo["name"] = doc.data().name;
                _userInfo["uid"] = doc.id;
                self.searchUsers.push(_userInfo);
              });
            }
          });

      if (self.searchUsers.length === 0) {
        await alert('검색된 데이디터가 없습니다.');
        return;
      }
      self.isSearchResult = true;

    },
    async getSearchPatient() {
      const self = this;
      if (self.search === '') {
        alert('검색어를 입력하세요.')
        return
      }
      self.searchUsers.splice(0)

      const db = firebase.firestore();

      const defRef_1 = db.collection(self.fbCollection)
      // .where('name', '==', self.search);
      await defRef_1
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size !== 0) {
              querySnapshot.forEach((doc) => {
                const _userInfo = {id: '', name: '', uid: ''};
                _userInfo.id = doc.data().id;
                _userInfo.name = doc.data().name;
                _userInfo.uid = doc.id;

                if (_userInfo.name.includes(self.search))
                  self.searchUsers.push(_userInfo);
              });
            }
          });

      const defRef_2 = db.collection(self.fbCollection)
      // .where('id', '==', self.search);
      await defRef_2
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size !== 0) {
              querySnapshot.forEach((doc) => {
                const _userInfo = {id: '', name: '', uid: ''};
                _userInfo["id"] = doc.data().id;
                _userInfo["name"] = doc.data().name;
                _userInfo["uid"] = doc.id;

                if (_userInfo["id"].includes(self.search)) {
                  // eslint-disable-next-line no-empty
                  if (self.searchUsers.find((element) => element.uid === _userInfo.uid)) {
                  } else {
                    self.searchUsers.push(_userInfo);
                  }
                }
              });
            }
          });

      if (self.searchUsers.length === 0) {
        await alert('검색된 데이터가 없습니다.');
        return;
      }

      self.isSearchResult = true;

    },
    setUserId(userInfo) {
      const self = this;
      self.isSearchResult = false;
      self.selectedUser = userInfo.name + '(' + userInfo.id + ')'
      self.userInfo = userInfo
    },
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 0, false)
      self.initialState()
    },
    async btnConfirm() {
      const self = this;
      if (self.selectedUser.trim().length === 0) {
        alert('환자를 검색해주세요.')
        return
      } else if (self.patientInfo.patientNo.trim().length === 0) {
        alert('번호를 생성해주세요.');
        return
      }
      const db = firebase.firestore();
      const _patientInfo = self.patientInfo
      _patientInfo.userInfo = self.userInfo
      _patientInfo.hospitalName = self.hospitalName
      _patientInfo.therapistName = self.therapistName
      _patientInfo.regDate = firebase.firestore.Timestamp.fromDate(new Date())

      await db.collection('patients')
          .add(_patientInfo)
          .then(async (result) => {
            await db.collection('therapists').doc(self.fbUid)
                .update({patientsCount: firebase.firestore.FieldValue.increment(+1)})
                .then(async () => {
                  await db.collection('users').doc(self.userInfo.uid)
                      .update({patientsId: result.id})
                      .then(() => {
                        self.$emit('callBackModal', 0, true, self.patientInfo)
                      })
                      .catch((err) => {
                        firebaseError(err)
                      })
                })
                .catch((err) => {
                  firebaseError(err)
                })
          }).catch((err) => {
            firebaseError(err)
          });

    },
    async getPatientCount() {
      const self = this;
      const db = firebase.firestore();
      await db.collection("therapists")
          .doc(self.fbUid).get().then(async (querySnapshot) => {
            const _t = querySnapshot.data();
            self.patientNoParts.no = _t.patientsCount;
            self.hospitalName = _t.hospitalInfo.name;
            self.therapistName = _t.name;
          });
    },

    makeNumber() {
      const self = this;
      if (self.patientNoParts.year == null ||
          self.patientNoParts.year === '' ||
          self.patientNoParts.month === '' ||
          self.patientNoParts.center === '') {
        alert('항목을 모두 입력해주세요..')
        return
      }

      self.patientInfo.patientNo = self.patientNoParts.year
          + self.patientNoParts.month
          + '_' + self.patientNoParts.center
          + '_' + (self.patientNoParts.no + 1);
    },
    initialState() {
      const self = this;
      self.search = ''
      self.userInfo = {
        id: '',
        name: '',
        uid: '',
      }
      self.patientInfo = {
        patientNo: '',
        therapistUid: this.$store.state.user.uid,
        visitCount: 0,
      }
      self.searchUsers = []
      self.isSearchResult = false
      self.yearOptions = []
      self.monthOptions = selectOption_data.monthly
      self.selectedUser = ''
      self.hospitalName = ''
      self.therapistName = ''
      self.patientNoParts = {
        year: '',
        month: '',
        center: '',
        no: '',
      }
      this.init();
    },
  }
}
</script>

<style scoped>

p, button {
  font-size: 16px !important;
}

.modal-container {
  position: absolute;
  width: 580px;
  height: 502px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 20px;
  background: #F8F8FA;
}

.icon-input {
  position: relative;
  margin-right: 12px;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.hospital-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.search-input {
  width: 444px;
  padding-left: 30px;
  background: #FFFFFF;
}

.hospital-input {
  width: 444px;
  margin-right: 12px;
}

.patient-info-line {
  display: flex;
  flex-direction: row;
}

.patient-name-id {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #0069CA;
  margin: 0 0 0 auto;
}

.sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
}


.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 16px;
  line-height: 36px;
  font-weight: 500 !important;
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #E14F4E;
  margin-bottom: 20px;
  margin-top: 8px;
}

.search-select {
  flex-grow: 1;
  background: #FFFFFF;
}

.search-select:first-child {
  margin-right: 9px;
}

/deep/ .search-select .md-form {
  width: 100% !important;
}

/deep/ .search-select .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
}

/deep/ .search-select .form-control::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #B1B1B1 !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
}

.guide-nick {
  padding: 13px 8px;
  width: 100%;
  height: 40px;
  background: #EDEDF1;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin-bottom: 10px;
}

.search-result-frame {
  position: absolute;
  width: 444px;
  max-height: 444px;
  overflow: auto;
  left: 20px;
  top: 180px;
  background: #FFFFFF;
  border: 2px solid #0069CA;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 0 5px;
}

.search-result-item {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  align-items: center;
  cursor: pointer;
}

.search-result-item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.info-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #000000;
  margin: 0;
}

.info-id {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #999999;
  margin: 0;
}


</style>
