<template>
  <div class="paper-main-frame" v-if="bbs">
    <h1 class="paper-title">Berg Balance Scale(BBS)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="score">
      <div class="score-text">
        <p class="first-box">점수</p>
        <p class="second-box">총점(56점)</p>
      </div>
      <div class="question">
        <div class="first-box">
          <div class="w55 checkmark block" v-if="bbs.bbs_1 === null">1</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_1 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_2 === null">2</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_2 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_3 === null">3</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_3 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_4 === null">4</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_4 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_5 === null">5</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_5 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_6 === null">6</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_6 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_7 === null">7</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_7 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_8 === null">8</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_8 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_9 === null">9</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_9 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_10 === null">10</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_10 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_11 === null">11</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_11 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_12 === null">12</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_12 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_13 === null">13</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_13 }}</div>
          <div class="w55 checkmark block" v-if="bbs.bbs_14 === null">14</div>
          <div class="w55 checkmark block-white" v-else>{{ bbs.bbs_14 }}</div>
        </div>
        <div class="checkmark block" style="margin-left: 10px" v-if="
        bbs.bbs_1 === null && bbs.bbs_2 === null && bbs.bbs_3 === null && bbs.bbs_4 === null &&  bbs.bbs_5 === null && bbs.bbs_6 === null && bbs.bbs_7 === null && bbs.bbs_8 === null &&
        bbs.bbs_9 === null && bbs.bbs_10 === null && bbs.bbs_11 === null &&bbs.bbs_12 === null && bbs.bbs_13 === null && bbs.bbs_14 === null">총점
        </div>
        <div class="checkmark block-white" style="margin-left: 10px" v-else>
          {{ bbs.bbs_1 + bbs.bbs_2 + bbs.bbs_3 + bbs.bbs_4 + bbs.bbs_5 + bbs.bbs_6 + bbs.bbs_7 + bbs.bbs_8 + bbs.bbs_9 + bbs.bbs_10 + bbs.bbs_11 + bbs.bbs_12 + bbs.bbs_13 + bbs.bbs_14 }}
        </div>
      </div>
    </div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <div>
          <table class="paper-table">
            <tbody>
            <tr>
              <td colspan="12" class="paper-topic">1. 앉은 자세에서 일어나기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 일어서시오, 될 수 있으면 손을 사용하지 않고 일어서십시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">손을 사용하지 않고일어서서 안정된 자세를 유지할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="bbs.bbs_1" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">손을 사용하여 스스로 설 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="bbs.bbs_1" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">일어서기를 몇 번 시도한 후 손을 사용하여 설 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="bbs.bbs_1" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">일어서기 위해 또는 안정을 유지하기 위해서는 최소한의 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="bbs.bbs_1" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">일어서기 위해 중간 정도 또는 최대한의 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="bbs.bbs_1" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">2. 잡지 않고 서있기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 아무 것도 잡지 말고 2분간 서 있으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안전하게 혼자서 2분 동안 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="bbs.bbs_2" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">옆에서 지켜봐 주면 2분 동안 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="bbs.bbs_2" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">잡지않고 30초 동안 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="bbs.bbs_2" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">잡지않고 30초 동안 서 있기 위해서는 여러 번의 시도가 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="bbs.bbs_2" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">도움없이는 30초 동안 서있을 수 없다.
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="bbs.bbs_2" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">3. 의자의 등받이에 기대지 않고 바른 자세로 앉기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 2분 동안 팔짱을 낀 채로 등을 등받이에 대지 않고 앉으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">2분 동안 안전하고 확실하게 앉을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="bbs.bbs_3" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">옆에서 지켜봐 주면 2분 동안 앉아있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="bbs.bbs_3" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">30초 동안 앉아 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="bbs.bbs_3" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">10초 동안 앉아 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="bbs.bbs_3" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">도움 없이는 10초 동안 앉아 있을 수 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="bbs.bbs_3" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">4. 선 자세에서 앉기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: (의자에)앉으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">손을 거의 사용하지 않고(또는 손을 조금 사용하여) 안전하게 앉는다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="bbs.bbs_4" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            <tr>
              <td colspan="10" class="paper-question">뭔가를 붙들어야 천천히 앉을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="bbs.bbs_4" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">앉을 때 다리 뒷부분을 의자에 기대고 천천히 앉는다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="bbs.bbs_4" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자 않기는 하지만 털썩 주저앉는다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="bbs.bbs_4" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">앉을 때 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="bbs.bbs_4" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">5. 의자에서 의자로 이동하기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 의자 2개를 준비하되 한 개는 팔걸이가 있는 것,
                또 다른 한 개는 팔걸이가 없는 것으로 준비하시오<br/>(의자 2개 대신 팔걸이가 있는 의자 1개와 침대를 이용할 수도 있다.)<br/>
                의자 2개를 서로 "ㄷ"자 모양으로 배열하고 한발을 축으로 하여 옮겨 앉기(pivot transfer)가 가능하게 하시오.<br/>
                대상자에게 의자에서 다른 의자로 옮겨 앉았다가 다시 원래 의자로 돌아오게 하시오.
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">손을 거의 사용하지 않고(또는 손을 조금 사용하여) 안전하게 옮겨 앉을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="bbs.bbs_5" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">확실히 손을 사용해야 안전하게 옮겨 앉을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="bbs.bbs_5" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">발로 가르쳐주거나 옆에서 지켜봐 주어야 옮겨 앉을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="bbs.bbs_5" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">한 사람의 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="bbs.bbs_5" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안전을 위해 옆에서 지켜봐 주거나 도움을 줄 두 사람이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="bbs.bbs_5" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">6. 두 눈을 감고 잡지 않고 서 있기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 두 눈을 감고 10초 동안 가만히 서 있으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">10초 동안 안전하게 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="bbs.bbs_6" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">옆에서 지켜봐 주면 10초 동안 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="bbs.bbs_6" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">3초 동안 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="bbs.bbs_6" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안정적으로 서 있으나 두 눈을 감고 3초 동안 유지 할 수는 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="bbs.bbs_6" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">넘어지는 것을 방지하기 위하여 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="bbs.bbs_6" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">7. 두 발을 붙이고 잡지 않고 서 있기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 두 발을 꼭 붙이고 아무 것도 잡지 말고 서 있으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자서 두 발을 붙이고 1분 동안 안정하게 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="bbs.bbs_7" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자서 두 발을 붙이고 옆에서 지켜봐 주면 1분 동안 안전하게 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="bbs.bbs_7" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자서 두 발을 붙이고 설 수 있으나 30초 동안 유지할 수는 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="bbs.bbs_7" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">두 발을 붙이는 데에 도움이 필요하나 15초 동안 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="bbs.bbs_7" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">두 발을 붙이는 데에 도움이 필요하며 15초 동안 서 있을 수 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="bbs.bbs_7" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">8. 선 자세에서 앞으로 팔을 뻗쳐 내밀기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 팔을 90도로 올리시오. 손가락을 펴고 가능한 한 최대로 앞으로 뻗으시오<br/>
                (검사자는 팔이 90도일 때 손가락 끝에 자를 둔다. 손가락을 앞으로 뻗을 때 자에 손이 닿지 않도록 한다.<br/>
                대상자가 최대로 앞으로 기울였을 때 손가락이 이동 한 거리를 기록한다. 가능하면 두 팔을 들게 하여 체간이 회전하는 것을 막도록 한다)
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">25cm 이상 앞으로 자신있게 뻗을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="bbs.bbs_8" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">12.5cm 이상 앞으로 안전하게 뻗을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="bbs.bbs_8" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question"> 5cm 이상 앞으로 안전하게 뻗을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="bbs.bbs_8" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">앞으로 뻗을 수는 있으나 옆에서 지켜봐 주는 것이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="bbs.bbs_8" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">넘어지지 않기 위해 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="bbs.bbs_8" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">9. 바닥에 있는 물건을 집어 올리기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 당신의 발 앞에 놓여있는 물건(신발/슬리퍼)을 집어 드시오.</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안전하고 쉽게 신발/슬리퍼를 집어들 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_9" v-model="bbs.bbs_9" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">신발/슬리퍼를 집어들 수 있으나 옆에서 지켜봐 주는 것이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_9" v-model="bbs.bbs_9" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question" style="white-space: nowrap">신발/슬리퍼를 집을 수는 없으나 신발/슬리퍼가 있는 지점에서부터 2.5cm~5cm의 거리까지 손이 닿으며 혼자 균형을 잡을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_9" v-model="bbs.bbs_9" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">신발/슬리퍼를 집어 들 수 없으며 잡으려고 시도하는 동안에도 옆에서 지켜봐 주는 것이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_9" v-model="bbs.bbs_9" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">신발/슬리퍼를 집으려고 시도해도 할 수 없으며 넘어지지않게 하려면 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_9" v-model="bbs.bbs_9" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">10. 왼쪽과 오른쪽으로 뒤돌아보기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 상체를 왼쪽으로 돌려 뒤돌아보시오. 오른쪽으로도 해 보시오<br/>
                (검사자는 물체를 들고 뒤에 서서 "이 물건을 보세요" 하며 몸통회전을 유도하도록 한다)
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">왼쪽과 오른쪽 방향으로 뒤돌아보며 체중 이동을 잘 할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_10" v-model="bbs.bbs_10" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">한쪽으로만 잘 돌아다보고 다른 쪽은 체중 이동이 적게 나타난다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_10" v-model="bbs.bbs_10" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">옆 방향으로만 비스듬히 돌릴 수 있으나 균형은 유지된다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_10" v-model="bbs.bbs_10" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">몸을 돌리려 할 때 옆에서 지켜봐 주는 것이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_10" v-model="bbs.bbs_10" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">넘어지지 않도록 하기 위해 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_10" v-model="bbs.bbs_10" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">11. 제자리에서 360도 회전하기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 한 방향으로 완전히 돌고 잠시 후 반대 방향으로 완전히 도십시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">각 방향을 4초 이내에 안전하게 360도 돌 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_11" v-model="bbs.bbs_11" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">한 방향으로만 4초 이내에 안전하게 360도 돌 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_11" v-model="bbs.bbs_11" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안전하게 360도 천천히 돌 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_11" v-model="bbs.bbs_11" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">근접해서 지켜봐 주거나 말로 지시를 해주어야 한다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_11" v-model="bbs.bbs_11" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">돌 때 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_11" v-model="bbs.bbs_11" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">12. 일정한 높이의 발판 위에 발을 교대로 놓기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 발판 위에 각 발을 번갈아 올려놓으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자 안전하게 서서, 20초 이내에 안전하게 발판 위에 발을 교대로 8회 올려놓을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_12" v-model="bbs.bbs_12" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자 서서, 20초 이상 걸려 발판 위에 완전하게 발을 교대로 8회 올려놓을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_12" v-model="bbs.bbs_12" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보조자의 도움없이 완전하게 교대로 발을 4회 발판에 올려놓을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_12" v-model="bbs.bbs_12" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">약간의 도움(최소한의 도움)으로 완전하게 발을 2회 이상 발판에 올려놓을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_12" v-model="bbs.bbs_12" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">넘어지지 않도록 하기 위해 도움이 필요하며 과제를 수행할 수 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_12" v-model="bbs.bbs_12" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">13. 한발 앞에 다른 발을 일자로 두고 서 있기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: (대상자에게 시범을 보인다) 한 발을 다른 발 바로 앞에 일자로 밀착하여 붙이시오.
                만약 한발을 다른 발 바로 앞에 붙일 수 없다고 여겨지면,<br/> 자신의 발길이(length of the foot)이상 앞으로 띄우되 정상보폭(stride width)만큼 벌려서 서시오(3점을 준다)
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자 두 발을 일자로 하여 30초 동안 그대로 서서 유지할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_13" v-model="bbs.bbs_13" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자 큰 발걸음(자신의 발길이 이상 앞으로 띄우되 보폭만큼 벌려서)으로 해서 30초 동안 유지할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_13" v-model="bbs.bbs_13" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자 작은 발걸음으로 해서 30초 동안 유지할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_13" v-model="bbs.bbs_13" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">걸음을 내딛는데 도움이 필요하나 그대로 서서 15초 동안 유지할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_13" v-model="bbs.bbs_13" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">발을 내딛거나 서 있는 동안 균형을 잃는다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_13" v-model="bbs.bbs_13" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">14. 한 다리로 서 있기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 아무 것도 잡지 않고 가능한 한 오랫동안 한 발로 서 있으시오</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자서 한 발을 들고 10초 이상 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_14" v-model="bbs.bbs_14" :value="4">
                  <span class="checkmark step-blue-2">4</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자서 한 발을 들고 5~10초 정도 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_14" v-model="bbs.bbs_14" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">혼자서 한 발을 들고 3초 동안 또는 그 이상 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_14" v-model="bbs.bbs_14" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">한 발을 들려고 시도하며, 3초 동안 유지하지는 못하지만 혼자서 서 있을 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_14" v-model="bbs.bbs_14" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">넘어지지 않기 위해서는 도움이 필요하거나 과제를 시도할 수 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_14" v-model="bbs.bbs_14" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <BergBalanceScaleResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :bbs="bbs"/>
  </div>
</template>

<script>

import {mdbScrollbar} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import BergBalanceScaleResultDialog from "@/views/therapist/menu/doEvaluation/paper/bbs/BergBalanceScaleResultDialog";

export default {
  name: "BergBalanceScale",
  components: {
    mdbScrollbar, BergBalanceScaleResultDialog
  },
  props: {
    fbDocId: String,
    bbs: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: 0,
      tools: {
        bbs: {}
      },
      isShowModal: false,
    };
  },
  methods: {
    async tempSave() {
      const self = this;
      await self.calculator();
      self.tools.bbs = self.bbs;
      self.tools.bbs['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'bbs', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (JSON.stringify(self.bbs).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;
      self.total =
          self.bbs.bbs_1 + self.bbs.bbs_2 + self.bbs.bbs_3 + self.bbs.bbs_4 + self.bbs.bbs_5 + self.bbs.bbs_6 + self.bbs.bbs_7 +
          self.bbs.bbs_8 + self.bbs.bbs_9 + self.bbs.bbs_10 + self.bbs.bbs_11 + self.bbs.bbs_12 + self.bbs.bbs_13 + self.bbs.bbs_14
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      await self.calculator();
      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'bbs', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
}

tr {
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 56px;
  font-size: 16px;
  border-bottom: 1px solid #E1E3E6;
}

.td-answer {
  text-align: center;
  font-weight: 500;
}

.td-bk {
  background: #F8F8FA;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  height: 570px;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.score {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.score .score-text {
  padding: 0 10px;
  width: 1070px;
  height: 48px;
  background: #0069CA;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #FFFFFF;
}

.score .question {
  display: flex;
  padding: 10px;
}

.score-text .first-box {
  border: 0;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.score-text .second-box {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  width: 110px;
}

.first-box {
  display: flex;
  justify-content: space-between;
  width: 920px;
  padding: 0 10px;
  border-right: 2px solid #D4D4D8;
}

.block {
  width: 103px !important;
  height: 48px !important;
  background: #F1F6FD !important;
}


.radio-round-group {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container {
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
  padding: 0 0 0 67px !important;
  cursor: pointer;
}

.container:first-child {
  margin-left: 10px;
}


/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 28px;
  background-color: #fff;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #A0B0C6;
}

.checkmark-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #F8F8FA;
  border-radius: 100px;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
}

.xs-mark {
  width: 30px !important;
  height: 30px !important;
  font-size: 16px !important;
  background: #fff !important;
}

.step-blue-2 {
  background: #E9F2FD;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  color: #0069CA;
  background: #FFFFFF;
  border: 2px solid #0069CA;
}

.block-white {
  width: 103px !important;
  height: 48px !important;
  color: #0069CA;
  background: #FFFFFF;
  border: 1px solid #0069CA;
}

.w55 {
  width: 55px !important;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
  font-size: 14px;
}


.paper-item {
  padding: 6px 20px;
  font-weight: 500;
  background: #F8F8FA;
}

.paper-measure {
  padding-left: 20px;
  font-weight: 500;
  background: #fff;
}

.paper-question {
  font-weight: 400;
  line-height: 22px;
  padding: 8px 0 8px 30px;
}

td {
  height: 48px;
}
</style>
