export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'time',
        field: 'time',
    },

];
