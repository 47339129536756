export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'aou_1',
        field: 'aou_1',
    },
    {
        label: 'aou_2',
        field: 'aou_2',
    },
    {
        label: 'aou_3',
        field: 'aou_3',
    },
    {
        label: 'aou_4',
        field: 'aou_4',
    },
    {
        label: 'aou_5',
        field: 'aou_5',
    },
    {
        label: 'aou_6',
        field: 'aou_6',
    },
    {
        label: 'aou_7',
        field: 'aou_7',
    },
    {
        label: 'aou_8',
        field: 'aou_8',
    },
    {
        label: 'aou_9',
        field: 'aou_9',
    },
    {
        label: 'aou_10',
        field: 'aou_10',
    },
    {
        label: 'aou_11',
        field: 'aou_11',
    },
    {
        label: 'aou_12',
        field: 'aou_12',
    },
    {
        label: 'aou_13',
        field: 'aou_13',
    },
    {
        label: 'aou_14',
        field: 'aou_14',
    },
    {
        label: 'aou_15',
        field: 'aou_15',
    },
    {
        label: 'aou_16',
        field: 'aou_16',
    },
    {
        label: 'aou_17',
        field: 'aou_17',
    },
    {
        label: 'aou_18',
        field: 'aou_18',
    },
    {
        label: 'aou_19',
        field: 'aou_19',
    },
    {
        label: 'aou_20',
        field: 'aou_20',
    },
    {
        label: 'aou_21',
        field: 'aou_21',
    },
    {
        label: 'aou_22',
        field: 'aou_22',
    },
    {
        label: 'aou_23',
        field: 'aou_23',
    },
    {
        label: 'aou_24',
        field: 'aou_24',
    },
    {
        label: 'aou_25',
        field: 'aou_25',
    },
    {
        label: 'aou_26',
        field: 'aou_26',
    },
    {
        label: 'aou_27',
        field: 'aou_27',
    },
    {
        label: 'aou_28',
        field: 'aou_28',
    },

    {
        label: 'qom_1',
        field: 'qom_1',
    },
    {
        label: 'qom_2',
        field: 'qom_2',
    },
    {
        label: 'qom_3',
        field: 'qom_3',
    },
    {
        label: 'qom_4',
        field: 'qom_4',
    },
    {
        label: 'qom_5',
        field: 'qom_5',
    },
    {
        label: 'qom_6',
        field: 'qom_6',
    },
    {
        label: 'qom_7',
        field: 'qom_7',
    },
    {
        label: 'qom_8',
        field: 'qom_8',
    },
    {
        label: 'qom_9',
        field: 'qom_9',
    },
    {
        label: 'qom_10',
        field: 'qom_10',
    },
    {
        label: 'qom_11',
        field: 'qom_11',
    },
    {
        label: 'qom_12',
        field: 'qom_12',
    },
    {
        label: 'qom_13',
        field: 'qom_13',
    },
    {
        label: 'qom_14',
        field: 'qom_14',
    },
    {
        label: 'qom_15',
        field: 'qom_15',
    },
    {
        label: 'qom_16',
        field: 'qom_16',
    },
    {
        label: 'qom_17',
        field: 'qom_17',
    },
    {
        label: 'qom_18',
        field: 'qom_18',
    },
    {
        label: 'qom_19',
        field: 'qom_19',
    },
    {
        label: 'qom_20',
        field: 'qom_20',
    },
    {
        label: 'qom_21',
        field: 'qom_21',
    },
    {
        label: 'qom_22',
        field: 'qom_22',
    },
    {
        label: 'qom_23',
        field: 'qom_23',
    },
    {
        label: 'qom_24',
        field: 'qom_24',
    },
    {
        label: 'qom_25',
        field: 'qom_25',
    },
    {
        label: 'qom_26',
        field: 'qom_26',
    },
    {
        label: 'qom_27',
        field: 'qom_27',
    },
    {
        label: 'qom_28',
        field: 'qom_28',
    },
];
