<template>
  <div class="main-container">
    <img src="@/assets/images/IA/img_primary_logo.png" alt="logo">
    <form v-on:submit.prevent="updatePassword">
      <div class="find-id-form">
        <h1>비밀번호 재설정</h1>
        <div class="label-box">
          <label>
            새로운 비밀번호
          </label>
          <input type="password" placeholder="8자리 이상 입력" class="custom-input-box" v-model="password"  autocomplete="off" >
        </div>
        <div class="label-box">
          <label>
            새로운 비밀번호 확인
          </label>
          <input type="password" placeholder="8자리 이상 입력" class="custom-input-box" v-model="repassword"  autocomplete="off"
                 v-on:keypress.enter.prevent="updatePassword">
        </div>
        <button class="custom-btn-box">비밀번호 재설정</button>
      </div>
    </form>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "TherapistRePassword",
  data() {
    return {
      fbCollection: 'therapist',
      password: '',
      repassword: '',
      isProgress: false,
      isShowModal: false,
      isShowErrorModal: false,
      email: '',
      email2: '',
    }
  },
  methods: {
    updatePassword() {
      const self = this;
      if (self.password.trim().length < 8) {
        alert('비밀번호는 최소 8자리 이상입니다.')
        return false
      }

      if (self.password.trim().length === 0) {
        alert('비밀번호를 입력하세요.')
        return false
      } else if (self.repassword.trim() !== self.password.trim()) {
        alert('패스워드가 일치하지 않습니다.')
        return false
      }

      const user = firebase.auth().currentUser;
      const newPassword = self.password;

      user.updatePassword(newPassword).then(() => {
        alert('패스워드가 변경되었습니다.')
        self.$router.replace('/therapist/myPage');
      }).catch((error) => {
        self.firebaseError(error)
      });
      // [END auth_update_password]
    },

    firebaseError(error) {
      if ('auth/invalid-verification-code' === error.code) {
        alert('인증번호가 유효하지 않습니다.');
      } else if ('auth/session-expired' === error.code) {
        alert('인증번호가 만료되었습니다.');
      } else if ('auth/too-many-requests' === error.code) {
        alert('잠시 후 다시 시도해 주세요.');
      } else if ('Error: The email address is badly formatted.' === error.code) {
        alert('이메일은 필수입니다.');
      } else if ('auth/requires-recent-login' === error.code) {
        alert('이 작업은 민감하며 최근 인증이 필요합니다.\n재 로그인 후 이 요청을 다시 시도 하십시오.');
      } else {
        console.log(error);
      }
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
        self.$router.go(-1)
      } else if (index === 4) {
        self.isShowErrorModal = false
      }
    },
  }
}
</script>

<style scoped>

.custom-input-box {
  font-size: 16px;
}

.guide-text {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 30px 0 29px;
}

strong {
  margin-left: 13px;
}

.find-id-frame {
  background-color: #A0B0C6;
}

.header {
  height: 80px;
  display: flex;
  flex-direction: row;
}

.btn-go-home {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.btn-go-home img {
  width: 20px;
  height: 20px;
}

.btn-go-home p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  margin: 0 15px 0 5px;
}

.main-container {
  height: 779px;
  text-align: center;
  background-color: #A0B0C6;
}


.main-container img {
  width: 193px;
  height: 41px;
  margin-top: 64px;
  margin-bottom: 30px;
}

.find-id-form {
  margin: 0 auto;
  width: 418px;
  height: 396px;
  background: #FFFFFF;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.find-id-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 40px;
}

.find-id-form h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 40px;
}

.find-id-form h4 strong {
  color: #0069CA;
  cursor: pointer;
  margin-left: 8px;
}

.find-id-form label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
  text-align: left !important;
}

.label-box {
  text-align: left;
  margin-bottom: 20px;
}

.btn-find {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
}

.btn-find:first-child {
  margin-right: 3px;
}

.btn-find:last-child {
  margin-left: 3px;
}

.custom-btn-box {
  margin-top: auto;
}
</style>


