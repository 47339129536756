<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        평가 완료 처리
      </h5>
    </div>
    <div class="modal-center">
      <p>모든 항목의 평가가 완료 되었는지 확인해주세요.<br/>
        결과 저장이 완료된 것만 클라우드 저장 후 평가가 종료됩니다.<br/>
      </p>
    </div>
    <div class="modal-footer">
      <button class="custom-btn-box-white btn-short" @click="btnCancel">닫기</button>
      <button class="primary-blue custom-btn-box btn-confirm" @click="btnConfirm">평가 완료</button>
    </div>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import Progress from "@/components/Progress";

export default {
  name: "EvaluationCompleteDialog",
  components: {Progress},
  props: {
    fbDocId: String,
  },
  data() {
    return {
      fbCollection: 'evaluations',
      evaluationsData: {},
      isProgress: false
    }
  },
  mounted() {
  },
  methods: {
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 0, false)
    },
    async btnConfirm() {
      const self = this;
      self.isProgress = true
      const db = firebase.firestore();
      const batch = db.batch();

      let tools = [];
      let data = '';
      let patientKey = '';
      await db.collection(self.fbCollection).doc(self.fbDocId)          // 컬랙션 "evaluations"
          .get().then((snapshot) => {
            self.evaluationsData = snapshot.data()
            const date = new Date(self.evaluationsData.regDate.seconds * 1000);
            self.evaluationsData.regDate = getDate(date);
            data = getDate(date)
            patientKey = self.evaluationsData.patientInfo.patientKey
            tools = Object.keys(self.evaluationsData.tools).filter(x => self.evaluationsData.tools[x].state === 2)
          });

      const data2 = {
        visitCount: firebase.firestore.FieldValue.increment(+1),
        diagnosis: self.evaluationsData.diseaseInfo.diagnosis,
        description: self.evaluationsData.diseaseInfo.description,
        lastDate: firebase.firestore.Timestamp.fromDate(new Date()),
        isProgress: false,
        evaluationId: ''
      }
      for (let i = 0; i < tools.length; i++) {
        data2['last' + tools[i]] = data;
      }

      const ref1 = await db.collection(self.fbCollection).doc(self.fbDocId)
      batch.set(ref1, {state: 1,}, {merge: true});
      const ref2 = await db.collection('patients').doc(patientKey)
      batch.set(ref2, data2, {merge: true});
      batch.commit().then(() => {
        this.$router.push('/therapist/doEvaluation/')
        self.isProgress = false
      });

    }
  }
}
</script>

<style scoped>
.modal-container {
  position: fixed;
  width: 580px;
  height: 276px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 8px;
  overflow: hidden;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 77px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  height: 126px;
  padding: 30px;
  background: #F8F8FA;
}

.modal-center h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 5px;
}

.modal-center h5 strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-left: 13px;
  margin-bottom: 7px;
}

.modal-center p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.modal-footer {
  background: #FFFFFF;
  height: 73px;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 16px;
  line-height: 36px;
  background-color: white;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.0125em;
  color: rgba(24, 24, 25, 0.9);
}


.btn-confirm {
  width: 110px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}

strong {
  color: #DC2626;
}

</style>
