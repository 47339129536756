<template>
  <div>
    <div class="modal-cover" v-show="isVideoModal||isVideoStandardModal"></div>
    <div class="sub-frame view-layout">
      <h1 class="title">평가 도구 정보</h1>
      <div class="guide-frame">
        <h5 v-if="toolName === 'Fugl-Meyer-Low'" v-html="toolName.substring(0, 10)"></h5>
        <h5 v-else v-html="toolName"></h5>
        <div v-html="toolsDescription"></div>
      </div>
      <div class="tab-frame">
        <div class="tab-nav">
          <div class="tab-nav-item" :class="{active : tabIndex === 0}" @click="tabIndex = 0">
            교육 영상
          </div>
          <div class="tab-nav-item" v-if="toolUe === 'ue'" :class="{active : tabIndex === 1}" @click="tabIndexCenter()">
            표준화
          </div>
          <div class="tab-nav-item" :class="{active : tabIndex === 2}" @click="tabIndex = 2">
            F&A
          </div>
        </div>
        <div class="tab-item" v-if="tabIndex === 0">
          <div class="search-container">
            <div class="icon-input">
              <img src="@/assets/images/IA/icon_search.png" alt="search">
              <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
            </div>
            <button class="custom-btn-box search-btn">검색</button>
          </div>
          <div class="table-container">
            <mdb-datatable-2
                class="text-center"
                borderless
                hover
                fullPagination
                @selected="onSelected($event)"
                :searching="{value: search, field: fieldName}"
                noFoundMessage="준비중 입니다."
                v-model="data"/>
          </div>
        </div>
        <div class="tab-item" v-else-if="tabIndex === 1">
          <div class="table-container">
            <div class="tab-2-frame">
              <mdb-datatable-2
                  class="text-center standard"
                  :pagination="false"
                  borderless
                  hover
                  v-model="dataStandardization"
              />
              <FuglMeyerEvaluationPaper v-if="$route.params.id === 'fm' && isShowStandard"
                                        :paperAllData="paperAllData"
                                        :paperData="paperData"
                                        :paperIndex="paperIndex"
                                        @changeState="changeState"/>
            </div>
          </div>
        </div>
        <div class="tab-item" v-if="tabIndex === 2">
          <div v-if="!isShowDetailFna">
            <div class="search-container">
              <div class="icon-input">
                <img src="@/assets/images/IA/icon_search.png" alt="search">
                <input v-model="searchFna" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
              </div>
              <button class="custom-btn-box search-btn">검색</button>
            </div>
            <div class="table-container">
              <mdb-datatable-2
                  class="text-center"
                  borderless
                  hover
                  @selected="onSelectedFna($event)"
                  :searching="{value: searchFna, field: fieldName} "
                  v-model="dataFna"
                  noFoundMessage="데이터가 없습니다."/>
            </div>
          </div>
          <div v-if="isShowDetailFna">
            <div class="search-container">
              <div style="margin-left: auto">
                <button class="table-btn" @click="prevBoard">
                  <img src="@/assets/images/IA/icon_prev_post.png" alt="list">
                  이전글
                </button>
                <button class="table-btn" @click="nextBoard">
                  <img src="@/assets/images/IA/icon_next_post.png" alt="list">다음글
                </button>
                <button class="table-btn" @click="isShowDetailFna = false">
                  <img src="@/assets/images/IA/icon_list_view.png" alt="list">목록
                </button>
              </div>
            </div>
            <div class="table-container">
              <div class="fna-title-box">
                <h4 class="fna-title">{{ fnaTitle }}</h4>
                <h4 class="fna-date">{{ fnaDate }}</h4>
              </div>
              <div class="fna-desc-box">
                <mdb-input type="textarea" id="editor" class="textarea-desc" outline v-model="fnaDesc" readOnly :rows="10"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VimeoDialog v-if="isVideoModal" @callBackModal="callBackModal" :selected="selected" :toolName="$route.params.id"/>
    <VimeoStandardDialog v-if="isVideoStandardModal" @callBackModal="callBackModal" :selected="selected"/>
  </div>
</template>

<script>
import FuglMeyerEvaluationPaper from "@/views/therapist/menu/evaluationToolsInfo/standardization/FuglMeyerEvaluationPaper";
import VimeoDialog from "@/views/therapist/menu/evaluationToolsInfo/VimeoDialog";
import {mdbDatatable2, mdbInput} from "mdbvue";
import fna_columns from "@/assets/data/fna_columns";
import standardization_columns from "@/assets/data/standardization_columns";
import educationalVideo_columns from "@/assets/data/educationalVideo_columns";
import {firebase} from "@/firebase/firebaseConfig";
import {getDateTime} from "@/lib/prettyDate";
import VimeoStandardDialog from "@/views/therapist/menu/evaluationToolsInfo/VimeoStandardDialog";

export default {
  name: "CommonToolsInfo",
  components: {
    FuglMeyerEvaluationPaper,
    VimeoDialog,
    mdbDatatable2, mdbInput,
    VimeoStandardDialog
  },
  props: {
    toolName: String,
    toolsDescription: String,
    toolUe: String,
  },
  data() {
    return {
      fbCollection: 'videos',
      fbUid: this.$store.state.user.uid,
      isVideoModal: false,
      isVideoStandardModal: false,
      isShowStandard: false,
      isShowDetailFna: false,
      search: '',
      searchFna: '',
      selected: null,
      fieldName: '',
      tabIndex: 0,
      dataFna: {
        columns: fna_columns,
        rows: []
      },
      dataStandardization: {
        columns: standardization_columns,
        rows: []
      },
      data: {
        columns: educationalVideo_columns,
        rows: []
      },

      fnaNo: 0,
      fnaTitle: '',
      fnaDate: '',
      fnaDesc: '',
      paperState: 0,
      paperData: {},
      paperIndex: 0,
      paperAllData: {},
      isEarlyVideoView: false,
    }
  },
  watch: {
    tabIndex(a) {
      const self = this;
      if (a !== 2) self.isShowDetailFna = false
    }
  },
  mounted() {
    this.dataInit()
  },
  created() {
    window.showStandard = this.showStandard;
    window.showVideo = this.showVideo;
  },
  methods: {
    dataInit() {
      const self = this;
      self.tabIndex = 0
      this.getDataEduVideoTable();
      if (self.toolUe === 'ue') this.getDataStandardVideoTable();
      this.getDataFnaTable();
    },
    getDataEduVideoTable() {
      const self = this;
      self.data.rows.splice(0)
      const db = firebase.firestore();
      db.collection(self.fbCollection)
          .where('toolName', '==', self.toolName)
          .where('type', '==', '교육 영상')
          .orderBy("orderNumber", "asc")
          .get().then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          _data["docId"] = doc.id;
          _data.time2 = '<div class="time-column"><img src="/images/icon_play.png" alt="icon_play" class="icon-play"><p class="blue-color">' + _data.time + '</p></div>'
          self.data.rows.push(_data);
        });
      })
    },

    getDataStandardVideoTable() {
      const self = this;
      self.dataStandardization.rows.splice(0)
      const db = firebase.firestore();
      db.collection('videoStandard')
          .doc(self.toolName)
          .get().then(async (querySnapshot) => {
        const _data = querySnapshot.data();
        _data.action = '<img src="/images/btn_play_video.png" alt="btn_play_video" class="action-btn" onclick="showVideo(\'' + querySnapshot.data().title + '\',\'' + querySnapshot.data().time + '\',\'' + querySnapshot.data().url + '\')">'
        self.dataStandardization.rows.push(_data);
        let isViewer = false;
        await db.collection('videoStandard')
            .doc(self.toolName)
            .collection('viewers')
            .doc(self.fbUid)
            .get().then((querySnapshot) => {
              isViewer = querySnapshot.exists
            });
        if (isViewer) {
          _data.status = '<div class="blue-color-2">열람완료</div>'
          self.isEarlyVideoView = true;
        } else {
          self.isEarlyVideoView = false
          _data.status = '미열람'
        }

        let paperStateNow = '미평가'
        if (self.paperState === 1) {
          paperStateNow = '<div class="red-color-2">재도전</div>';
        } else if (self.paperState === 2) {
          paperStateNow = '<div class="blue-color-2">표준화 완료</div>';
        }
        const demo = {
          orderNumber: 2,
          title: '자신의 표준화 평가 결과를 체크해주세요.',
          status: paperStateNow,
          action: '<img src="/images/btn_standard.png" alt="btn_standard" class="action-btn" onclick="showStandard()">',
        }
        self.dataStandardization.rows.push(demo);
      })
    },
    getDataFnaTable() {
      const self = this;
      // console.log('table',self.toolName)
      self.dataFna.rows.splice(0)
      const db = firebase.firestore();
      let orderNumber = 1;
      db.collection('f&a')
          .where('toolName', '==', self.toolName)
          .orderBy("regDate", "desc")
          .get().then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          _data.orderNumber = orderNumber++;
          _data["docId"] = doc.id;
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDateTime(date);
          self.dataFna.rows.push(_data);
        });
      })
    },
    callBackModal: async function (index) {
      const self = this;
      if (index === 0) {
        self.selected = null;
        self.isVideoModal = false
      } else if (index === 1) {
        self.isVideoStandardModal = false

        if (!self.isEarlyVideoView) {
          const db = firebase.firestore();
          await db.collection('videoStandard')
              .doc(self.toolName)
              .collection('viewers')
              .doc(self.fbUid)
              .set({view: true})
          self.tabIndexCenter()
        }
      }
    },
    onSelected(value) {
      const self = this;
      this.selected = value;
      if (this.selected != null) {
        self.isVideoModal = true;
      }
    },

    onSelectedFna(value) {
      const self = this;
      this.selected = value;
      if (this.selected != null) {
        self.fnaNo = self.selected.orderNumber;
        self.fnaTitle = self.selected.title;
        self.fnaDate = self.selected.regDate;
        self.fnaDesc = self.selected.desc;
        self.isShowDetailFna = true;
      }
    },

    showStandard() {
      const self = this;
      // TODO 표준화 평가를 제공받지 않음.
      if (self.$route.params.id !== 'fm' && self.$route.params.id !== 'fmLow') {
        alert('준비중 입니다.')
        return
      }

      if (self.paperState === 2) {
        return alert('이미 평가가 완료되었습니다.');
      }

      self.isShowStandard = true;
    },
    showVideo(title, time, url) {
      const self = this;
      // TODO 표준화 평가를 제공받지 않음.
      if (self.$route.params.id !== 'fm' && self.$route.params.id !== 'fmLow') {
        alert('준비중 입니다.')
        return
      }

      self.selected = {
        title: title,
        time: time,
        url: url
      }
      self.isVideoStandardModal = true
    },

    prevBoard() {
      const self = this;
      const users = self.dataFna.rows;
      let res = users.filter(it => it.orderNumber === self.fnaNo - 1);
      if (res.length !== 0) {
        self.fnaNo = res[0].orderNumber;
        self.fnaTitle = res[0].title;
        self.fnaDate = res[0].regDate;
        self.fnaDesc = res[0].desc;
      } else {
        alert('처음 글입니다.');
      }
    },

    nextBoard() {
      const self = this;
      const users = self.dataFna.rows;
      let res = users.filter(it => it.orderNumber === self.fnaNo + 1);
      if (res.length !== 0) {
        self.fnaNo = res[0].orderNumber;
        self.fnaTitle = res[0].title;
        self.fnaDate = res[0].regDate;
        self.fnaDesc = res[0].desc;
      } else {
        alert('마지막 글입니다.');
      }
    },

    tabIndexCenter() {
      const self = this;
      this.tabIndex = 1;
      self.getDataStandardPaper()
      self.getDataStandardVideoTable()
    },
    async getDataStandardPaper() {
      const self = this;
      const db = firebase.firestore();
      await db.collection('standardization')
          .doc(self.fbUid)
          .get()
          .then(async (snapshot) => {
            const _data = await snapshot.data();
            self.paperAllData = _data;
            // TODO 표준화 평가를 제공받지 않음.
            if (self.$route.params.id !== 'fm' && self.$route.params.id !== 'fmLow') {
              return
            }
            self.paperState = _data[self.$route.params.id].state
            self.paperIndex = _data[self.$route.params.id].index
            self.paperData = _data[self.$route.params.id].papers[self.paperIndex];
          });
    },
    changeState: function (index) {
      const self = this;
      if (index === 1) {
        self.tabIndex = 0;
        self.tabIndexCenter();
        self.isShowStandard = false;
      }
    },
  }
}
</script>

<style scoped>
.view-layout {
  max-width: 1140px;
  margin: 0 auto;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.nav-tools-bar {
  background-color: #0078AF;

}

.nav-tools-bar .view-layout {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 17px;
}

.nav-tools-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 12px;
  position: static;
  width: 250px;
  height: 50px;
}

.nav-tools-item p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: auto;
}

.nav-tools-item img {
  width: 18px;
  height: 18px;
  margin-left: auto;
}

hr {
  width: 1px;
  height: 50px;
  background: #0990CD;
  margin: 0;
  padding: 0;
  border: 0;
}


.sub-frame {
  padding: 40px 15px;
  background-color: #F8F8FA;
}

.guide-frame {
  height: 185px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 20px;
  word-break: keep-all;
}

.guide-frame h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 109px;
  text-align: center;
  height: 28px;
  background: #0069CA;
  border-radius: 100px;
}

.guide-frame p {

}

/deep/ .guide-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #0A1120;
  margin-top: 7px;
}

.tab-frame {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
}

.tab-nav {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E1E3E6;

}

.tab-nav-item {
  flex: 1;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.001em;
  color: #272833;
  font-style: normal;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
}

.active {
  background: #F1F6FD;
  border-bottom: 2px solid #0069CA;
  color: #272833;
}

.tab-item {
  padding: 20px;
}

.search-container {
  display: flex;
  flex-direction: row;
}

/deep/ .mdb-datatable thead th {
  font-size: 14px;
  font-weight: 700;
}

/deep/ .mdb-datatable tbody td:nth-child(3n) {
  color: #8D8D8D;
  font-weight: 400;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
}


.icon-input {
  position: relative;
  margin-left: auto;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-input {
  width: 282px;
  padding-left: 30px;
  background: #EDEDF1;
}

.search-btn {
  width: 78px;
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
}

.table-container {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

/deep/ .time-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .icon-play {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

/deep/ .blue-color {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 0;
}

/deep/ .blue-color-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #0069CA;
}

/deep/ .red-color-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #E14F4E;
}

.guide p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

/deep/ .mdb-datatable td:nth-child(2) {
  text-align: left;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F8F8FA;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(0, 0, 0, 0.075);
}

/deep/ .mdb-datatable th {
  padding: 0;
  height: 48px;
  vertical-align: middle;
}

/deep/ .mdb-datatable td {
  padding: 0;
  height: 48px;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
}

/deep/ .mdb-datatable-footer {
  padding: 5px 0 0 0;
  height: 64px;
  vertical-align: middle;
  align-items: center;
}

/deep/ .action-btn {
  width: 176px;
  height: 36px;
  cursor: pointer;
}


/deep/ .standard .mdb-datatable-footer {
  display: none;
}

.table-btn {
  height: 40px;
  padding: 0 10px;
  background: #EDEDF1;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  border: 0;
  margin-left: 10px;
}

.table-btn img {
  text-align: left;
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
  margin-right: 4px;
}

/deep/ .mdb-datatable-footer {
  display: none;
}


.fna-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0;
}

.fna-title-box {
  padding: 23px 32px;
  background: #FFFFFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.fna-desc-box {
  background: #F8F8FA;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.fna-date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin: 0;
}

/deep/ .textarea-desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/deep/ .textarea-desc textarea {
  padding: 23px 32px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #272833 !important;
  -webkit-text-fill-color: #272833;
  -webkit-opacity: 1;
  border: 0 !important;
  resize: none;
  margin: 0;
  overflow: overlay;
}
</style>
