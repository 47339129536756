export default {
    fm: '<p class="guide-text">푸글 마이어 상지 평가 (Fugl-meyer Assessment Upper Extremity, FMA-UE)는 뇌졸중 후 환자의 회복을 평가 및 측정하는 도구로 ' +
        '상지의 운동기능을 측정하기 위해 사용되는 평가도구입니다. 상지의 반사, 굴곡 시너지, 신전 시너지 움직임 및, <br/>손목과 손가락의 움직임, 협응성 등을 평가하며 0에서 2점 사이의 점수를 평가자가 부여하게 됩니다.<br/>' +
        '0점은 수행할 수 없음, 1점은 부분적으로 수행할 수 있음, 2점은 완전하게 수행할 수 있음을 의미합니다. 총 66점 만점으로 <br/>점수가 낮을수록 기능이 낮고, 점수가 높을수록 환자의 운동기능이 높음을 의미합니다.</p>',
    wmft: '<p class="guide-text">울프 운동 기능 검사 (Wolf Motor Function Test, WMFT)는 상지의 기능적 운동 능력을 평가하는 것으로 해당 움직임 시 <br/>시간과 움직임의 질을 평가하는 도구입니다. ' +
        '본래 21개의 항목을 사용하지만 널리 사용되어지는 수정된 버전은 <br/>17개의 항목을 포함하고 있습니다. ' +
        '각 항목은 뻗기, 쥐기 등의 일상생활 시 필요한 동작을 담고 있으며 <br/>움직임을 수행한 시간을 측정하게 됩니다. 9과 14번은 힘 및 강도를 측정하게 됩니다. <br/>' +
        '움직임의 질은 0에서 5점의 점수를 제공하는 6점 척도로 독립되게 평가할 수 있습니다. <br/>움직임 시간이 짧을수록 움직임의 질 점수가 높을 수록 기능의 수준이 높다는 것을 의미합니다.</p>',
    bb: '<p class="guide-text">박스와 블록 검사 (Box and Block, BB)은 손상측 손과 비손상측 손의 잡기(grasping) 능력을 보기 위해 사용되는 평가도구 입니다. <br/>' +
        '1분 동안 상자안에 있는 150개의 블록(큐브)를 반대쪽 상자에 옮긴 개수를 측정합니다. <br/>' +
        '1분 동안 옮긴 큐브의 개수가 높을수록 환자의 잡기 능력이 높음을 의미합니다.</p>',
    sis: '<p class="guide-text">뇌졸중 영향 척도(Stroke Impact Scale, SIS)는 뇌졸중 환자들을 대상으로 뇌졸중에 의한 신체적인 면, 일상생활 동작, 감성, <br/>의사소통, 기억, 사회 참여 등의 9가지 영역에서 변화 정도를 측정하기 위해 고안된 평가척도입니다. ' +
        '인터뷰 형식으로 진행되며 <br/>대상자는 평가자의 질문에 대해 5점 척도(예: 매우 강함, 강함, 보통, 약함, 전혀 없음)등으로 대답할 수 있습니다. <br/>' +
        '각 영역에 따라 척도가 달라질 수 있지만, 9번 영역을 제외한 나머지 영역은 점수가 낮을수록 뇌졸중으로 인해 받게 되는 영향이 적음을 의미합니다.</p>',
    aaut: '<p class="guide-text">실제 상지 사용 검사 (Actual Amount of Use Test, AAUT)는 운동활동지표(MAL)과 더불어 손상측의 실제 사용량이 얼마나 되는지 <br/>알아보는 검사입니다. ' +
        '대상자가 본인이 평가를 수행하고 있다는 것을 모르도록 시나리오를 설계하여 일상생활 속에서 손상측 상지의 사용을 평가합니다. ' +
        '비디오 촬영을 통해 자발적인 상지 사용량(amount of use)와 상지 움직임의 질(quality of movement)를 <br/>평가자가 추후 점수를 매기는 방법을 사용합니다. <br/>' +
        '사용량과 움직임의 질은 0에서 5점 척도로 구성되며, 점수가 높을수록 사용량이 많고 움직임의 질이 좋음을 의미합니다.</p>',
    mal: '<p class="guide-text">운동 활동 로그 (Motor Activity Log, MAL)는 대상자를 인터뷰하는 평가로, 개인의 실제 일상생활 속에서 손상측 상지를 얼마나 많이 그리고 잘 사용하였는지 평가하는 도구입니다. ' +
        '원래 30개의 항목에서 수정하여 28개의 항목으로 구성된 평가를 가장 많이 사용합니다. ' +
        '움직임의 양(amount of use)와 움직임의 질(quality of movement)를 측정하며 0에서 5점의 사이의 점수를 줄 수 있습니다. <br/>' +
        '평가자가 묻는 질문에 대상자는 본인의 점수를 평가자에게 이야기하며, 점수가 높을 수록 손상측 상지를 많이 사용하며 잘 사용함을 의미합니다.</p>',
    fmLow: '<p class="guide-text">푸글 마이어 하지 평가 (Fugl-Meyer Assessment Lower Extremity, FMA-LE)는 뇌졸중 후 환자의 회복을 평가 및 측정하는 도구로 <br/>' +
        '하지의 운동기능을 측정하기 위해 사용되는 평가도구입니다. 하지의 반사, 굴곡 시너지, 신전 시너지 움직임 및, 협응성 등을 평가하며 0에서 2점 사이의 점수를 평가자가 부여하게 됩니다.<br/>' +
        '0점은 수행할 수 없음, 1점은 부분적으로 수행할 수 있음, 2점은 완전하게 수행할 수 있음을 의미합니다.<br/>' +
        ' 총 34점 만점으로 점수가 낮을수록 기능이 낮고, 점수가 높을수록 환자의 운동기능이 높음을 의미합니다.\n</p>',
    bbs: '<p class="guide-text">Berg balance scale은 성인의 정적 균형과 낙상 위험을 평가하는데 사용되며 14개 항목으로 구성되어 있습니다.<br/>' +
        ' 각 항목당 0에서 4까지 5점 척도로 이루어져있으며, 56점 만점입니다. <br/>' +
        'BBS 0-20점은 균형 능력 손상, 21-40점은 허용 가능한 균형, 41-56점은 균형 능력이 좋음을 나타냅니다.</p>',
    tug: '<p class="guide-text">Timed Up And Go Test는 사람의 이동성을 측정하는데 사용되는 하지평가도구로 정적인 균형과 동적인 균형을 모두 필요로 합니다. <br/>' +
        '의자에서 일어나 3m 거리에 있는 장애물까지 걸어가 장애물을 돌아서 다시 의자에 앉는 방법입니다. 제한시간 없이 시간을 측정하며,  <br/>점수의 해석은 ‘10초 이내 = 스스로 완전히 활동 가능, 20초 이내 = 기본적 활동 가능, 30초 이상 = 보조 필요로 할 수 있다’ 입니다.</p>',
    ftsts: '<p class="guide-text">Five Times Sit To Stand Test (5TSTS)는 노인뿐만 아니라 뇌성마비, 뇌졸중, 골관절염 등 <br/>' +
        '환자들에게 기능적인 하지의 근력을 측정하고 균형조절 능력을 평가해 낙상의 재발을 예측하는 평가도구 입니다.<br/>' +
        ' 의자에서 일어났다가 앉는 동작을 5번 수행할 때의 시간을 측정하는 평가로 하지의 힘으로만 일어나도록 합니다.</p>',
    tmwt: '<p class="guide-text">10Meter Walk Test (10MWT)는 단거리 보행 속도 및 능력을 보는 평가도구입니다. <br/>10M(10미터)의 거리를 측정자의 도움없이 독립적으로 걷고 일반적으로 총 3회 10M를 걸을 때의 시간을 측정하여 속도를 계산합니다. <br/>' +
        '좀 더 정확히는 시작 위치에서부터 2M와 8M 사이의 거리를 대상자가 걸을 때의 시간(시작과 끝 2M는 제외)을 측정하여 <br/>속도를 계산하는 것이 일반적입니다. 대상자의 평소 걷는 걸음으로 3회 10m를 걷는 것을 측정한 후, 빠른 속도로 3회 걷는 것을<br/>측정합니다. 평소 걸음과 빠른 걸음 각각 평균 속도를 계산하며, 초기 평가 후 1개월마다 재평가를 시행합니다.</p>',
    smwt: '<p class="guide-text">6Minute Walk Test (6MWT), 6분 걷기 검사는 환자의 운동 능력을 객관적으로 측정하는 평가도구입니다. 보행 운동과 관련한 계통의 <br/>반응을 총체적으로 반영하고 일상생활에 필요한 운동 능력을 반영합니다. \n' +
        '6분 동안 환자가 걸은 거리를 측정하고 1분마다 피로도와 <br/>호흡 곤란도를 측정합니다. 만약 중간에 측정을 중단할 경우 해당 거리를 기록하고 그에 대한 사유를 기록하며 검사를 종료합니다.</p>',
    dgi: '<p class="guide-text">Dynamic Gait Index (DGI), 동적 보행 검사는 평평한 지면에서의 보행, 보행 속도 변경하기, 보행하면서 머리를 옆으로 돌리기, <br/>보행하면서 상하로 머리를 움직이기, 보행하다가 한 발을 축으로 해서 돌기, 장애물 위를 지나 걷기, 장애물 주위를 걷기, <br/>계단 오르기 같은 과제가 포함된 보행능력을 정량화 하여 측정할 수 있는 도구입니다. <br/>0점에서 3점까지 4점 척도로, 점수가 높을수록 보행에 손상이 적은 것을 의미합니다.</p>',
    abc: '<p class="guide-text">ABC Scale은 자가 보고(self-reporting) 측정 검사방법을 사용하여 균형을 잃지 않고 <br/>다양한 활동을 수행하는 것에 대한 개인의 균형 자신감을 평가하는 검사입니다. <br/>항목은 0에서 100까지 등급 척도를 이용하며, 점수가 높을수록 균형 능력에 자신이 있음을 나타냅니다.</p>',
}
