export default [
    {
        label: '환자 번호',
        field: 'patientNo',
        sort: true
    },
    {
        label: '환자명',
        field: 'name',
    },
    {
        label: '평가 회차',
        field: 'round',
        sort: true
    },
    {
        label: '평가일',
        field: 'regDate',
    },
    {
        label: 'FM',
        field: 'fmScore',
    },
    {
        label: 'WMFT',
        field: 'wmftTime',
    },
    {
        label: 'BB',
        field: 'bbScore',
    },
    {
        label: 'AAUT\n  (AOU)',
        field: 'aautAouAvg',
    },
    {
        label: 'AAUT\n  (QOM)',
        field: 'aautQomAvg',
    },
    {
        label: 'MAL\n  (AOU)',
        field: 'malAouAvg',
    },
    {
        label: 'MAL\n  (QOM)',
        field: 'malQomAvg',
    },
    {
        label: 'SIS',
        field: 'sisScore'
    },
    {
        label: 'BBS',
        field: 'bbsTotal',
    },
    {
        label: 'TUG',
        field: 'tugTime',
    },
    {
        label: 'FTSTS',
        field: 'ftstsTime',
    },
    {
        label: 'FM_L',
        field: 'fm_lTotalAll',
    },
    {
        label: 'TMWT \n(SS)',
        field: 'tmwtSelfSelected',
    },
    {
        label: 'TMWT \n(FAST)',
        field: 'tmwtFast',
    },
    {
        label: 'SMWT',
        field: 'smwtTotal',
    },
    {
        label: 'DGI',
        field: 'dgiTotal',
    },
    {
        label: 'ABCscale',
        field: 'abcTotal',
    },
];
