<template>
  <div class="main-container">
    <div class="search-container" v-show="isPdfClick">
      <p>조회 옵션 설정</p>
      <div class="bs-flex guide-text">
        <img src="@/assets/images/IA/check-circle.png" alt="">
        <p>평가 도구 별 평균 데이터는 하단의 엑셀파일 다운로드로 지원합니다.</p>
      </div>
      <div class="bs-flex" style="margin-top:10px">
        <h5 class="title3">조회 기간 선택</h5>
        <mdb-select
            :scroll=false
            outline
            v-model="basicOptions"
            placeholder="전체 기간"
            @getValue="getSelectDateValue"
            class="search-select "/>
        <mdb-date-picker-2 far datePickerIcon="fas fa-calendar" :class="{disableColor :isDateDisable === true}"
                           v-model="selectStartDate" class="data-pick" placeholder="0000-00-00" :disabled="isDateDisable"/>
        <p style="margin: 0 3px"> - </p>
        <mdb-date-picker-2 far datePickerIcon="fas fa-calendar" :class="{disableColor :isDateDisable === true}"
                           v-model="selectEndDate" class="data-pick" placeholder="0000-00-00" :disabled="isDateDisable"/>
        <h5 class="title2">환자 선택</h5>
        <mdb-select
            :scroll=false
            outline
            v-model="patientsOptions"
            placeholder="전체 기간"
            @getValue="getSelectPatientsValue"
            class="search-select "/>
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input" v-on:keypress.enter.prevent="dataInit"/>
        </div>
        <button class="custom-btn-box search-btn" @click="dataInit">
          <img src="@/assets/images/IA/icon_user_2.png" alt="user">
          환자 검색
        </button>
      </div>
    </div>
    <div class="col-flex" v-if="isNoSearch" v-show="isPdfClick">
      <img src="@/assets/images/IA/img_null.png" alt="null">
      <p>환자를 검색해주세요.</p>
    </div>
    <div class="table-container" v-show="isPdfClick" v-else>
      <mdb-datatable-2
          v-model="data"
          v-if="isNewData"
          multiselectable
          class="text-center"
          hover
          striped
          fullPagination
          @selected="onSelected($event)"
          noFoundMessage="환자명을 확인해 주세요."/>
      <mdb-datatable-2 v-show="false" :pagination="false" id="data-table" v-model="excelData"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-fm" class="data-table2" v-model="excelDataFm"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-wmft" class="data-table2" v-model="excelDataWmft"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-bb" class="data-table2" v-model="excelDataBb"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-sis" class="data-table2" v-model="excelDataSis"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-aaut" class="data-table2" v-model="excelDataAAut"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-mal" class="data-table2" v-model="excelDataMal"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-bbs" class="data-table2" v-model="excelDataBbs"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-tug" class="data-table2" v-model="excelDataTug"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-ftsts" class="data-table2" v-model="excelDataFtsts"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-fm_l" class="data-table2" v-model="excelDataFml"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-tmwt" class="data-table2" v-model="excelDataTmwt"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-smwt" class="data-table2" v-model="excelDataSmwt"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-dgi" class="data-table2" v-model="excelDataDgi"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." id="data-table-abc" class="data-table2" v-model="excelDataAbc"/>
    </div>
    <div class="bs-flex">
      <button class="custom-btn-box exel-btn" @click="downExel">
        <img src="@/assets/images/IA/excel-download.png" alt="excel">
        Excel Download
      </button>
    </div>
  </div>
</template>

<script>
import {
  mdbDatatable2,
  mdbDatePicker2,
  mdbSelect
} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import total_tool_columns from "@/assets/data/total_tool_columns";
import * as XLSX from "xlsx";
import total_tool_columns_fm from "@/assets/data/dataToolRecord/total_tool_columns_fm";
import total_tool_columns_bb from "@/assets/data/dataToolRecord/total_tool_columns_bb";
import total_tool_columns_wmft from "@/assets/data/dataToolRecord/total_tool_columns_wmft";
import total_tool_columns_sis from "@/assets/data/dataToolRecord/total_tool_columns_sis";
import total_tool_columns_aaut from "@/assets/data/dataToolRecord/total_tool_columns_aaut";
import total_tool_columns_mal from "@/assets/data/dataToolRecord/total_tool_columns_mal";
import total_tool_columns_bbs from "@/assets/data/dataToolRecord/total_tool_columns_bbs";
import total_tool_columns_tug from "@/assets/data/dataToolRecord/total_tool_columns_tug";
import total_tool_columns_ftsts from "@/assets/data/dataToolRecord/total_tool_columns_ftsts";
import total_tool_columns_tmwt from "@/assets/data/dataToolRecord/total_tool_columns_tmwt";
import total_tool_columns_smwt from "@/assets/data/dataToolRecord/total_tool_columns_smwt";
import total_tool_columns_dgi from "@/assets/data/dataToolRecord/total_tool_columns_dgi";
import total_tool_columns_abc from "@/assets/data/dataToolRecord/total_tool_columns_abc";
import total_tool_columns_fm_l from "@/assets/data/dataToolRecord/total_tool_columns_fm_l";
import questionAndAnswer from "@/assets/data/evaluationToolsInfo/questionAndAnswer";

export default {
  name: "DataToolRecord",
  components: {
    mdbSelect,
    mdbDatePicker2,
    mdbDatatable2,
  },
  data() {
    return {
      partsIndex: 0,
      autoDate: 0,
      basicOptions: [
        {text: "1주일", value: 7},
        {text: "1개월", value: 30},
        {text: "3개월", value: 90},
        {text: "6개월", value: 180},
        {text: "1년", value: 365},
        {text: "전체 기간", value: 0, selected: true},
      ],
      patientsOptions: [
        {text: "환자명", value: '환자명', selected: true},
        {text: "환자 번호", value: '환자 번호'},
      ],
      selectStartDate: '',
      selectEndDate: '',
      search: '',
      fieldName: '환자명',

      fbCollection: 'evaluations',
      fbTherapistUid: this.$store.state.user.uid,
      isDateDisable: false,
      selectTool: {
        fm: false,
        sis: false,
        aaut: false,
        bb: false,
        wmft: false,
        mal: false,
      },
      isShowTool: {
        fm: false,
        sis: false,
        aaut: false,
        mal: false,
        bb: false,
        wmft: false,
      },
      selectAll: false,
      isNotAll: false,
      isNoSearch: true,
      isOnData: false,
      isPdfClick: true,
      patientKey: '',
      evaluationInfo: {},
      isNewData: true,

      data: {columns: total_tool_columns, rows: []},
      excelData: {columns: total_tool_columns, rows: []},
      excelDataFm: {columns: total_tool_columns_fm, rows: []},
      excelDataWmft: {columns: total_tool_columns_wmft, rows: []},
      excelDataBb: {columns: total_tool_columns_bb, rows: []},
      excelDataSis: {columns: total_tool_columns_sis, rows: []},
      excelDataAAut: {columns: total_tool_columns_aaut, rows: []},
      excelDataMal: {columns: total_tool_columns_mal, rows: []},
      excelDataBbs: {columns: total_tool_columns_bbs, rows: []},
      excelDataTug: {columns: total_tool_columns_tug, rows: []},
      excelDataFtsts: {columns: total_tool_columns_ftsts, rows: []},
      excelDataFml: {columns: total_tool_columns_fm_l, rows: []},
      excelDataTmwt: {columns: total_tool_columns_tmwt, rows: []},
      excelDataSmwt: {columns: total_tool_columns_smwt, rows: []},
      excelDataDgi: {columns: total_tool_columns_dgi, rows: []},
      excelDataAbc: {columns: total_tool_columns_abc, rows: []},
      toolIndex: questionAndAnswer.tools,
      selectDataCount: 0,
    }
  },
  methods: {
    dataInit() {
      const self = this;
      self.isNoSearch = false;
      self.isNewData = false
      self.data.rows.splice(0)
      self.excelData.rows.splice(0)
      const db = firebase.firestore();

      let docBasic = db.collection(self.fbCollection)
          .where('therapistInfo.uid', '==', self.fbTherapistUid)
          .where('state', '==', 1);

      if (self.search !== '' && self.search !== undefined) {
        if (self.fieldName === '환자명') {
          docBasic = docBasic
              .where('patientInfo.name', '==', self.search)
        } else if (self.fieldName === '환자 번호') {
          docBasic = docBasic
              .where('patientInfo.patientNo', '==', self.search)
        }
      }

      if (self.selectStartDate !== '' && self.selectStartDate !== undefined) {
        let start = new Date(self.selectStartDate);
        start.setHours(0, 0, 0, 0)
        docBasic = docBasic
            .where('regDate', '>=', start)
      }

      if (self.selectEndDate !== '' && self.selectEndDate !== undefined) {
        let end = new Date(self.selectEndDate);
        end.setHours(23, 59, 59, 999)
        docBasic = docBasic
            .where('regDate', '<=', end)
      }

      docBasic.orderBy("regDate", "desc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          self.patientCount = querySnapshot.size;
          let i = 0
          querySnapshot.forEach((doc) => {
            const _data = doc.data();
            _data["docId"] = doc.id;
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            _data.patientNo = _data.patientInfo.patientNo
            _data.name = _data.patientInfo.name
            _data.id = _data.patientInfo.id
            _data.round = _data.round + '회차'
            _data.diagnosis = _data.diseaseInfo.diagnosis
            if (_data.tools.fm.totalAll != null) _data.fmScore = _data.tools.fm.totalAll.toString()
            if (_data.tools.fm.totalA != null) _data.fmScoreA = _data.tools.fm.totalA.toString()
            if (_data.tools.fm.totalB != null) _data.fmScoreB = _data.tools.fm.totalB.toString()
            if (_data.tools.fm.totalC != null) _data.fmScoreC = _data.tools.fm.totalC.toString()
            if (_data.tools.fm.totalD != null) _data.fmScoreD = _data.tools.fm.totalD.toString()
            if (_data.tools.aaut.totalAouAvg != null) _data.aautAouAvg = _data.tools.aaut.totalAouAvg.toString()
            if (_data.tools.aaut.totalQomAvg != null) _data.aautQomAvg = _data.tools.aaut.totalQomAvg.toString()
            if (_data.tools.bb.ma != null) _data.bbScoreMa = _data.tools.bb.ma.toString()
            if (_data.tools.bb.la != null) _data.bbScoreLa = _data.tools.bb.la.toString()
            if (_data.tools.wmft.totalAvg != null) _data.wmftTime = _data.tools.wmft.totalAvg.toString()
            if (_data.tools.mal.totalAouAvg != null) _data.malAouAvg = _data.tools.mal.totalAouAvg.toString()
            if (_data.tools.mal.totalQomAvg != null) _data.malQomAvg = _data.tools.mal.totalQomAvg.toString()
            if (_data.tools.sis.totalAvg != null) _data.sisScore = _data.tools.sis.totalAvg.toString()
            if (_data.tools.sis.totalSum != null) _data.sisSum = _data.tools.sis.totalSum.toString()
            if (_data.tools.bbs !== undefined) {
              if (_data.tools.bbs.total != null) _data.bbsSum = _data.tools.bbs.total.toString()
              if (_data.tools.dgi.total != null) _data.dgiSum = _data.tools.dgi.total.toString()
              if (_data.tools.abc.total != null) _data.abcSum = _data.tools.abc.total.toString()
              if (_data.tools.tug.time != null) _data.tugTime = _data.tools.tug.time.toString()
              if (_data.tools.ftsts.time != null) _data.ftstsTime = _data.tools.ftsts.time.toString()
              if (_data.tools.tmwt.fast_speed) {
                if (_data.tools.tmwt.self_selected_speed.average_self_selected != null) _data.tmwtSSAvgVelocity = _data.tools.tmwt.self_selected_speed.average_self_selected.toString()
                if (_data.tools.tmwt.fast_speed.average_self_selected != null) _data.tmwtFastAvgVelocity = _data.tools.tmwt.fast_speed.average_self_selected.toString()
              }
              if (_data.tools.smwt.distance_1 != null) _data.smwtTotal = (_data.tools.smwt.distance_1 + _data.tools.smwt.distance_2 + _data.tools.smwt.distance_3 +
                  _data.tools.smwt.distance_4 + _data.tools.smwt.distance_5 + _data.tools.smwt.distance_6).toString()
              if (_data.tools.fm_l.totalAll != null) _data.fm_lScore = _data.tools.fm_l.totalAll.toString()
              if (_data.tools.fm_l.totalE != null) _data.fm_lScoreE = _data.tools.fm_l.totalE.toString()
              if (_data.tools.fm_l.totalF != null) _data.fm_lScoreF = _data.tools.fm_l.totalF.toString()
            }
            self.data.rows.push(_data);
            self.checkTools(self.data.rows[i].tools, _data.docId)
            i++
          });
        }
      })
      setTimeout(function () {
        self.isNewData = true
      }, 100);

    },

    async checkTools(array1, id) {
      const self = this;
      let isSet = false;

      const toolsToUpdate = {};

      const toolKeys = [
        'bm', 'rom_mmt', 'mas', 'balance', 'fm', 'bb', 'wmft', 'sis', 'aaut',
        'mal', 'bbs', 'tug', 'ftsts', 'fm_l', 'tmwt', 'smwt', 'dgi', 'abc'
      ];

      toolKeys.forEach(toolKey => {
        if (array1[toolKey] === undefined) {
          array1[toolKey] = self.toolIndex[toolKey];
          isSet = true;
          toolsToUpdate[toolKey] = self.toolIndex[toolKey];
        }
      });

      if (isSet) {
        await firebase.firestore().collection('evaluations').doc(id)
            .set({ tools: array1 }, { merge: true });
      }
    },
    getDateStr(myDate) {
      const year = myDate.getFullYear();
      const month = ("00" + (myDate.getMonth() + 1)).slice(-2)
      const day = ("00" + myDate.getDate()).slice(-2)
      return year + '-' + month + '-' + day
    },

    getSelectDateValue(value) {
      const self = this;
      this.fieldName = value
      let now = new Date();
      self.selectEndDate = this.getDateStr(now)
      const dDay = now.getDate();
      switch (value) {
        case 0 : {
          self.isDateDisable = false;
          self.selectEndDate = '';
          self.selectStartDate = '';
          return
        }
        default :
          now.setDate(dDay - value);
          self.isDateDisable = true;
          self.selectStartDate = this.getDateStr(now)
          return
      }
    },
    getSelectPatientsValue(value) {
      this.fieldName = value
    },
    onSelected(value) {
      const self = this;
      self.selectDataCount = value.length
      self.excelData.rows.splice(0)
      self.excelDataFm.rows.splice(0)
      self.excelDataWmft.rows.splice(0)
      self.excelDataBb.rows.splice(0)
      self.excelDataSis.rows.splice(0)
      self.excelDataAAut.rows.splice(0)
      self.excelDataMal.rows.splice(0)
      self.excelDataBbs.rows.splice(0)
      self.excelDataTug.rows.splice(0)
      self.excelDataFtsts.rows.splice(0)
      self.excelDataFml.rows.splice(0)
      self.excelDataTmwt.rows.splice(0)
      self.excelDataSmwt.rows.splice(0)
      self.excelDataDgi.rows.splice(0)
      self.excelDataAbc.rows.splice(0)
      if (!value) {
        return;
      }

      const tooltools = ['fm', 'wmft', 'bb', 'sis', 'aaut', 'mal', 'bbs', 'tug', 'ftsts', 'fm_l', 'tmwt', 'smwt', 'dgi', 'abc'];

      value.forEach((element) => {
        tooltools.forEach((_tool) => {
          if (element.tools[_tool].state === 2) {
            if (_tool === 'fm') self.excelDataFm.rows.push(element)
            if (_tool === 'wmft') self.excelDataWmft.rows.push(element)
            if (_tool === 'bb') self.excelDataBb.rows.push(element)
            if (_tool === 'sis') self.excelDataSis.rows.push(element)
            if (_tool === 'aaut') self.excelDataAAut.rows.push(element)
            if (_tool === 'mal') self.excelDataMal.rows.push(element)
            if (_tool === 'bbs') self.excelDataBbs.rows.push(element)
            if (_tool === 'tug') self.excelDataTug.rows.push(element)
            if (_tool === 'ftsts') self.excelDataFtsts.rows.push(element)
            if (_tool === 'fm_l') self.excelDataFml.rows.push(element)
            if (_tool === 'tmwt') self.excelDataTmwt.rows.push(element)
            if (_tool === 'smwt') self.excelDataSmwt.rows.push(element)
            if (_tool === 'dgi') self.excelDataDgi.rows.push(element)
            if (_tool === 'abc') self.excelDataAbc.rows.push(element)
          }
        })
        self.excelData.rows.push(element)
      })
    },

    downExel() {
      const self = this;
      if (self.selectDataCount === 0) return alert('선택된 데이터가 없습니다.')
      let excelData = XLSX.utils.table_to_sheet(document.getElementById('data-table')); // table id
      let workBook = XLSX.utils.book_new(); // 새 시트 생성

      XLSX.utils.book_append_sheet(workBook, excelData, 'All');

      let sheetName = '';

      const tooltools = ['fm', 'wmft', 'bb', 'sis', 'aaut', 'mal', 'bbs', 'tug', 'ftsts', 'fm_l', 'tmwt', 'smwt', 'dgi', 'abc'];
      for (let i = 0; i < tooltools.length; i++) {
        let excelData2 = XLSX.utils.table_to_sheet(document.getElementsByClassName('data-table2')[i]);
        sheetName = tooltools[i];
        XLSX.utils.book_append_sheet(workBook, excelData2, sheetName);  // 시트 명명, 데이터 지정
      }

      const d = new Date();
      const today = d.getFullYear()
          + "-" + ("00" + (d.getMonth() + 1)).slice(-2)
          + "-" + ("00" + d.getDate()).slice(-2)
          + " " + ("00" + d.getHours()).slice(-2)
          + ":" + ("00" + d.getMinutes()).slice(-2)
          + ":" + ("00" + d.getSeconds()).slice(-2)
      XLSX.writeFile(workBook, 'NRF_평가도구별평균_' + today + '.xlsx'); // 엑셀파일 생성
    }
  }
}
</script>

<style scoped>
.main-container {

}

.search-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.search-container p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0 0 10px
}

.bs-flex {
  display: flex;
  align-items: center;
}

.col-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 221px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 20px;
}

.col-flex img {
  width: 131px;
  margin-bottom: 8px;
}

.col-flex p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  padding-bottom: 10px;
  margin: 0 8px 0 0;
}

.title2 {
  width: 100px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 8px 0 0;
  padding-right: 8px;
  text-align: right;
}

.title3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-right: 12px;
}

.search_btn_active {
  width: 100px;
  height: 38px;
  background: #F1F6FD !important;
  border: 1px solid #0069CA !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #0069CA !important;
}

/deep/ .search-select .md-form {
  width: 100px !important;
  margin: 0 8px 0 0 !important;
}

.search-btn-2 {
  width: 100px;
  height: 38px;
  background: #F8F8FA;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #B1B1B1;
  margin-right: 8px;
}

/deep/ .mdb-datepicker-2 .form-control {
  height: 40px;
  margin: 0 !important;
  width: 123px;
}

/deep/ .mdb-datepicker-2.md-form.md-outline {
  margin: 0;
}

.search-input {
  width: 258px;
  padding-left: 30px;
  font-size: 16px;
}


.guide-text {
  margin: 0 0 10px 0;
}

.guide-text img {
  width: 18px;
  margin-left: auto;
  margin-right: 5px;
}

.guide-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #0A1120;
  margin: 0;
}

.check-box-area {
  width: 100%;
  justify-content: space-around;
  height: 78px;
  background: #F8F8FA;
  border-radius: 8px;
  display: flex;
  padding: 15px 20px;
  align-items: center;
}

.check-box-area p {
  font-style: normal;
  width: 126px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0;
}

.divide {
  width: 2px;
  height: 48px;
  margin: 0 8px;
  background: #FFFFFF;
}

.icon-input {
  position: relative;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-btn {
  width: 110px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.search-btn img {
  margin-right: 6px;
  width: 18px;
}

.table-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  margin-top: 20px
}

/deep/
.form-check-input[type="checkbox"].filled-in:checked + label:after {
  background-color: #0069CA !important;
  border: 2px solid #0069CA !important;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F1F6FD;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(227, 238, 252, 1);
}

/deep/ .mdb-datatable thead th {
  font-weight: 700;
  padding-left: 0 !important;
  min-width: 89px;
  white-space: pre-wrap;
}

/deep/ .mdb-datatable thead th:first-child {
  padding-top: 16px;
}

/deep/ .mdb-datatable tbody td {
  padding-left: 0 !important;
  min-width: 89px;
}

/deep/ .mdb-datatable tbody td:first-child {
  min-width: 30px !important;
}

/deep/ .mdb-datatable thead th:first-child {
  min-width: 30px !important;
  text-align: center;
}

/deep/ td {
  text-align: center !important;
  cursor: pointer;
}

.disableColor {
  background: #EDEDF1;
}

.exel-btn {
  width: 185px;
  height: 40px;
  background: #08A86E;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 auto;
}

.exel-btn img {
  width: 18px;
  margin: 0 15px 0 10px;
}

/deep/ .patient-select-box .md-form {
  width: 320px !important;
}

/deep/ .table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff
}

/deep/ .form-check {
  padding-left: 23px;
}

/deep/ .search-select input {
  padding-left: 7.5px !important;
}

/deep/ .mdb-datepicker-2 input {
  padding-left: 7.5px !important;
}

/deep/ .mdb-datepicker-2 i {
  margin-top: 3px;
}

</style>
