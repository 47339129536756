<template>
  <div class="search-result-item" @click="setUserId">
    <img src="@/assets/images/IA/icon_patient_xs.png" alt="add">
    <div class="search-result-item-info">
      <p class="info-name">{{ searchUser.name }}</p>
      <p class="info-id">{{ searchUser.id }}</p>
    </div>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "SearchListView",
  props: {
    searchUser: Object,
  },
  methods: {
    async setUserId() {
      const self = this;

      let isExist = false;
      const db = firebase.firestore();
      const defRef_3 = db.collection('patients').where('userInfo.uid', '==', self.searchUser.uid);
      await defRef_3
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size !== 0) {
              isExist = true;
            }
          });

      if (isExist) {
        await alert('이미 등록된 환자입니다.');
        return;
      }

      self.$emit('setUserId', self.searchUser)
    },
  },
}
</script>

<style scoped>
.search-result-item {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  align-items: center;
  cursor: pointer;
}

.search-result-item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.info-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #000000;
  margin: 0;
}

.info-id {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #999999;
  margin: 0;
}
</style>
