export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'lt_shoulder_flexion',
        field: 'lt_shoulder_flexion',
    },
    {
        label: 'lt_shoulder_extension',
        field: 'lt_shoulder_extension',
    },
    {
        label: 'lt_shoulder_abduction',
        field: 'lt_shoulder_abduction',
    },
    {
        label: 'lt_shoulder_adduction',
        field: 'lt_shoulder_adduction',
    },
    {
        label: 'lt_shoulder_internal_rotation',
        field: 'lt_shoulder_internal_rotation',
    },
    {
        label: 'lt_shoulder_external_rotation',
        field: 'lt_shoulder_external_rotation',
    },
    {
        label: 'lt_elbow_flexion',
        field: 'lt_elbow_flexion',
    },
    {
        label: 'lt_elbow_extension',
        field: 'lt_elbow_extension',
    },
    {
        label: 'lt_forearm_supination',
        field: 'lt_forearm_supination',
    },
    {
        label: 'lt_forearm_pronation',
        field: 'lt_forearm_pronation',
    },
    {
        label: 'lt_wrist_flexion',
        field: 'lt_wrist_flexion',
    },
    {
        label: 'lt_wrist_extension',
        field: 'lt_wrist_extension',
    },
    {
        label: 'lt_wrist_radial_deviation',
        field: 'lt_wrist_radial_deviation',
    },
    {
        label: 'lt_wrist_ulnar_deviation',
        field: 'lt_wrist_ulnar_deviation',
    },
    {
        label: 'lt_hip_flexion',
        field: 'lt_hip_flexion',
    },
    {
        label: 'lt_hip_extension',
        field: 'lt_hip_extension',
    },
    {
        label: 'lt_hip_abduction',
        field: 'lt_hip_abduction',
    },
    {
        label: 'lt_hip_adduction',
        field: 'lt_hip_adduction',
    },
    {
        label: 'lt_hip_internal_rotation',
        field: 'lt_hip_internal_rotation',
    },
    {
        label: 'lt_hip_external_rotation',
        field: 'lt_hip_external_rotation',
    },
    {
        label: 'lt_knee_flexion',
        field: 'lt_knee_flexion',
    },
    {
        label: 'lt_knee_extension',
        field: 'lt_knee_extension',
    },
    {
        label: 'lt_knee_internal_rotation',
        field: 'lt_knee_internal_rotation',
    },
    {
        label: 'lt_knee_external_rotation',
        field: 'lt_knee_external_rotation',
    },
    {
        label: 'lt_ankle_dorsi_flexion',
        field: 'lt_ankle_dorsi_flexion',
    },
    {
        label: 'lt_ankle_plantar_flexion',
        field: 'lt_ankle_plantar_flexion',
    },
    {
        label: 'lt_ankle_inversion',
        field: 'lt_ankle_inversion',
    },
    {
        label: 'lt_ankle_eversion',
        field: 'lt_ankle_eversion',
    },
    {
        label: 'rt_shoulder_flexion',
        field: 'rt_shoulder_flexion',
    },
    {
        label: 'rt_shoulder_extension',
        field: 'rt_shoulder_extension',
    },
    {
        label: 'rt_shoulder_abduction',
        field: 'rt_shoulder_abduction',
    },
    {
        label: 'rt_shoulder_adduction',
        field: 'rt_shoulder_adduction',
    },
    {
        label: 'rt_shoulder_internal_rotation',
        field: 'rt_shoulder_internal_rotation',
    },
    {
        label: 'rt_shoulder_external_rotation',
        field: 'rt_shoulder_external_rotation',
    },
    {
        label: 'rt_elbow_flexion',
        field: 'rt_elbow_flexion',
    },
    {
        label: 'rt_elbow_extension',
        field: 'rt_elbow_extension',
    },
    {
        label: 'rt_forearm_supination',
        field: 'rt_forearm_supination',
    },
    {
        label: 'rt_forearm_pronation',
        field: 'rt_forearm_pronation',
    },
    {
        label: 'rt_wrist_flexion',
        field: 'rt_wrist_flexion',
    },
    {
        label: 'rt_wrist_extension',
        field: 'rt_wrist_extension',
    },
    {
        label: 'rt_wrist_radial_deviation',
        field: 'rt_wrist_radial_deviation',
    },
    {
        label: 'rt_wrist_ulnar_deviation',
        field: 'rt_wrist_ulnar_deviation',
    },
    {
        label: 'rt_hip_flexion',
        field: 'rt_hip_flexion',
    },
    {
        label: 'rt_hip_extension',
        field: 'rt_hip_extension',
    },
    {
        label: 'rt_hip_abduction',
        field: 'rt_hip_abduction',
    },
    {
        label: 'rt_hip_adduction',
        field: 'rt_hip_adduction',
    },
    {
        label: 'rt_hip_internal_rotation',
        field: 'rt_hip_internal_rotation',
    },
    {
        label: 'rt_hip_external_rotation',
        field: 'rt_hip_external_rotation',
    },
    {
        label: 'rt_knee_flexion',
        field: 'rt_knee_flexion',
    },
    {
        label: 'rt_knee_extension',
        field: 'rt_knee_extension',
    },
    {
        label: 'rt_knee_internal_rotation',
        field: 'rt_knee_internal_rotation',
    },
    {
        label: 'rt_knee_external_rotation',
        field: 'rt_knee_external_rotation',
    },
    {
        label: 'rt_ankle_dorsi_flexion',
        field: 'rt_ankle_dorsi_flexion',
    },
    {
        label: 'rt_ankle_plantar_flexion',
        field: 'rt_ankle_plantar_flexion',
    },
    {
        label: 'rt_ankle_inversion',
        field: 'rt_ankle_inversion',
    },
    {
        label: 'rt_ankle_eversion',
        field: 'rt_ankle_eversion',
    },

];
