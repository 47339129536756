<template>
  <div class="paper-frame">
    <div class="modal-cover" v-show="isShowModal"></div>
    <h5 class="title">Fugl-Meyer 표준화 평가지</h5>
    <div class="guide">
      <img src="@/assets/images/IA/information-circle.png" alt="circle">
      <p>
        본 과정은 푸글마이어 상지평가(Fugl-Meyer Assessment Upper Extremity, FMA-UE)에 대한 표준화 과정을 위한 것입니다.<br/>
        Part A와 Part B로 구성되어 있으며, 본인의 FM 평가를 바탕으로 다음 항목들을 채점하십시오.<br/>
        표준화과정을 위해서는 본인이 평가한 환자의 FM 점수가 필요합니다.<br/>
        각 요소에 대해 영상을 보고 참(T)인지 거짓(F)인지 체크하십시오.<br/>
        참(T)이 전체 질문 (142개) 중 90%가 넘으면 표준화 과정에 통과한 것이며, 검증된 평가자로 환자 평가를 진행하시면 되겠습니다.
      </p>
    </div>
    <div class="paper-box">
      <div class="title-box">
        <p>Part A : 일반적인 규칙</p>
      </div>
      <div class="grey-box">
        <p>일반적인 규칙</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partA[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_001" class="custom-control-input" id="fm_001_1" v-model="paperData.fm_001" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_001_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_001" class="custom-control-input" id="fm_001_0" v-model="paperData.fm_001" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_001_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partA[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_002" class="custom-control-input" id="fm_002_1" v-model="paperData.fm_002" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_002_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_002" class="custom-control-input" id="fm_002_0" v-model="paperData.fm_002" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_002_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partA[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_003" class="custom-control-input" id="fm_003_1" v-model="paperData.fm_003" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_003_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_003" class="custom-control-input" id="fm_003_0" v-model="paperData.fm_003" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_003_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partA[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_004" class="custom-control-input" id="fm_004_1" v-model="paperData.fm_004" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_004_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_004" class="custom-control-input" id="fm_004_0" v-model="paperData.fm_004" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_004_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partA[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_005" class="custom-control-input" id="fm_005_1" v-model="paperData.fm_005" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_005_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_005" class="custom-control-input" id="fm_005_0" v-model="paperData.fm_005" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_005_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partA[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_006" class="custom-control-input" id="fm_006_1" v-model="paperData.fm_006" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_006_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_006" class="custom-control-input" id="fm_006_0" v-model="paperData.fm_006" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_006_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="score-line">
        <h5> Total Scores</h5>
        <p class="true-count" v-html="paperData.fm_001 + paperData.fm_002 + paperData.fm_003 +paperData.fm_004 +paperData.fm_005 +paperData.fm_006"></p>
        <p class="slash">/</p>
        <p class="false-count">6</p>
      </div>
      <div class="title-box">
        <p>Part B : FMA-UE 항목별 평가</p>
      </div>
      <div class="guide-2">
        <img src="@/assets/images/IA/information-circle.png" alt="circle">
        <p>
          참고사항 :<br/>
          1. 대상자에게 평가를 진행시 비손상측의 움직임을 먼저 수행하도록 진행합니다.<br/>
          2. 대상자가 동작을 수행하기 전에 대상자의 PROM을 평가합니다.<br/>
          3. 시작 자세가 올바른지 확인합니다.<br/>
          4. 정확한 구두지시를 내립니다. 이때 평가자가 데모(Demonstration)을 해서 본인의 동작을 대상자가 따라할 수 있게 해도 좋습니다.<br/>
          5. 손상측을 이용해 최대 3번 동작을 수행하도록 진행하며, 점수를 정확히 기록합니다.<br/>
          6. 대상자가 첫 번째 또는 두번째 수행에서 ‘2점(만점)’을 받은 경우 세번째 수행은 진행하지 않아도 됩니다.<br/>
        </p>
      </div>
      <div class="grey-box">
        <p>1. Reflex</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_1[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_007" class="custom-control-input" id="fm_007_1" v-model="paperData.fm_007" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_007_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_007" class="custom-control-input" id="fm_007_0" v-model="paperData.fm_007" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_007_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_1[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_008" class="custom-control-input" id="fm_008_1" v-model="paperData.fm_008" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_008_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_008" class="custom-control-input" id="fm_008_0" v-model="paperData.fm_008" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_008_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_1[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_009" class="custom-control-input" id="fm_009_1" v-model="paperData.fm_009" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_009_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_009" class="custom-control-input" id="fm_009_0" v-model="paperData.fm_009" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_009_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_1[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_010" class="custom-control-input" id="fm_010_1" v-model="paperData.fm_010" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_010_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_010" class="custom-control-input" id="fm_010_0" v-model="paperData.fm_010" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_010_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_1[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_011" class="custom-control-input" id="fm_011_1" v-model="paperData.fm_011" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_011_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_011" class="custom-control-input" id="fm_011_0" v-model="paperData.fm_011" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_011_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>2. Flexor Synergy</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_2[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_012" class="custom-control-input" id="fm_012_1" v-model="paperData.fm_012" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_012_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_012" class="custom-control-input" id="fm_012_0" v-model="paperData.fm_012" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_012_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_2[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_013" class="custom-control-input" id="fm_013_1" v-model="paperData.fm_013" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_013_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_013" class="custom-control-input" id="fm_013_0" v-model="paperData.fm_013" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_013_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_2[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_014" class="custom-control-input" id="fm_014_1" v-model="paperData.fm_014" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_014_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_014" class="custom-control-input" id="fm_014_0" v-model="paperData.fm_014" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_014_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_2[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_015" class="custom-control-input" id="fm_015_1" v-model="paperData.fm_015" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_015_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_015" class="custom-control-input" id="fm_015_0" v-model="paperData.fm_015" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_015_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_2[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_016" class="custom-control-input" id="fm_016_1" v-model="paperData.fm_016" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_016_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_016" class="custom-control-input" id="fm_016_0" v-model="paperData.fm_016" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_016_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>3. Extensor Synergy</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_3[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_017" class="custom-control-input" id="fm_017_1" v-model="paperData.fm_017" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_017_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_017" class="custom-control-input" id="fm_017_0" v-model="paperData.fm_017" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_017_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_3[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_018" class="custom-control-input" id="fm_018_1" v-model="paperData.fm_018" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_018_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_018" class="custom-control-input" id="fm_018_0" v-model="paperData.fm_018" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_018_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_3[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_019" class="custom-control-input" id="fm_019_1" v-model="paperData.fm_019" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_019_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_019" class="custom-control-input" id="fm_019_0" v-model="paperData.fm_019" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_019_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_3[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_020" class="custom-control-input" id="fm_020_1" v-model="paperData.fm_020" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_020_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_020" class="custom-control-input" id="fm_020_0" v-model="paperData.fm_020" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_020_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_3[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_021" class="custom-control-input" id="fm_021_1" v-model="paperData.fm_021" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_021_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_021" class="custom-control-input" id="fm_021_0" v-model="paperData.fm_021" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_021_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>4. Movement combining synergies</p>
      </div>
      <div class="grey-box">
        <p>4a. Movement combining synergies: Hand to lumbar spine</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_a[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_022" class="custom-control-input" id="fm_022_1" v-model="paperData.fm_022" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_022_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_022" class="custom-control-input" id="fm_022_0" v-model="paperData.fm_022" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_022_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_a[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_023" class="custom-control-input" id="fm_023_1" v-model="paperData.fm_023" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_023_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_023" class="custom-control-input" id="fm_023_0" v-model="paperData.fm_023" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_023_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_a[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_024" class="custom-control-input" id="fm_024_1" v-model="paperData.fm_024" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_024_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_024" class="custom-control-input" id="fm_024_0" v-model="paperData.fm_024" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_024_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_a[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_025" class="custom-control-input" id="fm_025_1" v-model="paperData.fm_025" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_025_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_025" class="custom-control-input" id="fm_025_0" v-model="paperData.fm_025" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_025_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_a[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_026" class="custom-control-input" id="fm_026_1" v-model="paperData.fm_026" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_026_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_026" class="custom-control-input" id="fm_026_0" v-model="paperData.fm_026" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_026_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>4b. Movement combining synergies: Shoulder flexion to 90°, elbow at 0°</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_b[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_027" class="custom-control-input" id="fm_027_1" v-model="paperData.fm_027" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_027_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_027" class="custom-control-input" id="fm_027_0" v-model="paperData.fm_027" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_027_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_b[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_028" class="custom-control-input" id="fm_028_1" v-model="paperData.fm_028" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_028_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_028" class="custom-control-input" id="fm_028_0" v-model="paperData.fm_028" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_028_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_b[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_029" class="custom-control-input" id="fm_029_1" v-model="paperData.fm_029" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_029_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_029" class="custom-control-input" id="fm_029_0" v-model="paperData.fm_029" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_029_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_b[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_030" class="custom-control-input" id="fm_030_1" v-model="paperData.fm_030" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_030_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_030" class="custom-control-input" id="fm_030_0" v-model="paperData.fm_030" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_030_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_b[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_031" class="custom-control-input" id="fm_031_1" v-model="paperData.fm_031" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_031_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_031" class="custom-control-input" id="fm_031_0" v-model="paperData.fm_031" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_031_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>4c. Movement combining synergies: Pronation/supination of forearm, elbow at 90°, shoulder at 0°:</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_c[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_032" class="custom-control-input" id="fm_032_1" v-model="paperData.fm_032" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_032_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_032" class="custom-control-input" id="fm_032_0" v-model="paperData.fm_032" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_032_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_c[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_033" class="custom-control-input" id="fm_033_1" v-model="paperData.fm_033" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_033_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_033" class="custom-control-input" id="fm_033_0" v-model="paperData.fm_033" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_033_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_c[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_034" class="custom-control-input" id="fm_034_1" v-model="paperData.fm_034" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_034_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_034" class="custom-control-input" id="fm_034_0" v-model="paperData.fm_034" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_034_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_c[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_035" class="custom-control-input" id="fm_035_1" v-model="paperData.fm_035" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_035_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_035" class="custom-control-input" id="fm_035_0" v-model="paperData.fm_035" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_035_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_4_c[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_036" class="custom-control-input" id="fm_036_1" v-model="paperData.fm_036" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_036_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_036" class="custom-control-input" id="fm_036_0" v-model="paperData.fm_036" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_036_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>5. Movement out of synergies</p>
      </div>
      <div class="grey-box">
        <p>5a. Movement out of synergies: Shoulder abduction to 90°, elbow at 0°, and forearm pronated.</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_a[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_037" class="custom-control-input" id="fm_037_1" v-model="paperData.fm_037" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_037_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_037" class="custom-control-input" id="fm_037_0" v-model="paperData.fm_037" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_037_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_a[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_038" class="custom-control-input" id="fm_038_1" v-model="paperData.fm_038" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_038_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_038" class="custom-control-input" id="fm_038_0" v-model="paperData.fm_038" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_038_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_a[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_039" class="custom-control-input" id="fm_039_1" v-model="paperData.fm_039" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_039_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_039" class="custom-control-input" id="fm_039_0" v-model="paperData.fm_039" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_039_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_a[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_040" class="custom-control-input" id="fm_040_1" v-model="paperData.fm_040" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_040_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_040" class="custom-control-input" id="fm_040_0" v-model="paperData.fm_040" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_040_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_a[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_041" class="custom-control-input" id="fm_041_1" v-model="paperData.fm_041" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_041_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_041" class="custom-control-input" id="fm_041_0" v-model="paperData.fm_041" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_041_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>5b. Movement out of synergies: Shoulder flexion from 90°-180°, elbow at 0°, and forearm in mid-position.</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_b[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_042" class="custom-control-input" id="fm_042_1" v-model="paperData.fm_042" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_042_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_042" class="custom-control-input" id="fm_042_0" v-model="paperData.fm_042" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_042_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_b[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_043" class="custom-control-input" id="fm_043_1" v-model="paperData.fm_043" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_043_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_043" class="custom-control-input" id="fm_043_0" v-model="paperData.fm_043" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_043_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_b[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_044" class="custom-control-input" id="fm_044_1" v-model="paperData.fm_044" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_044_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_044" class="custom-control-input" id="fm_044_0" v-model="paperData.fm_044" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_044_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_b[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_045" class="custom-control-input" id="fm_045_1" v-model="paperData.fm_045" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_045_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_045" class="custom-control-input" id="fm_045_0" v-model="paperData.fm_045" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_045_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_b[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_046" class="custom-control-input" id="fm_046_1" v-model="paperData.fm_046" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_046_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_046" class="custom-control-input" id="fm_046_0" v-model="paperData.fm_046" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_046_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>5c. Movement out of synergies: Pronation/supination of forearm, elbow at 0°, shoulder at 30°-90° of flexion</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_c[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_047" class="custom-control-input" id="fm_047_1" v-model="paperData.fm_047" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_047_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_047" class="custom-control-input" id="fm_047_0" v-model="paperData.fm_047" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_047_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_c[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_048" class="custom-control-input" id="fm_048_1" v-model="paperData.fm_048" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_048_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_048" class="custom-control-input" id="fm_048_0" v-model="paperData.fm_048" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_048_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_c[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_049" class="custom-control-input" id="fm_049_1" v-model="paperData.fm_049" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_049_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_049" class="custom-control-input" id="fm_049_0" v-model="paperData.fm_049" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_049_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_c[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_050" class="custom-control-input" id="fm_050_1" v-model="paperData.fm_050" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_050_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_050" class="custom-control-input" id="fm_050_0" v-model="paperData.fm_050" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_050_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_5_c[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_051" class="custom-control-input" id="fm_051_1" v-model="paperData.fm_051" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_051_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_051" class="custom-control-input" id="fm_051_0" v-model="paperData.fm_051" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_051_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>6. Normal reflex activity: Biceps and/or finger flexors and triceps</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_6[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_052" class="custom-control-input" id="fm_052_1" v-model="paperData.fm_052" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_052_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_052" class="custom-control-input" id="fm_052_0" v-model="paperData.fm_052" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_052_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_6[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_053" class="custom-control-input" id="fm_053_1" v-model="paperData.fm_053" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_053_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_053" class="custom-control-input" id="fm_053_0" v-model="paperData.fm_053" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_053_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_6[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_054" class="custom-control-input" id="fm_054_1" v-model="paperData.fm_054" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_054_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_054" class="custom-control-input" id="fm_054_0" v-model="paperData.fm_054" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_054_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>7: Wrist</p>
      </div>
      <div class="grey-box">
        <p>7a. Wrist: Stability, elbow at 90°, and shoulder at 0°</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_a[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_055" class="custom-control-input" id="fm_055_1" v-model="paperData.fm_055" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_055_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_055" class="custom-control-input" id="fm_055_0" v-model="paperData.fm_055" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_055_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_a[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_056" class="custom-control-input" id="fm_056_1" v-model="paperData.fm_056" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_056_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_056" class="custom-control-input" id="fm_056_0" v-model="paperData.fm_056" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_056_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_a[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_057" class="custom-control-input" id="fm_057_1" v-model="paperData.fm_057" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_057_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_057" class="custom-control-input" id="fm_057_0" v-model="paperData.fm_057" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_057_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_a[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_058" class="custom-control-input" id="fm_058_1" v-model="paperData.fm_058" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_058_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_058" class="custom-control-input" id="fm_058_0" v-model="paperData.fm_058" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_058_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_a[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_059" class="custom-control-input" id="fm_059_1" v-model="paperData.fm_059" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_059_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_059" class="custom-control-input" id="fm_059_0" v-model="paperData.fm_059" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_059_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_a[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_060" class="custom-control-input" id="fm_060_1" v-model="paperData.fm_060" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_060_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_060" class="custom-control-input" id="fm_060_0" v-model="paperData.fm_060" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_060_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>7b.Wrist: Flexion/extension, elbow at 90°, and shoulder at 0°:</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_b[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_061" class="custom-control-input" id="fm_061_1" v-model="paperData.fm_061" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_061_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_061" class="custom-control-input" id="fm_061_0" v-model="paperData.fm_061" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_061_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_b[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_062" class="custom-control-input" id="fm_062_1" v-model="paperData.fm_062" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_062_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_062" class="custom-control-input" id="fm_062_0" v-model="paperData.fm_062" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_062_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_b[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_063" class="custom-control-input" id="fm_063_1" v-model="paperData.fm_063" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_063_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_063" class="custom-control-input" id="fm_063_0" v-model="paperData.fm_063" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_063_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_b[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_064" class="custom-control-input" id="fm_064_1" v-model="paperData.fm_064" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_064_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_064" class="custom-control-input" id="fm_064_0" v-model="paperData.fm_064" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_064_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_b[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_065" class="custom-control-input" id="fm_065_1" v-model="paperData.fm_065" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_065_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_065" class="custom-control-input" id="fm_065_0" v-model="paperData.fm_065" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_065_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>7c.Wrist: Stability, elbow at 0°, and shoulder at 30° flexion:</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_c[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_066" class="custom-control-input" id="fm_066_1" v-model="paperData.fm_066" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_066_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_066" class="custom-control-input" id="fm_066_0" v-model="paperData.fm_066" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_066_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_c[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_067" class="custom-control-input" id="fm_067_1" v-model="paperData.fm_067" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_067_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_067" class="custom-control-input" id="fm_067_0" v-model="paperData.fm_067" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_067_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_c[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_068" class="custom-control-input" id="fm_068_1" v-model="paperData.fm_068" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_068_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_068" class="custom-control-input" id="fm_068_0" v-model="paperData.fm_068" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_068_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_c[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_069" class="custom-control-input" id="fm_069_1" v-model="paperData.fm_069" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_069_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_069" class="custom-control-input" id="fm_069_0" v-model="paperData.fm_069" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_069_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_c[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_070" class="custom-control-input" id="fm_070_1" v-model="paperData.fm_070" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_070_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_070" class="custom-control-input" id="fm_070_0" v-model="paperData.fm_070" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_070_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_c[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_071" class="custom-control-input" id="fm_071_1" v-model="paperData.fm_071" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_071_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_071" class="custom-control-input" id="fm_071_0" v-model="paperData.fm_071" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_071_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>7d.Wrist: Flexion/extension, elbow at 0°, and shoulder at 30° flexion</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_d[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_072" class="custom-control-input" id="fm_072_1" v-model="paperData.fm_072" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_072_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_072" class="custom-control-input" id="fm_072_0" v-model="paperData.fm_072" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_072_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_d[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_073" class="custom-control-input" id="fm_073_1" v-model="paperData.fm_073" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_073_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_073" class="custom-control-input" id="fm_073_0" v-model="paperData.fm_073" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_073_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_d[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_074" class="custom-control-input" id="fm_074_1" v-model="paperData.fm_074" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_074_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_074" class="custom-control-input" id="fm_074_0" v-model="paperData.fm_074" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_074_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_d[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_075" class="custom-control-input" id="fm_075_1" v-model="paperData.fm_075" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_075_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_075" class="custom-control-input" id="fm_075_0" v-model="paperData.fm_075" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_075_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_d[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_076" class="custom-control-input" id="fm_076_1" v-model="paperData.fm_076" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_076_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_076" class="custom-control-input" id="fm_076_0" v-model="paperData.fm_076" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_076_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_d[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_077" class="custom-control-input" id="fm_077_1" v-model="paperData.fm_077" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_077_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_077" class="custom-control-input" id="fm_077_0" v-model="paperData.fm_077" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_077_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>7e.Wrist: Circumduction</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_e[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_078" class="custom-control-input" id="fm_078_1" v-model="paperData.fm_078" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_078_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_078" class="custom-control-input" id="fm_078_0" v-model="paperData.fm_078" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_078_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_e[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_079" class="custom-control-input" id="fm_079_1" v-model="paperData.fm_079" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_079_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_079" class="custom-control-input" id="fm_079_0" v-model="paperData.fm_079" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_079_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_e[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_080" class="custom-control-input" id="fm_080_1" v-model="paperData.fm_080" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_080_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_080" class="custom-control-input" id="fm_080_0" v-model="paperData.fm_080" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_080_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_e[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_081" class="custom-control-input" id="fm_081_1" v-model="paperData.fm_081" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_081_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_081" class="custom-control-input" id="fm_081_0" v-model="paperData.fm_081" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_081_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_7_e[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_082" class="custom-control-input" id="fm_082_1" v-model="paperData.fm_082" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_082_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_082" class="custom-control-input" id="fm_082_0" v-model="paperData.fm_082" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_082_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8.Hand:</p>
      </div>
      <div class="grey-box">
        <p>8a. Hand: Finger mass flexion:</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_a[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_083" class="custom-control-input" id="fm_083_1" v-model="paperData.fm_083" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_083_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_083" class="custom-control-input" id="fm_083_0" v-model="paperData.fm_083" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_083_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_a[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_084" class="custom-control-input" id="fm_084_1" v-model="paperData.fm_084" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_084_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_084" class="custom-control-input" id="fm_084_0" v-model="paperData.fm_084" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_084_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_a[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_085" class="custom-control-input" id="fm_085_1" v-model="paperData.fm_085" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_085_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_085" class="custom-control-input" id="fm_085_0" v-model="paperData.fm_085" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_085_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_a[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_086" class="custom-control-input" id="fm_086_1" v-model="paperData.fm_086" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_086_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_086" class="custom-control-input" id="fm_086_0" v-model="paperData.fm_086" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_086_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_a[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_087" class="custom-control-input" id="fm_087_1" v-model="paperData.fm_087" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_087_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_087" class="custom-control-input" id="fm_087_0" v-model="paperData.fm_087" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_087_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8b.Hand: Finger mass extension:</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_b[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_088" class="custom-control-input" id="fm_088_1" v-model="paperData.fm_088" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_088_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_088" class="custom-control-input" id="fm_088_0" v-model="paperData.fm_088" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_088_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_b[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_089" class="custom-control-input" id="fm_089_1" v-model="paperData.fm_089" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_089_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_089" class="custom-control-input" id="fm_089_0" v-model="paperData.fm_089" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_089_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_b[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_090" class="custom-control-input" id="fm_090_1" v-model="paperData.fm_090" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_090_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_090" class="custom-control-input" id="fm_090_0" v-model="paperData.fm_090" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_090_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_b[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_091" class="custom-control-input" id="fm_091_1" v-model="paperData.fm_091" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_091_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_091" class="custom-control-input" id="fm_091_0" v-model="paperData.fm_091" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_091_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_b[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_092" class="custom-control-input" id="fm_092_1" v-model="paperData.fm_092" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_092_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_092" class="custom-control-input" id="fm_092_0" v-model="paperData.fm_092" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_092_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8c.Hand: Grasp I</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_c[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_093" class="custom-control-input" id="fm_093_1" v-model="paperData.fm_093" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_093_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_093" class="custom-control-input" id="fm_093_0" v-model="paperData.fm_093" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_093_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_c[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_094" class="custom-control-input" id="fm_094_1" v-model="paperData.fm_094" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_094_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_094" class="custom-control-input" id="fm_094_0" v-model="paperData.fm_094" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_094_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_c[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_095" class="custom-control-input" id="fm_095_1" v-model="paperData.fm_095" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_095_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_095" class="custom-control-input" id="fm_095_0" v-model="paperData.fm_095" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_095_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_c[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_096" class="custom-control-input" id="fm_096_1" v-model="paperData.fm_096" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_096_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_096" class="custom-control-input" id="fm_096_0" v-model="paperData.fm_096" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_096_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_c[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_097" class="custom-control-input" id="fm_097_1" v-model="paperData.fm_097" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_097_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_097" class="custom-control-input" id="fm_097_0" v-model="paperData.fm_097" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_097_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8d.Hand: Grasp II</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_d[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_098" class="custom-control-input" id="fm_098_1" v-model="paperData.fm_098" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_098_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_098" class="custom-control-input" id="fm_098_0" v-model="paperData.fm_098" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_098_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_d[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_099" class="custom-control-input" id="fm_099_1" v-model="paperData.fm_099" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_099_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_099" class="custom-control-input" id="fm_099_0" v-model="paperData.fm_099" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_099_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_d[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_100" class="custom-control-input" id="fm_100_1" v-model="paperData.fm_100" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_100_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_100" class="custom-control-input" id="fm_100_0" v-model="paperData.fm_100" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_100_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_d[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_101" class="custom-control-input" id="fm_101_1" v-model="paperData.fm_101" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_101_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_101" class="custom-control-input" id="fm_101_0" v-model="paperData.fm_101" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_101_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_d[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_102" class="custom-control-input" id="fm_102_1" v-model="paperData.fm_102" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_102_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_102" class="custom-control-input" id="fm_102_0" v-model="paperData.fm_102" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_102_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_d[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_103" class="custom-control-input" id="fm_103_1" v-model="paperData.fm_103" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_103_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_103" class="custom-control-input" id="fm_103_0" v-model="paperData.fm_103" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_103_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8e.Hand: Grasp III</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_e[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_104" class="custom-control-input" id="fm_104_1" v-model="paperData.fm_104" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_104_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_104" class="custom-control-input" id="fm_104_0" v-model="paperData.fm_104" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_104_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_e[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_105" class="custom-control-input" id="fm_105_1" v-model="paperData.fm_105" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_105_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_105" class="custom-control-input" id="fm_105_0" v-model="paperData.fm_105" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_105_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_e[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_106" class="custom-control-input" id="fm_106_1" v-model="paperData.fm_106" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_106_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_106" class="custom-control-input" id="fm_106_0" v-model="paperData.fm_106" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_106_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_e[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_107" class="custom-control-input" id="fm_107_1" v-model="paperData.fm_107" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_107_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_107" class="custom-control-input" id="fm_107_0" v-model="paperData.fm_107" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_107_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_e[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_108" class="custom-control-input" id="fm_108_1" v-model="paperData.fm_108" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_108_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_108" class="custom-control-input" id="fm_108_0" v-model="paperData.fm_108" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_108_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_e[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_109" class="custom-control-input" id="fm_109_1" v-model="paperData.fm_109" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_109_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_109" class="custom-control-input" id="fm_109_0" v-model="paperData.fm_109" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_109_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8f.Hand: Grasp IV</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_f[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_110" class="custom-control-input" id="fm_110_1" v-model="paperData.fm_110" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_110_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_110" class="custom-control-input" id="fm_110_0" v-model="paperData.fm_110" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_110_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_f[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_111" class="custom-control-input" id="fm_111_1" v-model="paperData.fm_111" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_111_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_111" class="custom-control-input" id="fm_111_0" v-model="paperData.fm_111" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_111_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_f[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_112" class="custom-control-input" id="fm_112_1" v-model="paperData.fm_112" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_112_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_112" class="custom-control-input" id="fm_112_0" v-model="paperData.fm_112" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_112_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_f[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_113" class="custom-control-input" id="fm_113_1" v-model="paperData.fm_113" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_113_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_113" class="custom-control-input" id="fm_113_0" v-model="paperData.fm_113" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_113_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_f[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_114" class="custom-control-input" id="fm_114_1" v-model="paperData.fm_114" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_114_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_114" class="custom-control-input" id="fm_114_0" v-model="paperData.fm_114" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_114_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_f[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_115" class="custom-control-input" id="fm_115_1" v-model="paperData.fm_115" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_115_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_115" class="custom-control-input" id="fm_115_0" v-model="paperData.fm_115" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_115_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>8g.Hand: Grasp V</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_g[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_116" class="custom-control-input" id="fm_116_1" v-model="paperData.fm_116" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_116_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_116" class="custom-control-input" id="fm_116_0" v-model="paperData.fm_116" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_116_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_g[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_117" class="custom-control-input" id="fm_117_1" v-model="paperData.fm_117" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_117_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_117" class="custom-control-input" id="fm_117_0" v-model="paperData.fm_117" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_117_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_g[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_118" class="custom-control-input" id="fm_118_1" v-model="paperData.fm_118" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_118_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_118" class="custom-control-input" id="fm_118_0" v-model="paperData.fm_118" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_118_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_g[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_119" class="custom-control-input" id="fm_119_1" v-model="paperData.fm_119" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_119_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_119" class="custom-control-input" id="fm_119_0" v-model="paperData.fm_119" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_119_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_g[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_120" class="custom-control-input" id="fm_120_1" v-model="paperData.fm_120" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_120_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_120" class="custom-control-input" id="fm_120_0" v-model="paperData.fm_120" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_120_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_8_g[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_121" class="custom-control-input" id="fm_121_1" v-model="paperData.fm_121" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_121_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_121" class="custom-control-input" id="fm_121_0" v-model="paperData.fm_121" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_121_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="grey-box">
        <p>9. Coordination and speed: Finger to nose</p>
        <p class="true-count">T(1)</p>
        <p class="false-count">F(0)</p>
      </div>
      <div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[0].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_122" class="custom-control-input" id="fm_122_1" v-model="paperData.fm_122" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_122_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_122" class="custom-control-input" id="fm_122_0" v-model="paperData.fm_122" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_122_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[1].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_123" class="custom-control-input" id="fm_123_1" v-model="paperData.fm_123" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_123_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_123" class="custom-control-input" id="fm_123_0" v-model="paperData.fm_123" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_123_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[2].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_124" class="custom-control-input" id="fm_124_1" v-model="paperData.fm_124" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_124_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_124" class="custom-control-input" id="fm_124_0" v-model="paperData.fm_124" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_124_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[3].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_125" class="custom-control-input" id="fm_125_1" v-model="paperData.fm_125" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_125_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_125" class="custom-control-input" id="fm_125_0" v-model="paperData.fm_125" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_125_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[4].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_126" class="custom-control-input" id="fm_126_1" v-model="paperData.fm_126" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_126_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_126" class="custom-control-input" id="fm_126_0" v-model="paperData.fm_126" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_126_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[5].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_127" class="custom-control-input" id="fm_127_1" v-model="paperData.fm_127" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_127_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_127" class="custom-control-input" id="fm_127_0" v-model="paperData.fm_127" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_127_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
        <div>
          <mdb-row class="question-line">
            <mdb-col col="10">
              <p v-html="partB_9[6].title"></p>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_128" class="custom-control-input" id="fm_128_1" v-model="paperData.fm_128" :value="1">
              <label class="custom-control-label answer-true-btn" for="fm_128_1">T</label>
            </mdb-col>
            <mdb-col col="1">
              <input type="radio" name="fm_128" class="custom-control-input" id="fm_128_0" v-model="paperData.fm_128" :value="0">
              <label class="custom-control-label answer-true-btn" for="fm_128_0">F</label>
            </mdb-col>
          </mdb-row>
        </div>
      </div>
      <div class="score-line">
        <h5> Total Scores</h5>
        <p class="true-count" v-html="
 paperData.fm_007 + paperData.fm_008 + paperData.fm_009 + paperData.fm_010 + paperData.fm_011 + paperData.fm_012 + paperData.fm_013
 + paperData.fm_014 + paperData.fm_015 + paperData.fm_016 + paperData.fm_017 + paperData.fm_018 + paperData.fm_019 + paperData.fm_020
 + paperData.fm_021 + paperData.fm_022 + paperData.fm_023 + paperData.fm_024 + paperData.fm_025 + paperData.fm_026 + paperData.fm_027
 + paperData.fm_028 + paperData.fm_029 + paperData.fm_030 + paperData.fm_031 + paperData.fm_032 + paperData.fm_033 + paperData.fm_034
 + paperData.fm_035 + paperData.fm_036 + paperData.fm_037 + paperData.fm_038 + paperData.fm_039 + paperData.fm_040 + paperData.fm_041
 + paperData.fm_042 + paperData.fm_043 + paperData.fm_044 + paperData.fm_045 + paperData.fm_046 + paperData.fm_047 + paperData.fm_048
 + paperData.fm_049 + paperData.fm_050 + paperData.fm_051 + paperData.fm_052 + paperData.fm_053 + paperData.fm_054 + paperData.fm_055
 + paperData.fm_056 + paperData.fm_057 + paperData.fm_058 + paperData.fm_059 + paperData.fm_060 + paperData.fm_061 + paperData.fm_062
 + paperData.fm_063 + paperData.fm_064 + paperData.fm_065 + paperData.fm_066 + paperData.fm_067 + paperData.fm_068 + paperData.fm_069
 + paperData.fm_070 + paperData.fm_071 + paperData.fm_072 + paperData.fm_073 + paperData.fm_074 + paperData.fm_075 + paperData.fm_076
 + paperData.fm_077 + paperData.fm_078 + paperData.fm_079 + paperData.fm_080 + paperData.fm_081 + paperData.fm_082 + paperData.fm_083
 + paperData.fm_084 + paperData.fm_085 + paperData.fm_086 + paperData.fm_087 + paperData.fm_088 + paperData.fm_089 + paperData.fm_090
 + paperData.fm_091 + paperData.fm_092 + paperData.fm_093 + paperData.fm_094 + paperData.fm_095 + paperData.fm_096 + paperData.fm_097
 + paperData.fm_098 + paperData.fm_099 + paperData.fm_100 + paperData.fm_101 + paperData.fm_102 + paperData.fm_103 + paperData.fm_104
 + paperData.fm_105 + paperData.fm_106 + paperData.fm_107 + paperData.fm_108 + paperData.fm_109 + paperData.fm_110 + paperData.fm_111
 + paperData.fm_112 + paperData.fm_113 + paperData.fm_114 + paperData.fm_115 + paperData.fm_116 + paperData.fm_117 + paperData.fm_118
 + paperData.fm_119 + paperData.fm_120 + paperData.fm_121 + paperData.fm_122 + paperData.fm_123 + paperData.fm_124 + paperData.fm_125
 + paperData.fm_126 + paperData.fm_127 + paperData.fm_128
"></p>
        <p class="slash">/</p>
        <p class="false-count">122</p>
      </div>
      <hr style="margin: 0;">
      <div class="score-line" style="background: #F1F6FD !important;">
        <h5> All Scores</h5>
        <p class="true-count" style="font-weight:700 !important;" v-html="
        paperData.fm_001 + paperData.fm_002 + paperData.fm_003 +paperData.fm_004 +paperData.fm_005 +paperData.fm_006 + paperData.fm_007 +
        paperData.fm_008 + paperData.fm_009 + paperData.fm_010 + paperData.fm_011 + paperData.fm_012 + paperData.fm_013
 + paperData.fm_014 + paperData.fm_015 + paperData.fm_016 + paperData.fm_017 + paperData.fm_018 + paperData.fm_019 + paperData.fm_020
 + paperData.fm_021 + paperData.fm_022 + paperData.fm_023 + paperData.fm_024 + paperData.fm_025 + paperData.fm_026 + paperData.fm_027
 + paperData.fm_028 + paperData.fm_029 + paperData.fm_030 + paperData.fm_031 + paperData.fm_032 + paperData.fm_033 + paperData.fm_034
 + paperData.fm_035 + paperData.fm_036 + paperData.fm_037 + paperData.fm_038 + paperData.fm_039 + paperData.fm_040 + paperData.fm_041
 + paperData.fm_042 + paperData.fm_043 + paperData.fm_044 + paperData.fm_045 + paperData.fm_046 + paperData.fm_047 + paperData.fm_048
 + paperData.fm_049 + paperData.fm_050 + paperData.fm_051 + paperData.fm_052 + paperData.fm_053 + paperData.fm_054 + paperData.fm_055
 + paperData.fm_056 + paperData.fm_057 + paperData.fm_058 + paperData.fm_059 + paperData.fm_060 + paperData.fm_061 + paperData.fm_062
 + paperData.fm_063 + paperData.fm_064 + paperData.fm_065 + paperData.fm_066 + paperData.fm_067 + paperData.fm_068 + paperData.fm_069
 + paperData.fm_070 + paperData.fm_071 + paperData.fm_072 + paperData.fm_073 + paperData.fm_074 + paperData.fm_075 + paperData.fm_076
 + paperData.fm_077 + paperData.fm_078 + paperData.fm_079 + paperData.fm_080 + paperData.fm_081 + paperData.fm_082 + paperData.fm_083
 + paperData.fm_084 + paperData.fm_085 + paperData.fm_086 + paperData.fm_087 + paperData.fm_088 + paperData.fm_089 + paperData.fm_090
 + paperData.fm_091 + paperData.fm_092 + paperData.fm_093 + paperData.fm_094 + paperData.fm_095 + paperData.fm_096 + paperData.fm_097
 + paperData.fm_098 + paperData.fm_099 + paperData.fm_100 + paperData.fm_101 + paperData.fm_102 + paperData.fm_103 + paperData.fm_104
 + paperData.fm_105 + paperData.fm_106 + paperData.fm_107 + paperData.fm_108 + paperData.fm_109 + paperData.fm_110 + paperData.fm_111
 + paperData.fm_112 + paperData.fm_113 + paperData.fm_114 + paperData.fm_115 + paperData.fm_116 + paperData.fm_117 + paperData.fm_118
 + paperData.fm_119 + paperData.fm_120 + paperData.fm_121 + paperData.fm_122 + paperData.fm_123 + paperData.fm_124 + paperData.fm_125
 + paperData.fm_126 + paperData.fm_127 + paperData.fm_128"></p>
        <p class="slash">/</p>
        <p class="false-count" style="font-weight:700 !important;">128</p>
      </div>
    </div>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <FuglMeyerEvaluationFailDialog v-if="isShowModal" @callBackModal="callBackModal"
                                   :totalPartAScore="totalPartAScore"
                                   :totalPartBScore="totalPartBScore"
                                   :paperData="paperData"
                                   :paperIndex="paperIndex"
                                   :paperAllData="paperAllData"

    />
  </div>
</template>

<script>
import toolsFm from "@/assets/data/evaluationToolsInfo/toolsFm";
import FuglMeyerEvaluationFailDialog
  from "@/views/therapist/menu/evaluationToolsInfo/standardization/components/FuglMeyerEvaluationFailDialog";
import {firebase} from "@/firebase/firebaseConfig";
import {mdbCol, mdbRow} from "mdbvue";

export default {
  name: "FuglMeyerEvaluationPaper",
  components: {
    FuglMeyerEvaluationFailDialog,
    mdbCol,
    mdbRow,
  },
  props: {
    paperAllData: Object,
    paperData: Object,
    paperIndex: Number,
  },
  data() {
    return {
      fbCollection: 'standardization',
      fbUid: this.$store.state.user.uid,
      partA: toolsFm.partA[0],
      partB_1: toolsFm.partB[0],
      partB_2: toolsFm.partB[1],
      partB_3: toolsFm.partB[2],
      partB_4_a: toolsFm.partB[3],
      partB_4_b: toolsFm.partB[4],
      partB_4_c: toolsFm.partB[5],
      partB_5_a: toolsFm.partB[6],
      partB_5_b: toolsFm.partB[7],
      partB_5_c: toolsFm.partB[8],
      partB_6: toolsFm.partB[9],
      partB_7_a: toolsFm.partB[10],
      partB_7_b: toolsFm.partB[11],
      partB_7_c: toolsFm.partB[12],
      partB_7_d: toolsFm.partB[13],
      partB_7_e: toolsFm.partB[14],
      partB_8_a: toolsFm.partB[15],
      partB_8_b: toolsFm.partB[16],
      partB_8_c: toolsFm.partB[17],
      partB_8_d: toolsFm.partB[18],
      partB_8_e: toolsFm.partB[19],
      partB_8_f: toolsFm.partB[20],
      partB_8_g: toolsFm.partB[21],
      partB_9: toolsFm.partB[22],
      totalPartAScore: 0,
      partAScoreList: [],
      totalPartBScore: 0,
      partBScoreList: [],
      isShowModal: false,
      fm: {},
    }
  },
  methods: {

    scorePartA: function (id, score) {
      const self = this;
      self.partAScoreList[id] = score
      const noNull = self.partAScoreList.filter(Boolean)
      self.totalPartAScore = noNull.reduce((a, b) => parseInt(a) + parseInt(b), 0)
    },
    scorePartB: function (id, score) {
      const self = this;
      self.partBScoreList[id] = score
      const noNull = self.partBScoreList.filter(Boolean)
      self.totalPartBScore = noNull.reduce((a, b) => parseInt(a) + parseInt(b), 0)
    },
    async tempSave() {
      const self = this;

      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbUid)
          .set(self.paperAllData, {merge: true})
          .then(async () => {
            alert('임시 저장 되었습니다.');
          });
    },
    async resultSave() {
      const self = this;
      if (JSON.stringify(self.paperData).includes('null')) {
        alert('완료되지 않은 평가 항목이 있습니다..')
        return
      }
      await self.calculator();
      self.showModal()
    },
    calculator() {
      const self = this;

      self.totalPartAScore = self.paperData.fm_001 + self.paperData.fm_002 + self.paperData.fm_003 + self.paperData.fm_004 + self.paperData.fm_005 + self.paperData.fm_006;
      self.totalPartBScore = self.paperData.fm_007 + self.paperData.fm_008 + self.paperData.fm_009 + self.paperData.fm_010 + self.paperData.fm_011 + self.paperData.fm_012 + self.paperData.fm_013
          + self.paperData.fm_014 + self.paperData.fm_015 + self.paperData.fm_016 + self.paperData.fm_017 + self.paperData.fm_018 + self.paperData.fm_019 + self.paperData.fm_020
          + self.paperData.fm_021 + self.paperData.fm_022 + self.paperData.fm_023 + self.paperData.fm_024 + self.paperData.fm_025 + self.paperData.fm_026 + self.paperData.fm_027
          + self.paperData.fm_028 + self.paperData.fm_029 + self.paperData.fm_030 + self.paperData.fm_031 + self.paperData.fm_032 + self.paperData.fm_033 + self.paperData.fm_034
          + self.paperData.fm_035 + self.paperData.fm_036 + self.paperData.fm_037 + self.paperData.fm_038 + self.paperData.fm_039 + self.paperData.fm_040 + self.paperData.fm_041
          + self.paperData.fm_042 + self.paperData.fm_043 + self.paperData.fm_044 + self.paperData.fm_045 + self.paperData.fm_046 + self.paperData.fm_047 + self.paperData.fm_048
          + self.paperData.fm_049 + self.paperData.fm_050 + self.paperData.fm_051 + self.paperData.fm_052 + self.paperData.fm_053 + self.paperData.fm_054 + self.paperData.fm_055
          + self.paperData.fm_056 + self.paperData.fm_057 + self.paperData.fm_058 + self.paperData.fm_059 + self.paperData.fm_060 + self.paperData.fm_061 + self.paperData.fm_062
          + self.paperData.fm_063 + self.paperData.fm_064 + self.paperData.fm_065 + self.paperData.fm_066 + self.paperData.fm_067 + self.paperData.fm_068 + self.paperData.fm_069
          + self.paperData.fm_070 + self.paperData.fm_071 + self.paperData.fm_072 + self.paperData.fm_073 + self.paperData.fm_074 + self.paperData.fm_075 + self.paperData.fm_076
          + self.paperData.fm_077 + self.paperData.fm_078 + self.paperData.fm_079 + self.paperData.fm_080 + self.paperData.fm_081 + self.paperData.fm_082 + self.paperData.fm_083
          + self.paperData.fm_084 + self.paperData.fm_085 + self.paperData.fm_086 + self.paperData.fm_087 + self.paperData.fm_088 + self.paperData.fm_089 + self.paperData.fm_090
          + self.paperData.fm_091 + self.paperData.fm_092 + self.paperData.fm_093 + self.paperData.fm_094 + self.paperData.fm_095 + self.paperData.fm_096 + self.paperData.fm_097
          + self.paperData.fm_098 + self.paperData.fm_099 + self.paperData.fm_100 + self.paperData.fm_101 + self.paperData.fm_102 + self.paperData.fm_103 + self.paperData.fm_104
          + self.paperData.fm_105 + self.paperData.fm_106 + self.paperData.fm_107 + self.paperData.fm_108 + self.paperData.fm_109 + self.paperData.fm_110 + self.paperData.fm_111
          + self.paperData.fm_112 + self.paperData.fm_113 + self.paperData.fm_114 + self.paperData.fm_115 + self.paperData.fm_116 + self.paperData.fm_117 + self.paperData.fm_118
          + self.paperData.fm_119 + self.paperData.fm_120 + self.paperData.fm_121 + self.paperData.fm_122 + self.paperData.fm_123 + self.paperData.fm_124 + self.paperData.fm_125
          + self.paperData.fm_126 + self.paperData.fm_127 + self.paperData.fm_128
    },
    callBackModal: function (index) {
      const self = this;
      self.isShowModal = false
      if (index === 1) {
        self.totalPartAScore = 0
        self.totalPartBScore = 0
        self.$emit('changeState', 1)
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-frame {
  padding: 43px 40px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
}

.guide {
  display: flex;
  margin-top: 20px;
}

.guide img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.guide p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.paper-box {
  width: 100%;
  height: 570px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 20px 0;
  overflow: overlay;
}

.paper-box .title-box {
  height: 48px;
  background: #F1F6FD;
  box-shadow: inset 0 -1px 0 #E7E7ED;
  display: flex;
  align-items: center;
}

.paper-box p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 0 0 20px;
}

.paper-box .grey-box {
  height: 48px;
  background: #F8F8FA;
  display: flex;
  align-items: center;
  padding-right: 10px;
}


.paper-box .grey-box .true-count {
  margin-left: auto;
  width: 80px;
  padding: 0 10px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
}

.paper-box .grey-box .false-count {
  width: 80px;
  padding: 0 10px;
  text-align: center;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
}


.paper-box .question-line {
  margin: 0;
  align-items: center;
  padding: 0 10px 0 20px;
  height: 48px;
}

.paper-box .question-line p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0;
  padding-right: 20px;
}


.answer-true-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

.answer-false-btn {
  width: 38px;
  height: 38px;
  background: #F8F8FA;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

/deep/ .col, .col-1, .col-2, .col-3 .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  padding: 0 15px;
}

/deep/ .col-10:first-child {
  text-align: left;
}

/deep/ .mdb-datatable thead th {
  font-size: 16px !important;
}

.score-line {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #F8F8FA;
  justify-content: flex-end;
  padding: 0 10px;
}

.score-line h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #272833;
}

.score-line .true-count {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0;
  padding: 0 10px;
  width: 52px;
  text-align: right;
}

.score-line .slash {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin: 0;
  width: 52px;
  text-align: center;

}

.score-line .false-count {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  padding: 0 10px;
  margin: 0;
  width: 52px;
}


.guide-2 {
  display: flex;
  background-color: #F1F6FD;
  padding: 5px 22px 23px;
}

.guide-2 img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.guide-2 p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-box .question-line {
  margin: 0;
  align-items: center;
  padding: 0 10px 0 20px;
  height: 48px;
}

.paper-box .question-line p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0;
}

.answer-true-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-false-btn {
  width: 38px;
  height: 38px;
  background: #F8F8FA;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

.active {
  background: #0069CA;
  color: #FFFFFF;

}


</style>
