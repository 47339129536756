<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Fugl-Meyer</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <div>
          <table class="paper-table">
            <tbody>
            <tr>
              <td colspan="12" class="paper-topic">A. 상지 (앉은 자세)</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-item">I. 운동반사</td>
              <td class="td-answer td-bk">없음(0)</td>
              <td class="td-answer td-bk">유발됨(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="10"><strong>굽힘근:</strong> 위팔두갈래근 및 손가락 굽힘근 (최소 한 부위)</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_1_1" class="custom-control-input" id="a_1_1_0" v-model="fm.a_1_1" :value="0">
                  <label class="custom-control-label" for="a_1_1_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_1_1" class="custom-control-input" id="a_1_1_2" v-model="fm.a_1_1" :value="2">
                  <label class="custom-control-label" for="a_1_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="10"><strong>폄근:</strong> 위팔세갈래근</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_1_2" class="custom-control-input" id="a_1_2_0" v-model="fm.a_1_2" :value="0">
                  <label class="custom-control-label" for="a_1_2_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_1_2" class="custom-control-input" id="a_1_2_2" v-model="fm.a_1_2" :value="2">
                  <label class="custom-control-label" for="a_1_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="10">소계 I (최대 4점)</td>
              <td class="td-answer paper-total-score" colspan="2">{{ fm.a_1_1 + fm.a_1_2 }}</td>
            </tr>
            <tr>
              <td class="paper-item" colspan="9">II. 협동군(시너지) 내에서의 수의적 움직임 (중력의 도움 없이)</td>
              <td class="td-answer td-bk">수행못함(0)</td>
              <td class="td-answer td-bk">부분수행(1)</td>
              <td class="td-answer td-bk">완전수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="5"><strong>굽힘근 협동작용:</strong> 손을 반대쪽 무릎에서 같은쪽 귀로 이동.</td>
              <td colspan="2">어깨</td>
              <td colspan="2">뒤당김</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_1" class="custom-control-input" id="a_2_1_0" v-model="fm.a_2_1" :value="0">
                  <label class="custom-control-label" for="a_2_1_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_1" class="custom-control-input" id="a_2_1_1" v-model="fm.a_2_1" :value="1">
                  <label class="custom-control-label" for="a_2_1_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_1" class="custom-control-input" id="a_2_1_2" v-model="fm.a_2_1" :value="2">
                  <label class="custom-control-label" for="a_2_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="5">폄근 협동에서 (어깨 모음/안쪽돌림, 팔꿉 폄, 으로 아래팔 엎침)<br/> 굽힘 협동 (어깨 벌림 /바깥돌림, 팔꿉 굽힘, 아래팔 뒤침)으로</td>
              <td colspan="2"></td>
              <td colspan="2">올림</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_2" class="custom-control-input" id="a_2_2_0" v-model="fm.a_2_2" :value="0">
                  <label class="custom-control-label" for="a_2_2_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_2" class="custom-control-input" id="a_2_2_1" v-model="fm.a_2_2" :value="1">
                  <label class="custom-control-label" for="a_2_2_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_2" class="custom-control-input" id="a_2_2_2" v-model="fm.a_2_2" :value="2">
                  <label class="custom-control-label" for="a_2_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td colspan="2"></td>
              <td colspan="2">벌림(90°)</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_3" class="custom-control-input" id="a_2_3_0" v-model="fm.a_2_3" :value="0">
                  <label class="custom-control-label" for="a_2_3_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_3" class="custom-control-input" id="a_2_3_1" v-model="fm.a_2_3" :value="1">
                  <label class="custom-control-label" for="a_2_3_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_3" class="custom-control-input" id="a_2_3_2" v-model="fm.a_2_3" :value="2">
                  <label class="custom-control-label" for="a_2_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td colspan="2"></td>
              <td colspan="2">바깥돌림</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_4" class="custom-control-input" id="a_2_4_0" v-model="fm.a_2_4" :value="0">
                  <label class="custom-control-label" for="a_2_4_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_4" class="custom-control-input" id="a_2_4_1" v-model="fm.a_2_4" :value="1">
                  <label class="custom-control-label" for="a_2_4_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_4" class="custom-control-input" id="a_2_4_2" v-model="fm.a_2_4" :value="2">
                  <label class="custom-control-label" for="a_2_4_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td colspan="2">팔꿉</td>
              <td colspan="2">굽힘</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_5" class="custom-control-input" id="a_2_5_0" v-model="fm.a_2_5" :value="0">
                  <label class="custom-control-label" for="a_2_5_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_5" class="custom-control-input" id="a_2_5_1" v-model="fm.a_2_5" :value="1">
                  <label class="custom-control-label" for="a_2_5_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_5" class="custom-control-input" id="a_2_5_2" v-model="fm.a_2_5" :value="2">
                  <label class="custom-control-label" for="a_2_5_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td colspan="2">어깨팔</td>
              <td colspan="2">뒤침</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_6" class="custom-control-input" id="a_2_6_0" v-model="fm.a_2_6" :value="0">
                  <label class="custom-control-label" for="a_2_6_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_6" class="custom-control-input" id="a_2_6_1" v-model="fm.a_2_6" :value="1">
                  <label class="custom-control-label" for="a_2_6_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_6" class="custom-control-input" id="a_2_6_2" v-model="fm.a_2_6" :value="2">
                  <label class="custom-control-label" for="a_2_6_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="5"><strong>폄근 협동작용:</strong> 손을 같은 쪽 귀에서 반대쪽 무릎으로</td>
              <td colspan="2">어깨</td>
              <td colspan="2">모음/안쪽돌림</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_7" class="custom-control-input" id="a_2_7_0" v-model="fm.a_2_7" :value="0">
                  <label class="custom-control-label" for="a_2_7_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_7" class="custom-control-input" id="a_2_7_1" v-model="fm.a_2_7" :value="1">
                  <label class="custom-control-label" for="a_2_7_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_7" class="custom-control-input" id="a_2_7_2" v-model="fm.a_2_7" :value="2">
                  <label class="custom-control-label" for="a_2_7_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td colspan="2">팔꿉</td>
              <td colspan="2">폄</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_8" class="custom-control-input" id="a_2_8_0" v-model="fm.a_2_8" :value="0">
                  <label class="custom-control-label" for="a_2_8_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_8" class="custom-control-input" id="a_2_8_1" v-model="fm.a_2_8" :value="1">
                  <label class="custom-control-label" for="a_2_8_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_8" class="custom-control-input" id="a_2_8_2" v-model="fm.a_2_8" :value="2">
                  <label class="custom-control-label" for="a_2_8_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"></td>
              <td colspan="2">아래팔</td>
              <td colspan="2">엎침</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_9" class="custom-control-input" id="a_2_9_0" v-model="fm.a_2_9" :value="0">
                  <label class="custom-control-label" for="a_2_9_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_9" class="custom-control-input" id="a_2_9_1" v-model="fm.a_2_9" :value="1">
                  <label class="custom-control-label" for="a_2_9_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_2_9" class="custom-control-input" id="a_2_9_2" v-model="fm.a_2_9" :value="2">
                  <label class="custom-control-label" for="a_2_9_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="10"><strong>소계 II (최대 18점)</strong></td>
              <td class="td-answer paper-total-score" colspan="2">{{ fm.a_2_1 + fm.a_2_2 + fm.a_2_3 + fm.a_2_4 + fm.a_2_5 + fm.a_2_6 + fm.a_2_7 + fm.a_2_8 + fm.a_2_9 }}</td>
            </tr>
            <tr>
              <td class="paper-item" colspan="9">Ⅲ. 혼합 협동군 내에서의 수의적 움직임 (보상작용 없이)</td>
              <td class="td-answer td-bk">수행못함(0)</td>
              <td class="td-answer td-bk">부분수행(1)</td>
              <td class="td-answer td-bk">완전수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="2"><strong>손을 허리뼈로</strong><br/>(무릎위에 손)</td>
              <td colspan="7">수행하지 못하거나 손이 위앞엉덩뼈가시(ASIS)까지 위치</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_1" class="custom-control-input" id="a_3_1_0" v-model="fm.a_3_1" :value="0">
                  <label class="custom-control-label" for="a_3_1_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">손이 위앞엉덩뼈가시 뒤에 위치(보상작용 없이)</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_1" class="custom-control-input" id="a_3_1_1" v-model="fm.a_3_1" :value="1">
                  <label class="custom-control-label" for="a_3_1_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">손이 허리뼈로 (보상작용 없이)</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_1" class="custom-control-input" id="a_3_1_2" v-model="fm.a_3_1" :value="2">
                  <label class="custom-control-label" for="a_3_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="2"><strong>어깨 굽힘 0-90°</strong><br/>(팔꿉 0°/엎침-뒤침 0°)</td>
              <td colspan="7">즉각적 벌어짐 또는 팔꿉 굽혀짐</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_2" class="custom-control-input" id="a_3_2_0" v-model="fm.a_3_2" :value="0">
                  <label class="custom-control-label" for="a_3_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">움직이는 동안의 벌어짐 또는 팔꿉 굽혀짐</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_2" class="custom-control-input" id="a_3_2_1" v-model="fm.a_3_2" :value="1">
                  <label class="custom-control-label" for="a_3_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">어깨의 벌어짐 또는 팔꿉의 굽혀짐 없이 90° 굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_2" class="custom-control-input" id="a_3_2_2" v-model="fm.a_3_2" :value="2">
                  <label class="custom-control-label" for="a_3_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="2"><strong>엎침-뒤침</strong><br/>(팔꿉 90°/어깨 0°)</td>
              <td colspan="7">엎침/뒤침 못함, 준비자세 불가능</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_3" class="custom-control-input" id="a_3_3_0" v-model="fm.a_3_3" :value="0">
                  <label class="custom-control-label" for="a_3_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>

            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">제한적 엎침/뒤침 준비자세 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_3" class="custom-control-input" id="a_3_3_1" v-model="fm.a_3_3" :value="1">
                  <label class="custom-control-label" for="a_3_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">완전한 엎침/뒤침 준비자세 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_3_3" class="custom-control-input" id="a_3_3_2" v-model="fm.a_3_3" :value="2">
                  <label class="custom-control-label" for="a_3_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9">소계 Ⅲ (최대 6점)</td>
              <td class="td-answer paper-total-score" colspan="3">{{ fm.a_3_1 + fm.a_3_2 + fm.a_3_3 }}</td>
            </tr>
            <tr>
              <td class="paper-item" colspan="9">IV. 협동작용이 거의 없거나 없는 수의적 움직임</td>
              <td class="td-answer td-bk">수행못함(0)</td>
              <td class="td-answer td-bk">부분수행(1)</td>
              <td class="td-answer td-bk">완전수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="2"><strong>어깨 벌림 0-90°</strong><br/>(팔꿉 0°/아래팔 엎침)</td>
              <td colspan="7">즉각적인 뒤침 또는 팔꿉 굽혀짐</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_1" class="custom-control-input" id="a_4_1_0" v-model="fm.a_4_1" :value="0">
                  <label class="custom-control-label" for="a_4_1_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">움직이는 동안의 뒤침 또는 팔꿉 굽혀짐</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_1" class="custom-control-input" id="a_4_1_1" v-model="fm.a_4_1" :value="1">
                  <label class="custom-control-label" for="a_4_1_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">벌림 90°, 폄과 엎침 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_1" class="custom-control-input" id="a_4_1_2" v-model="fm.a_4_1" :value="2">
                  <label class="custom-control-label" for="a_4_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="2"><strong>어깨 굽힘 90°-180°</strong><br/>(팔꿉 0°/엎침-뒤침 0°)</td>
              <td colspan="7">즉각적 벌어짐 또는 팔꿉 굽혀짐</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_2" class="custom-control-input" id="a_4_2_0" v-model="fm.a_4_2" :value="0">
                  <label class="custom-control-label" for="a_4_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">움직이는 동안의 벌어짐 또는 팔꿉 굽혀짐</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_2" class="custom-control-input" id="a_4_2_1" v-model="fm.a_4_2" :value="1">
                  <label class="custom-control-label" for="a_4_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">180°, 어깨의 벌어짐 또는 팔꿉 굽혀짐 없이</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_2" class="custom-control-input" id="a_4_2_2" v-model="fm.a_4_2" :value="2">
                  <label class="custom-control-label" for="a_4_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="2"><strong>엎침/뒤침</strong><br/>(팔꿉 0°/어깨 30°-90°)</td>
              <td colspan="7">엎침/뒤침 못함, 준비자세 불가능</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_3" class="custom-control-input" id="a_4_3_0" v-model="fm.a_4_3" :value="0">
                  <label class="custom-control-label" for="a_4_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">제한적 엎침/뒤침, 준비자세 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_3" class="custom-control-input" id="a_4_3_1" v-model="fm.a_4_3" :value="1">
                  <label class="custom-control-label" for="a_4_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">완전한 엎침/뒤침, 준비자세 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_4_3" class="custom-control-input" id="a_4_3_2" v-model="fm.a_4_3" :value="2">
                  <label class="custom-control-label" for="a_4_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9">소계 IV (최대 6점)</td>
              <td class="td-answer paper-total-score" colspan="3">{{ fm.a_4_1 + fm.a_4_2 + fm.a_4_3 }}</td>
            </tr>
            <tr>
              <td class="paper-item paper-padding" colspan="9"><strong>V. 정상 운동 반사</strong><br/>
                (항목 IV에서 6점 만점을 획득한 경우에만 평가한다. 건측과 비교)
              </td>
              <td class="td-answer td-bk">과항진(0)</td>
              <td class="td-answer td-bk">나타남(1)</td>
              <td class="td-answer td-bk">정상(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="2">위팔두갈래근,</td>
              <td colspan="7">세 개 중 두 개의 반사가 현저히 과항진 되어있음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_5_1" class="custom-control-input" id="a_5_3_0" v-model="fm.a_5_1" :value="0" disabled v-if="fm.a_4_1 + fm.a_4_2 + fm.a_4_3 !== 6">
                  <input type="radio" name="a_5_1" class="custom-control-input" id="a_5_3_0" v-model="fm.a_5_1" :value="0" v-else>
                  <label class="custom-control-label" for="a_5_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="paper-question" colspan="2">위팔세갈래근,</td>
              <td colspan="7">하나의 반사가 현저히 과항진 되거나 또는 최소 두 개의 반사가 나타남</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_5_1" class="custom-control-input" id="a_5_3_1" v-model="fm.a_5_1" :value="0" disabled v-if="fm.a_4_1 + fm.a_4_2 + fm.a_4_3 !== 6">
                  <input type="radio" name="a_5_1" class="custom-control-input" id="a_5_3_1" v-model="fm.a_5_1" :value="1" v-else>
                  <label class="custom-control-label" for="a_5_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="paper-question" colspan="2">손가락굽힘근</td>
              <td colspan="7">최대 하나의 반사가 나타남, 과항진 분사 없음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="a_5_1" class="custom-control-input" id="a_5_3_2" v-model="fm.a_5_1" :value="0" disabled v-if="fm.a_4_1 + fm.a_4_2 + fm.a_4_3 !== 6">
                  <input type="radio" name="a_5_1" class="custom-control-input" id="a_5_3_2" v-model="fm.a_5_1" :value="2" v-else>
                  <label class="custom-control-label" for="a_5_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>소계 V (최대 2점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-if="fm.a_4_1 + fm.a_4_2 + fm.a_4_3 !== 6"><strong>0</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-else><strong>{{ fm.a_5_1 + 0 }}</strong></td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 A (죄대 36점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-if="fm.a_4_1 + fm.a_4_2 + fm.a_4_3 !== 6"><strong>{{
                  fm.a_1_1 + fm.a_1_2 +
                  fm.a_2_1 + fm.a_2_2 + fm.a_2_3 + fm.a_2_4 + fm.a_2_5 + fm.a_2_6 + fm.a_2_7 + fm.a_2_8 + fm.a_2_9 +
                  fm.a_3_1 + fm.a_3_2 + fm.a_3_3 +
                  fm.a_4_1 + fm.a_4_2 + fm.a_4_3
                }}</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-else><strong>{{
                  fm.a_1_1 + fm.a_1_2 +
                  fm.a_2_1 + fm.a_2_2 + fm.a_2_3 + fm.a_2_4 + fm.a_2_5 + fm.a_2_6 + fm.a_2_7 + fm.a_2_8 + fm.a_2_9 +
                  fm.a_3_1 + fm.a_3_2 + fm.a_3_3 +
                  fm.a_4_1 + fm.a_4_2 + fm.a_4_3 +
                  fm.a_5_1
                }}</strong></td>
            </tr>
            <tr>
              <td class="paper-topic paper-padding" colspan="9">
                B.손목<br/>
                (준비자세를 취하거나 유지하기 위해서 팔꿉에 지지 가능,<br/>
                손목에는 지지없음, 검사전 손목의 수동관절가동범위 확인)
              </td>
              <td class="td-answer td-bk">수행못함(0)</td>
              <td class="td-answer td-bk">부분수행(1)</td>
              <td class="td-answer td-bk">완전수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4"><strong>15° 손등굽힘에서의 안정성</strong><br/>(팔꿉 90°, 아래팔 엎침, 어깨 0°)</td>
              <td colspan="5">능동적 손등굽힘 15° 미만</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_1" class="custom-control-input" id="b_1_1_0" v-model="fm.b_1_1" :value="0">
                  <label class="custom-control-label" for="b_1_1_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">손등굽힘 15°, 저항에 견디지 못함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_1" class="custom-control-input" id="b_1_1_1" v-model="fm.b_1_1" :value="1">
                  <label class="custom-control-label" for="b_1_1_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">저항에 대한 손등굽힘 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_1" class="custom-control-input" id="b_1_1_2" v-model="fm.b_1_1" :value="2">
                  <label class="custom-control-label" for="b_1_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4"><strong>반복적 손등굽힘/손바닥 굽힘</strong><br/>(팔꿉 90°, 아래팔 엎침<br/>어깨 0°, 약간의 손가락 굽힘)</td>
              <td colspan="5">수의적으로 수행하지 못함</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_2" class="custom-control-input" id="b_1_2_0" v-model="fm.b_1_2" :value="0">
                  <label class="custom-control-label" for="b_1_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">제한된 능동관절가동범위</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_2" class="custom-control-input" id="b_1_2_1" v-model="fm.b_1_2" :value="1">
                  <label class="custom-control-label" for="b_1_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">완전한 능동관절가동범위, 부드럽게</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_2" class="custom-control-input" id="b_1_2_2" v-model="fm.b_1_2" :value="2">
                  <label class="custom-control-label" for="b_1_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4"><strong>15° 손등굽힘에서의 안정성</strong><br/>(팔꿉 0°, 아래팔 엎침,<br/>약간의 어깨 굽힘/벌림)</td>
              <td colspan="5">능동적 손등굽힘 15° 미만</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_3" class="custom-control-input" id="b_1_3_0" v-model="fm.b_1_3" :value="0">
                  <label class="custom-control-label" for="b_1_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">손등굽힘 15°, 저항에 견디지 못함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_3" class="custom-control-input" id="b_1_3_1" v-model="fm.b_1_3" :value="1">
                  <label class="custom-control-label" for="b_1_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">저항에도 손등굽힘 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_3" class="custom-control-input" id="b_1_3_2" v-model="fm.b_1_3" :value="2">
                  <label class="custom-control-label" for="b_1_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4"><strong>반복적 손등굽힘/손바닥 굽힘</strong><br/>(팔꿈 0°, 아래팔 엎침,<br/>약간의 어깨 굽힘/벌림)</td>
              <td colspan="5">수의적으로 수행하지 못함</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_4" class="custom-control-input" id="b_1_4_0" v-model="fm.b_1_4" :value="0">
                  <label class="custom-control-label" for="b_1_4_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">제한된 능동관절가동범위</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_4" class="custom-control-input" id="b_1_4_1" v-model="fm.b_1_4" :value="1">
                  <label class="custom-control-label" for="b_1_4_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">완전한 능동관절가동범위, 부드럽게</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_4" class="custom-control-input" id="b_1_4_2" v-model="fm.b_1_4" :value="2">
                  <label class="custom-control-label" for="b_1_4_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4"><strong>회전</strong><br/>(팔꿉 90°, 아래팔 엎침, 어깨 0°)</td>
              <td colspan="5">수의적으로 수행하지 못함</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_5" class="custom-control-input" id="b_1_5_0" v-model="fm.b_1_5" :value="0">
                  <label class="custom-control-label" for="b_1_5_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">덜컥거리는(jerky)움직임 또는 불완전한</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_5" class="custom-control-input" id="b_1_5_1" v-model="fm.b_1_5" :value="1">
                  <label class="custom-control-label" for="b_1_5_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">완전하고 부드러운 회전</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="b_1_5" class="custom-control-input" id="b_1_5_2" v-model="fm.b_1_5" :value="2">
                  <label class="custom-control-label" for="b_1_5_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9">총점 B (최대 10점)</td>
              <td class="td-answer paper-total-score" colspan="3" v-html="fm.b_1_1 + fm.b_1_2 + fm.b_1_3 + fm.b_1_4 + fm.b_1_5 "></td>
            </tr>
            <tr>
              <td class="paper-topic paper-padding" colspan="9">C.손
                <br/>(팔꿉 굽힘 90° 유지를 위해서 팔꿈에 지지 제공 가능, 손목에는 지지 불가,<br/>
                건측 손과 비교, 물건들을 놓아두고, 능동적 잡기)
              </td>
              <td class="td-answer td-bk">수행못함(0)</td>
              <td class="td-answer td-bk">부분수행(1)</td>
              <td class="td-answer td-bk">완전수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4"><strong>전체 굽힘</strong></td>
              <td colspan="5"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_1" class="custom-control-input" id="c_1_1_0" v-model="fm.c_1_1" :value="0">
                  <label class="custom-control-label" for="c_1_1_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4">능동 또는 수동으로</td>
              <td colspan="5"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_1" class="custom-control-input" id="c_1_1_1" v-model="fm.c_1_1" :value="1">
                  <label class="custom-control-label" for="c_1_1_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4">완전히 편 상태에서</td>
              <td colspan="5"></td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_1" class="custom-control-input" id="c_1_1_2" v-model="fm.c_1_1" :value="2">
                  <label class="custom-control-label" for="c_1_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4"><strong>전체 폄</strong></td>
              <td colspan="5"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_2" class="custom-control-input" id="c_1_2_0" v-model="fm.c_1_2" :value="0">
                  <label class="custom-control-label" for="c_1_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4">능동 또는 수동으로</td>
              <td colspan="5"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_2" class="custom-control-input" id="c_1_2_1" v-model="fm.c_1_2" :value="1">
                  <label class="custom-control-label" for="c_1_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4">완전히 굽힌 상태에서</td>
              <td colspan="5"></td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_2" class="custom-control-input" id="c_1_2_2" v-model="fm.c_1_2" :value="2">
                  <label class="custom-control-label" for="c_1_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="12"><strong>잡기</strong></td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4">
                <strong>a.고리 잡기</strong><br/>
                몸쪽 손가락뼈사이관절과 먼쪽 손가락뼈사이관절<br/>
                (제2-5 손가락)굽힘, 제2-5손가락 손허리손가락관절 폄
              </td>
              <td colspan="5">수행할 수 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_3" class="custom-control-input" id="c_1_3_0" v-model="fm.c_1_3" :value="0">
                  <label class="custom-control-label" for="c_1_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">자세 유지 가능하나 약함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_3" class="custom-control-input" id="c_1_3_1" v-model="fm.c_1_3" :value="1">
                  <label class="custom-control-label" for="c_1_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">저항에 대하여 자세를 유지</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_3" class="custom-control-input" id="c_1_3_2" v-model="fm.c_1_3" :value="2">
                  <label class="custom-control-label" for="c_1_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4">
                <strong>b.엄지 모음</strong><br/>
                제1손목손허리관절, 손허리손가락관절, 손가락뼈사이<br/>
                관절 0°, 엄지와 제2손허리손가락관절 사이에 종이 끼움
              </td>
              <td colspan="5">수행할 수 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_4" class="custom-control-input" id="c_1_4_0" v-model="fm.c_1_4" :value="0">
                  <label class="custom-control-label" for="c_1_4_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">종이 잡기를 유지할 수 있으나 당기면 버티지 못함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_4" class="custom-control-input" id="c_1_4_1" v-model="fm.c_1_4" :value="1">
                  <label class="custom-control-label" for="c_1_4_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">당겨도 종이 잡기를 유지할 수 있음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_4" class="custom-control-input" id="c_1_4_2" v-model="fm.c_1_4" :value="2">
                  <label class="custom-control-label" for="c_1_4_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4">
                <strong>c.손끝 잡기(맞섬)</strong><br/>
                엄지손가락 바닥을 두 번째 손가락 바닥에 맞댄다,<br/>
                연필, 위로 당김
              </td>
              <td colspan="5">수행할 수 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_5" class="custom-control-input" id="c_1_5_0" v-model="fm.c_1_5" :value="0">
                  <label class="custom-control-label" for="c_1_5_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">연필 잡기를 유지할 수 있으나 당기면 버티지 못함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_5" class="custom-control-input" id="c_1_5_1" v-model="fm.c_1_5" :value="1">
                  <label class="custom-control-label" for="c_1_5_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">당겨도 연필 잡기를 유지할 수 있음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_5" class="custom-control-input" id="c_1_5_2" v-model="fm.c_1_5" :value="2">
                  <label class="custom-control-label" for="c_1_5_2"></label>
                </div>
              </td>
            </tr>
            <tr>

              <td class="paper-question paper-padding" colspan="4">
                <strong>d.원통 잡기</strong><br/>
                원통 모양의 물건 (작은 캔), 위로 당김,<br/>
                엄지와 손가락둘의 맞섬
              </td>
              <td colspan="5">수행할 수 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_6" class="custom-control-input" id="c_1_6_0" v-model="fm.c_1_6" :value="0">
                  <label class="custom-control-label" for="c_1_6_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">원통 잡기를 유지할 수 있으나 당기면 버티지 못함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_6" class="custom-control-input" id="c_1_6_1" v-model="fm.c_1_6" :value="1">
                  <label class="custom-control-label" for="c_1_6_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">당겨도 원통 잡기 유지를 할 수 있음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_6" class="custom-control-input" id="c_1_6_2" v-model="fm.c_1_6" :value="2">
                  <label class="custom-control-label" for="c_1_6_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4">
                <strong>e.구형 잡기</strong><br/>
                손가락들의 벌림/굽힘, 엄지 맞섬,<br/>
                테니스 공, 당김
              </td>
              <td colspan="5">수행할 수 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_7" class="custom-control-input" id="c_1_7_0" v-model="fm.c_1_7" :value="0">
                  <label class="custom-control-label" for="c_1_7_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">공잡기를 유지할 수 있으나 당기면 버티지 못함</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_7" class="custom-control-input" id="c_1_7_1" v-model="fm.c_1_7" :value="1">
                  <label class="custom-control-label" for="c_1_7_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">당겨도 공 잡기를 유지할 수 있음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="c_1_7" class="custom-control-input" id="c_1_7_2" v-model="fm.c_1_7" :value="2">
                  <label class="custom-control-label" for="c_1_7_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 C (최대 14점)</strong></td>
              <td class="paper-total-score" colspan="3">{{
                  fm.c_1_1 + fm.c_1_2 + fm.c_1_3 + fm.c_1_4 + fm.c_1_5 + fm.c_1_6 + fm.c_1_7
                }}
              </td>
            </tr>
            <tr>
              <td class="paper-topic paper-padding" colspan="9">
                D.협응/속도<br/>
                (앉은 자세, 양팔로 한번의 시도 후, 눈은 감은 상태, 두 번째 손가락 끝을 무릎에서 코로, 가능 한 빠르게 5회)
              </td>
              <td class="td-answer td-bk">현저함(0)</td>
              <td class="td-answer td-bk">약간(1)</td>
              <td class="td-answer td-bk">없음(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4"><strong>떨림</strong></td>
              <td colspan="5">최소 한번의 완전한 움직임</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_1" class="custom-control-input" id="d_1_1_0" v-model="fm.d_1_1" :value="0">
                  <label class="custom-control-label" for="d_1_1_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_1" class="custom-control-input" id="d_1_1_1" v-model="fm.d_1_1" :value="1">
                  <label class="custom-control-label" for="d_1_1_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_1" class="custom-control-input" id="d_1_1_2" v-model="fm.d_1_1" :value="2">
                  <label class="custom-control-label" for="d_1_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4"><strong>겨냥이상</strong></td>
              <td colspan="5">현저하거나 비체계적</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_2" class="custom-control-input" id="d_1_2_0" v-model="fm.d_1_2" :value="0">
                  <label class="custom-control-label" for="d_1_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">약하거나 체계적</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_2" class="custom-control-input" id="d_1_2_1" v-model="fm.d_1_2" :value="1">
                  <label class="custom-control-label" for="d_1_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">겨냥이상 없음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_2" class="custom-control-input" id="d_1_2_2" v-model="fm.d_1_2" :value="2">
                  <label class="custom-control-label" for="d_1_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-item" colspan="4"></td>
              <td class="paper-item" colspan="5"></td>
              <td class="td-answer td-bk">≥ 6s</td>
              <td class="td-answer td-bk">2-5s</td>
              <td class="td-answer td-bk">＜ 2s</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4"><strong>시간</strong><br/>손을 무릎에서 시작해서 끝냄</td>
              <td colspan="5">건측보다 최소 6초 이상 느림</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_3" class="custom-control-input" id="d_1_3_0" v-model="fm.d_1_3" :value="0">
                  <label class="custom-control-label" for="d_1_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">건측보다 2-5초 느림</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_3" class="custom-control-input" id="d_1_3_1" v-model="fm.d_1_3" :value="1">
                  <label class="custom-control-label" for="d_1_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td colspan="5">건측과의 차이가 2초 미만</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="d_1_3" class="custom-control-input" id="d_1_3_2" v-model="fm.d_1_3" :value="2">
                  <label class="custom-control-label" for="d_1_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 D (최대 6점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-html="fm.d_1_1 + fm.d_1_2 + fm.d_1_3"></td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 A-D (최대 66점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-html="fm.a_1_1 + fm.a_1_2 +
                  fm.a_2_1 + fm.a_2_2 + fm.a_2_3 + fm.a_2_4 + fm.a_2_5 + fm.a_2_6 + fm.a_2_7 + fm.a_2_8 + fm.a_2_9 +
                  fm.a_3_1 + fm.a_3_2 + fm.a_3_3 +
                  fm.a_4_1 + fm.a_4_2 + fm.a_4_3 +
                  fm.a_5_1
                      +  fm.b_1_1 + fm.b_1_2 + fm.b_1_3 + fm.b_1_4 + fm.b_1_5
                  + fm.c_1_1 + fm.c_1_2 + fm.c_1_3 + fm.c_1_4 + fm.c_1_5 + fm.c_1_6 + fm.c_1_7
                  + fm.d_1_1 + fm.d_1_2 + fm.d_1_3"></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <FuglMyerResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :fm="fm"/>
  </div>

</template>

<script>
import {mdbScrollbar} from "mdbvue";
import FuglMyerResultDialog from "@/views/therapist/menu/doEvaluation/paper/fm/FuglMyerResultDialog";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "FuglMyer",
  components: {
    FuglMyerResultDialog,
    mdbScrollbar,
  },
  props: {
    fbDocId: String,
    fm: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: {
        totalA: 0,
        totalB: 0,
        totalC: 0,
        totalD: 0,
        totalAll: 0,
      },
      tools: {
        fm: {}
      },
      isShowModal: false,
    }
  },
  mounted() {
    this.setEdit()
  },
  methods: {
    setEdit() {
      const self = this;
      if (self.fm.a_4_1 + self.fm.a_4_2 + self.fm.a_4_3 < 6 && self.fm.a_5_1 !== null) {
        self.fm.a_5_1 = null;
      }
    },
    async tempSave() {
      const self = this;
      await self.calculator();

      self.tools.fm = self.fm;
      self.tools.fm['state'] = 1;
      self.tools.fm['totalA'] = null
      self.tools.fm['totalB'] = null
      self.tools.fm['totalC'] = null
      self.tools.fm['totalD'] = null
      self.tools.fm['totalAll'] = null
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'fm', 1)
          });
    },
    checkNullData() {
      const self = this;
      const nullList = Object.values(self.fm);
      const nullCount = nullList.filter((element) => element == null).length
      if (nullCount > 1) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      if (nullCount === 1) {
        if (self.fm.a_4_1 + self.fm.a_4_2 + self.fm.a_4_3 !== 6 && self.fm.a_5_1 === null) {
          return false;
        }
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;

      self.setEdit()
      let _5_1 = self.fm.a_5_1
      if(self.fm.a_5_1 === null) _5_1 = 0
      self.total.totalA = self.fm.a_1_1 + self.fm.a_1_2 +
          self.fm.a_2_1 + self.fm.a_2_2 + self.fm.a_2_3 + self.fm.a_2_4 + self.fm.a_2_5 + self.fm.a_2_6 + self.fm.a_2_7 + self.fm.a_2_8 + self.fm.a_2_9 +
          self.fm.a_3_1 + self.fm.a_3_2 + self.fm.a_3_3 +
          self.fm.a_4_1 + self.fm.a_4_2 + self.fm.a_4_3 +
          _5_1;
      self.total.totalB = self.fm.b_1_1 + self.fm.b_1_2 + self.fm.b_1_3 + self.fm.b_1_4 + self.fm.b_1_5
      self.total.totalC = self.fm.c_1_1 + self.fm.c_1_2 + self.fm.c_1_3 + self.fm.c_1_4 + self.fm.c_1_5 + self.fm.c_1_6 + self.fm.c_1_7
      self.total.totalD = self.fm.d_1_1 + self.fm.d_1_2 + self.fm.d_1_3;
      self.total.totalAll = self.total.totalA + self.total.totalB + self.total.totalC + self.total.totalD;

      if (self.fm.state === 1) {
        delete self.fm.totalA
        delete self.fm.totalB
        delete self.fm.totalC
        delete self.fm.totalD
        delete self.fm.totalAll
      }
    },
    async resultSave() {
      const self = this;
      self.calculator();
      if (self.checkNullData()) {
        return
      }
      await self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'fm', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    },
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 48px;
  font-size: 16px;
}

.td-answer {
  text-align: center;
  font-weight: 500;
}

.td-bk {
  background: #F8F8FA;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  height: 570px;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
}

.paper-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}

.paper-item {
  padding-left: 20px;
  font-weight: 500;
  background: #F8F8FA;
}

.paper-question {
  padding-left: 20px;
}

.paper-total-score {
  text-align: center;
  font-weight: 500;
  color: #0069CA;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}

label.custom-control-label {
  cursor: pointer;
}

label.custom-control-label::before {
  border: 2px solid grey;
  width: 20px;
  height: 20px;
}

</style>
