export default [
    {
        label: 'STEP',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '진행항목',
        field: 'title',
        sort: true
    },
    {
        label: '수행상태',
        field: 'status',
        sort: false
    },
    {
        label: '',
        field: 'action',
        sort: false
    },
];
