<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">10Meter Walk Test (10MWT)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="scroll-frame">
      <div class="paper-frame">
        <div class="paper-guide-frame">
          <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
          <p class="paper-guide">10M의 거리를 측정자의 도움없이 독립적으로 걷고 일반적으로 총 3회 측정하여 평균속도와 최고속도의 평균값으로 속도를 측정합니다.
            <br/>2m 지점에서 스톱워치를 시작하고 8m지점에서 스톱워치를 종료하여 시간을 측정합니다.</p>
        </div>
        <div class="form-container">
          <div class="assistive-check">
            <div class="sud-title">Assistive Device and/or Bracing Used(보조도구나 보조기 사용 여부)</div>
            <div class="btn-check-box">
              <label class="radio-btn">
                <input type="radio" name="tmwt_assistive_device" v-model="tmwt.assistive_device" :value="1">
                <span class="custom-btn-box check-btn">사용</span>
              </label>
              <label class="radio-btn">
                <input type="radio" name="tmwt_assistive_device" v-model="tmwt.assistive_device" :value="2">
                <span class="custom-btn-box check-btn">사용하지않음</span>
              </label>
            </div>
          </div>
          <div class="assist-box">
            <div class="pop-up" v-if="isShowPopUp">
              <div class="text">
                <h5>Assist 1~7 능력</h5>
                <p>1. Total Assist(환자의 과제 수행능력 0~24%)<br/>
                  2. Maximum assistance(환자의 과제 수행능력 25~49%)<br/>
                  3. Moderate assistance(환자의 과제 수행능력 50~74%)<br/>
                  4. Minimum assistance(환자의 과제 수행능력 75~99%)<br/>
                  5. Supervision(신체적 접촉없이 서있거나 서는 것의 도움필요)<br/>
                  6. Modified independent(보조기 사용, 시간 필요, 약간의 안전)<br/>
                  7. Independent</p>
              </div>
              <img src="@/assets/images/IA/btn_close.png" alt="" @click="isShowPopUp= false">
            </div>
            <div class="sud-title">Assist
              <img style="width: 20px; margin-left: 10px; cursor: pointer;"
                   src="@/assets/images/IA/icon_solid_info.png" alt="null" @click="isShowPopUp = true">
            </div>
            <div class="radio-group">
              <label class="container xs-container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="1">
                <span class="checkmark xs-mark">1</span>
              </label>
              <label class="container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="2">
                <span class="checkmark">2</span>
              </label>
              <label class="container xs-container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="3">
                <span class="checkmark xs-mark">3</span>
              </label>
              <label class="container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="4">
                <span class="checkmark">4</span>
              </label>
              <label class="container xs-container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="5">
                <span class="checkmark xs-mark">5</span>
              </label>
              <label class="container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="6">
                <span class="checkmark">6</span>
              </label>
              <label class="container xs-container">
                <input type="radio" name="tmwt_assist" v-model="tmwt.assist" :value="7">
                <span class="checkmark xs-mark">7</span>
              </label>
            </div>
          </div>
          <div class="sud-title">Self-selected speed(평소 걸음)</div>
          <div class="form-group">
            <div class="left">
              <div class="time-input">
                <p class="label">Trial 1</p>
                <div class="input-box">
                  <input type="number" class="form-control" placeholder="시간입력" @input="inputTrial(1,'self_selected_speed')"
                         :class="{btnActive: isStopTrial1,disabled: isStopTrial1}" v-model.number="tmwt.self_selected_speed.trial_1" min="0">
                  <p class="time-unit">sec.</p>
                  <button class="custom-btn-box" @click="stopBtn(1)">NT
                  </button>
                </div>
              </div>
              <div class="time-input">
                <p class="label">Trial 2</p>
                <div class="input-box">
                  <input type="number" class="form-control" placeholder="시간입력" @input="inputTrial(2,'self_selected_speed')"
                         :class="{btnActive: isStopTrial2,disabled: isStopTrial2}" v-model.number="tmwt.self_selected_speed.trial_2" min="0">
                  <p class="time-unit">sec.</p>
                  <button class="custom-btn-box" :class="{disabled: isStopTrial1}"
                          @click="stopBtn(2)">NT
                  </button>
                </div>
              </div>
              <div class="time-input">
                <p class="label">Trial 3</p>
                <div class="input-box">
                  <input type="number" id="selfSelected" class="form-control" placeholder="시간입력" @input="inputTrial(3,'self_selected_speed')"
                         :class="{btnActive: isStopTrial3,disabled: isStopTrial3}" v-model.number="tmwt.self_selected_speed.trial_3" min="0">
                  <p class="time-unit">sec.</p>
                  <button class="custom-btn-box" :class="{disabled: isStopTrial1 || isStopTrial2}"
                          @click="stopBtn(3)">NT
                  </button>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="time-input">
                <p class="label">Average time</p>
                <input type="number" class="form-control" placeholder="평균시간" disabled min="0"
                       v-model="tmwt.self_selected_speed.average_time">
                <p class="time-unit">sec.</p>
              </div>
              <div class="time-input">
                <p class="label">Average Self-selected speed walking Velocity</p>
                <input type="number" class="form-control" placeholder="평균속도" disabled min="0"
                       v-model="tmwt.self_selected_speed.average_self_selected">
                <p class="time-unit">m/s</p>
              </div>
            </div>
          </div>
          <div class="sud-title">Fast speed(빠른 걸음)</div>
          <div class="form-group">
            <div class="left">
              <div class="time-input">
                <p class="label">Trial 1</p>
                <div class="input-box">
                  <input type="number" class="form-control" placeholder="시간입력" @input="inputTrial(1,'fast_speed')"
                         :class="{disabled: isStopTrial4,btnActive: isStopTrial4}" v-model.number="tmwt.fast_speed.trial_1" min="0">
                  <p class="time-unit">sec.</p>
                  <button class="custom-btn-box" @click="stopBtn(4)">NT
                  </button>
                </div>
              </div>
              <div class="time-input">
                <p class="label">Trial 2</p>
                <div class="input-box">
                  <input type="number" class="form-control" placeholder="시간입력" @input="inputTrial(2,'fast_speed')"
                         :class="{disabled: isStopTrial5,btnActive: isStopTrial5}" v-model.number="tmwt.fast_speed.trial_2" min="0">
                  <p class="time-unit">sec.</p>
                  <button class="custom-btn-box" :class="{disabled: isStopTrial4}"
                          @click="stopBtn(5)">NT
                  </button>
                </div>
              </div>
              <div class="time-input">
                <p class="label">Trial 3</p>
                <div class="input-box">
                  <input type="number" id="selfSelected" class="form-control" placeholder="시간입력" @input="inputTrial(3,'fast_speed')"
                         :class="{disabled: isStopTrial6,btnActive: isStopTrial6}" v-model.number="tmwt.fast_speed.trial_3" min="0">
                  <p class="time-unit">sec.</p>
                  <button class="custom-btn-box" :class="{disabled: isStopTrial4 || isStopTrial5}"
                          @click="stopBtn(6)">NT
                  </button>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="time-input">
                <p class="label">Average time</p>
                <input type="number" class="form-control" placeholder="평균시간" disabled min="0"
                       v-model="tmwt.fast_speed.average_time">
                <p class="time-unit">sec.</p>
              </div>
              <div class="time-input">
                <p class="label">Average Fast speed walking Velocity</p>
                <input type="number" class="form-control" placeholder="평균속도" disabled min="0"
                       v-model="tmwt.fast_speed.average_self_selected">
                <p class="time-unit">m/s</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
  </div>

</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "TenMeterWalkTest",
  components: {},
  props: {
    fbDocId: String,
    tmwt: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      tools: {
        tmwt: {}
      },
      isStopTrial1: false,
      isStopTrial2: false,
      isStopTrial3: false,
      isStopTrial4: false,
      isStopTrial5: false,
      isStopTrial6: false,
      isShowPopUp: false,
      isShowModal: false,
    };
  },
  mounted() {
    const selfSpeed = this.tmwt.self_selected_speed
    const fastSpeed = this.tmwt.fast_speed
    if (selfSpeed.trial_1 !== null && selfSpeed.trial_2 === null && selfSpeed.trial_3 === null) {
      this.isStopTrial2 = true
      this.isStopTrial3 = true
    } else if (selfSpeed.trial_1 !== null && selfSpeed.trial_2 !== null && selfSpeed.trial_3 === null) this.isStopTrial3 = true
    if (fastSpeed.trial_1 !== null && fastSpeed.trial_2 === null && fastSpeed.trial_3 === null) {
      this.isStopTrial5 = true
      this.isStopTrial6 = true
    } else if (fastSpeed.trial_1 !== null && fastSpeed.trial_2 !== null && fastSpeed.trial_3 === null) this.isStopTrial6 = true
  },
  methods: {
    inputTrial(index, category) {
      const self = this;
      self.averageReset(index, category)
      self.average(index, category)
    },
    async tempSave() {
      const self = this;
      self.tools.tmwt = self.tmwt;

      const selfSpeed = self.tools.tmwt.self_selected_speed
      const fastSpeed = self.tools.tmwt.fast_speed
      if (selfSpeed.trial_1 < 0 || selfSpeed.trial_2 < 0 || selfSpeed.trial_3 < 0 ||
          fastSpeed.trial_1 < 0 || fastSpeed.trial_2 < 0 || fastSpeed.trial_3 < 0) {
        alert('0 미만으로 내려갈 수 없습니다.')
        return
      }
      self.saveValue()
      self.tools.tmwt['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'tmwt', 1)
          });
    },
    async resultSave() {
      const self = this;
      const selfSpeed = self.tmwt.self_selected_speed
      const fastSpeed = self.tmwt.fast_speed
      if (self.tmwt.assistive_device === null) return alert('보조도구 사용 여부를 체크해주세요.')
      if (self.tmwt.assist === null) return alert('Assist를 선택해 주세요.')
      if (selfSpeed.trial_1 < 0 || selfSpeed.trial_2 < 0 || selfSpeed.trial_3 < 0 ||
          fastSpeed.trial_1 < 0 || fastSpeed.trial_2 < 0 || fastSpeed.trial_3 < 0) {
        alert('0 미만으로 내려갈 수 없습니다.')
        return
      } else if (
          selfSpeed.average_time === '' || selfSpeed.average_time === null ||
          selfSpeed.average_self_selected === '' || selfSpeed.average_self_selected === null ||
          fastSpeed.average_time === '' || fastSpeed.average_time === null ||
          fastSpeed.average_self_selected === '' || fastSpeed.average_self_selected === null
      ) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return
      }
      self.saveValue()
      self.btnConfirm()
    },
    saveValue() {
      const self = this;
      let selfSpeed = self.tmwt.self_selected_speed
      let fastSpeed = self.tmwt.fast_speed
      if (self.isStopTrial1) {
        selfSpeed.trial_1 = null
        selfSpeed.trial_2 = null
        selfSpeed.trial_3 = null
      }
      if (self.isStopTrial2) {
        selfSpeed.trial_2 = null
        selfSpeed.trial_3 = null
      }
      if (self.isStopTrial3) selfSpeed.trial_3 = null
      if (self.isStopTrial4) {
        fastSpeed.trial_1 = null
        fastSpeed.trial_2 = null
        fastSpeed.trial_3 = null
      }
      if (self.isStopTrial5) {
        fastSpeed.trial_2 = null
        fastSpeed.trial_3 = null
      }
      if (self.isStopTrial6) fastSpeed.trial_3 = null
    },
    btnConfirm() {
      const self = this;
      self.tools.tmwt = self.tmwt;
      self.tools.tmwt['state'] = 2;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'tmwt', 2)
          });
    },
    average(index, category) {
      const self = this;
      const speed = self.tmwt[category]

      if (category === 'self_selected_speed') {
        if (self.isStopTrial1) {
          speed.average_time = ''
          speed.average_self_selected = ''
        } else if (self.isStopTrial2) {
          speed.average_time = speed.trial_1.toFixed(2)
          speed.average_self_selected = (6 / speed.trial_1).toFixed(2)
        } else if (self.isStopTrial3) {

          if (speed.trial_1 !== null && speed.trial_2 !== null) {
            speed.average_time = ((speed.trial_1 + speed.trial_2) / 2).toFixed(2)
            speed.average_self_selected = (((6 / speed.trial_1) + (6 / speed.trial_2)) / 2).toFixed(2)
          }
        }
        if (!self.isStopTrial1 && !self.isStopTrial2 && !self.isStopTrial3) {
          if (speed.trial_1 !== null && speed.trial_2 !== null && speed.trial_3 !== null) {
            speed.average_time = ((speed.trial_1 + speed.trial_2 + speed.trial_3) / 3).toFixed(2)
            speed.average_self_selected = (((6 / speed.trial_1) + (6 / speed.trial_2) + (6 / speed.trial_3)) / 3).toFixed(2)
          }
        }
      } else if (category === 'fast_speed') {
        if (self.isStopTrial4) {
          speed.average_time = ''
          speed.average_self_selected = ''
        } else if (self.isStopTrial5) {
          speed.average_time = speed.trial_1.toFixed(2)
          speed.average_self_selected = (6 / speed.trial_1).toFixed(2)
        } else if (self.isStopTrial6) {
          if (speed.trial_1 !== null && speed.trial_2 !== null) {
            speed.average_time = ((speed.trial_1 + speed.trial_2) / 2).toFixed(2)
            speed.average_self_selected = (((6 / speed.trial_1) + (6 / speed.trial_2)) / 2).toFixed(2)
          }
        }
        if (!self.isStopTrial4 && !self.isStopTrial5 && !self.isStopTrial6) {
          if (speed.trial_1 !== null && speed.trial_2 !== null && speed.trial_3 !== null) {
            speed.average_time = ((speed.trial_1 + speed.trial_2 + speed.trial_3) / 3).toFixed(2)
            speed.average_self_selected = (((6 / speed.trial_1) + (6 / speed.trial_2) + (6 / speed.trial_3)) / 3).toFixed(2)
          }
        }
      }
    },
    averageReset(index, category) {
      const self = this;
      const speed = self.tmwt[category]
      if (speed['trial_' + index] === '') speed['trial_' + index] = null
      speed.average_time = ''
      speed.average_self_selected = ''
    },
    stopBtn(index) {
      const self = this
      switch (index) {
        case 1:
        case 4:
          self['isStopTrial' + index] = !self['isStopTrial' + index]
          self['isStopTrial' + (index + 1)] = self['isStopTrial' + index]
          self['isStopTrial' + (index + 2)] = self['isStopTrial' + index]
          break;
        case 2:
        case 5:
          self['isStopTrial' + index] = !self['isStopTrial' + index]
          self['isStopTrial' + (index + 1)] = self['isStopTrial' + index]
          break;
        case 3:
        case 6:
          self['isStopTrial' + index] = !self['isStopTrial' + index]
          break;
      }
      if (index < 4) {
        self.averageReset(index, 'self_selected_speed')
        self.average(index, 'self_selected_speed')
      } else if (index > 3) {
        self.averageReset(index - 2, 'fast_speed')
        self.average(index, 'fast_speed')
      }
    }
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.assistive-check,
.assist-box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.assistive-check .sud-title,
.assist-box .sud-title {
  margin: 0 !important;
}

.pop-up {
  padding: 23px 20px;
  position: absolute;
  width: 732px;
  height: 232px;
  left: 147px;
  bottom: 85%;
  transform: translateY(50%);
  background: #FFFFFF;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 50;
  display: flex;
}

.pop-up .text {
  width: 658px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.pop-up .text h5 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
}

.pop-up img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.btn-check-box {
  display: flex;
  justify-content: space-between;
  width: 530px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align-items: center;*/
}

.form-group {
  width: 100%;
  height: 242px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
}

.form-group > div {
  width: 530px;
}

.form-group .left .time-input:nth-child(2),
.form-group .right .time-input:nth-child(1) {
  margin: 10px 0;
}

.form-group input {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  height: 40px;
  line-height: 22px;
  text-align: right;
  padding-right: 48px;
}

.time-input {
  position: relative;
}

.time-input .input-box {
  display: flex;
}

.time-input .input-box .form-control {
  width: 438px;
}

.time-input .input-box button {
  background: #B1B1B1;
  color: #ffffff;
  width: 86px !important;
  margin-left: 6px;
  line-height: 22px;
}

.time-input .input-box .btnActive {
  background: #F8F8FA;
  color: #B1B1B1;
}

.time-input .time-unit {
  position: absolute;
  bottom: 9px;
  right: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #A0B0C6;
}

.time-input .input-box .time-unit {
  right: 100px;
}

input:disabled {
  background: #F8F8FA;
}

input::placeholder {
  color: #B1B1B1;
}

.sud-title {
  color: #0069CA;
  font-weight: 500;
  margin-bottom: 10px;
}

.form-container > .sud-title {
  margin: 20px 0;
}

.paper-main-frame {
  background-color: #ffffff !important;
  position: relative;

}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}

.paper-frame {
  width: 100%;
  margin: 0;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}

.time-input .label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #A0B0C6;
  margin-bottom: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.radio-group {
  height: 46px;
  width: 530px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.container {
  cursor: pointer;
  width: 46px;
  font-size: 18px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 !important;
  margin: 0;
}

.container:last-child {
  margin-right: 0;
}


/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

.check-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 46px;
  background: #F1F6FD;
  font-weight: 500;
  color: #A0B0C6;
  line-height: 24px;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: #F1F6FD;
  border-radius: 100px;
  border: 0;
  color: #A0B0C6;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark,
.btn-check-box input:checked ~ .check-btn {
  color: #0069CA;
  background: #FFFFFF;
  border: 2px solid #0069CA;
}


</style>
