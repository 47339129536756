<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        진행할 평가도구 선택
      </h5>
    </div>
    <div class="modal-center" v-if="evaluationTools">
      <!--      <p>평가 결과 저장이 완료된 평가는 변경할 수 없습니다.</p>-->
      <div class="tools-title">
        <div class="tools-sub-title">Basic Measure</div>
      </div>
      <div class="tools">
        <div class="tools-item bm-box" v-if="evaluationTools.bm === undefined || !evaluationTools.bm.checked">
          <div class="bm">
            <div class="sub-title doNotSelected">
              <p class="tool-title">ROM, MMT</p>
            </div>
            <div class="sub-title doNotSelected">
              <p class="tool-title">MAS</p>
            </div>
            <div class="sub-title doNotSelected">
              <p class="tool-title">Balance</p>
            </div>
          </div>
        </div>
        <div class="tools-item bm-box" v-else-if="evaluationTools.bm.checked">
          <div class="bm">
            <div class="sub-title">
              <p class="tool-title">ROM, MMT</p>
              <p class="active-item btn-cursor" v-if="evaluationTools.rom_mmt.state === 1" @click="btnSelectedTool('rom_mmt')"> 임시저장됨</p>
              <p class="edit-item btn-cursor" v-if="evaluationTools.rom_mmt.state === 2 && isEvaluationEdit" @click="btnSelectedTool('rom_mmt')"> 수정하기</p>
              <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.rom_mmt.state === 2" @click="btnSelectedTool('rom_mmt')"><img src="@/assets/images/IA/check.png" alt="check"></p>
              <p class="btn-cursor" v-if="evaluationTools.rom_mmt.state !== 1 && evaluationTools.rom_mmt.state !== 2" @click="btnSelectedTool('rom_mmt')">평가하기</p>
            </div>
            <div class="sub-title">
              <p class="tool-title">MAS</p>
              <p class="active-item btn-cursor" v-if="evaluationTools.mas.state === 1" @click="btnSelectedTool('mas')"> 임시저장됨</p>
              <p class="edit-item btn-cursor" v-if="evaluationTools.mas.state === 2 && isEvaluationEdit" @click="btnSelectedTool('mas')"> 수정하기</p>
              <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.mas.state === 2" @click="btnSelectedTool('mas')"><img src="@/assets/images/IA/check.png" alt="check"></p>
              <p class="btn-cursor" v-if="evaluationTools.mas.state !== 1 && evaluationTools.mas.state !== 2" @click="btnSelectedTool('mas')">평가하기</p>
            </div>
            <div class="sub-title">
              <p class="tool-title">Balance</p>
              <p class="active-item btn-cursor" v-if="evaluationTools.balance.state === 1" @click="btnSelectedTool('balance')"> 임시저장됨</p>
              <p class="edit-item btn-cursor" v-if="evaluationTools.balance.state === 2 && isEvaluationEdit" @click="btnSelectedTool('balance')"> 수정하기</p>
              <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.balance.state === 2" @click="btnSelectedTool('balance')"><img src="@/assets/images/IA/check.png" alt="check"></p>
              <p class="btn-cursor" v-if="evaluationTools.balance.state !== 1 && evaluationTools.balance.state !== 2" @click="btnSelectedTool('balance')">평가하기</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tools-bottom-container">
        <div class="left">
          <div class="tools-title">
            <div class="tools-sub-title">Upper Extremity</div>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_1 = !active.tool_1">Body Function & Structure
              <img v-if="active.tool_1" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_1" :duration="500">
              <div class="tools-item doNotSelected" v-if="evaluationTools.fm === undefined || !evaluationTools.fm.checked">
                <p class="tool-title">Fugl-Meyer</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.fm.checked">
                <p class="tool-title">Fugl-Meyer</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.fm.state === 1" @click="btnSelectedTool('fm')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.fm.state === 2 && isEvaluationEdit" @click="btnSelectedTool('fm')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.fm.state === 2" @click="btnSelectedTool('fm')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.fm.state !== 1 && evaluationTools.fm.state !== 2" @click="btnSelectedTool('fm')">평가하기</p>
              </div>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_2 = !active.tool_2">Activity
              <img v-if="active.tool_2" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_2" :duration="500">
              <div class="tools-item doNotSelected" v-if="evaluationTools.bb === undefined || !evaluationTools.bb.checked">
                <p class="tool-title">BB</p>
              </div>
              <div class="tools-item " v-else-if="evaluationTools.bb.checked">
                <p class="tool-title">BB</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.bb.state === 1" @click="btnSelectedTool('bb')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.bb.state === 2 && isEvaluationEdit" @click="btnSelectedTool('bb')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.bb.state === 2" @click="btnSelectedTool('bb')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.bb.state !== 1 && evaluationTools.bb.state !== 2" @click="btnSelectedTool('bb')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.wmft === undefined || !evaluationTools.wmft.checked">
                <p class="tool-title ">WMFT</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.wmft.checked">
                <p class="tool-title">WMFT</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.wmft.state === 1" @click="btnSelectedTool('wmft')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.wmft.state === 2 && isEvaluationEdit" @click="btnSelectedTool('wmft')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.wmft.state === 2" @click="btnSelectedTool('wmft')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.wmft.state !== 1 && evaluationTools.wmft.state !== 2" @click="btnSelectedTool('wmft')">평가하기</p>
              </div>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_3 = !active.tool_3">Participation
              <img v-if="active.tool_3" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_3" :duration="500">
              <div class="tools-item doNotSelected" v-if="evaluationTools.sis === undefined || !evaluationTools.sis.checked">
                <p class="tool-title ">SIS</p>
              </div>
              <div class="tools-item " v-else-if="evaluationTools.sis.checked">
                <p class="tool-title">SIS</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.sis.state === 1" @click="btnSelectedTool('sis')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.sis.state === 2 && isEvaluationEdit" @click="btnSelectedTool('sis')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.sis.state === 2" @click="btnSelectedTool('sis')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.sis.state !== 1 && evaluationTools.sis.state !== 2" @click="btnSelectedTool('sis')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.aaut === undefined || !evaluationTools.aaut.checked">
                <p class="tool-title ">AAUT</p>
              </div>
              <div class="tools-item " v-else-if="evaluationTools.aaut.checked">
                <p class="tool-title">AAUT</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.aaut.state === 1" @click="btnSelectedTool('aaut')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.aaut.state === 2 && isEvaluationEdit" @click="btnSelectedTool('aaut')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.aaut.state === 2" @click="btnSelectedTool('aaut')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.aaut.state !== 1 && evaluationTools.aaut.state !== 2" @click="btnSelectedTool('aaut')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.mal === undefined || !evaluationTools.mal.checked">
                <p class="tool-title ">MAL</p>
              </div>
              <div class="tools-item " v-else-if="evaluationTools.mal.checked">
                <p class="tool-title">MAL</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.mal.state === 1" @click="btnSelectedTool('mal')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.mal.state === 2 && isEvaluationEdit" @click="btnSelectedTool('mal')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.mal.state === 2" @click="btnSelectedTool('mal')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.mal.state !== 1 && evaluationTools.mal.state !== 2" @click="btnSelectedTool('mal')">평가하기</p>
              </div>
            </slide-up-down>
          </div>
        </div>
        <div class="right">
          <div class="tools-title">
            <div class="tools-sub-title">Lower Extremity</div>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_4 = !active.tool_4">Body Function & Structure
              <img v-if="active.tool_4" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_4" :duration="500">
              <div class="tools-item doNotSelected" v-if="evaluationTools.bbs === undefined || !evaluationTools.bbs.checked">
                <p class="tool-title">BBS</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.bbs.checked">
                <p class="tool-title">BBS</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.bbs.state === 1" @click="btnSelectedTool('bbs')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.bbs.state === 2 && isEvaluationEdit" @click="btnSelectedTool('bbs')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.bbs.state === 2" @click="btnSelectedTool('bbs')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.bbs.state !== 1 && evaluationTools.bbs.state !== 2" @click="btnSelectedTool('bbs')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.tug === undefined || !evaluationTools.tug.checked">
                <p class="tool-title">TUG</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.tug.checked">
                <p class="tool-title">TUG</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.tug.state === 1" @click="btnSelectedTool('tug')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.tug.state === 2 && isEvaluationEdit" @click="btnSelectedTool('tug')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.tug.state === 2" @click="btnSelectedTool('tug')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.tug.state !== 1 && evaluationTools.tug.state !== 2" @click="btnSelectedTool('tug')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.ftsts === undefined || !evaluationTools.ftsts.checked">
                <p class="tool-title">FTSTS</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.ftsts.checked">
                <p class="tool-title">FTSTS</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.ftsts.state === 1" @click="btnSelectedTool('ftsts')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.ftsts.state === 2 && isEvaluationEdit" @click="btnSelectedTool('ftsts')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.ftsts.state === 2" @click="btnSelectedTool('ftsts')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.ftsts.state !== 1 && evaluationTools.ftsts.state !== 2" @click="btnSelectedTool('ftsts')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.fm_l === undefined || !evaluationTools.fm_l.checked">
                <p class="tool-title">Fugl-Meyer</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.fm_l.checked">
                <p class="tool-title">Fugl-Meyer</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.fm_l.state === 1" @click="btnSelectedTool('fm_l')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.fm_l.state === 2 && isEvaluationEdit" @click="btnSelectedTool('fm_l')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.fm_l.state === 2" @click="btnSelectedTool('fm_l')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.fm_l.state !== 1 && evaluationTools.fm_l.state !== 2" @click="btnSelectedTool('fm_l')">평가하기</p>
              </div>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_5 = !active.tool_5">Activity
              <img v-if="active.tool_5" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_5" :duration="500">
              <div class="tools-item doNotSelected" v-if="evaluationTools.tmwt === undefined || !evaluationTools.tmwt.checked">
                <p class="tool-title">10MWT</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.tmwt.checked">
                <p class="tool-title">10MWT</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.tmwt.state === 1" @click="btnSelectedTool('tmwt')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.tmwt.state === 2 && isEvaluationEdit" @click="btnSelectedTool('tmwt')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.tmwt.state === 2" @click="btnSelectedTool('tmwt')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.tmwt.state !== 1 && evaluationTools.tmwt.state !== 2" @click="btnSelectedTool('tmwt')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.smwt === undefined || !evaluationTools.smwt.checked">
                <p class="tool-title">6MWT</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.smwt.checked">
                <p class="tool-title">6MWT</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.smwt.state === 1" @click="btnSelectedTool('smwt')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.smwt.state === 2 && isEvaluationEdit" @click="btnSelectedTool('smwt')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.smwt.state === 2" @click="btnSelectedTool('smwt')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.smwt.state !== 1 && evaluationTools.smwt.state !== 2" @click="btnSelectedTool('smwt')">평가하기</p>
              </div>
              <div class="tools-item doNotSelected" v-if="evaluationTools.dgi === undefined || !evaluationTools.dgi.checked">
                <p class="tool-title">DGI</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.dgi.checked">
                <p class="tool-title">DGI</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.dgi.state === 1" @click="btnSelectedTool('dgi')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.dgi.state === 2 && isEvaluationEdit" @click="btnSelectedTool('dgi')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.dgi.state === 2" @click="btnSelectedTool('dgi')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.dgi.state !== 1 && evaluationTools.dgi.state !== 2" @click="btnSelectedTool('dgi')">평가하기</p>
              </div>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_6 = !active.tool_6">Participation
              <img v-if="active.tool_6" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_6" :duration="500">
              <div class="tools-item doNotSelected" v-if="evaluationTools.abc === undefined || !evaluationTools.abc.checked">
                <p class="tool-title">ABCscale</p>
              </div>
              <div class="tools-item" v-else-if="evaluationTools.abc.checked">
                <p class="tool-title">ABCscale</p>
                <p class="active-item btn-cursor" v-if="evaluationTools.abc.state === 1" @click="btnSelectedTool('abc')"> 임시저장됨</p>
                <p class="edit-item btn-cursor" v-if="evaluationTools.abc.state === 2 && isEvaluationEdit" @click="btnSelectedTool('abc')"> 수정하기</p>
                <p class="active-item btn-cursor icon-check" v-else-if="evaluationTools.abc.state === 2" @click="btnSelectedTool('abc')"><img src="@/assets/images/IA/check.png" alt="check"></p>
                <p class="btn-cursor" v-if="evaluationTools.abc.state !== 1 && evaluationTools.abc.state !== 2" @click="btnSelectedTool('abc')">평가하기</p>
              </div>
            </slide-up-down>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class=" custom-btn-box-white btn-short" @click="btnCancel">닫기</button>
    </div>
  </div>

</template>

<script>
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "EvaluationToolsSelectDialog",
  components: {
    SlideUpDown,
  },
  props: {
    tools: Object,
  },
  data() {
    return {
      checkData: false,
      fbCollection: 'evaluations',
      fbDocId: this.$route.params.id.toString(),
      active: {tool_1: true, tool_2: true, tool_3: true, tool_4: true, tool_5: true, tool_6: true,},
      evaluationTools: null,
      isEvaluationEdit: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getStateEndCheck()
      this.checkIsEditPage()
    },
    checkIsEditPage() {
      const self = this;
      if (localStorage.isEvaluationEdit) {
        self.isEvaluationEdit = true;
      }
    },
    getStateEndCheck() {
      const self = this;
      self.evaluationTools = JSON.parse(JSON.stringify(self.tools));
      if (self.tools.fm !== undefined)
        self.active.tool_1 = self.evaluationTools.fm.checked
      if (self.tools.bb !== undefined || self.tools.wmft !== undefined)
        self.active.tool_2 = self.evaluationTools.bb.checked || self.evaluationTools.wmft.checked
      if (self.tools.sis !== undefined || self.tools.aaut !== undefined || self.tools.mal !== undefined)
        self.active.tool_3 = self.evaluationTools.sis.checked || self.evaluationTools.aaut.checked || self.evaluationTools.mal.checked
      if (self.tools.bbs !== undefined || self.tools.tug !== undefined || self.tools.ftsts !== undefined || self.tools.fm_l !== undefined)
        self.active.tool_4 = self.evaluationTools.bbs.checked || self.evaluationTools.tug.checked || self.evaluationTools.ftsts.checked || self.evaluationTools.fm_l.checked
      if (self.tools.tmwt !== undefined || self.tools.smwt !== undefined || self.tools.dgi !== undefined)
        self.active.tool_5 = self.evaluationTools.tmwt.checked || self.evaluationTools.smwt.checked || self.evaluationTools.dgi.checked
      if (self.tools.abc !== undefined)
        self.active.tool_6 = self.evaluationTools.abc.checked

    },
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 0, false)
    },
    btnSelectedTool(selPagerName) {
      const self = this;
      self.$emit('callBackModal', 4, false, selPagerName)
    },
  }
}
</script>

<style scoped>

button {
  font-size: 16px !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 3;
}


.modal-container {
  position: fixed;
  width: 580px;
  height: 657px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}


.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 23px 20px;
  background: #F8F8FA;
  max-height: 507px;
  overflow: overlay;
}

.modal-center p {

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #DC2626;
}

.tools-bottom-container {
  display: flex;
  justify-content: space-between;
}

.tools-bottom-container > div {
  width: 265px;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  padding: 16px;
}

.modal-footer button {
  margin: 0;
}

.btn-short {
  width: 84px;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.custom-btn-box-white {
  background: #ffffff;
  border: 1px solid #E1E3E6;
}


.search-result-item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.tools-sub-title {
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E1E3E6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #0069CA;
  text-align: left;
  padding: 0 8px 0 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tools-title {
  margin-top: 20px;
}

.tools-title .tools-sub-title {
  background: #00498A;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  color: #FFFFFF;
}

.tools-sub-title img {
  width: 18px;
  height: 18px;
  margin-left: auto;
}

.tools-item {
  height: 40px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 6px;
}

.tools img {
  width: 18px;
  height: 18px;
}

.bm-box {
  display: flex;
  height: 132px;
  padding: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}

.bm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

.bm .sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  width: 100%;
  height: 34px;
  border: 1px solid #E1E3E6;
  font-weight: 400;
  line-height: 22px;
  color: #000000;

}

.tools-item p {
  font-size: 14px;
  color: #8D8D8D;
}

.tools-item .tool-title {
  font-size: 16px;
  color: #000000;
}

.tools-item .active-item {
  color: #E14F4E;
}

.tools-item .edit-item {
  color: #8D8D8D;
}

.btn-cursor {
  cursor: pointer;
}

.doNotSelected {
  background: #f0f0f0;
}

.doNotSelected p {
  color: #d2d2d2 !important;
}

</style>
