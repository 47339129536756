<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Modified Ashowrth Scale(MAS)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-guide-frame">
      <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
      <p class="paper-guide">MAS에 대한 설명 <span class="blue-btn" @click="isShowPopUp = true">더보기</span></p>
      <div class="pop-up" v-if="isShowPopUp">
        <div class="text">
          <h5>MAS 등급</h5>
          <div>
            <div class="gray">
              <h5>Grade</h5>
              <h5>Modified Ashworth Scale</h5>
            </div>
            <div>
              <p>G0</p>
              <p>모든 범위에서 muscle tone이 없다.</p>
            </div>
            <div>
              <p>G1</p>
              <p>ROM의 마지막 단계에서 muscle tone이 있다.</p>
            </div>
            <div>
              <p>G1+</p>
              <p>ROM의 50%부터 muscle tone이 있다.</p>
            </div>
            <div>
              <p>G2</p>
              <p>ROM 모든 범위에서 muscle tone이 있다.</p>
            </div>
            <div>
              <p>G3</p>
              <p>수동적 움직임이 가능하나 힘들다.</p>
            </div>
            <div>
              <p>G4</p>
              <p>신전, 굴곡에서 경직(Rigidity)으로 수동적 움직임 불가</p>
            </div>
            <div>
              <p>NT</p>
              <p>Not Tested.</p>
            </div>
          </div>
        </div>
        <img src="@/assets/images/IA/btn_close.png" alt="" @click="isShowPopUp= false">
      </div>
      <button @click="masIsNT">NT</button>
    </div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-table">
        <div class="paper-topic">
          <div class="header-line first ">Joint/Segment</div>
          <div class="header-line second">Movement</div>
          <div class="header-line last">MAS</div>
        </div>
        <div class="times">
          <div class="first"></div>
          <div class="second"></div>
          <div class="last">
            <div>Lt</div>
            <div class="line"/>
            <div>Rt</div>
          </div>
        </div>
        <div class="times">
          <div class="first">Shoulder</div>
          <div class="second">Flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_1 = !active.lt.tool_1">{{ mas.lt.shoulder.flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_1 = !active.rt.tool_1">{{ mas.rt.shoulder.flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_1" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.flexion" :value="'G0'"
                     @click="active.lt.tool_1 = !active.lt.tool_1">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.flexion" :value="'G1'"
                     @click="active.lt.tool_1 = !active.lt.tool_1">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.flexion" :value="'G1+'"
                     @click="active.lt.tool_1 = !active.lt.tool_1">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.flexion" :value="'G2'"
                     @click="active.lt.tool_1 = !active.lt.tool_1">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.flexion" :value="'G3'"
                     @click="active.lt.tool_1 = !active.lt.tool_1">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.flexion" :value="'G4'"
                     @click="active.lt.tool_1 = !active.lt.tool_1">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_1" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.flexion" :value="'G0'"
                     @click="active.rt.tool_1 = !active.rt.tool_1">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.flexion  " :value="'G1'"
                     @click="active.rt.tool_1 = !active.rt.tool_1">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.flexion  " :value="'G1+'"
                     @click="active.rt.tool_1 = !active.rt.tool_1">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.flexion  " :value="'G2'"
                     @click="active.rt.tool_1 = !active.rt.tool_1">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.flexion  " :value="'G3'"
                     @click="active.rt.tool_1 = !active.rt.tool_1">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.flexion  " :value="'G4'"
                     @click="active.rt.tool_1 = !active.rt.tool_1">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_2 = !active.lt.tool_2">{{ mas.lt.shoulder.extension }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_2 = !active.rt.tool_2">{{ mas.rt.shoulder.extension }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_2" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.extension" :value="'G0'"
                     @click="active.lt.tool_2 = !active.lt.tool_2">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.extension" :value="'G1'"
                     @click="active.lt.tool_2 = !active.lt.tool_2">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.extension" :value="'G1+'"
                     @click="active.lt.tool_2 = !active.lt.tool_2">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.extension" :value="'G2'"
                     @click="active.lt.tool_2 = !active.lt.tool_2">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.extension" :value="'G3'"
                     @click="active.lt.tool_2 = !active.lt.tool_2">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.extension" :value="'G4'"
                     @click="active.lt.tool_2 = !active.lt.tool_2">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_2" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.extension" :value="'G0'"
                     @click="active.rt.tool_2 = !active.rt.tool_2">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.extension" :value="'G1'"
                     @click="active.rt.tool_2 = !active.rt.tool_2">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.extension" :value="'G1+'"
                     @click="active.rt.tool_2 = !active.rt.tool_2">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.extension" :value="'G2'"
                     @click="active.rt.tool_2 = !active.rt.tool_2">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.extension" :value="'G3'"
                     @click="active.rt.tool_2 = !active.rt.tool_2">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.extension" :value="'G4'"
                     @click="active.rt.tool_2 = !active.rt.tool_2">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Abduction</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_3 = !active.lt.tool_3">{{ mas.lt.shoulder.abduction }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_3 = !active.rt.tool_3">{{ mas.rt.shoulder.abduction }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_3" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.abduction" :value="'G0'" @click="active.lt.tool_3 = !active.lt.tool_3">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.abduction" :value="'G1'"
                     @click="active.lt.tool_3 = !active.lt.tool_3">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.abduction" :value="'G1+'"
                     @click="active.lt.tool_3 = !active.lt.tool_3">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.abduction" :value="'G2'"
                     @click="active.lt.tool_3 = !active.lt.tool_3">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.abduction" :value="'G3'"
                     @click="active.lt.tool_3 = !active.lt.tool_3">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.abduction" :value="'G4'"
                     @click="active.lt.tool_3 = !active.lt.tool_3">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_3" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.abduction" :value="'G0'"
                     @click="active.rt.tool_3 = !active.rt.tool_3">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.abduction" :value="'G1'"
                     @click="active.rt.tool_3 = !active.rt.tool_3">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.abduction" :value="'G1+'"
                     @click="active.rt.tool_3 = !active.rt.tool_3">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.abduction" :value="'G2'"
                     @click="active.rt.tool_3 = !active.rt.tool_3">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.abduction" :value="'G3'"
                     @click="active.rt.tool_3 = !active.rt.tool_3">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.abduction" :value="'G4'"
                     @click="active.rt.tool_3 = !active.rt.tool_3">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Adduction</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_4 = !active.lt.tool_4">{{ mas.lt.shoulder.adduction }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_4 = !active.rt.tool_4">{{ mas.rt.shoulder.adduction }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_4" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.adduction" :value="'G0'"
                     @click="active.lt.tool_4 = !active.lt.tool_4">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.adduction" :value="'G1'"
                     @click="active.lt.tool_4 = !active.lt.tool_4">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.adduction" :value="'G1+'"
                     @click="active.lt.tool_4 = !active.lt.tool_4">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.adduction" :value="'G2'"
                     @click="active.lt.tool_4 = !active.lt.tool_4">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.adduction" :value="'G3'"
                     @click="active.lt.tool_4 = !active.lt.tool_4">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.shoulder.adduction" :value="'G4'"
                     @click="active.lt.tool_4 = !active.lt.tool_4">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_4" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.adduction" :value="'G0'"
                     @click="active.rt.tool_4 = !active.rt.tool_4">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.adduction" :value="'G1'"
                     @click="active.rt.tool_4 = !active.rt.tool_4">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.adduction" :value="'G1+'"
                     @click="active.rt.tool_4 = !active.rt.tool_4">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.adduction" :value="'G2'"
                     @click="active.rt.tool_4 = !active.rt.tool_4">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.adduction" :value="'G3'"
                     @click="active.rt.tool_4 = !active.rt.tool_4">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.shoulder.adduction" :value="'G4'"
                     @click="active.rt.tool_4 = !active.rt.tool_4">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Elbow</div>
          <div class="second">Flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_5 = !active.lt.tool_5">{{ mas.lt.elbow.flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_5 = !active.rt.tool_5">{{ mas.rt.elbow.flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_5" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.flexion" :value="'G0'"
                     @click="active.lt.tool_5 = !active.lt.tool_5">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.flexion" :value="'G1'"
                     @click="active.lt.tool_5 = !active.lt.tool_5">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.flexion" :value="'G1+'"
                     @click="active.lt.tool_5 = !active.lt.tool_5">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.flexion" :value="'G2'"
                     @click="active.lt.tool_5 = !active.lt.tool_5">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.flexion" :value="'G3'"
                     @click="active.lt.tool_5 = !active.lt.tool_5">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.flexion" :value="'G4'"
                     @click="active.lt.tool_5 = !active.lt.tool_5">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_5" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.flexion" :value="'G0'"
                     @click="active.rt.tool_5 = !active.rt.tool_5">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.flexion" :value="'G1'"
                     @click="active.rt.tool_5 = !active.rt.tool_5">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.flexion" :value="'G1+'"
                     @click="active.rt.tool_5 = !active.rt.tool_5">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.flexion" :value="'G2'"
                     @click="active.rt.tool_5 = !active.rt.tool_5">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.flexion" :value="'G3'"
                     @click="active.rt.tool_5 = !active.rt.tool_5">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.flexion" :value="'G4'"
                     @click="active.rt.tool_5 = !active.rt.tool_5">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_6 = !active.lt.tool_6">{{ mas.lt.elbow.extension }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_6 = !active.rt.tool_6">{{ mas.rt.elbow.extension }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_6" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.extension" :value="'G0'"
                     @click="active.lt.tool_6 = !active.lt.tool_6">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.extension" :value="'G1'"
                     @click="active.lt.tool_6 = !active.lt.tool_6">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.extension" :value="'G1+'"
                     @click="active.lt.tool_6 = !active.lt.tool_6">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.extension" :value="'G2'"
                     @click="active.lt.tool_6 = !active.lt.tool_6">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.extension" :value="'G3'"
                     @click="active.lt.tool_6 = !active.lt.tool_6">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.elbow.extension" :value="'G4'"
                     @click="active.lt.tool_6 = !active.lt.tool_6">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_6" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.extension" :value="'G0'"
                     @click="active.rt.tool_6 = !active.rt.tool_6">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.extension" :value="'G1'"
                     @click="active.rt.tool_6 = !active.rt.tool_6">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.extension" :value="'G1+'"
                     @click="active.rt.tool_6 = !active.rt.tool_6">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.extension" :value="'G2'"
                     @click="active.rt.tool_6 = !active.rt.tool_6">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.extension" :value="'G3'"
                     @click="active.rt.tool_6 = !active.rt.tool_6">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.elbow.extension" :value="'G4'"
                     @click="active.rt.tool_6 = !active.rt.tool_6">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Wrist</div>
          <div class="second">Flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_7 = !active.lt.tool_7">{{ mas.lt.wrist.flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_7 = !active.rt.tool_7">{{ mas.rt.wrist.flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_7" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.flexion" :value="'G0'"
                     @click="active.lt.tool_7 = !active.lt.tool_7">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.flexion" :value="'G1'"
                     @click="active.lt.tool_7 = !active.lt.tool_7">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.flexion" :value="'G1+'"
                     @click="active.lt.tool_7 = !active.lt.tool_7">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.flexion" :value="'G2'"
                     @click="active.lt.tool_7 = !active.lt.tool_7">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.flexion" :value="'G3'"
                     @click="active.lt.tool_7 = !active.lt.tool_7">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.flexion" :value="'G4'"
                     @click="active.lt.tool_7 = !active.lt.tool_7">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_7" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.flexion" :value="'G0'"
                     @click="active.rt.tool_7 = !active.rt.tool_7">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.flexion" :value="'G1'"
                     @click="active.rt.tool_7 = !active.rt.tool_7">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.flexion" :value="'G1+'"
                     @click="active.rt.tool_7 = !active.rt.tool_7">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.flexion" :value="'G2'"
                     @click="active.rt.tool_7 = !active.rt.tool_7">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.flexion" :value="'G3'"
                     @click="active.rt.tool_7 = !active.rt.tool_7">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.flexion" :value="'G4'"
                     @click="active.rt.tool_7 = !active.rt.tool_7">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_8 = !active.lt.tool_8">{{ mas.lt.wrist.extension }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_8 = !active.rt.tool_8">{{ mas.rt.wrist.extension }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_8" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.extension" :value="'G0'"
                     @click="active.lt.tool_8 = !active.lt.tool_8">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.extension" :value="'G1'"
                     @click="active.lt.tool_8 = !active.lt.tool_8">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.extension" :value="'G1+'"
                     @click="active.lt.tool_8 = !active.lt.tool_8">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.extension" :value="'G2'"
                     @click="active.lt.tool_8 = !active.lt.tool_8">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.extension" :value="'G3'"
                     @click="active.lt.tool_8 = !active.lt.tool_8">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.wrist.extension" :value="'G4'"
                     @click="active.lt.tool_8 = !active.lt.tool_8">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_8" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.extension" :value="'G0'"
                     @click="active.rt.tool_8 = !active.rt.tool_8">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.extension" :value="'G1'"
                     @click="active.rt.tool_8 = !active.rt.tool_8">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.extension" :value="'G1+'"
                     @click="active.rt.tool_8 = !active.rt.tool_8">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.extension" :value="'G2'"
                     @click="active.rt.tool_8 = !active.rt.tool_8">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.extension" :value="'G3'"
                     @click="active.rt.tool_8 = !active.rt.tool_8">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.wrist.extension" :value="'G4'"
                     @click="active.rt.tool_8 = !active.rt.tool_8">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Hip</div>
          <div class="second">Flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_9 = !active.lt.tool_9">{{ mas.lt.hip.flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_9 = !active.rt.tool_9">{{ mas.rt.hip.flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_9" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.flexion" :value="'G0'"
                     @click="active.lt.tool_9 = !active.lt.tool_9">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.flexion" :value="'G1'"
                     @click="active.lt.tool_9 = !active.lt.tool_9">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.flexion" :value="'G1+'"
                     @click="active.lt.tool_9 = !active.lt.tool_9">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.flexion" :value="'G2'"
                     @click="active.lt.tool_9 = !active.lt.tool_9">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.flexion" :value="'G3'"
                     @click="active.lt.tool_9 = !active.lt.tool_9">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.flexion" :value="'G4'"
                     @click="active.lt.tool_9 = !active.lt.tool_9">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_9" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.flexion" :value="'G0'"
                     @click="active.rt.tool_9 = !active.rt.tool_9">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.flexion" :value="'G1'"
                     @click="active.rt.tool_9 = !active.rt.tool_9">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.flexion" :value="'G1+'"
                     @click="active.rt.tool_9 = !active.rt.tool_9">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.flexion" :value="'G2'"
                     @click="active.rt.tool_9 = !active.rt.tool_9">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.flexion" :value="'G3'"
                     @click="active.rt.tool_9 = !active.rt.tool_9">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.flexion" :value="'G4'"
                     @click="active.rt.tool_9 = !active.rt.tool_9">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_10 = !active.lt.tool_10">{{ mas.lt.hip.extension }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_10 = !active.rt.tool_10">{{ mas.rt.hip.extension }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_10" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.extension" :value="'G0'"
                     @click="active.lt.tool_10 = !active.lt.tool_10">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.extension" :value="'G1'"
                     @click="active.lt.tool_10 = !active.lt.tool_10">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.extension" :value="'G1+'"
                     @click="active.lt.tool_10 = !active.lt.tool_10">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.extension" :value="'G2'"
                     @click="active.lt.tool_10 = !active.lt.tool_10">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.extension" :value="'G3'"
                     @click="active.lt.tool_10 = !active.lt.tool_10">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.extension" :value="'G4'"
                     @click="active.lt.tool_10 = !active.lt.tool_10">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_10" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.extension" :value="'G0'"
                     @click="active.rt.tool_10 = !active.rt.tool_10">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.extension" :value="'G1'"
                     @click="active.rt.tool_10 = !active.rt.tool_10">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.extension" :value="'G1+'"
                     @click="active.rt.tool_10 = !active.rt.tool_10">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.extension" :value="'G2'"
                     @click="active.rt.tool_10 = !active.rt.tool_10">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.extension" :value="'G3'"
                     @click="active.rt.tool_10 = !active.rt.tool_10">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.extension" :value="'G4'"
                     @click="active.rt.tool_10 = !active.rt.tool_10">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Abduction</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_11 = !active.lt.tool_11">{{ mas.lt.hip.abduction }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_11 = !active.rt.tool_11">{{ mas.rt.hip.abduction }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_11" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.abduction" :value="'G0'"
                     @click="active.lt.tool_11 = !active.lt.tool_11">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.abduction" :value="'G1'"
                     @click="active.lt.tool_11 = !active.lt.tool_11">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.abduction" :value="'G1+'"
                     @click="active.lt.tool_11 = !active.lt.tool_11">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.abduction" :value="'G2'"
                     @click="active.lt.tool_11 = !active.lt.tool_11">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.abduction" :value="'G3'"
                     @click="active.lt.tool_11 = !active.lt.tool_11">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.abduction" :value="'G4'"
                     @click="active.lt.tool_11 = !active.lt.tool_11">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_11" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.abduction" :value="'G0'"
                     @click="active.rt.tool_11 = !active.rt.tool_11">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.abduction" :value="'G1'"
                     @click="active.rt.tool_11 = !active.rt.tool_11">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.abduction" :value="'G1+'"
                     @click="active.rt.tool_11 = !active.rt.tool_11">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.abduction" :value="'G2'"
                     @click="active.rt.tool_11 = !active.rt.tool_11">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.abduction" :value="'G3'"
                     @click="active.rt.tool_11 = !active.rt.tool_11">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.abduction" :value="'G4'"
                     @click="active.rt.tool_11 = !active.rt.tool_11">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Adduction</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_12 = !active.lt.tool_12">{{ mas.lt.hip.adduction }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_12 = !active.rt.tool_12">{{ mas.rt.hip.adduction }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_12" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.adduction" :value="'G0'"
                     @click="active.lt.tool_12 = !active.lt.tool_12">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.adduction" :value="'G1'"
                     @click="active.lt.tool_12 = !active.lt.tool_12">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.adduction" :value="'G1+'"
                     @click="active.lt.tool_12 = !active.lt.tool_12">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.adduction" :value="'G2'"
                     @click="active.lt.tool_12 = !active.lt.tool_12">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.adduction" :value="'G3'"
                     @click="active.lt.tool_12 = !active.lt.tool_12">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.hip.adduction" :value="'G4'"
                     @click="active.lt.tool_12 = !active.lt.tool_12">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_12" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.adduction" :value="'G0'"
                     @click="active.rt.tool_12 = !active.rt.tool_12">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.adduction" :value="'G1'"
                     @click="active.rt.tool_12 = !active.rt.tool_12">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.adduction" :value="'G1+'"
                     @click="active.rt.tool_12 = !active.rt.tool_12">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.adduction" :value="'G2'"
                     @click="active.rt.tool_12 = !active.rt.tool_12">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.adduction" :value="'G3'"
                     @click="active.rt.tool_12 = !active.rt.tool_12">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.hip.adduction" :value="'G4'"
                     @click="active.rt.tool_12 = !active.rt.tool_12">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Knee</div>
          <div class="second">Flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_13 = !active.lt.tool_13">{{ mas.lt.knee.flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_13 = !active.rt.tool_13">{{ mas.rt.knee.flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_13" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.knee.flexion" :value="'G0'"
                     @click="active.lt.tool_13 = !active.lt.tool_13">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.knee.flexion" :value="'G1'"
                     @click="active.lt.tool_13 = !active.lt.tool_13">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.knee.flexion" :value="'G1+'"
                     @click="active.lt.tool_13 = !active.lt.tool_13">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.knee.flexion" :value="'G2'"
                     @click="active.lt.tool_13 = !active.lt.tool_13">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.knee.flexion" :value="'G3'"
                     @click="active.lt.tool_13 = !active.lt.tool_13">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.knee.flexion" :value="'G4'"
                     @click="active.lt.tool_13 = !active.lt.tool_13">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_13" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.knee.flexion" :value="'G0'"
                     @click="active.rt.tool_13 = !active.rt.tool_13">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.knee.flexion" :value="'G1'"
                     @click="active.rt.tool_13 = !active.rt.tool_13">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.knee.flexion" :value="'G1+'"
                     @click="active.rt.tool_13 = !active.rt.tool_13">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.knee.flexion" :value="'G2'"
                     @click="active.rt.tool_13 = !active.rt.tool_13">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.knee.flexion" :value="'G3'"
                     @click="active.rt.tool_13 = !active.rt.tool_13">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.knee.flexion" :value="'G4'"
                     @click="active.rt.tool_13 = !active.rt.tool_13">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Ankle</div>
          <div class="second">Dorsi flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_14 = !active.lt.tool_14">{{ mas.lt.ankle.dorsi_flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_14 = !active.rt.tool_14">{{ mas.rt.ankle.dorsi_flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_14" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.dorsi_flexion" :value="'G0'"
                     @click="active.lt.tool_14 = !active.lt.tool_14">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.dorsi_flexion" :value="'G1'"
                     @click="active.lt.tool_14 = !active.lt.tool_14">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.dorsi_flexion" :value="'G1+'"
                     @click="active.lt.tool_14 = !active.lt.tool_14">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.dorsi_flexion" :value="'G2'"
                     @click="active.lt.tool_14 = !active.lt.tool_14">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.dorsi_flexion" :value="'G3'"
                     @click="active.lt.tool_14 = !active.lt.tool_14">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.dorsi_flexion" :value="'G4'"
                     @click="active.lt.tool_14 = !active.lt.tool_14">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_14" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.dorsi_flexion" :value="'G0'"
                     @click="active.rt.tool_14 = !active.rt.tool_14">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.dorsi_flexion" :value="'G1'"
                     @click="active.rt.tool_14 = !active.rt.tool_14">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.dorsi_flexion" :value="'G1+'"
                     @click="active.rt.tool_14 = !active.rt.tool_14">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.dorsi_flexion" :value="'G2'"
                     @click="active.rt.tool_14 = !active.rt.tool_14">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.dorsi_flexion" :value="'G3'"
                     @click="active.rt.tool_14 = !active.rt.tool_14">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.dorsi_flexion" :value="'G4'"
                     @click="active.rt.tool_14 = !active.rt.tool_14">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Plantar flexion</div>
          <div class="last">
            <div>
              <button class="input-box" @click="active.lt.tool_15 = !active.lt.tool_15">{{ mas.lt.ankle.plantar_flexion }}
              </button>
            </div>
            <div>
              <button class="input-box" @click="active.rt.tool_15 = !active.rt.tool_15">{{ mas.rt.ankle.plantar_flexion }}
              </button>
            </div>
          </div>
        </div>
        <slide-up-down class="times" :active="active.lt.tool_15" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.plantar_flexion" :value="'G0'"
                     @click="active.lt.tool_15 = !active.lt.tool_15">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.plantar_flexion" :value="'G1'"
                     @click="active.lt.tool_15 = !active.lt.tool_15">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.plantar_flexion" :value="'G1+'"
                     @click="active.lt.tool_15 = !active.lt.tool_15">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.plantar_flexion" :value="'G2'"
                     @click="active.lt.tool_15 = !active.lt.tool_15">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.plantar_flexion" :value="'G3'"
                     @click="active.lt.tool_15 = !active.lt.tool_15">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.lt.ankle.plantar_flexion" :value="'G4'"
                     @click="active.lt.tool_15 = !active.lt.tool_15">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <slide-up-down class="times" :active="active.rt.tool_15" :duration="200">
          <div class="dropdown-lime">
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.plantar_flexion" :value="'G0'"
                     @click="active.rt.tool_15 = !active.rt.tool_15">
              <span class="checkmark">G0</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.plantar_flexion" :value="'G1'"
                     @click="active.rt.tool_15 = !active.rt.tool_15">
              <span class="checkmark">G1</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.plantar_flexion" :value="'G1+'"
                     @click="active.rt.tool_15 = !active.rt.tool_15">
              <span class="checkmark">G1+</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.plantar_flexion" :value="'G2'"
                     @click="active.rt.tool_15 = !active.rt.tool_15">
              <span class="checkmark">G2</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.plantar_flexion" :value="'G3'"
                     @click="active.rt.tool_15 = !active.rt.tool_15">
              <span class="checkmark">G3</span>
            </label>
            <label class="container">
              <input type="radio" name="test" v-model="mas.rt.ankle.plantar_flexion" :value="'G4'"
                     @click="active.rt.tool_15 = !active.rt.tool_15">
              <span class="checkmark">G4</span>
            </label>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second"></div>
          <div class="last"></div>
        </div>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";
import {mdbScrollbar} from "mdbvue";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "ModifiedAshowrthScale",
  components: {
    mdbScrollbar, SlideUpDown,
  },
  props: {
    fbDocId: String,
    mas: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: 0,
      tools: {
        mas: {}
      },
      active: {
        lt: {
          tool_1: false, tool_2: false, tool_3: false, tool_4: false, tool_5: false,
          tool_6: false, tool_7: false, tool_8: false, tool_9: false, tool_10: false,
          tool_11: false, tool_12: false, tool_13: false, tool_14: false, tool_15: false
        },
        rt: {
          tool_1: false, tool_2: false, tool_3: false, tool_4: false, tool_5: false,
          tool_6: false, tool_7: false, tool_8: false, tool_9: false, tool_10: false,
          tool_11: false, tool_12: false, tool_13: false, tool_14: false, tool_15: false
        }
      },
      isShowPopUp: false,
      isShowModal: false,
    };
  },
  methods: {
    masIsNT() {
      const self = this;
      self.masLtNT()
      self.masRtNT()
    },
    masLtNT() {
      const self = this;
      self.mas.lt.shoulder.flexion = 'NT'
      self.mas.lt.shoulder.extension = 'NT'
      self.mas.lt.shoulder.abduction = 'NT'
      self.mas.lt.shoulder.adduction = 'NT'
      self.mas.lt.elbow.flexion = 'NT'
      self.mas.lt.elbow.extension = 'NT'
      self.mas.lt.wrist.flexion = 'NT'
      self.mas.lt.wrist.extension = 'NT'
      self.mas.lt.hip.flexion = 'NT'
      self.mas.lt.hip.extension = 'NT'
      self.mas.lt.hip.abduction = 'NT'
      self.mas.lt.hip.adduction = 'NT'
      self.mas.lt.knee.flexion = 'NT'
      self.mas.lt.ankle.dorsi_flexion = 'NT'
      self.mas.lt.ankle.plantar_flexion = 'NT'
    },
    masRtNT() {
      const self = this;
      self.mas.rt.shoulder.flexion = 'NT'
      self.mas.rt.shoulder.extension = 'NT'
      self.mas.rt.shoulder.abduction = 'NT'
      self.mas.rt.shoulder.adduction = 'NT'
      self.mas.rt.elbow.flexion = 'NT'
      self.mas.rt.elbow.extension = 'NT'
      self.mas.rt.wrist.flexion = 'NT'
      self.mas.rt.wrist.extension = 'NT'
      self.mas.rt.hip.flexion = 'NT'
      self.mas.rt.hip.extension = 'NT'
      self.mas.rt.hip.abduction = 'NT'
      self.mas.rt.hip.adduction = 'NT'
      self.mas.rt.knee.flexion = 'NT'
      self.mas.rt.ankle.dorsi_flexion = 'NT'
      self.mas.rt.ankle.plantar_flexion = 'NT'
    },
    async tempSave() {
      const self = this;
      self.tools.mas = self.mas;
      self.tools.mas['state'] = 1;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'mas', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (JSON.stringify(self.mas).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      self.btnConfirm()
    },
    btnConfirm() {
      const self = this;
      self.tools.mas = self.mas;
      self.tools.mas['state'] = 2;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'mas', 2)
          });
    },
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.blue-btn {
  color: #0069CA;
  cursor: pointer;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide-frame button {
  margin-left: auto;
  width: 62px;
  height: 40px;
  background: #F1F6FD;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #0069CA;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

.paper-table > div {
  display: flex;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
  height: 48px;
  font-size: 16px;
  text-align: center;
}

.paper-topic {
  font-weight: 700;
  background: #F1F6FD;
  font-size: 14px;
  text-align: center !important;
  justify-content: center;
}

.header-line {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
  font-size: 14px;
}

.times {
  margin: 0 10px;
  display: flex;
  justify-content: center;
}

.first {
  display: flex;
  align-items: center;
  text-align: left;
  width: 374px !important;
  font-weight: 700;
  line-height: 28px;
  color: #272833;
}

.second {
  display: flex;
  align-items: center;
  text-align: left;
  width: 296px !important;
  font-weight: 400;
}

.dropdown-lime {
  margin-left: auto;
  width: 380px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  font-weight: 500;
  height: 100%;
}

.last {
  width: 380px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  font-weight: 500;
  height: 100%;
}

.last div {
  width: 195px;
  padding: 10px;
}

.last .input-box {
  width: 100%;
  height: 28px;
  background: #F1F6FD;
  border-radius: 4px;
  border: 0;
  text-align: center;
  font-size: 14px !important;
  font-weight: 500;
  color: #0069CA;
}

.line {
  width: 2px !important;
  height: 28px !important;
  padding: 0 !important;
  background: #D4D4D8;
}

.container {
  cursor: pointer;
  width: 380px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px;
  margin: 0;
  display: flex;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #A0B0C6;
  width: 43px;
  height: 28px;
  background: #F8F8FA;
  border-radius: 4px;
  margin-right: 20px;
}

.checkmark:last-child {
  margin-right: 0;
}

/*bottom btn*/

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}

.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}


/*pop-up*/
.pop-up {
  padding: 23px 20px;
  position: absolute;
  width: 732px;
  height: 319px;
  left: 167px;
  bottom: 68%;
  transform: translateY(50%);
  background: #FFFFFF;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 50;
  display: flex;
}

.pop-up .text {
  width: 658px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.pop-up .text h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  min-width: 134px;
}

.pop-up .text .gray {
  color: #8D8D8D;
}

.pop-up .text > div {
  margin-top: 5px;
}

.pop-up .text div > div {
  display: flex;
}

.pop-up .text div div p {
  min-width: 134px;
  margin-top: 10px;
}

.pop-up img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.scroll-frame button:focus {
  border: 2px solid #0069CA;
}

</style>
