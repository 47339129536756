export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'dgi_1',
        field: 'dgi_1',
    },
    {
        label: 'dgi_2',
        field: 'dgi_2',
    },
    {
        label: 'dgi_3',
        field: 'dgi_3',
    },
    {
        label: 'dgi_4',
        field: 'dgi_4',
    },
    {
        label: 'dgi_5',
        field: 'dgi_5',
    },
    {
        label: 'dgi_6',
        field: 'dgi_6',
    },
    {
        label: 'dgi_7',
        field: 'dgi_7',
    },
    {
        label: 'dgi_8',
        field: 'dgi_8',
    },

];
