<template>
  <div class="paper-main-frame" v-if="dgi">
    <h1 class="paper-title">Dynamic Gait Index (DGI)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="score">
      <div class="score-text">
        <p class="first-box">점수</p>
        <p class="second-box">총점(24점)</p>
      </div>
      <div class="question">
        <div class="first-box">
          <div class="checkmark block" v-if="dgi.dgi_1 === null">1</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_1 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_2 === null">2</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_2 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_3 === null">3</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_3 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_4 === null">4</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_4 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_5 === null">5</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_5 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_6 === null">6</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_6 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_7 === null">7</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_7 }}</div>
          <div class="checkmark block" v-if="dgi.dgi_8 === null">8</div>
          <div class="checkmark block-white" v-else>{{ dgi.dgi_8 }}</div>
        </div>
        <div class="checkmark block" style="margin-left: 10px" v-if="
        dgi.dgi_1 === null && dgi.dgi_2 === null && dgi.dgi_3 === null && dgi.dgi_4 === null &&
        dgi.dgi_5 === null && dgi.dgi_6 === null && dgi.dgi_7 === null && dgi.dgi_8 === null">총점
        </div>
        <div class="checkmark block-white" style="margin-left: 10px" v-else>
          {{ dgi.dgi_1 + dgi.dgi_2 + dgi.dgi_3 + dgi.dgi_4 + dgi.dgi_5 + dgi.dgi_6 + dgi.dgi_7 + dgi.dgi_8 }}
        </div>
      </div>
    </div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <div>
          <table class="paper-table">
            <tbody>
            <tr>
              <td colspan="12" class="paper-topic">1. 평평한 지면에서의 보행</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항 : 표시된 시작 지점에서 끝까지(6.1m) 보통의 속도로 걸으세요.</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행보조도구 없이, 적정 속도로 균형이 무너지지 않고, 정상적인 보행 패턴으로 6.1m를 걸어간다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="dgi.dgi_1" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보조 도구를 사용하여 조금 느린 속도로, 약간의 보행의 편향을 보이며 6.1m를 걸어간다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="dgi.dgi_1" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">느린 속도로, 비정상적인 보행패턴과 불균형이 보이며 6.1m를 걸어간다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="dgi.dgi_1" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">도움 없이 6.1m를 걸을 수 없고, 심한 비정상적 보행패턴 또는 불균형이 보인다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_1" v-model="dgi.dgi_1" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">2. 보행속도 변경하기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 처음 1.5m는 보행 속도를 보통의 속도로 시작하고 제가 “시작”이라고 말하면 두 번째 1.5m는 최대한 빨리
                걷고,
                <br/>다시 제가 “천천히”라고 말하면 세 번째 1.5m는 최대한 느리게 걸으세요.
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">균형을 잃지 않고, 정상적인 보행패턴에서 벗어나지 않으며 순조롭게 보행속도를 변경할 수 있다.
                <br/>피 실험자의 보통, 빠른, 느린 보행속도가 유의하게 다르다.
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="dgi.dgi_2" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question" style="white-space: nowrap">보행 속도를 변경할 수 있으나 정상적인 보행패턴에서 약간 벗어나거나,
                <br/>정상적인 보행패턴에 벗어나지 않으나 보통, 빠른, 느린 보행 속도 사이에 유의한 차이를 보이지 않거나, 보행 보조 도구를 사용한다.
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="dgi.dgi_2" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도를 아주 미세한 수준으로만 변경할 수 있거나, 보행 속도 변경 시 비정상적인 보행패턴에서 유의하게 벗어난다.
                <br/>또는 균형을 잃으며 보행 속도를 변경하지만 균형을 회복하고 계속 걸을 수 있다.
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="dgi.dgi_2" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도를 변경할 수 없거나, 균형을 잃어 벽을 잡으려 하거나, 주변에서 잡아 주어야 한다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_2" v-model="dgi.dgi_2" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">3. 보행하면서 머리를 옆으로 돌리기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 보통 보행속도로 걷기 시작하고 제가 “오른쪽을 보세요.”라고 말하면 계속 일직선으로 똑바로 걸으며 머리를 오른쪽으로 돌리세요.
                <br/>계속 오른쪽을 보며 걷고 있다가 제가 “왼쪽을 보세요.”라고 말하면 계속 일직선으로 똑바로 걸으며 머리를 왼쪽으로 돌리세요.
                <br/>계속 왼쪽을 보고 걷고 있다가 제가 “똑바로 앞을 보세요.”라고 말하면 일직선으로 똑바로 걸으며 머리를 정면으로 돌리세요.
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행에 변화없이 머리를 순조롭게 돌린다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="dgi.dgi_3" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도에 약간의 변화가 있지만 머리를 순조롭게 돌린다.
                <br/>(순조로운 보행에 있어 약간의 지장이 있거나 보행 보조 도구를 사용한다.)
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="dgi.dgi_3" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도가 변하면서 머리를 돌리고, 보행 속도가 느려지며, 비틀거림이 있거나 회복하여 걷기를 할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="dgi.dgi_3" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행이 심하게 교란되면서 과제를 수행한다.
                <br/>(보행로의 폭 경계에서 15°를 벗어날 정도로 비틀거리며 균형을 잃고, 걷기를 중단하고 벽을 잡으려 한다.)
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_3" v-model="dgi.dgi_3" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">4. 보행하면서 상하로 머리를 움직이기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 보통 보행 속도로 걷기 시작하세요. 제가 “위를 보세요.”라고 말하면 계속 일직선으로 똑바로 걸으면서 머리를 위로 제쳐 위를 보세요.
                <br/>계속 위를 보고 걷고 있다가 제가 “아래를 보세요.”라고 말하면 계속 일직선으로 똑바로 걸으며 머리를 숙여 아래를 보세요.
                <br/>계속 고개를 아래로 숙여 걷다가 제가 “똑바로 앞을 보세요.”라고 말하면 머리를 들어 정면으로 오게 하세요.
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행에 변화없이 머리를 순조롭게 돌린다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="dgi.dgi_4" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도에 약간의 변화가 있지만 머리를 순조롭게 돌린다.
                <br/>(순조로운 보행에 있어 약간의 지장이 있거나 보행 보조 도구를 사용한다.)
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="dgi.dgi_4" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도가 변하면서 머리를 돌리고, 보행 속도가 느려지며, 비틀거림이 있거나 회복하여 걷기를 할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="dgi.dgi_4" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행이 심하게 교란되면서 과제를 수행한다.
                <br/>(보행로의 폭 경계에서 15°를 벗어날 정도로 비틀거리며 균형을 잃고, 걷기를 중단하고 벽을 잡으려 한다.)
              </td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_4" v-model="dgi.dgi_4" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">5. 보행하다가 한 발을 축으로 해서 돌기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 보통의 속도로 걷기 시작하세요. 제가 “멈추고 도세요” 라고 말하면 최대한 빨리 뒤로 돌아 반대편 쪽을 향하여 멈춰 서세요.</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">3초 이내에 축을 중심으로 안전하게 회전하며 균형을 잃지 않고 재빨리 멈춰 설 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="dgi.dgi_5" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">3초 이상 걸리나 안전하게 축을 중심으로 돌아서 균형을 잃지 않고 멈춰 선다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="dgi.dgi_5" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">천천히 돌고, 언어적 신호가 필요하거나, 회전하고 멈춰 설 때 균형을 잡기 위해 중간 중간 멈춰 선다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="dgi.dgi_5" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안전하게 회전할 수 없고, 돌고 멈추는데 도움이 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_5" v-model="dgi.dgi_5" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">6. 장애물 위를 지나 걷기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 보통의 속도로 걷기 시작하세요. 신발 상자에 도달하면 상자 주위를 돌아 가지 말고 상자 위를 건넌 후 계속 걸어가세요.</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도의 변화 없이 신발 상자를 넘어 갈 수 있다. 균형을 잃지 않는다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="dgi.dgi_6" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">상자를 넘을 수 있으나 속도가 느려지고 상자를 안전하게 넘어가기 위해 발걸음을 조정해야 한다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="dgi.dgi_6" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">상자를 넘을 수 있으나 상자 앞에서 반드시 멈춰 섰다가 상자를 넘는다. 언어적인 신호가 필요 할 수 있다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="dgi.dgi_6" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보조 없이 과제를 수행할 수 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_6" v-model="dgi.dgi_6" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">7. 장애물 주위를 걷기</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 보통의 속도로 걷기 시작하세요. 첫 번째 원뿔에 도달하면 원뿔의 오른쪽으로 원뿔 주위를 돌아 걸으세요.
                <br/>두 번째 원뿔에 도달하면 원뿔의 왼쪽으로 원뿔 주위를 돌아 걸으세요. (8자형 보행으로 두 원뿔의 간격은 1.83m이다.)
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">보행 속도의 변화 없이 안전하게 원뿔 주위를 돌아 걸을 수 있으며 균형을 잃지 않는다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="dgi.dgi_7" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">두 개의 원뿔을 모두 돌아 걸을 수 있지만 원뿔을 지나가기 위해 꼭 속도를 낮추어야 한다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="dgi.dgi_7" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">원뿔 주위를 돌아 걸을 수 있지만 과제를 완수하기 위해 유의하게 속도를 낮추어야 하거나 언어적인 신호가 필요 하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="dgi.dgi_7" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">원뿔 주위를 돌아갈 수 없고, 원뿔 하나 또는 두 개 모두가 발에 걸리거나 물리적 보조가 필요하다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_7" v-model="dgi.dgi_7" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">8. 계단</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">지시사항: 평상시처럼 실제로 계단을 걸어 올라가듯이 계단을 올라가세요. (필요 시 난간을 잡고 계단을 올라가세요.)
                <br/>계단의 맨 꼭대기에 도달하면 뒤로 돌아 걸어 내려오세요.
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">난간을 잡지 않고 발을 교대로 사용하여 계단을 오르내린다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="dgi.dgi_8" :value="3">
                  <span class="checkmark step-blue-2">3</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">발을 교대로 사용하지만 반드시 난간을 잡아야 한다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="dgi.dgi_8" :value="2">
                  <span class="checkmark step-blue-2">2</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">한 계단에 두 발이 모두 놓여 지며 반드시 난간을 잡아야 한다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="dgi.dgi_8" :value="1">
                  <span class="checkmark step-blue-2">1</span>
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="10" class="paper-question">안전하게 과제를 수행할 수 없다.</td>
              <td colspan="2">
                <label class="container">
                  <input type="radio" name="a_8" v-model="dgi.dgi_8" :value="0">
                  <span class="checkmark step-blue-2">0</span>
                </label>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <DynamicGaitIndexResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :dgi="dgi"/>
  </div>
</template>

<script>

import {mdbScrollbar} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import DynamicGaitIndexResultDialog from "@/views/therapist/menu/doEvaluation/paper/dgi/DynamicGaitIndexResultDialog";

export default {
  name: "DynamicGaitIndex",
  components: {
    mdbScrollbar, DynamicGaitIndexResultDialog
  },
  props: {
    fbDocId: String,
    dgi: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: 0,
      tools: {
        dgi: {}
      },
      isShowModal: false,
    };
  },
  methods: {
    async tempSave() {
      const self = this;
      await self.calculator();
      self.tools.dgi = self.dgi;
      self.tools.dgi['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'dgi', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (JSON.stringify(self.dgi).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;
      self.total =
          self.dgi.dgi_1 + self.dgi.dgi_2 + self.dgi.dgi_3 + self.dgi.dgi_4 + self.dgi.dgi_5 + self.dgi.dgi_6 + self.dgi.dgi_7 + self.dgi.dgi_8;
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      await self.calculator();
      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'dgi', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
}
tr {
  border-bottom: 1px solid #E1E3E6;
}
td {
  max-width: 80px;
  min-width: 80px;
  height: 56px;
  font-size: 16px;
  border-bottom: 1px solid #E1E3E6;
}

.td-answer {
  text-align: center;
  font-weight: 500;
}

.td-bk {
  background: #F8F8FA;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  height: 570px;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.score {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.score .score-text {
  padding: 0 10px;
  width: 1070px;
  height: 48px;
  background: #0069CA;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #FFFFFF;
}

.score .question {
  display: flex;
  padding: 10px;
}

.score-text .first-box {
  border: 0;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.score-text .second-box {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  width: 110px;
}

.first-box {
  display: flex;
  justify-content: space-between;
  width: 920px;
  padding: 0 10px;
  border-right: 2px solid #D4D4D8;
}

.block {
  width: 103px !important;
  height: 48px !important;
  background: #F1F6FD !important;
}


.radio-round-group {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container {
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
  padding: 0 0 0 67px !important;
  cursor: pointer;
}

.container:first-child {
  margin-left: 10px;
}


/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 28px;
  background-color: #fff;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #A0B0C6;
}

.checkmark-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #F8F8FA;
  border-radius: 100px;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
}

.xs-mark {
  width: 30px !important;
  height: 30px !important;
  font-size: 16px !important;
  background: #fff !important;
}

.step-blue-2 {
  background: #E9F2FD;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  color: #0069CA;
  background: #FFFFFF;
  border: 2px solid #0069CA;
}

.block-white {
  width: 103px !important;
  height: 48px !important;
  color: #0069CA;
  background: #FFFFFF;
  border: 1px solid #0069CA;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
  font-size: 14px;
}


.paper-item {
  padding: 6px 20px;
  font-weight: 500;
  background: #F8F8FA;
}

.paper-measure {
  padding-left: 20px;
  font-weight: 500;
  background: #fff;
}

.paper-question {
  font-weight: 400;
  line-height: 22px;
  padding: 8px 0 8px 30px;
}

td {
  height: 48px;
}
</style>
