<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">6Minute Walking Test (6MWT)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-guide-frame">
      <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
      <p class="paper-guide">6분 동안 이동한 거리를 측정하고 중간에 측정을 중단할 경우 거리를 기록하고 그에 대한 사유를 기록합니다.
        <br>6분간 할 수 있을 만큼 최대한 걷는 거리를 측정합니다.</p>
    </div>
    <div class="assist-box">
      <div class="pop-up" v-if="isShowAssistPopUp">
        <div class="text">
          <h5>Assist 1~7 능력</h5>
          <p>1. Total Assist(환자의 과제 수행능력 0~24%)<br/>
            2. Maximum assistance(환자의 과제 수행능력 25~49%)<br/>
            3. Moderate assistance(환자의 과제 수행능력 50~74%)<br/>
            4. Minimum assistance(환자의 과제 수행능력 75~99%)<br/>
            5. Supervision(신체적 접촉없이 서있거나 서는 것의 도움필요)<br/>
            6. Modified independent(보조기 사용, 시간 필요, 약간의 안전)<br/>
            7. Independent</p>
        </div>
        <img src="@/assets/images/IA/btn_close.png" alt="" @click="isShowAssistPopUp= false">
      </div>
      <div class="sud-title">Assist
        <img style="width: 20px; margin-left: 10px; cursor: pointer;"
             src="@/assets/images/IA/icon_solid_info.png" alt="null" @click="isShowAssistPopUp = true">
      </div>
      <div class="radio-group">
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="1">
          <span class="checkmark">1</span>
        </label>
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="2">
          <span class="checkmark">2</span>
        </label>
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="3">
          <span class="checkmark">3</span>
        </label>
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="4">
          <span class="checkmark">4</span>
        </label>
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="5">
          <span class="checkmark">5</span>
        </label>
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="6">
          <span class="checkmark">6</span>
        </label>
        <label class="container">
          <input type="radio" name="smwt_assist" v-model="smwt.assist" :value="7">
          <span class="checkmark">7</span>
        </label>
      </div>
    </div>
    <div class="scroll-frame" height="390px" width="100%">
      <div class="paper-frame">
        <table class="paper-table">
          <tr>
            <td class="paper-topic">Time mins</td>
            <td class="paper-topic">PRE
              <img style="width: 20px; margin-right: 5px;" @click="isShowPREPopUp = true"
                   src="@/assets/images/IA/icon_solid_info.png" alt="null">
              <div class="pop-up pre-pop-up" v-if="isShowPREPopUp">
                <div class="text">
                  <h5>Borg Rating Scale</h5>
                  <p>(6~20사이는 운동 시 심박수의 1/10과 일치합니다.)</p>
                  <p>6</p>
                  <div>
                    <p class="num">7</p>
                    <div class="line"/>
                    <p>Very, very light</p>
                  </div>
                  <p>8</p>
                  <div>
                    <p class="num">9</p>
                    <div class="line"/>
                    <p>Very light</p>
                  </div>
                  <p>10</p>
                  <div>
                    <p class="num">11</p>
                    <div class="line"/>
                    <p>Fairly light</p>
                  </div>
                  <p>12</p>
                  <div>
                    <p class="num">13</p>
                    <div class="line"/>
                    <p>Somewhat hard</p>
                  </div>
                  <p>14</p>
                  <div>
                    <p class="num">15</p>
                    <div class="line"/>
                    <p>Hard</p>
                  </div>
                  <p>16</p>
                  <div>
                    <p class="num">17</p>
                    <div class="line"/>
                    <p>Very hard</p>
                  </div>
                  <p>18</p>
                  <div>
                    <p class="num">19</p>
                    <div class="line"/>
                    <p>Very, very hard</p>
                  </div>
                  <p>20</p>
                </div>
                <img src="@/assets/images/IA/btn_close.png" alt="" @click="isShowPREPopUp= false">
              </div>
            </td>
            <td class="paper-topic">Distance walked(m)</td>
            <td class="paper-topic">Rest / Comments</td>
          </tr>
          <tr>
            <td class="times">Rest</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_rest" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_rest')" @blur="checkValue('pre_rest')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_rest" disabled></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_rest"></td>
          </tr>
          <tr>
            <td class="times">1 min</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_1')" @blur="checkValue('pre_1')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_1" maxlength="3"></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_1"></td>
          </tr>
          <tr>
            <td class="times">2 min</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_2" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_2')" @blur="checkValue('pre_2')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_2" maxlength="3"></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_2"></td>
          </tr>
          <tr>
            <td class="times">3 min</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_3" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_3')" @blur="checkValue('pre_3')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_3" maxlength="3"></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_3"></td>
          </tr>
          <tr>
            <td class="times">4 min</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_4" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_4')" @blur="checkValue('pre_4')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_4" maxlength="3"></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_4"></td>
          </tr>
          <tr>
            <td class="times">5 min</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_5" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_5')" @blur="checkValue('pre_5')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_5" maxlength="3"></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_5"></td>
          </tr>
          <tr>
            <td class="times">6 min</td>
            <td class="">
              <input type="number" v-model.number="smwt.pre_6" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                     @input="validateInput('pre_6')" @blur="checkValue('pre_6')">
            </td>
            <td class="">
              <input type="number" v-model.number="smwt.distance_6" maxlength="3"></td>
            <td class="comments">
              <input type="text" v-model="smwt.comments_6"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "SixMinuteWalkingTest",
  components: {},
  props: {
    fbDocId: String,
    smwt: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: 0,
      tools: {
        smwt: {}
      },
      previousValues: null,
      isShowAssistPopUp: false,
      isShowPREPopUp: false,
      isShowModal: false,
    };
  },
  mounted() {
    this.smwt.distance_rest = 0
  },
  methods: {
    async tempSave() {
      const self = this;
      // await self.calculator();

      self.tools.smwt = self.smwt;
      self.tools.smwt['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'smwt', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (self.smwt.assist === 0) {
        alert('Assist를 선택해주세요')
        return true;
      }
      if (self.smwt.pre_rest === null || self.smwt.pre_rest === '') {
        alert('Rest의 PRE를 입력해주세요')
        return true;
      }
      if (self.smwt.pre_1 === null || self.smwt.pre_1 === '' ||
          self.smwt.distance_1 === null || self.smwt.distance_1 === '') {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      for (let i = 1; i < 7; i++) {
        const PRE = self.smwt['pre_' + i]
        const Distance = self.smwt['distance_' + i]
        if ((PRE !== null && Distance === '') || (PRE !== null && Distance === null)) {
          alert('아직 완료되지 않은 항목이 있습니다.')
          return true
        }
        if ((Distance !== null && PRE === '') || (Distance !== null && PRE === null)) {
          alert('아직 완료되지 않은 항목이 있습니다.')
          return true
        }
      }
      return false;
    },
    // calculator() {
    //   const self = this;
    //   self.total =
    //       self.smwt.a_1 + self.smwt.a_2 + self.smwt.a_3 + self.smwt.a_4 + self.smwt.a_5 + self.smwt.a_6 + self.smwt.a_7 + self.smwt.a_8;
    // },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      // await self.calculator();
      self.btnConfirm()
    },
    btnConfirm() {
      const self = this;
      self.tools.smwt = self.smwt;
      self.tools.smwt['state'] = 2;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'smwt', 2)
          });
    },
    validateInput(field) {
      const self = this;
      const value = self.smwt[field].toString();

      if (self.smwt[field] === '') return
      if (value.length > 2) return self.smwt[field] = self.previousValues || null;

      switch (self.smwt[field]) {
        case 1: case 2:
          setTimeout(() => {
            if (self.smwt[field] === '') return
            if (self.smwt[field] < 6) {
              alert('6 이하의 값을 입력할 수 없습니다.')
              self.smwt[field] = 6
            }
          }, 1500);
          return;
        case 0: case 3: case 4: case 5:
          alert('6 이하의 값을 입력할 수 없습니다.')
          self.smwt[field] = 6;
          return;
      }
      if (self.smwt[field] > 20) {
        self.smwt[field] = 20
        alert('20 이상의 값을 입력할 수 없습니다.')
      }
      self.previousValues = self.smwt[field];
    },
    checkValue(field) {
      const self = this;
      self.previousValues = null
      if (self.smwt[field] === null || self.smwt[field] === '') return
      if (self.smwt[field] < 6) {
        alert('6 이하의 값을 입력할 수 없습니다.')
        self.smwt[field] = 6
      } else if (self.smwt[field] > 20) {
        alert('20 이상의 값을 입력할 수 없습니다.')
        self.smwt[field] = 20
      }
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

.paper-table {
  width: 100%;
  font-size: 14px;
}

td {
  width: 238px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

td:first-child {
  padding-left: 10px;
  width: 248px;
  text-align: left;
}

td:last-child {
  width: 346px !important;
  padding-right: 10px;
}

.times {
  font-weight: 500;
  padding-left: 20px !important;
}

.comments {
  padding-right: 20px !important;
}

input {
  width: 100%;
  height: 28px;
  background: #F1F6FD;
  border-radius: 4px;
  border: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #0069CA;
}

input:focus {
  outline: 2px solid #0069CA !important;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.container {
  cursor: pointer;
  width: 46px;
  font-size: 18px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 !important;
  margin: 0;
}

.container:last-child {
  margin-right: 0;
}

/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: #F1F6FD;
  border-radius: 100px;
  border: 0;
  color: #A0B0C6;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  color: #0069CA;
  background: #FFFFFF;
  border: 2px solid #0069CA;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}

.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  font-weight: 700;
  background: #F1F6FD;
  font-size: 14px;
  text-align: center !important;
}

.assist-box {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.assistive-check .sud-title,
.assist-box .sud-title {
  margin: 0 !important;
}

.sud-title {
  color: #0069CA;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}


.pop-up {
  padding: 23px 20px;
  position: absolute;
  width: 732px;
  height: 232px;
  left: 167px;
  bottom: 70%;
  transform: translateY(50%);
  background: #FFFFFF;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 50;
  display: flex;
}

.pop-up .text {
  width: 658px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.pop-up .text h5 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
}

.pop-up img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.pre-pop-up {
  text-align: left;
  width: 413px;
  height: 500px;
  left: 30%;
  transform: translateY(80%);
}

.pre-pop-up .text {
  white-space: nowrap;
  height: 454px !important;
}

.pre-pop-up .text h5 {
  margin-bottom: 5px;
}

.pre-pop-up .text > p,
.pre-pop-up .text > div {
  margin: 5px 0;
}

.pre-pop-up .text div {
  color: #0069CA;
  display: flex;
  align-items: center;
}

.pre-pop-up .text div .num {
  width: 18px;
}

.pre-pop-up .text div .line {
  height: 1px;
  width: 90px;
  background: #0069CA;
  margin: 0 10px;
}

.radio-group {
  height: 46px;
  width: 459px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark,
.btn-check-box input:checked ~ .check-btn {
  color: #0069CA;
  background: #FFFFFF;
  border: 2px solid #0069CA;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
