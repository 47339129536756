import { render, staticRenderFns } from "./EvaluationDeleteDialog.vue?vue&type=template&id=bbd0a584&scoped=true&"
import script from "./EvaluationDeleteDialog.vue?vue&type=script&lang=js&"
export * from "./EvaluationDeleteDialog.vue?vue&type=script&lang=js&"
import style0 from "./EvaluationDeleteDialog.vue?vue&type=style&index=0&id=bbd0a584&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbd0a584",
  null
  
)

export default component.exports