<template>
  <div>
    <div class="nav-tools-bar">
      <div class="view-layout">
        <hr>
        <div>
          <div class="nav-tools-item" @click="active.tool_tab_1 = !active.tool_tab_1">
            <p v-html="selectedTool.tool_tab_1"></p>
            <img src="@/assets/images/IA/icon_down_xl.png" alt="down">
          </div>
          <div class="nav-tool-bar-sub">
            <slide-up-down :active="active.tool_tab_1" :duration="300">
              <div class="tool-tab" @click="firstTab('ue')">
                <p v-html="subToolName.tools_extremity.ue.name"></p>
              </div>
              <div class="tool-tab" @click="firstTab('le')">
                <p v-html="subToolName.tools_extremity.le.name"></p>
              </div>
            </slide-up-down>
          </div>
        </div>
        <hr>
        <div>
          <div class="nav-tools-item" @click="active.tool_tab_2 = !active.tool_tab_2">
            <p v-if="toolUe === 'ue'">{{ selectedTool.tool_tab_2 }}</p>
            <p v-else>{{ selectedTool.tool_tab_2.substring(0, selectedTool.tool_tab_2.length - 1) }}</p>
            <img src="@/assets/images/IA/icon_down_xl.png" alt="down">
          </div>
          <div class="nav-tool-bar-sub" v-if="selectedTool.tool_tab_1 === subToolName.tools_extremity.ue.name">
            <slide-up-down :active="active.tool_tab_2" :duration="300">
              <div class="tool-tab" @click="secondTab('bs')">
                <p>{{ subToolName.tools_extremity.ue.types.bs.name }}</p>
              </div>
              <div class="tool-tab" @click="secondTab('a')">
                <p>{{ subToolName.tools_extremity.ue.types.a.name }}</p>
              </div>
              <div class="tool-tab" @click="secondTab('p')">
                <p>{{ subToolName.tools_extremity.ue.types.p.name }}</p>
              </div>
            </slide-up-down>
          </div>
          <div class="nav-tool-bar-sub" v-else-if="selectedTool.tool_tab_1 === subToolName.tools_extremity.le.name">
            <slide-up-down :active="active.tool_tab_2" :duration="300">
              <div class="tool-tab" @click="secondTab('bs')">
                <p>{{ subToolName.tools_extremity.le.types.bs.name.substring(0, subToolName.tools_extremity.le.types.bs.name.length - 1) }}</p>
              </div>
              <div class="tool-tab" @click="secondTab('a')">
                <p>{{ subToolName.tools_extremity.le.types.a.name.substring(0, subToolName.tools_extremity.le.types.a.name.length - 1) }}</p>
              </div>
              <div class="tool-tab" @click="secondTab('p')">
                <p>{{ subToolName.tools_extremity.le.types.p.name.substring(0, subToolName.tools_extremity.le.types.p.name.length - 1) }}</p>
              </div>
            </slide-up-down>
          </div>
        </div>
        <hr>
        <div>
          <div class="nav-tools-item" @click="active.tool_tab_3 =!active.tool_tab_3">
            <p v-html="selectedTool.tool_tab_3.substring(0, 10)"></p>
            <img src="@/assets/images/IA/icon_down_xl.png" alt="down">
          </div>
          <div class="nav-tool-bar-sub" v-if="selectedTool.tool_tab_2 === subToolName.tools_extremity.ue.types.bs.name && toolUe === 'ue'">
            <slide-up-down :active="active.tool_tab_3" :duration="300">
              <div class="tool-tab" @click="thirdTab('fm')">
                <p>{{ subToolName.tools_extremity.ue.types.bs.options.fm }}</p>
              </div>
            </slide-up-down>
          </div>
          <div class="nav-tool-bar-sub" v-else-if="selectedTool.tool_tab_2 === subToolName.tools_extremity.ue.types.a.name && toolUe === 'ue'">
            <slide-up-down :active="active.tool_tab_3" :duration="300">
              <div class="tool-tab" @click="nowReady('bb')">
                <p>{{ subToolName.tools_extremity.ue.types.a.options.bb }}</p>
              </div>
              <div class="tool-tab" @click="nowReady('wmft')">
                <p>{{ subToolName.tools_extremity.ue.types.a.options.wmft }}</p>
              </div>
            </slide-up-down>
          </div>
          <div class="nav-tool-bar-sub" v-else-if="selectedTool.tool_tab_2 === subToolName.tools_extremity.ue.types.p.name && toolUe === 'ue'">
            <slide-up-down :active="active.tool_tab_3" :duration="300">
              <div class="tool-tab" @click="nowReady('sis')">
                <p>{{ subToolName.tools_extremity.ue.types.p.options.sis }}</p>
              </div>
              <div class="tool-tab" @click="nowReady('mal')">
                <p>{{ subToolName.tools_extremity.ue.types.p.options.mal }}</p>
              </div>
              <div class="tool-tab" @click="nowReady('aaut')">
                <p>{{ subToolName.tools_extremity.ue.types.p.options.aaut }}</p>
              </div>
            </slide-up-down>
          </div>
          <div class="nav-tool-bar-sub"
               v-else-if="selectedTool.tool_tab_2 === subToolName.tools_extremity.le.types.bs.name && toolUe === 'le'">
            <slide-up-down :active="active.tool_tab_3" :duration="300">
              <div class="tool-tab" @click="thirdTab('bbs')">
                <p>{{ subToolName.tools_extremity.le.types.bs.options.bbs }}</p>
              </div>
              <div class="tool-tab" @click="thirdTab('tug')">
                <p>{{ subToolName.tools_extremity.le.types.bs.options.tug }}</p>
              </div>
              <div class="tool-tab" @click="thirdTab('ftsts')">
                <p>{{ subToolName.tools_extremity.le.types.bs.options.ftsts }}</p>
              </div>
              <div class="tool-tab" @click="thirdTab('fmLow')">
                <p>{{ subToolName.tools_extremity.le.types.bs.options.fmLow.substring(0, subToolName.tools_extremity.le.types.bs.options.fmLow.length - 1) }}</p>
              </div>
            </slide-up-down>
          </div>
          <div class="nav-tool-bar-sub"
               v-else-if="selectedTool.tool_tab_2 === subToolName.tools_extremity.le.types.a.name && toolUe === 'le'">
            <slide-up-down :active="active.tool_tab_3" :duration="300">
              <div class="tool-tab" @click="thirdTab('tmwt')">
                <p>{{ subToolName.tools_extremity.le.types.a.options.tmwt }}</p>
              </div>
              <div class="tool-tab" @click="thirdTab('smwt')">
                <p>{{ subToolName.tools_extremity.le.types.a.options.smwt }}</p>
              </div>
              <div class="tool-tab" @click="thirdTab('dgi')">
                <p>{{ subToolName.tools_extremity.le.types.a.options.dgi }}</p>
              </div>
            </slide-up-down>
          </div>
          <div class="nav-tool-bar-sub"
               v-else-if="selectedTool.tool_tab_2 === subToolName.tools_extremity.le.types.p.name && toolUe === 'le'">
            <slide-up-down :active="active.tool_tab_3" :duration="300">
              <div class="tool-tab" @click="thirdTab('abc')">
                <p>{{ subToolName.tools_extremity.le.types.p.options.abc }}</p>
              </div>
            </slide-up-down>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <CommonToolsInfo :toolName="toolName"
                     :toolsDescription="toolsDescription"
                     :toolUe="toolUe"
                     ref="toolsInfo"
                     v-if="toolName !== ''"/>
  </div>

</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import CommonToolsInfo from "@/views/therapist/menu/evaluationToolsInfo/tools/CommonToolsInfo";
import toolsDescription from "@/assets/data/evaluationToolsInfo/toolsDescription";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "EvaluationToolsInfo",
  components: {
    CommonToolsInfo,
    SlideUpDown,
  },
  data() {
    return {
      fbCollection: 'standardization',
      fbUid: this.$store.state.user.uid,
      toolName: '',
      toolsDescription: toolsDescription.fm,
      selectedTool: {
        tool_tab_1: 'Upper Extremity',
        tool_tab_2: 'Body Function & Structure',
        tool_tab_3: 'Fugl-Meyer',
      },
      toolUe: 'ue',
      subToolName: {
        tools_extremity: {
          ue: {
            name: 'Upper Extremity',
            types: {
              bs: {
                name: 'Body Function & Structure',
                options: {
                  fm: 'Fugl-Meyer'
                }
              },
              a: {
                name: 'Activity',
                options: {
                  bb: 'BB',
                  wmft: 'WMFT'
                }
              },
              p: {
                name: 'Participation',
                options: {
                  sis: 'SIS',
                  aaut: 'AAUT',
                  mal: 'MAL'
                }
              }
            }
          },
          le: {
            name: 'Lower Extremity',
            types: {
              bs: {
                name: 'Body Function & Structure2',
                options: {
                  bbs: 'BBS',
                  tug: 'TUG',
                  ftsts: 'FTSTS',
                  fmLow: 'Fugl-Meyer2'
                }
              },
              a: {
                name: 'Activity2',
                options: {
                  tmwt: '10MWT',
                  smwt: '6MWT',
                  dgi: 'DGI'
                }
              },
              p: {
                name: 'Participation2',
                options: {
                  abc: 'ABC scale',
                }
              }
            }
          }
        },
      },

      active: {
        tool_tab_1: false,
        tool_tab_2: false,
        tool_tab_3: false,
      },
      realToolsName: {
        fmLow: 'Fugl-Meyer-Low',
        fm: 'Fugl-Meyer',
        bb: 'BB',
        wmft: 'WMFT',
        sis: 'SIS',
        aaut: 'AAUT',
        mal: 'MAL',
      },
    }
  },
  mounted() {
    this.dataInit()
  },
  methods: {
    nowReady(toolName) {
      alert(`${toolName}은(는) 준비중입니다.`)
    },
    dataInit() {
      const self = this;
      switch (self.$route.params.id) {
        case 'fm':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.bs.options.fm
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.bs.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
          self.toolName = 'Fugl-Meyer'
          break;
        case 'bb':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.a.options.bb
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.a.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
          self.toolName = 'BB'
          break;
        case 'wmft':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.a.options.wmft
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.a.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
          self.toolName = 'WMFT'
          break;
        case 'sis':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.sis
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.p.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
          self.toolName = 'SIS'
          break;
        case 'aaut':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.aaut
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.p.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
          self.toolName = 'AAUT'
          break;
        case 'mal':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.mal
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.p.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
          self.toolName = 'MAL'
          break;
        case 'bbs':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.bbs
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.bs.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = 'BBS'
          self.toolUe = 'le'
          break;
        case 'tug':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.tug
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.bs.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = 'TUG'
          self.toolUe = 'le'
          break;
        case 'ftsts':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.ftsts
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.bs.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = 'FTSTS'
          self.toolUe = 'le'
          break;
        case 'fmLow':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.fmLow
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.bs.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = 'Fugl-Meyer-Low'
          self.toolUe = 'le'
          break;
        case 'tmwt':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.tmwt
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.a.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = '10MWT'
          self.toolUe = 'le'
          break;
        case 'smwt':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.smwt
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.a.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = '6MWT'
          self.toolUe = 'le'
          break;
        case 'dgi':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.dgi
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.a.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = 'DGI'
          self.toolUe = 'le'
          break;
        case 'abc':
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.p.options.abc
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.p.name
          self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
          self.toolName = 'ABC scale'
          self.toolUe = 'le'
          break;
      }
      self.changeDescription(self.$route.params.id)
    },
    firstTab(type) {
      const self = this;
      self.toolUe = type
      if (self.toolUe === 'ue') {
        self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.ue.name
        self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.bs.name
        self.selectedTool.tool_tab_3 = 'Fugl-Meyer'
      } else {
        self.selectedTool.tool_tab_1 = self.subToolName.tools_extremity.le.name
        self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.bs.name
        self.selectedTool.tool_tab_3 = 'BBS'
      }
      self.active.tool_tab_2 = true;
      self.active.tool_tab_1 = false;
      self.active.tool_tab_3 = false;
    },
    secondTab(type) {
      const self = this;
      if (self.toolUe === 'ue') {
        if (type === 'bs') {
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.bs.options.fm
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.bs.name
        } else if (type === 'a') {
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.a.options.bb
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.a.name
        } else {
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.sis
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.ue.types.p.name
        }
      } else {
        if (type === 'bs') {
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.bbs
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.bs.name
        } else if (type === 'a') {
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.tmwt
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.a.name
        } else {
          self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.p.options.abc
          self.selectedTool.tool_tab_2 = self.subToolName.tools_extremity.le.types.p.name
        }
      }
      self.active.tool_tab_1 = false;
      self.active.tool_tab_2 = false;
      self.active.tool_tab_3 = true;
    },
    async thirdTab(type) {
      const self = this;
      if (self.toolUe === 'ue') {
        if (self.selectedTool.tool_tab_2 === self.subToolName.tools_extremity.ue.types.bs.name) {
          if (type === 'fm') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.bs.options.fm
            self.toolName = 'Fugl-Meyer'
          }
        } else if (self.selectedTool.tool_tab_2 === self.subToolName.tools_extremity.ue.types.a.name) {
          if (type === 'bb') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.a.options.bb
            self.toolName = 'BB'
          } else {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.a.options.wmft
            self.toolName = 'WMFT'
          }
        } else if (self.selectedTool.tool_tab_2 === self.subToolName.tools_extremity.ue.types.p.name) {
          if (type === 'sis') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.sis
            self.toolName = 'SIS'
          } else if (type === 'aaut') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.aaut
            self.toolName = 'AAUT'
          } else {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.ue.types.p.options.mal
            self.toolName = 'MAL'
          }
        }
      } else {
        if (self.selectedTool.tool_tab_2 === self.subToolName.tools_extremity.le.types.bs.name) {
          if (type === 'bbs') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.bbs
            self.toolName = 'BBS'
          } else if (type === 'tug') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.tug
            self.toolName = 'TUG'
          } else if (type === 'ftsts') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.ftsts
            self.toolName = 'FTSTS'
          } else if (type === 'fmLow') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.bs.options.fmLow
            self.toolName = 'Fugl-Meyer-Low'
          }
        } else if (self.selectedTool.tool_tab_2 === self.subToolName.tools_extremity.le.types.a.name) {
          if (type === 'tmwt') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.tmwt
            self.toolName = '10MWT'
          } else if (type === 'smwt') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.smwt
            self.toolName = '6MWT'
          } else if (type === 'dgi') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.a.options.dgi
            self.toolName = 'DGI'
          }
        } else if (self.selectedTool.tool_tab_2 === self.subToolName.tools_extremity.le.types.p.name) {
          if (type === 'abc') {
            self.selectedTool.tool_tab_3 = self.subToolName.tools_extremity.le.types.p.options.abc
            self.toolName = 'ABC scale'
          }
        }
      }
      self.active.tool_tab_1 = false;
      self.active.tool_tab_2 = false;
      self.active.tool_tab_3 = false;
      await self.changeDescription(type);
      if (self.$route.path !== '/therapist/evaluationToolsInfo/' + type) {
        await self.$router.push({path: '/therapist/evaluationToolsInfo/' + type});
      }
      self.$refs.toolsInfo.dataInit()
    },
    changeDescription(type) {
      const self = this;
      if (self.toolName === 'Fugl-Meyer' || type === 'fm') {
        self.toolsDescription = toolsDescription.fm
        self.standardPaper()
      } else if (self.toolName === 'BB') {
        self.toolsDescription = toolsDescription.bb
      } else if (self.toolName === 'WMFT') {
        self.toolsDescription = toolsDescription.wmft
      } else if (self.toolName === 'SIS') {
        self.toolsDescription = toolsDescription.sis
      } else if (self.toolName === 'AAUT') {
        self.toolsDescription = toolsDescription.aaut
      } else if (self.toolName === 'MAL') {
        self.toolsDescription = toolsDescription.mal
      } else if (self.toolName === 'BBS') {
        self.toolsDescription = toolsDescription.bbs
      } else if (self.toolName === 'TUG') {
        self.toolsDescription = toolsDescription.tug
      } else if (self.toolName === 'FTSTS') {
        self.toolsDescription = toolsDescription.ftsts
      } else if (self.toolName === 'Fugl-Meyer-Low' || type === 'fmLow') {
        self.toolsDescription = toolsDescription.fmLow
      } else if (self.toolName === '10MWT') {
        self.toolsDescription = toolsDescription.tmwt
      } else if (self.toolName === '6MWT') {
        self.toolsDescription = toolsDescription.smwt
      } else if (self.toolName === 'DGI') {
        self.toolsDescription = toolsDescription.dgi
      } else if (self.toolName === 'ABC scale') {
        self.toolsDescription = toolsDescription.abc
      }
    },
    async standardPaper() {
      const self = this;
      const db = firebase.firestore();
      const toolsName = self.$route.params.id;

      const setData = {}
      setData[toolsName] = {
        state: 0, //0: 미평가, 1, 재도전, 2: 평가완료
        index: 0,
        papers: [{
          fm_001: null, fm_002: null, fm_003: null, fm_004: null, fm_005: null, fm_006: null, fm_007: null, fm_008: null, fm_009: null, fm_010: null,
          fm_011: null, fm_012: null, fm_013: null, fm_014: null, fm_015: null, fm_016: null, fm_017: null, fm_018: null, fm_019: null, fm_020: null,
          fm_021: null, fm_022: null, fm_023: null, fm_024: null, fm_025: null, fm_026: null, fm_027: null, fm_028: null, fm_029: null, fm_030: null,
          fm_031: null, fm_032: null, fm_033: null, fm_034: null, fm_035: null, fm_036: null, fm_037: null, fm_038: null, fm_039: null, fm_040: null,
          fm_041: null, fm_042: null, fm_043: null, fm_044: null, fm_045: null, fm_046: null, fm_047: null, fm_048: null, fm_049: null, fm_050: null,
          fm_051: null, fm_052: null, fm_053: null, fm_054: null, fm_055: null, fm_056: null, fm_057: null, fm_058: null, fm_059: null, fm_060: null,
          fm_061: null, fm_062: null, fm_063: null, fm_064: null, fm_065: null, fm_066: null, fm_067: null, fm_068: null, fm_069: null, fm_070: null,
          fm_071: null, fm_072: null, fm_073: null, fm_074: null, fm_075: null, fm_076: null, fm_077: null, fm_078: null, fm_079: null, fm_080: null,
          fm_081: null, fm_082: null, fm_083: null, fm_084: null, fm_085: null, fm_086: null, fm_087: null, fm_088: null, fm_089: null, fm_090: null,
          fm_091: null, fm_092: null, fm_093: null, fm_094: null, fm_095: null, fm_096: null, fm_097: null, fm_098: null, fm_099: null, fm_100: null,
          fm_101: null, fm_102: null, fm_103: null, fm_104: null, fm_105: null, fm_106: null, fm_107: null, fm_108: null, fm_109: null, fm_110: null,
          fm_111: null, fm_112: null, fm_113: null, fm_114: null, fm_115: null, fm_116: null, fm_117: null, fm_118: null, fm_119: null, fm_120: null,
          fm_121: null, fm_122: null, fm_123: null, fm_124: null, fm_125: null, fm_126: null, fm_127: null, fm_128: null,
          score: 0,
          pass: 0,
          state: 0,
        }]
      }

      await db.collection(self.fbCollection)
          .doc(self.fbUid)
          .get()
          .then(async (snapshot) => {
            if (!snapshot.exists) {
              await db.collection(self.fbCollection)
                  .doc(self.fbUid)
                  .set(setData)
            }
          });
    },
  }
}
</script>

<style scoped>
.view-layout {
  width: 1140px;
  margin: 0 auto;
}

.nav-tools-bar {
  background-color: #0078AF;

}

.nav-tool-bar-sub {

}

.tool-tab {
  width: 250px;
  background-color: white;
  text-align: center;
  padding: 8px 12px;
  border: 1px solid #E1E3E6;
  border-top: none;
  cursor: pointer;
  z-index: 100;
  position: relative;
}

.tool-tab p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}


.nav-tools-bar .view-layout {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 17px;
}

.nav-tools-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 12px;
  position: static;
  width: 250px;
  height: 50px;
  cursor: pointer;

}

.nav-tools-item p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: auto;
}

.nav-tools-item img {
  width: 18px;
  height: 18px;
}


hr {
  width: 1px;
  height: 50px;
  background: #0990CD;
  margin: 0;
  padding: 0;
  border: 0;
}


.guide-frame h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 109px;
  text-align: center;
  height: 28px;
  background: #0069CA;
  border-radius: 100px;
}

.guide-frame p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.0025em;
  color: #0A1120;
  margin-top: 5px;
}


.tab-nav {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E1E3E6;

}


/deep/ .mdb-datatable thead th {
  font-size: 14px;
  font-weight: 700;
}

/deep/ .mdb-datatable tbody td:nth-child(3n) {
  color: #8D8D8D;
  font-weight: 400;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 3;
}


/deep/ .time-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .icon-play {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

/deep/ .blue-color {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 0;
}

.guide p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

/deep/ .mdb-datatable td:nth-child(2) {
  text-align: left;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F8F8FA;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(0, 0, 0, 0.075);
}

/deep/ .mdb-datatable th {
  padding: 0;
  height: 48px;
  vertical-align: middle;
}

/deep/ .mdb-datatable td {
  padding: 0;
  height: 48px;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
}

/deep/ .mdb-datatable-footer {
  padding: 5px 0 0 0;
  height: 64px;
  vertical-align: middle;
  align-items: center;
}

/deep/ .action-btn {
  width: 176px;
  height: 36px;
  cursor: pointer;
}


/deep/ .standard .mdb-datatable-footer {
  display: none;
}
</style>
