export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '영상 제목',
        field: 'title',
        sort: true
    },
    {
        label: '시간',
        field: 'time2',
        sort: false
    },
];
