<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        평가도구 추가
      </h5>
    </div>
    <div class="modal-center">
      <div class="top-line">
        <p class="sub-title text-black">질환정보 입력</p>
        <button class="custom-btn-box-white btn-get-disease-info" @click="btnGetDiseaseInfo">이전 질환정보 불러오기</button>
      </div>
      <p class="sub-title">진단명</p>
      <div class="row-box">
        <mdb-select outline v-model="diagnosisOptions" placeholder="진단명" @getValue="getSelectDiagnosisValue" class="search-select row-box-item"/>
        <input type="text" class="custom-input-box row-box-item" placeholder="세부 병명 입력" v-model="fbEvaluationInfo.diseaseInfo.description">
      </div>
      <p class="sub-title">손상 발생일</p>
      <mdb-date-picker-2 inline v-model="fbEvaluationInfo.diseaseInfo.injuredDate" placeholder="날짜 선택" class="date-picker" right/>
      <p class="sub-title">손잡이</p>
      <mdb-select outline v-model="handsOptions" placeholder="손잡이 선택" @getValue="getSelectHandsValue" class="search-select search-box-item"/>
      <p class="sub-title">손상 부위 / 손상측</p>
      <div class="row-box">
        <mdb-select outline v-model="partsOptions" placeholder="손상 부위 선택" @getValue="getSelectPartsValue" class="search-select search-box-item"/>
        <mdb-select outline v-model="sideOptions" placeholder="손상측 선택" @getValue="getSelectSideValue" class="search-select search-box-item"/>
      </div>
      <div class="tools-select-line">
        <p class="sub-title text-black">평가도구 선택</p>
      </div>
      <div class="tools-title">
        <div class="tools-sub-title">Basic Measure</div>
      </div>
      <div class="tools">
        <mdb-input type="checkbox" id="checkbox7" name="BM" v-model="fbEvaluationInfo.tools.bm.checked" class="tools-item bm-box" label=" ">
          <div class="bm">
            <div>ROM, MMT</div>
            <div>MAS</div>
            <div>Balance</div>
          </div>
        </mdb-input>
      </div>
      <div class="tools-bottom-container">
        <div class="left">
          <div class="tools-title">
            <div class="tools-sub-title">Upper Extremity</div>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_1 = !active.tool_1">Body Function & Structure
              <img v-if="active.tool_1" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_1" :duration="500">
              <mdb-input type="checkbox" id="checkbox1" name="FM" v-model="fbEvaluationInfo.tools.fm.checked" class="tools-item" label="Fugl-Meyer"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_2 = !active.tool_2">Activity
              <img v-if="active.tool_2" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_2" :duration="500">
              <mdb-input type="checkbox" id="checkbox2" name="BB" v-model="fbEvaluationInfo.tools.bb.checked" class="tools-item" label="BB"/>
              <mdb-input type="checkbox" id="checkbox3" name="WMFT" v-model="fbEvaluationInfo.tools.wmft.checked" class="tools-item" label="WMFT"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_3 = !active.tool_3">Participation
              <img v-if="active.tool_3" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_3" :duration="500">
              <mdb-input type="checkbox" id="checkbox4" name="SIS" v-model="fbEvaluationInfo.tools.sis.checked" class="tools-item" label="SIS"/>
              <mdb-input type="checkbox" id="checkbox5" name="AAUT" v-model="fbEvaluationInfo.tools.aaut.checked" class="tools-item" label="AAUT"/>
              <mdb-input type="checkbox" id="checkbox6" name="MAL" v-model="fbEvaluationInfo.tools.mal.checked" class="tools-item" label="MAL"/>
            </slide-up-down>
          </div>
        </div>
        <div class="right">
          <div class="tools-title">
            <div class="tools-sub-title">Lower Extremity</div>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_4 = !active.tool_4">Body Function & Structure
              <img v-if="active.tool_4" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_4" :duration="500">
              <mdb-input type="checkbox" id="checkbox8" name="BBS" v-model="fbEvaluationInfo.tools.bbs.checked" class="tools-item" label="BBS"/>
              <mdb-input type="checkbox" id="checkbox9" name="TUG" v-model="fbEvaluationInfo.tools.tug.checked" class="tools-item" label="TUG"/>
              <mdb-input type="checkbox" id="checkbox10" name="FTSTS" v-model="fbEvaluationInfo.tools.ftsts.checked" class="tools-item" label="FTSTS"/>
              <mdb-input type="checkbox" id="checkbox11" name="FM_L" v-model="fbEvaluationInfo.tools.fm_l.checked" class="tools-item" label="Fugl-Meyer"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_5 = !active.tool_5">Activity
              <img v-if="active.tool_5" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_5" :duration="500">
              <mdb-input type="checkbox" id="checkbox12" name="TMWT" v-model="fbEvaluationInfo.tools.tmwt.checked" class="tools-item" label="10MWT"/>
              <mdb-input type="checkbox" id="checkbox13" name="SMWT" v-model="fbEvaluationInfo.tools.smwt.checked" class="tools-item" label="6MWT"/>
              <mdb-input type="checkbox" id="checkbox14" name="DGI" v-model="fbEvaluationInfo.tools.dgi.checked" class="tools-item" label="DGI"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_6 = !active.tool_6">Participation
              <img v-if="active.tool_6" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_6" :duration="500">
              <mdb-input type="checkbox" id="checkbox15" name="ABCscale" v-model="fbEvaluationInfo.tools.abc.checked" class="tools-item" label="ABCscale"/>
            </slide-up-down>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class=" custom-btn-box-white btn-short" @click="btnCancel">취소</button>
      <button class="primary-blue custom-btn-box btn-short" @click="btnAdd">추가</button>
    </div>
    <Progress :isProgress="isProgress"/>
  </div>

</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import {mdbDatePicker2, mdbInput, mdbSelect} from "mdbvue";
import moment from "moment";
import {firebase} from "@/firebase/firebaseConfig";
import Progress from "@/components/Progress.vue";

export default {
  name: "EvaluationToolsAddDialog",
  components: {
    SlideUpDown,Progress,
    mdbInput,
    mdbSelect,
    mdbDatePicker2,
  },
  props: {
    selectPatientInfo: Object
  },
  data() {
    return {
      fbCollection: 'evaluations',
      isProgress: false,
      beforeDiseaseInfo: {},
      active: {
        tool_1: true,
        tool_2: true,
        tool_3: true,
        tool_4: true,
        tool_5: true,
        tool_6: true,
      },
      diagnosisOptions: [
        {text: "뇌졸중", value: "뇌졸중"},
        {text: "파킨슨", value: "파킨슨"},
        {text: "외상성 뇌손상", value: "외상성 뇌손상"},
        {text: "척수 손상", value: "척수 손상"},
        {text: "치매", value: "치매"},
        {text: "암", value: "암"},
        {text: "다발성 경화증", value: "다발성 경화증"},
        {text: "뇌정증(간질)", value: "뇌정증(간질)"},
        {text: "말초신경병증", value: "말초신경병증"},
      ],
      handsOptions: [
        {text: "오른쪽", value: "오른쪽"},
        {text: "왼쪽", value: "왼쪽"},
        {text: "양쪽 모두", value: "양쪽 모두"},
      ],
      partsOptions: [
        {text: "상지", value: "상지"},
        {text: "하지", value: "하지"},
        {text: "상,하지 모두", value: "상,하지 모두"},
      ],
      sideOptions: [
        {text: "오른쪽", value: "오른쪽"},
        {text: "왼쪽", value: "왼쪽"},
        {text: "양쪽 모두", value: "양쪽 모두"},
      ],
      selectedEvaluationInfo: {},
      fbEvaluationInfo: {
        diseaseInfo: {
          description: '',
          diagnosis: '',
          usedHand: '',
          injuredDate: '',
          injuredPart: '',
          injuredSide: '',
        },
        therapistInfo: {
          name: '',
          uid: '',
          patientKey: '',
        },
        patientInfo: {},
        state: 0,
        round: 0,
        tools: {
          bm: {name: 'Basic Measure', checked: true,},
          rom_mmt: {
            name: 'rom_mmt', checked: true, state: 0,
            rom: {
              lt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              },
              rt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              }
            },
            mmt: {
              lt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              },
              rt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                elbow: {flexion: null, extension: null,},
                forearm: {supination: null, pronation: null,},
                wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
              }
            }
          },
          mas: {
            name: 'mas', checked: true, state: 0,
            lt: {
              shoulder: {flexion: null, extension: null, abduction: null, adduction: null},
              elbow: {flexion: null, extension: null,},
              wrist: {flexion: null, extension: null,},
              hip: {flexion: null, extension: null, abduction: null, adduction: null},
              knee: {flexion: null,},
              ankle: {dorsi_flexion: null, plantar_flexion: null}
            },
            rt: {
              shoulder: {flexion: null, extension: null, abduction: null, adduction: null},
              elbow: {flexion: null, extension: null,},
              wrist: {flexion: null, extension: null,},
              hip: {flexion: null, extension: null, abduction: null, adduction: null},
              knee: {flexion: null,},
              ankle: {dorsi_flexion: null, plantar_flexion: null}
            }
          },
          balance: {
            name: 'balance', checked: true, state: 0,
            sitting: {static: null, dynamic: null},
            standing: {static: null, dynamic: null}
          },
          fm: {
            name: 'Fugl-Meyer(상)', checked: true, state: 0,
            a_1_1: null, a_1_2: null, a_2_1: null, a_2_2: null, a_2_3: null, a_2_4: null, a_2_5: null, a_2_6: null, a_2_7: null,
            a_2_8: null, a_2_9: null, a_3_1: null, a_3_2: null, a_3_3: null, a_4_1: null, a_4_2: null, a_4_3: null, a_5_1: null,
            b_1_1: null, b_1_2: null, b_1_3: null, b_1_4: null, b_1_5: null,
            c_1_1: null, c_1_2: null, c_1_3: null, c_1_4: null, c_1_5: null, c_1_6: null, c_1_7: null,
            d_1_1: null, d_1_2: null, d_1_3: null,
          },
          bb: {
            name: 'BB', checked: true, state: 0,
            ma: null, la: null
          },
          wmft: {
            name: 'WMFT', checked: true, state: 0,
            ma: {
              ma_1: null, ma_2: null, ma_3: null, ma_4: null, ma_5: null, ma_6: null, ma_7: null, ma_8: null, ma_9: null, ma_10: null,
              ma_11: null, ma_12: null, ma_13: null, ma_14: null, ma_15: null,
              kg: {kg_1: null, kg_2: null, kg_3: null,},
              pw: {pw_1: null, pw_2: null, pw_3: null,},
            },
            la: {
              la_1: null, la_2: null, la_3: null, la_4: null, la_5: null, la_6: null, la_7: null, la_8: null, la_9: null, la_10: null,
              la_11: null, la_12: null, la_13: null, la_14: null, la_15: null,
              kg: {kg_1: null, kg_2: null, kg_3: null,},
              pw: {pw_1: null, pw_2: null, pw_3: null,},
            },
          },
          sis: {
            name: 'SIS', checked: true, state: 0,
            a_1_1: null, a_1_2: null, a_1_3: null, a_1_4: null,
            a_2_1: null, a_2_2: null, a_2_3: null, a_2_4: null, a_2_5: null, a_2_6: null, a_2_7: null,
            a_3_1: null, a_3_2: null, a_3_3: null, a_3_4: null, a_3_5: null, a_3_6: null, a_3_7: null, a_3_8: null, a_3_9: null,
            a_4_1: null, a_4_2: null, a_4_3: null, a_4_4: null, a_4_5: null, a_4_6: null, a_4_7: null,
            a_5_1: null, a_5_2: null, a_5_3: null, a_5_4: null, a_5_5: null, a_5_6: null, a_5_7: null, a_5_8: null, a_5_9: null, a_5_10: null,
            a_6_1: null, a_6_2: null, a_6_3: null, a_6_4: null, a_6_5: null, a_6_6: null, a_6_7: null, a_6_8: null, a_6_9: null,
            a_7_1: null, a_7_2: null, a_7_3: null, a_7_4: null, a_7_5: null,
            a_8_1: null, a_8_2: null, a_8_3: null, a_8_4: null, a_8_5: null, a_8_6: null, a_8_7: null, a_8_8: null,
            a_9_1: null,
          },
          aaut: {
            name: 'AAUT', checked: true, state: 0,
            aou: {
              aou_1: null, aou_2: null, aou_3: null, aou_4: null, aou_5: null, aou_6: null, aou_7: null,
              aou_8: null, aou_9: null, aou_10: null, aou_11: null, aou_12: null, aou_13: null, aou_14: null,
            },
            qom: {
              qom_1: null, qom_2: null, qom_3: null, qom_4: null, qom_5: null, qom_6: null, qom_7: null,
              qom_8: null, qom_9: null, qom_10: null, qom_11: null, qom_12: null, qom_13: null, qom_14: null,
            },
          },
          mal: {
            name: 'MAL', checked: true, state: 0,
            aou: {
              aou_1: null, aou_2: null, aou_3: null, aou_4: null, aou_5: null, aou_6: null, aou_7: null, aou_8: null, aou_9: null, aou_10: null,
              aou_11: null, aou_12: null, aou_13: null, aou_14: null, aou_15: null, aou_16: null, aou_17: null, aou_18: null, aou_19: null,
              aou_20: null, aou_21: null, aou_22: null, aou_23: null, aou_24: null, aou_25: null, aou_26: null, aou_27: null, aou_28: null,
            },
            qom: {
              qom_1: null, qom_2: null, qom_3: null, qom_4: null, qom_5: null, qom_6: null, qom_7: null, qom_8: null, qom_9: null, qom_10: null,
              qom_11: null, qom_12: null, qom_13: null, qom_14: null, qom_15: null, qom_16: null, qom_17: null, qom_18: null, qom_19: null,
              qom_20: null, qom_21: null, qom_22: null, qom_23: null, qom_24: null, qom_25: null, qom_26: null, qom_27: null, qom_28: null,
            },
          },
          bbs: {
            name: 'BBS', checked: true, state: 0,
            bbs_1: null, bbs_2: null, bbs_3: null, bbs_4: null, bbs_5: null, bbs_6: null, bbs_7: null,
            bbs_8: null, bbs_9: null, bbs_10: null, bbs_11: null, bbs_12: null, bbs_13: null, bbs_14: null,
          },
          tug: {
            name: 'TUG', checked: true, state: 0,
            time: null
          },
          ftsts: {
            name: 'FTSTS', checked: true, state: 0,
            time: null
          },
          fm_l: {
            name: 'Fugl-Meyer(하)', checked: true, state: 0,
            e_1_1: null, e_1_2: null,
            e_2_1: null, e_2_2: null, e_2_3: null, e_2_4: null, e_2_5: null, e_2_6: null, e_2_7: null,
            e_3_1: null, e_3_2: null,
            e_4_1: null, e_4_2: null,
            e_5_1: null,
            f_1_1: null, f_1_2: null, f_1_3: null,
          },
          tmwt: {
            name: '10MWT', checked: true, state: 0,
            assistive_device: null, assist: null,
            self_selected_speed: {trial_1: null, trial_2: null, trial_3: null, average_time: null, average_self_selected: null},
            fast_speed: {trial_1: null, trial_2: null, trial_3: null, average_time: null, average_self_selected: null}
          },
          smwt: {
            name: '6MWT', checked: true, state: 0, assist: 0,
            pre_rest: null, pre_1: null, pre_2: null, pre_3: null, pre_4: null, pre_5: null, pre_6: null,
            distance_rest: null, distance_1: null, distance_2: null, distance_3: null, distance_4: null, distance_5: null, distance_6: null,
            comments_rest: null, comments_1: null, comments_2: null, comments_3: null, comments_4: null, comments_5: null, comments_6: null,
          },
          dgi: {
            name: 'DGI', checked: true, state: 0,
            dgi_1: null, dgi_2: null, dgi_3: null, dgi_4: null, dgi_5: null, dgi_6: null, dgi_7: null, dgi_8: null,
          },
          abc: {
            name: 'ABCscale', checked: true, state: 0,
            abc_1: null, abc_2: null, abc_3: null, abc_4: null,
            abc_5: null, abc_6: null, abc_7: null, abc_8: null,
            abc_9: null, abc_10: null, abc_11: null, abc_12: null,
            abc_13: null, abc_14: null, abc_15: null, abc_16: null
          }
        }
      }
    }
  },
  methods: {
    getSelectDiagnosisValue(value) {
      if (value !== null) this.fbEvaluationInfo.diseaseInfo.diagnosis = value
    },
    getSelectHandsValue(value) {
      if (value !== null) this.fbEvaluationInfo.diseaseInfo.usedHand = value
    },
    getSelectPartsValue(value) {
      if (value !== null) this.fbEvaluationInfo.diseaseInfo.injuredPart = value
    },
    getSelectSideValue(value) {
      if (value !== null) this.fbEvaluationInfo.diseaseInfo.injuredSide = value
    },

    async btnGetDiseaseInfo() {
      const self = this;
      const db = firebase.firestore();
      await db.collection('evaluations')
          .where('patientInfo.patientKey', '==', self.selectPatientInfo.patientKey)
          .orderBy('regDate', 'desc')
          .limit(1)
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.size === 0) {
              alert('이전 질환정보가 없습니다.');
              return
            }
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              self.beforeDiseaseInfo = {..._data.diseaseInfo}
              self.compareSelect()
            });
          });
    },

    compareSelect() {
      const self = this

      let index = self.diagnosisOptions.findIndex((element) => element.value === self.beforeDiseaseInfo.diagnosis);
      self.diagnosisOptions[index].selected = true
      self.diagnosisOptions.push()
      self.fbEvaluationInfo.diseaseInfo.diagnosis = self.beforeDiseaseInfo.diagnosis
      self.fbEvaluationInfo.diseaseInfo.description = self.beforeDiseaseInfo.description
      self.fbEvaluationInfo.diseaseInfo.injuredDate = self.beforeDiseaseInfo.injuredDate
      index = self.handsOptions.findIndex((element) => element.value === self.beforeDiseaseInfo.usedHand);
      self.handsOptions[index].selected = true
      self.handsOptions.push()
      index = self.partsOptions.findIndex((element) => element.value === self.beforeDiseaseInfo.injuredPart);
      self.partsOptions[index].selected = true
      self.partsOptions.push()
      index = self.sideOptions.findIndex((element) => element.value === self.beforeDiseaseInfo.injuredSide);
      self.sideOptions[index].selected = true
      self.sideOptions.push()
    },

    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 2, false)
    },
    async btnAdd() {
      const self = this;

      if (self.fbEvaluationInfo.diseaseInfo.diagnosis === '' || self.fbEvaluationInfo.diseaseInfo.injuredDate === '' || self.fbEvaluationInfo.diseaseInfo.usedHand === '' ||
          self.fbEvaluationInfo.diseaseInfo.injuredPart === '' || self.fbEvaluationInfo.diseaseInfo.injuredSide === '') {
        alert('질환정보를 모두 입력해 주세요.');
        return
      }

      if (!self.fbEvaluationInfo.tools.fm.checked && !self.fbEvaluationInfo.tools.bb.checked && !self.fbEvaluationInfo.tools.wmft.checked && !self.fbEvaluationInfo.tools.sis.checked &&
          !self.fbEvaluationInfo.tools.aaut.checked && !self.fbEvaluationInfo.tools.mal.checked && !self.fbEvaluationInfo.tools.bm.checked && !self.fbEvaluationInfo.tools.bbs.checked &&
          !self.fbEvaluationInfo.tools.tug.checked && !self.fbEvaluationInfo.tools.ftsts.checked && !self.fbEvaluationInfo.tools.fm_l.checked && !self.fbEvaluationInfo.tools.tmwt.checked &&
          !self.fbEvaluationInfo.tools.smwt.checked && !self.fbEvaluationInfo.tools.dgi.checked && !self.fbEvaluationInfo.tools.abc.checked) {
        alert('평가도구는 최소 1개 이상 필수 선택입니다.');
        return
      }

      if (!moment(self.fbEvaluationInfo.diseaseInfo.injuredDate, 'YYYY-MM-DD', true).isValid()) {
        alert('날짜 형식을 확인해주세요. ex)2000-01-01')
        return
      }
      await self.bmChecked()
      await self.setDataTools()
    },
    bmChecked() {
      const self = this;

      self.fbEvaluationInfo.tools.rom_mmt.checked = self.fbEvaluationInfo.tools.bm.checked
      self.fbEvaluationInfo.tools.mas.checked = self.fbEvaluationInfo.tools.bm.checked
      self.fbEvaluationInfo.tools.balance.checked = self.fbEvaluationInfo.tools.bm.checked
    },
    async setDataTools() {
      const self = this;
      self.isProgress = true

      const _evaluationInfo = self.fbEvaluationInfo
      _evaluationInfo.patientInfo = self.selectPatientInfo

      const checkedTools = Object.values(_evaluationInfo.tools).filter(element => element.checked)
      _evaluationInfo.selectedEvaluationInfo = checkedTools.map(({name}) => name);

      _evaluationInfo.therapistInfo = {
        name: self.$store.state.user.displayName,
        uid: self.$store.state.user.uid,
      }
      _evaluationInfo.round = parseInt(_evaluationInfo.patientInfo.visitCount) + 1
      delete _evaluationInfo.patientInfo.visitCount
      _evaluationInfo.regDate = firebase.firestore.Timestamp.fromDate(new Date())

      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .add(_evaluationInfo)
          .then(async (result) => {
            db.collection('patients')
                .doc(_evaluationInfo.patientInfo.patientKey)
                .set({isProgress: true, evaluationId: result.id}, {merge: true})
                .then(async () => {
                  self.$emit('callBackModal', 2, true, result.id)
                  self.isProgress= false
                });
          });
    },
  }
}
</script>

<style scoped>

button {
  font-size: 16px !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
}


.modal-container {
  position: fixed;
  width: 580px;
  height: 657px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 8px;
  overflow: hidden;
}


.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 77px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 23px 20px;
  background: #F8F8FA;
  max-height: 507px;
  overflow: overlay;
}

.top-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-line .custom-control {
  display: flex;
  align-items: center;
}

.custom-checkbox .custom-control-label::before {
  margin-top: -2px;
}

.custom-control-label::after {
  transform: translateY(-2px);
}

.top-line .custom-control-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8D8D8D;
}

.tools-bottom-container {
  display: flex;
  justify-content: space-between;
}

.tools-bottom-container > div {
  width: 265px;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.row-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.row-box-item {
  flex: 1;
}

.patient-info-line {
  display: flex;
  flex-direction: row;
}

.sub-title:first-child {
  margin-top: 0;
  margin-bottom: 0;
}

.sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #0069CA;
  margin-top: 20px;
  margin-bottom: 10px;
}


.modal-footer {
  background: #FFFFFF;
  height: 73px;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.custom-btn-box-white {
  background: #ffffff;
  border: 1px solid #E1E3E6;
}

.search-select {
  flex-grow: 1;
  background-color: #ffffff;
}

.search-select:first-child {
  margin-right: 9px;
}

/deep/ .date-picker .md-form {
  margin: 0 !important;
}

/deep/ .search-select .md-form {
  width: 100% !important;
}

/deep/ .search-select .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
}

/deep/ .search-select .form-control::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #B1B1B1 !important;
}

.search-result-item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.date-picker {
  background-color: #ffffff;
  height: 40px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

.date-picker /deep/ .form-control {
  color: #000000;
  height: 40px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

.date-picker {
  font-size: 18px;
}

.tools-select-line {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.guide-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #E14F4E;
  margin-bottom: 0;
  margin-left: auto;
}

.tools-sub-title {
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E1E3E6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #0069CA;
  text-align: left;
  padding: 0 8px 0 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tools-title {
  margin-top: 20px;
}

.tools-title .tools-sub-title {
  background: #00498A;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  color: #FFFFFF;
}

.tools-sub-title img {
  width: 18px;
  height: 18px;
  margin-left: auto;
}

.tools-item {
  height: 40px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.tools img {
  width: 18px;
  height: 18px;
}

/deep/ .form-check {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  padding-left: 8px;
}

.bm-box {
  display: flex;
  height: 132px;
  padding: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}

/deep/ .bm-box label {
  margin: 0 !important;
  padding-left: 30px;
}

/deep/ .bm-box label::before {
  margin-left: 2px !important;
}

.bm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.bm div {
  padding: 6px 10px;
  width: 494px;
  height: 34px;
  border: 1px solid #E1E3E6;
}

.btn-get-disease-info {
  width: 153px;
  height: 38px;
  background: #00498A;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px;
  color: #FFFFFF;
  letter-spacing: 0.1px;
  white-space: nowrap;
}

</style>
