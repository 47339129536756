export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'sitting_static',
        field: 'sitting_static',
    },
    {
        label: 'sitting_dynamic',
        field: 'sitting_dynamic',
    },
    {
        label: 'standing_static',
        field: 'standing_static',
    },
    {
        label: 'standing_dynamic',
        field: 'standing_dynamic',
    },

];
