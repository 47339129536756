<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">AAUT(Actual Amount of Use Test)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-frame">
      <div class="paper-guide-frame">
        <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
        <p class="paper-guide">실제 사용정도 검사로 AOU, QOM을 각 측정할 수 있습니다.</p>
      </div>
      <div class="tab-frame">
        <div class="tab-nav">
          <div class="tab-nav-item" :class="{active : tabIndex === 0}" @click="tabIndex = 0">
            AOU(Amount of Use)
          </div>
          <div class="tab-nav-item" :class="{active : tabIndex === 1}" @click="tabIndex = 1">
            QOM(Quality of Movement)
          </div>
        </div>
        <div class="tab-item" v-if="tabIndex === 0">
          <AautAou :aou="aaut.aou"/>
        </div>
        <div class="tab-item" v-else>
          <AautQom :qom="aaut.qom"/>
        </div>
      </div>
    </div>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <AautResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :aaut="aaut"/>
  </div>
</template>

<script>
import AautAou from "./AautAou";
import AautQom from "./AautQom";
import AautResultDialog from "@/views/therapist/menu/doEvaluation/paper/aaut/AautResultDialog";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "ActualAmountOfUse",
  components: {AautResultDialog, AautQom, AautAou},
  props: {
    fbDocId: String,
    aaut: Object,
    isEdit: Boolean
  },
  data() {
    return {
      tabIndex: -1,
      fbCollection: 'evaluations',
      total: {
        aou: {
          answer: 0,
          score: 0,
          avg: 0,
        },
        qom: {
          answer: 0,
          score: 0,
          avg: 0,
        },
      },
      tools: {
        aaut: {
          aou: [],
          qom: [],
        }
      },
      isShowModal: false,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const self = this;
      self.tabIndex = 0
    },
    async tempSave() {
      const self = this;
      await self.calculator();

      self.tools.aaut = self.aaut;
      self.tools.aaut['state'] = 1;
      self.tools.aaut['totalAouAnswer'] = null;
      self.tools.aaut['totalQomAnswer'] = null;
      self.tools.aaut['totalAouScore'] = null;
      self.tools.aaut['totalQomScore'] = null;
      self.tools.aaut['totalAouAvg'] = null;
      self.tools.aaut['totalQomAvg'] = null;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'aaut', 1)
          });
    },
    checkNullData() {
      const self = this;
      const tempAou = {...self.aaut.aou}
      if (JSON.stringify(tempAou).includes('null')) {
        alert('AOU에서 아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      const tempQom = {...self.aaut.qom}
      if (JSON.stringify(tempQom).includes('null')) {
        alert('QOM에서 아직 완료되지 않은 항목이 있습니다.')
        // const isAouNull = JSON.stringify(tempAou).includes('null')
        // const isQomNull = JSON.stringify(tempQom).includes('null')
        // let isNull = ''
        //
        // if (!isAouNull) isNull = 'AOU'
        // else if (!isQomNull) isNull = 'QOM'
        //
        // if (isNull === 'AOU') {
        //   if (isQomNull) {
        //     const result = confirm('QOM에서 아직 완료되지 않은 항목이 있습니다. ' + '\n평가를 마치겠습니까?');
        //     if (result) return false
        //     else return true;
        //   }
        // } else if (isNull === 'QOM') {
        //   if (isAouNull) {
        //     const result = confirm('AOU에서 아직 완료되지 않은 항목이 있습니다. ' + '\n평가를 마치겠습니까?')
        //     if (result) return false
        //     else return true;
        //   }
        // } else {
        //   alert('아무것도 완료되지 않았습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;
      self.total.aou.answer = 0;
      self.total.aou.score = 0;
      self.total.aou.avg = 0;
      const aouArrayList = Object.values(self.aaut.aou);
      let nullCountA = aouArrayList.filter((element) => element == null).length;
      if (nullCountA !== 14) {
        for (let i = 0; i < aouArrayList.length; i++) {
          if (aouArrayList[i] !== 'NT') {
            self.total.aou.answer++;
            self.total.aou.score = aouArrayList[i] + self.total.aou.score;
          }
        }
        if (self.total.aou.score !== 0) {
          self.total.aou.avg = (self.total.aou.score / self.total.aou.answer).toFixed(1);
        }

        // console.log('총 대답갯수 : ' + self.total.aou.answer);
        // console.log('총 점수 : ' + self.total.aou.score);
        // console.log('총 평균 : ' + self.total.aou.avg);
      }

      self.total.qom.answer = 0;
      self.total.qom.score = 0;
      self.total.qom.avg = 0;
      const qomArrayList = Object.values(self.aaut.qom);
      let nullCountB = qomArrayList.filter((element) => element == null).length;
      if (nullCountB !== 14) {
        for (let i = 0; i < qomArrayList.length; i++) {
          if (qomArrayList[i] !== 'NT') {
            self.total.qom.answer++;
            self.total.qom.score = qomArrayList[i] + self.total.qom.score;
          }
        }
        if (self.total.qom.score !== 0) {
          self.total.qom.avg = (self.total.qom.score / self.total.qom.answer).toFixed(1)
        }

        // console.log('총 대답갯수 : ' + self.total.qom.answer);
        // console.log('총 점수 : ' + self.total.qom.score);
        // console.log('총 평균 : ' + self.total.qom.avg);
      }
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      await self.calculator();
      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'aaut', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.form-container {
  display: flex;
  align-items: center;
}

.form-group {
  width: 570px;
}

.form-group > input {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E1E3E6;
}

input::placeholder {
  color: #B1B1B1;
}

.form-group > label {
  color: #0069CA;
  font-weight: 500;
  font-size: 16px;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  margin: 0;
}

.tab-frame {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
}

.tab-nav {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E1E3E6;
}

.tab-nav-item {
  flex: 1;
  position: relative;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.001em;
  color: #272833;
  font-style: normal;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
}

.active {
  background: #F1F6FD;
  border-bottom: 2px solid #0069CA;
  color: #272833;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}
</style>
