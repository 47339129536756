export default [
    {
        label: '환자 번호',
        field: 'patientNo',
        sort: true
    },
    {
        label: '평가 회차',
        field: 'round',
        sort: true
    },
    {
        label: '아이디',
        field: 'id',
        sort: true
    },
    {
        label: '환자명',
        field: 'name',
        sort: true
    },
    {
        label: 'FastSpeed 평균속도',
        field: 'tmwtFastAvgVelocity',
        sort: true
    },
    {
        label: 'self_selected 평균속도',
        field: 'tmwtSSAvgVelocity',
        sort: true
    },
    {
        label: '마지막 평가일',
        field: 'regDate',
        sort: true
    },
    {
        label: '마지막 진단명',
        field: 'diagnosis',
        sort: true
    },
];
