<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">SIS(Stroke Impact Scale)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-guide-frame">
      <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
      <p class="paper-guide">뇌졸중 영향 척도를 체크할 수 있습니다.</p>
    </div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <div>
          <table class="paper-table">
            <tbody>
            <tr>
              <td colspan="12" class="paper-topic">다음은 (본인의) 뇌졸중 결과로 야기된 신체 문제에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">1. 지난 1주일 동안, 뇌졸중으로 인해 손상 받은 신체 부위의 힘은 어느 정도입니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 마비측 팔의 힘</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_1_1" v-model="sis.a_1_1" :value="5">
                      <span class="checkmark step-blue-2">매우 강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_1" v-model="sis.a_1_1" :value="4">
                      <span class="checkmark step-blue-1">강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_1" v-model="sis.a_1_1" :value="3">
                      <span class="checkmark">보통</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_1" v-model="sis.a_1_1" :value="2">
                      <span class="checkmark step-gray-1">약함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_1" v-model="sis.a_1_1" :value="1">
                      <span class="checkmark step-gray-2">전혀 없음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 마비측 손의 잡는 힘</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_1_2" v-model="sis.a_1_2" :value="5">
                      <span class="checkmark step-blue-2">매우 강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_2" v-model="sis.a_1_2" :value="4">
                      <span class="checkmark step-blue-1">강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_2" v-model="sis.a_1_2" :value="3">
                      <span class="checkmark">보통</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_2" v-model="sis.a_1_2" :value="2">
                      <span class="checkmark step-gray-1">약함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_2" v-model="sis.a_1_2" :value="1">
                      <span class="checkmark step-gray-2">전혀 없음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 마비측 다리의 힘</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_1_3" v-model="sis.a_1_3" :value="5">
                      <span class="checkmark step-blue-2">매우 강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_3" v-model="sis.a_1_3" :value="4">
                      <span class="checkmark step-blue-1">강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_3" v-model="sis.a_1_3" :value="3">
                      <span class="checkmark">보통</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_3" v-model="sis.a_1_3" :value="2">
                      <span class="checkmark step-gray-1">약함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_3" v-model="sis.a_1_3" :value="1">
                      <span class="checkmark step-gray-2">전혀 없음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 마비측 발과 발목이 힘</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_1_4" v-model="sis.a_1_4" :value="5">
                      <span class="checkmark step-blue-2">매우 강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_4" v-model="sis.a_1_4" :value="4">
                      <span class="checkmark step-blue-1">강함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_4" v-model="sis.a_1_4" :value="3">
                      <span class="checkmark">보통</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_4" v-model="sis.a_1_4" :value="2">
                      <span class="checkmark step-gray-1">약함</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_1_4" v-model="sis.a_1_4" :value="1">
                      <span class="checkmark step-gray-2">전혀 없음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 (본인의) 기억과 생각에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">2. 지난 1주일 동안, 다음 항목에서 얼마나 어려움이 있었습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 사람들이 방금 말한 것 기억하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_1" v-model="sis.a_2_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_1" v-model="sis.a_2_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_1" v-model="sis.a_2_1" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_1" v-model="sis.a_2_1" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_1" v-model="sis.a_2_1" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 전날에 일어난 일들 기억하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_2" v-model="sis.a_2_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_2" v-model="sis.a_2_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_2" v-model="sis.a_2_2" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_2" v-model="sis.a_2_2" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_2" v-model="sis.a_2_2" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 해야 할 일 기억하기(예. 약속 일정 지키기, 약 복용하기)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_3" v-model="sis.a_2_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_3" v-model="sis.a_2_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_3" v-model="sis.a_2_3" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_3" v-model="sis.a_2_3" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_3" v-model="sis.a_2_3" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 요일 기억하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_4" v-model="sis.a_2_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_4" v-model="sis.a_2_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_4" v-model="sis.a_2_4" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_4" v-model="sis.a_2_4" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_4" v-model="sis.a_2_4" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 집중하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_5" v-model="sis.a_2_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_5" v-model="sis.a_2_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_5" v-model="sis.a_2_5" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_5" v-model="sis.a_2_5" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_5" v-model="sis.a_2_5" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">6) 빠르게 생각하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_6" v-model="sis.a_2_6" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_6" v-model="sis.a_2_6" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_6" v-model="sis.a_2_6" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_6" v-model="sis.a_2_6" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_6" v-model="sis.a_2_6" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">7) 일상적인 문제 해결하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_2_7" v-model="sis.a_2_7" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_7" v-model="sis.a_2_7" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_7" v-model="sis.a_2_7" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_7" v-model="sis.a_2_7" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_2_7" v-model="sis.a_2_7" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 뇌졸중이 발병한 후 (본인의) 기분 변화와 감정 조절 능력에 대해 어떻게 느끼는지에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">3. 지난 1주일 동안, 본인은 얼마나 자주 다음과 같이 느꼈습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 슬프다고 느낌</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_1" v-model="sis.a_3_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_1" v-model="sis.a_3_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_1" v-model="sis.a_3_1" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_1" v-model="sis.a_3_1" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_1" v-model="sis.a_3_1" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 주변에 아무도 없다고 느낌</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_2" v-model="sis.a_3_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_2" v-model="sis.a_3_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_2" v-model="sis.a_3_2" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_2" v-model="sis.a_3_2" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_2" v-model="sis.a_3_2" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 본인이 다른 사람에게 부담이 된다고 느낌</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_3" v-model="sis.a_3_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_3" v-model="sis.a_3_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_3" v-model="sis.a_3_3" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_3" v-model="sis.a_3_3" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_3" v-model="sis.a_3_3" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 본인은 기대하는 것이 아무것도 없다고 느낌</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_4" v-model="sis.a_3_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_4" v-model="sis.a_3_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_4" v-model="sis.a_3_4" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_4" v-model="sis.a_3_4" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_4" v-model="sis.a_3_4" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 자신의 실수에 대해 자책함</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_5" v-model="sis.a_3_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_5" v-model="sis.a_3_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_5" v-model="sis.a_3_5" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_5" v-model="sis.a_3_5" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_5" v-model="sis.a_3_5" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">6) 이전과 같이 뭔가를 즐길 수 있음</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_6" v-model="sis.a_3_6" :value="1">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_6" v-model="sis.a_3_6" :value="2">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_6" v-model="sis.a_3_6" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_6" v-model="sis.a_3_6" :value="4">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_6" v-model="sis.a_3_6" :value="5">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">7) 상당히 불안함</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_7" v-model="sis.a_3_7" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_7" v-model="sis.a_3_7" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_7" v-model="sis.a_3_7" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_7" v-model="sis.a_3_7" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_7" v-model="sis.a_3_7" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">8) 삶이 가치가 있다고 느낌</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_8" v-model="sis.a_3_8" :value="1">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_8" v-model="sis.a_3_8" :value="2">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_8" v-model="sis.a_3_8" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_8" v-model="sis.a_3_8" :value="4">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_8" v-model="sis.a_3_8" :value="5">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">9) 적어도 하루에 한 번 이상 미소 짓기와 웃기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_3_9" v-model="sis.a_3_9" :value="1">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_9" v-model="sis.a_3_9" :value="2">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_9" v-model="sis.a_3_9" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_9" v-model="sis.a_3_9" :value="4">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_3_9" v-model="sis.a_3_9" :value="5">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 (본인이) 읽은 것을 이해하고 대화에서 들은 것을 이해하는 능력 및 다른 사람과 의사소통을 하는 능력에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">4. 지난 1주일 동안, 다음 항목에서 얼마나 어려움이 있었습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 본인 앞에 있는 사람의 이름 부르기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_1" v-model="sis.a_4_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_1" v-model="sis.a_4_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_1" v-model="sis.a_4_1" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_1" v-model="sis.a_4_1" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_1" v-model="sis.a_4_1" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 대화하는 동안 본인에게 이야기 한 내용 이해하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_2" v-model="sis.a_4_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_2" v-model="sis.a_4_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_2" v-model="sis.a_4_2" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_2" v-model="sis.a_4_2" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_2" v-model="sis.a_4_2" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 질문에 답하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_3" v-model="sis.a_4_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_3" v-model="sis.a_4_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_3" v-model="sis.a_4_3" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_3" v-model="sis.a_4_3" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_3" v-model="sis.a_4_3" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 사물의 이름을 정확하게 말하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_4" v-model="sis.a_4_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_4" v-model="sis.a_4_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_4" v-model="sis.a_4_4" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_4" v-model="sis.a_4_4" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_4" v-model="sis.a_4_4" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 그룹의 사람들과의 대화에 참여하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_5" v-model="sis.a_4_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_5" v-model="sis.a_4_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_5" v-model="sis.a_4_5" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_5" v-model="sis.a_4_5" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_5" v-model="sis.a_4_5" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">6) 전화로 통화하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_6" v-model="sis.a_4_6" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_6" v-model="sis.a_4_6" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_6" v-model="sis.a_4_6" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_6" v-model="sis.a_4_6" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_6" v-model="sis.a_4_6" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">7) 정확한 전화번호를 선택하고 번호를 눌러 다른 사람에게 전화하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_4_7" v-model="sis.a_4_7" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_7" v-model="sis.a_4_7" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_7" v-model="sis.a_4_7" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_7" v-model="sis.a_4_7" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_4_7" v-model="sis.a_4_7" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 (본인이) 일상생활에서 수행하는 활동에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">5. 지난 2주일 동안, 다음 항목에서 얼마나 어려움이 있었습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 수저 사용하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_1" v-model="sis.a_5_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_1" v-model="sis.a_5_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_1" v-model="sis.a_5_1" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_1" v-model="sis.a_5_1" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_1" v-model="sis.a_5_1" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 윗옷 입기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_2" v-model="sis.a_5_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_2" v-model="sis.a_5_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_2" v-model="sis.a_5_2" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_2" v-model="sis.a_5_2" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_2" v-model="sis.a_5_2" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 씻기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_3" v-model="sis.a_5_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_3" v-model="sis.a_5_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_3" v-model="sis.a_5_3" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_3" v-model="sis.a_5_3" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_3" v-model="sis.a_5_3" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 발톱 깎기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_4" v-model="sis.a_5_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_4" v-model="sis.a_5_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_4" v-model="sis.a_5_4" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_4" v-model="sis.a_5_4" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_4" v-model="sis.a_5_4" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 화장실에 제 시간에 가기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_5" v-model="sis.a_5_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_5" v-model="sis.a_5_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_5" v-model="sis.a_5_5" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_5" v-model="sis.a_5_5" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_5" v-model="sis.a_5_5" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">6) 소변 조절(실수하지 않고)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_6" v-model="sis.a_5_6" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_6" v-model="sis.a_5_6" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_6" v-model="sis.a_5_6" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_6" v-model="sis.a_5_6" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_6" v-model="sis.a_5_6" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">7) 대변 조절(실수하지 않고)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_7" v-model="sis.a_5_7" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_7" v-model="sis.a_5_7" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_7" v-model="sis.a_5_7" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_7" v-model="sis.a_5_7" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_7" v-model="sis.a_5_7" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">8) 간단한 집안일(예, 먼지 털기, 이부자리 정리, 쓰레기 버리기, 설거지)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_8" v-model="sis.a_5_8" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_8" v-model="sis.a_5_8" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_8" v-model="sis.a_5_8" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_8" v-model="sis.a_5_8" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_8" v-model="sis.a_5_8" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">9) 쇼핑하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_9" v-model="sis.a_5_9" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_9" v-model="sis.a_5_9" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_9" v-model="sis.a_5_9" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_9" v-model="sis.a_5_9" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_9" v-model="sis.a_5_9" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">10) 힘든 집안일(예, 청소하기, 빨래하기 또는 마당 관리)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_5_10" v-model="sis.a_5_10" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_10" v-model="sis.a_5_10" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_10" v-model="sis.a_5_10" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_10" v-model="sis.a_5_10" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_5_10" v-model="sis.a_5_10" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 집과 지역사회에서 (본인의) 이동능력에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">6. 지난 2주일 동안, 다음 항목에서 얼마나 어려움이 있었습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 균형을 잃지 않고 앉아있기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_1" v-model="sis.a_6_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_1" v-model="sis.a_6_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_1" v-model="sis.a_6_1" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_1" v-model="sis.a_6_1" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_1" v-model="sis.a_6_1" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 균형을 잃지 않고 서 있기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_2" v-model="sis.a_6_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_2" v-model="sis.a_6_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_2" v-model="sis.a_6_2" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_2" v-model="sis.a_6_2" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_2" v-model="sis.a_6_2" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 균형을 잃지 않고 걷기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_3" v-model="sis.a_6_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_3" v-model="sis.a_6_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_3" v-model="sis.a_6_3" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_3" v-model="sis.a_6_3" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_3" v-model="sis.a_6_3" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 침대에서 의자로 이동하기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_4" v-model="sis.a_6_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_4" v-model="sis.a_6_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_4" v-model="sis.a_6_4" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_4" v-model="sis.a_6_4" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_4" v-model="sis.a_6_4" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 한 구획/블록 걷기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_5" v-model="sis.a_6_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_5" v-model="sis.a_6_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_5" v-model="sis.a_6_5" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_5" v-model="sis.a_6_5" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_5" v-model="sis.a_6_5" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">6) 빠르게 걷기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_6" v-model="sis.a_6_6" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_6" v-model="sis.a_6_6" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_6" v-model="sis.a_6_6" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_6" v-model="sis.a_6_6" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_6" v-model="sis.a_6_6" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">7) 계단으로 한 층 올라가기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_7" v-model="sis.a_6_7" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_7" v-model="sis.a_6_7" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_7" v-model="sis.a_6_7" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_7" v-model="sis.a_6_7" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_7" v-model="sis.a_6_7" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">8) 계단으로 여러 층 올라가기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_8" v-model="sis.a_6_8" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_8" v-model="sis.a_6_8" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_8" v-model="sis.a_6_8" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_8" v-model="sis.a_6_8" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_8" v-model="sis.a_6_8" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">9) 차에 타고 내리기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_6_9" v-model="sis.a_6_9" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_9" v-model="sis.a_6_9" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_9" v-model="sis.a_6_9" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_9" v-model="sis.a_6_9" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_6_9" v-model="sis.a_6_9" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 뇌졸중으로 인해 영향을 가장 많이 받은 손을 사용하는 능력에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">7. 지난 2주일 동안, 다음 항목에서 뇌졸중으로 인해 손상 받은 손을 사용하는 데 얼마나 어려움이 있었습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 문손잡이 돌리기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_7_1" v-model="sis.a_7_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_1" v-model="sis.a_7_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_1" v-model="sis.a_7_1" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_1" v-model="sis.a_7_1" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_1" v-model="sis.a_7_1" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 캔이나 병 열기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_7_2" v-model="sis.a_7_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_2" v-model="sis.a_7_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_2" v-model="sis.a_7_2" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_2" v-model="sis.a_7_2" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_2" v-model="sis.a_7_2" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 신발 끈 매기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_7_3" v-model="sis.a_7_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_3" v-model="sis.a_7_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_3" v-model="sis.a_7_3" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_3" v-model="sis.a_7_3" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_3" v-model="sis.a_7_3" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 넥타이 매기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_7_4" v-model="sis.a_7_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_4" v-model="sis.a_7_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_4" v-model="sis.a_7_4" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_4" v-model="sis.a_7_4" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_4" v-model="sis.a_7_4" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 동전 집기</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_7_5" v-model="sis.a_7_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>어려움 없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_5" v-model="sis.a_7_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_5" v-model="sis.a_7_5" :value="3">
                      <span class="checkmark">다소<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_5" v-model="sis.a_7_5" :value="2">
                      <span class="checkmark step-gray-1">매우<br/>어려움 있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_7_5" v-model="sis.a_7_5" :value="1">
                      <span class="checkmark step-gray-2">극도의<br/>어려움 있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">다음은 본인이 평소에 했던 활동에 참여하는 능력과 본인에게 의미 있고 삶의 목적을 찾는 것을 도와주는 데 뇌졸중이 얼마나 영향을 주는지에 대한 질문입니다.</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">8. 지난 4주일 동안, 다음 항목에서 얼마나 제한이 있었습니까?</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">1) 직업(유급, 자원봉사 등)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_1" v-model="sis.a_8_1" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_1" v-model="sis.a_8_1" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_1" v-model="sis.a_8_1" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_1" v-model="sis.a_8_1" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_1" v-model="sis.a_8_1" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">2) 사회 활동</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_2" v-model="sis.a_8_2" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_2" v-model="sis.a_8_2" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_2" v-model="sis.a_8_2" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_2" v-model="sis.a_8_2" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_2" v-model="sis.a_8_2" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">3) 정적인 여가 활동(공예, 독서)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_3" v-model="sis.a_8_3" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_3" v-model="sis.a_8_3" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_3" v-model="sis.a_8_3" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_3" v-model="sis.a_8_3" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_3" v-model="sis.a_8_3" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">4) 활동적인 여가 활동(스포츠, 소풍, 여행)</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_4" v-model="sis.a_8_4" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_4" v-model="sis.a_8_4" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_4" v-model="sis.a_8_4" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_4" v-model="sis.a_8_4" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_4" v-model="sis.a_8_4" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">5) 가족이나 친구로서의 역할</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_5" v-model="sis.a_8_5" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_5" v-model="sis.a_8_5" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_5" v-model="sis.a_8_5" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_5" v-model="sis.a_8_5" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_5" v-model="sis.a_8_5" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">6) 영성 활동이나 종교 활동에 참여</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_6" v-model="sis.a_8_6" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_6" v-model="sis.a_8_6" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_6" v-model="sis.a_8_6" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_6" v-model="sis.a_8_6" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_6" v-model="sis.a_8_6" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">7) 원하는 대로 본인의 생활을 조절하는 능력</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_7" v-model="sis.a_8_7" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_7" v-model="sis.a_8_7" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_7" v-model="sis.a_8_7" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_7" v-model="sis.a_8_7" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_7" v-model="sis.a_8_7" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6" class="paper-question">8) 다른 사람을 도와줄 수 있는 능력</td>
              <td colspan="6">
                <div class="radio-flex">
                  <div class="radio-group">
                    <label class="container">
                      <input type="radio" name="a_8_8" v-model="sis.a_8_8" :value="5">
                      <span class="checkmark step-blue-2">전혀<br/>없음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_8" v-model="sis.a_8_8" :value="4">
                      <span class="checkmark step-blue-1">약간<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_8" v-model="sis.a_8_8" :value="3">
                      <span class="checkmark">다소<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_8" v-model="sis.a_8_8" :value="2">
                      <span class="checkmark step-gray-1">대부분<br/>있음</span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_8_8" v-model="sis.a_8_8" :value="1">
                      <span class="checkmark step-gray-2">항상<br/>있음</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="paper-topic">뇌졸중 회복</td>
            </tr>
            <tr>
              <td colspan="12" class="paper-item">9. 다음 0에서 100까지의 척도에서 100은 완전한 회복을 나타나고 0은 전혀 회복되지 않은 것을 나타냅니다.<br/>어느 정도 뇌졸중이 회복되었는지를 다음의 척도에 표시하세요.</td>
            </tr>
            <tr>
              <td colspan="6" class="paper-measure" style="color: #E14F4E;">
                <p>회복되지 않음</p>
              </td>
              <td colspan="6" class="paper-measure" style="color: #0069CA; text-align: right; ;padding-right: 20px;">
                <p>완전히 회복됨</p>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="radio-flex">
                  <div class="radio-round-group">
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="0">
                      <span class="checkmark-round">0</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="5">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="10">
                      <span class="checkmark-round">10</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="15">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="20">
                      <span class="checkmark-round">20</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="25">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="30">
                      <span class="checkmark-round">30</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="35">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="40">
                      <span class="checkmark-round">40</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="45">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="50">
                      <span class="checkmark-round">50</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="55">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="60">
                      <span class="checkmark-round">60</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="65">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="70">
                      <span class="checkmark-round">70</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="75">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="80">
                      <span class="checkmark-round">80</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="85">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="90">
                      <span class="checkmark-round">90</span>
                    </label>
                    <label class="container xs-container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="95">
                      <span class="checkmark-round xs-mark"></span>
                    </label>
                    <label class="container">
                      <input type="radio" name="a_9_1" v-model="sis.a_9_1" :value="100">
                      <span class="checkmark-round">100</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <SisResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :sis="sis"/>
  </div>
</template>

<script>

import {mdbScrollbar} from "mdbvue";
import SisResultDialog from "./SisResultDialog";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "StrokeImpactScale",
  components: {SisResultDialog, mdbScrollbar},
  props: {
    fbDocId: String,
    sis: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: {
        sum: 0,
        avg: 0,
      },
      tools: {
        sis: {}
      },
      isShowModal: false,
    };
  },
  methods: {
    async tempSave() {
      const self = this;
      await self.calculator();

      self.tools.sis = self.sis;
      self.tools.sis['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'sis', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (JSON.stringify(self.sis).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;
      self.total.sum =
          self.sis.a_1_1 + self.sis.a_1_2 + self.sis.a_1_3 + self.sis.a_1_4 +
          self.sis.a_2_1 + self.sis.a_2_2 + self.sis.a_2_3 + self.sis.a_2_4 + self.sis.a_2_5 + self.sis.a_2_6 + self.sis.a_2_7 +
          self.sis.a_3_1 + self.sis.a_3_2 + self.sis.a_3_3 + self.sis.a_3_4 + self.sis.a_3_5 + self.sis.a_3_6 + self.sis.a_3_7 + self.sis.a_3_8 + self.sis.a_3_9 +
          self.sis.a_4_1 + self.sis.a_4_2 + self.sis.a_4_3 + self.sis.a_4_4 + self.sis.a_4_5 + self.sis.a_4_6 + self.sis.a_4_7 +
          self.sis.a_5_1 + self.sis.a_5_2 + self.sis.a_5_3 + self.sis.a_5_4 + self.sis.a_5_5 + self.sis.a_5_6 + self.sis.a_5_7 + self.sis.a_5_8 + self.sis.a_5_9 + self.sis.a_5_10 +
          self.sis.a_6_1 + self.sis.a_6_2 + self.sis.a_6_3 + self.sis.a_6_4 + self.sis.a_6_5 + self.sis.a_6_6 + self.sis.a_6_7 + self.sis.a_6_8 + self.sis.a_6_9 +
          self.sis.a_7_1 + self.sis.a_7_2 + self.sis.a_7_3 + self.sis.a_7_4 + self.sis.a_7_5 +
          self.sis.a_8_1 + self.sis.a_8_2 + self.sis.a_8_3 + self.sis.a_8_4 + self.sis.a_8_5 + self.sis.a_8_6 + self.sis.a_8_7 + self.sis.a_8_8 +
          self.sis.a_9_1;
      self.total.avg = (self.total.sum / 60).toFixed(1)
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      await self.calculator();
      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'sis', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 56px;
  font-size: 16px;
}

.td-answer {
  text-align: center;
  font-weight: 500;
}

.td-bk {
  background: #F8F8FA;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  height: 570px;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.radio-flex {
  display: flex;
  align-items: center;
}

.radio-group {
  width: 510px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.radio-round-group {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container {
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px 0 0;
  padding: 0 !important;
  cursor: pointer;
}

.container:first-child {
  margin-left: 10px;
}


/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 46px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #E1E3E6;
  line-height: 16px;
  text-align: center;
  color: #B1B1B1;
}

.checkmark-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #F8F8FA;
  border-radius: 100px;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
}

.xs-mark {
  width: 30px !important;
  height: 30px !important;
  font-size: 16px !important;
  background: #fff !important;
}

.step-blue-2 {
  background: #E9F2FD;
}

.step-blue-1 {
  background: #F1F6FD;
}

.step-gray-1 {
  background: #F8F8FA;
}

.step-gray-2 {
  background: #EDEDF1;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0069CA;
  color: #fff;
  border: 2px solid #0069CA;
}

.container input:checked ~ .checkmark-round {
  background-color: #0069CA;
  color: #fff;
  border: 2px solid #0069CA;
}

.container input:checked ~ .xs-mark {
  background-color: #fff;
  color: #0069CA;
  border: 2px solid #0069CA;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
}


.paper-item {
  padding-left: 20px;
  font-weight: 500;
  background: #F8F8FA;
}

.paper-measure {
  padding-left: 20px;
  font-weight: 500;
  background: #fff;
}

.paper-question {
  padding-left: 30px;
}


hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}

</style>
