<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">WMFT(Wolf Motor Function Test)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-frame">
      <div class="paper-guide-frame">
        <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
        <p class="paper-guide">손상측, 비손상측 둘중에 하나 혹은 둘다 측정할 수 있습니다.</p>
      </div>
      <div class="tab-frame">
        <div class="tab-nav">
          <div class="tab-nav-item" :class="{active : tabIndex === 0}" @click="tabIndex = 0">
            More-affected
          </div>
          <div class="tab-nav-item" :class="{active : tabIndex === 1}" @click="tabIndex = 1">
            Less-affected
          </div>
        </div>
        <div class="tab-item" v-if="tabIndex === 0">
          <WmftMoreAffected :ma="wmft.ma"/>
        </div>
        <div class="tab-item" v-else>
          <WmftLessAffected :la="wmft.la"/>
        </div>
      </div>
    </div>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <WmftResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :wmft="wmft"/>
  </div>
</template>

<script>
import WmftResultDialog from "./WmftResultDialog";
import WmftMoreAffected from "./WmftMoreAffected";
import WmftLessAffected from "./WmftLessAffected";
import {firebase} from "../../../../../../firebase/firebaseConfig";

export default {
  name: "WolfMotorFunction",
  components: {WmftLessAffected, WmftMoreAffected, WmftResultDialog,},
  props: {
    fbDocId: String,
    wmft: Object,
    isEdit: Boolean
  },
  data() {
    return {
      tabIndex: -1,
      fbCollection: 'evaluations',
      total: {
        ma: {
          answer: 0,
          score: 0,
          avg: 0,
        },
        la: {
          answer: 0,
          score: 0,
          avg: 0,
        }
      },
      tools: {
        wmft: {}
      },
      isShowModal: false,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const self = this;
      self.tabIndex = 0
    },
    async tempSave() {
      const self = this;
      if (!self.calculator()) {
        return
      }

      self.tools.wmft = self.wmft;
      self.tools.wmft['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'wmft', 1)
          });
    },
    checkNullData() {
      const self = this;
      let nullList = []
      let nullListKg = []
      let nullListPw = []
      // if (self.tabIndex === 0) {
        nullList = Object.values(self.wmft.ma);
        nullListKg = Object.values(self.wmft.ma.kg);
        nullListPw = Object.values(self.wmft.ma.pw);

        if (nullList.filter(element => typeof element === "number").length < 15 || nullListKg.filter(element => typeof element === "number").length < 3 || nullListPw.filter(element => typeof element === "number").length < 3) {
          alert('More-affected 에서 아직 완료되지 않은 항목이 있습니다.')
          return true;
        }

        if (nullList.filter(element => element < 0).length > 0 || nullListKg.filter(element => element < 0).length > 0 || nullListPw.filter(element => element < 0).length > 0) {
          alert('0 미만으로 내려갈 수 없습니다.')
          return true;
        }
      // } else if (self.tabIndex === 1) {
      //   nullList = Object.values(self.wmft.la);
      //   nullListKg = Object.values(self.wmft.la.kg);
      //   nullListPw = Object.values(self.wmft.la.pw);
      //
      //   if (nullList.filter(element => typeof element === "number").length < 15 || nullListKg.filter(element => typeof element === "number").length < 3 || nullListPw.filter(element => typeof element === "number").length < 3) {
      //     alert('Less-affected 에서 아직 완료되지 않은 항목이 있습니다.')
      //     return true;
      //   }
      //
      //   if (nullList.filter(element => element < 0).length > 0 || nullListKg.filter(element => element < 0).length > 0 || nullListPw.filter(element => element < 0).length > 0) {
      //     alert('0 미만으로 내려갈 수 없습니다.')
      //     return true;
      //   }
      //
      // }

      return false;
    },
    calculator() {
      const self = this;
      self.total.ma.answer = 0;
      self.total.ma.avg = 0;
      self.total.ma.score = 0;
      const maArrayList = Object.values(self.wmft.ma);
      for (let i = 0; i < maArrayList.length; i++) {
        if (!isNaN(maArrayList[i])) {
          if (maArrayList[i] > 120) {
            alert('120초를 초과 할 수 없습니다.')
            return false
          }

          self.total.ma.answer++;
          self.total.ma.score = maArrayList[i] + self.total.ma.score;
        }
      }
      self.total.ma.avg = (self.total.ma.score / self.total.ma.answer).toFixed(1);
      return true
    }
    ,
    async resultSave() {
      const self = this;
      self.tools.wmft = self.wmft;

      if (self.checkNullData()) {
        return
      }
      if (!self.calculator()) {
        return
      }
      self.showModal()
    }
    ,
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'wmft', 2)
        }
      }
    }
    ,
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.form-container {
  display: flex;
  align-items: center;
}

.form-group {
  width: 570px;
}

.form-group > input {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E1E3E6;
}

input::placeholder {
  color: #B1B1B1;
}

.form-group > label {
  color: #0069CA;
  font-weight: 500;
  font-size: 16px;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  margin: 0;
}

.tab-frame {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
}

.tab-nav {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E1E3E6;

}

.tab-nav-item {
  flex: 1;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.001em;
  color: #272833;
  font-style: normal;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
}

.active {
  background: #F1F6FD;
  border-bottom: 2px solid #0069CA;
  color: #272833;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}
</style>
