<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        평가를 이어서 진행 하시겠습니까?
      </h5>
    </div>
    <div class="modal-center">
      <p>완료되지 않은 평가를 이어 하실 수 있습니다.<br/>
        평가 완료 처리 시, 결과 저장이 완료된 것만 기록에 반영되고 평가가 종료됩니다.<br/>
      </p>
    </div>
    <div class="modal-footer">
      <button class="custom-btn-box-white btn-short" @click="btnDone">평가 완료 처리</button>
      <button class="custom-btn-box-white btn-cancellation" @click="btnClose">다음에 하기</button>
      <button class="primary-blue custom-btn-box btn-confirm" @click="btnContinue">평가 이어서 진행하기</button>
    </div>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import Progress from "@/components/Progress.vue";

export default {
  name: "EvaluationContinueDialog",
  components: {Progress},
  props: {
    selectPatientInfo: Object,
  },
  data() {
    return {
      fbCollection: 'evaluations',
      evaluationsData: {},
      isProgress: false
    }
  },
  methods: {
    async btnDone() {                 // 평가완료처리
      const self = this;
      self.isProgress = true
      const db = firebase.firestore();
      const batch = db.batch();

      let tools = [];
      let data = '';
      let patientKey = '';
      await db.collection(self.fbCollection)
          .doc(self.selectPatientInfo.fbDocId)          // 컬랙션 "evaluations"
          .get()
          .then((snapshot) => {
            self.evaluationsData = snapshot.data()
            const date = new Date(self.evaluationsData.regDate.seconds * 1000);
            self.evaluationsData.regDate = getDate(date);
            data = getDate(date)
            patientKey = self.evaluationsData.patientInfo.patientKey
            tools = Object.keys(self.evaluationsData.tools).filter(x => self.evaluationsData.tools[x].state === 2)
          });

      const data2 = {
        visitCount: firebase.firestore.FieldValue.increment(+1),
        diagnosis: self.evaluationsData.diseaseInfo.diagnosis,
        description: self.evaluationsData.diseaseInfo.description,
        lastDate: firebase.firestore.Timestamp.fromDate(new Date()),
        isProgress: false,
        evaluationId: ''
      }

      for (let i = 0; i < tools.length; i++) {
        data2['last' + tools[i]] = data;
      }

      const ref1 = await db.collection(self.fbCollection).doc(self.selectPatientInfo.fbDocId)
      batch.set(ref1, {state: 1,}, {merge: true});
      const ref2 = await db.collection('patients').doc(patientKey)
      batch.set(ref2, data2, {merge: true});
      batch.commit().then(() => {
        self.$emit('callBackModal', 10, true)
        self.isProgress = false
      });

    },

    async btnRemove() {
      const self = this;
      const db = firebase.firestore();
      const batch = db.batch();

      const data2 = {
        isProgress: false,
        evaluationId: ''
      }

      const ref1 = await db.collection(self.fbCollection).doc(self.selectPatientInfo.fbDocId)
      batch.delete(ref1);
      const ref2 = await db.collection('patients').doc(self.selectPatientInfo.patientKey)
      batch.set(ref2, data2, {merge: true});
      batch.commit().then(() => {
        self.$emit('callBackModal', 10, true)
      });
    },
    btnClose(){
      const self = this;
      self.$emit('callBackModal', 10, false)
    },
    btnContinue() {
      const self = this;
      self.$router.push(`/therapist/doEvaluation/${self.selectPatientInfo.fbDocId}`);
    },
  }
}
</script>

<style scoped>

.modal-container {
  position: fixed;
  width: 580px;
  height: 254px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
  border-radius: 7px;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 77px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px;
  background: #F8F8FA;
  white-space: nowrap;
}

.modal-center h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 5px;
}

.modal-center h5 strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-left: 13px;
  margin-bottom: 7px;
}

.modal-center p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.modal-footer {
  height: 73px;
  padding: 0 16px 0;
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.btn-cancellation {
  width: 119px;
  font-size: 16px;
  line-height: 36px;
  background-color: #A0B0C6;
  font-style: normal;
  font-weight: 500;
  border: 0 !important;
  letter-spacing: 0.0125em;
  color: #fff;
  margin: 0 8px 0 0 !important;
}

.btn-short {
  width: 137px;
  font-size: 16px;
  line-height: 36px;
  font-style: normal;
  font-weight: 500;
  border: 1px solid #E14F4E;
  letter-spacing: 0.0125em;
  color: #E14F4E;
  margin: 0 auto 0 0 !important;
}

.btn-confirm {
  width: 180px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}


</style>
