<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        평가 결과를 저장하시겠습니까?
      </h5>
    </div>
    <div class="modal-center">
      <p v-if="!isEdit">해당 평가 결과를 저장하고 완료 처리합니다. </p>
      <p v-else>해당 평가 결과를 수정하고 완료 처리합니다. </p>
    </div>
    <div class="modal-footer">
      <button class="custom-btn-box-white btn-short" @click="btnCancel">닫기</button>
      <button class="primary-blue custom-btn-box btn-confirm" @click="btnConfirm" v-if="!isEdit">평가 결과 저장</button>
      <button class="primary-blue custom-btn-box btn-confirm" @click="btnConfirm" v-else>평가 결과 수정</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "EvaluationStoreDialog",
  data() {
    return {
      isEdit: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.checkIsEdit()
    },
    checkIsEdit() {
      const self = this;
      if (localStorage.isEvaluationEdit) {
        console.log(localStorage.isEvaluationEdit)
        self.isEdit = true;
      }

    },
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', false)
    },
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal', true)
    }
  }
}
</script>

<style scoped>

strong {
  color: #E14F4E;
}

.modal-container {
  position: fixed;
  width: 580px;
  height: 232px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 77px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px;
  background: #F8F8FA;
  height: 82px;
}

.modal-center h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 5px;
}

.modal-center h5 strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-left: 13px;
  margin-bottom: 7px;
}

.modal-center p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  height: 73px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 16px;
  line-height: 36px;
  background-color: white;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.0125em;
  color: rgba(24, 24, 25, 0.9);
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #E14F4E;
  margin-bottom: 20px;
  margin-top: 8px;
}

.btn-confirm {
  width: 137px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}


</style>
