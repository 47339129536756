export default [
    {
        label: '평가 날짜',
        field: 'regDate',
        sort: false
    },
    {
        label: '진단명',
        field: 'diagnosis',
        sort: false
    },
    {
        label: '손상 발생일',
        field: 'injuredDate',
        sort: false
    },
    {
        label: '손잡이',
        field: 'usedHand',
        sort: false
    },
    {
        label: '손상 부위',
        field: 'injuredPart',
        sort: false
    },
    {
        label: '손상측',
        field: 'injuredSide',
        sort: false
    },
    {
        label: '평가 수정',
        field: 'tools',
        sort: false,
    },
    {
        label: '',
        field : 'active'
    },
];
