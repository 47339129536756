export default [
    {
        label: '환자 번호',
        field: 'patientNo',
        sort: true
    },
    {
        label: '아이디',
        field: 'id',
        sort: true
    },
    {
        label: '성명',
        field: 'name',
        sort: false
    },
    {
        label: '첫 등록 일자',
        field: 'regDate',
        sort: false
    },
    {
        label: '방문회차',
        field: 'visitCount',
        sort: false,
    },
    {
        label: '마지막 진단명',
        field: 'diagnosis',
        sort: false
    },
    {
        label: '',
        field: 'action',
        sort: false
    },


];
