export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'e_1_1',
        field: 'e_1_1',
    },
    {
        label: 'e_1_2',
        field: 'e_1_2',
    },
    {
        label: 'e_2_1',
        field: 'e_2_1',
    },
    {
        label: 'e_2_2',
        field: 'e_2_2',
    },
    {
        label: 'e_2_3',
        field: 'e_2_3',
    },
    {
        label: 'e_2_4',
        field: 'e_2_4',
    },
    {
        label: 'e_2_5',
        field: 'e_2_5',
    },
    {
        label: 'e_2_6',
        field: 'e_2_6',
    },
    {
        label: 'e_2_7',
        field: 'e_2_7',
    },
    {
        label: 'e_3_1',
        field: 'e_3_1',
    },
    {
        label: 'e_3_2',
        field: 'e_3_2',
    },
    {
        label: 'e_4_1',
        field: 'e_4_1',
    },
    {
        label: 'e_4_2',
        field: 'e_4_2',
    },
    {
        label: 'e_5_1',
        field: 'e_5_1',
    },
    {
        label: 'f_1_1',
        field: 'f_1_1',
    },
    {
        label: 'f_1_2',
        field: 'f_1_2',
    },
    {
        label: 'f_1_3',
        field: 'f_1_3',
    },


];
