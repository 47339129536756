<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Fugl-Meyer Lower Extremity (FM)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <div>
          <table class="paper-table">
            <tbody>
            <tr>
              <td colspan="12" class="paper-topic">E. 하지</td>
            </tr>
            <tr>
              <td colspan="10" class="paper-item">I. 운동반사 (바로누운자세)</td>
              <td class="td-answer td-bk">없음(0)</td>
              <td class="td-answer td-bk">유발됨(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="2"><strong>굽힘근</strong></td>
              <td class="paper-question" colspan="8">무릎, 굽힘근</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_1_1" class="custom-control-input" id="e_1_1_0" v-model="fm_l.e_1_1" :value="0">
                  <label class="custom-control-label" for="e_1_1_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_1_1" class="custom-control-input" id="e_1_1_2" v-model="fm_l.e_1_1" :value="2">
                  <label class="custom-control-label" for="e_1_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="2"><strong>폄근</strong></td>
              <td class="paper-question" colspan="8">무릎힘줄, 아킬레스힘줄 (최소 한 부위)</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_1_2" class="custom-control-input" id="e_1_2_0" v-model="fm_l.e_1_2" :value="0">
                  <label class="custom-control-label" for="e_1_2_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_1_2" class="custom-control-input" id="e_1_2_2" v-model="fm_l.e_1_2" :value="2">
                  <label class="custom-control-label" for="e_1_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="10">소계 I (최대 4점)</td>
              <td class="td-answer paper-total-score" colspan="2">{{ fm_l.e_1_1 + fm_l.e_1_2 }}</td>
            </tr>

            <tr>
              <td class="paper-item" colspan="9">II. 협동군(시너지) 내에서의 수의적 움직임 (바로누운자세)</td>
              <td class="td-answer td-bk">수행<br>못함 (0)</td>
              <td class="td-answer td-bk">부분적<br>수행(1)</td>
              <td class="td-answer td-bk">완전한<br>수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="5" rowspan="3"><strong>굽힘 협동작용</strong><br/>엉덩관절 최대 굽힘 (벌림/바깥돌림), 무릎과 발목 관절의 <br/>최대 굽힘 (능동적 무릎 굽힘 확인을 위한 먼쪽 힘줄 촉지)
              </td>
              <td colspan="4">엉덩관절굽힘</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_1" class="custom-control-input" id="e_2_1_0" v-model="fm_l.e_2_1" :value="0">
                  <label class="custom-control-label" for="e_2_1_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_1" class="custom-control-input" id="e_2_1_1" v-model="fm_l.e_2_1" :value="1">
                  <label class="custom-control-label" for="e_2_1_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_1" class="custom-control-input" id="e_2_1_2" v-model="fm_l.e_2_1" :value="2">
                  <label class="custom-control-label" for="e_2_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">무릎굽힘</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_2" class="custom-control-input" id="e_2_2_0" v-model="fm_l.e_2_2" :value="0">
                  <label class="custom-control-label" for="e_2_2_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_2" class="custom-control-input" id="e_2_2_1" v-model="fm_l.e_2_2" :value="1">
                  <label class="custom-control-label" for="e_2_2_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_2" class="custom-control-input" id="e_2_2_2" v-model="fm_l.e_2_2" :value="2">
                  <label class="custom-control-label" for="e_2_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">발목발등굽힘</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_3" class="custom-control-input" id="e_2_3_0" v-model="fm_l.e_2_3" :value="0">
                  <label class="custom-control-label" for="e_2_3_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_3" class="custom-control-input" id="e_2_3_1" v-model="fm_l.e_2_3" :value="1">
                  <label class="custom-control-label" for="e_2_3_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_3" class="custom-control-input" id="e_2_3_2" v-model="fm_l.e_2_3" :value="2">
                  <label class="custom-control-label" for="e_2_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="5" rowspan="4"><strong>폄 협동작용</strong><br/>굽힘 협동에서 엉덩관절 폄/모음, 무릎 폄과 발목관절 발바닥굽힘으로. 능동적 움직임을 확인하기 위해서 저항을 적용, 움직임과 근력을 모두 평가 (건측과 비교)
              </td>
              <td colspan="4">엉덩관절 폄</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_4" class="custom-control-input" id="e_2_4_0" v-model="fm_l.e_2_4" :value="0">
                  <label class="custom-control-label" for="e_2_4_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_4" class="custom-control-input" id="e_2_4_1" v-model="fm_l.e_2_4" :value="1">
                  <label class="custom-control-label" for="e_2_4_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_4" class="custom-control-input" id="e_2_4_2" v-model="fm_l.e_2_4" :value="2">
                  <label class="custom-control-label" for="e_2_4_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">모음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_5" class="custom-control-input" id="e_2_5_0" v-model="fm_l.e_2_5" :value="0">
                  <label class="custom-control-label" for="e_2_5_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_5" class="custom-control-input" id="e_2_5_1" v-model="fm_l.e_2_5" :value="1">
                  <label class="custom-control-label" for="e_2_5_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_5" class="custom-control-input" id="e_2_5_2" v-model="fm_l.e_2_5" :value="2">
                  <label class="custom-control-label" for="e_2_5_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">무릎 폄</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_6" class="custom-control-input" id="e_2_6_0" v-model="fm_l.e_2_6" :value="0">
                  <label class="custom-control-label" for="e_2_6_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_6" class="custom-control-input" id="e_2_6_1" v-model="fm_l.e_2_6" :value="1">
                  <label class="custom-control-label" for="e_2_6_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_6" class="custom-control-input" id="e_2_6_2" v-model="fm_l.e_2_6" :value="2">
                  <label class="custom-control-label" for="e_2_6_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">발목발바닥굽힘</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_7" class="custom-control-input" id="e_2_7_0" v-model="fm_l.e_2_7" :value="0">
                  <label class="custom-control-label" for="e_2_7_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_7" class="custom-control-input" id="e_2_7_1" v-model="fm_l.e_2_7" :value="1">
                  <label class="custom-control-label" for="e_2_7_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_2_7" class="custom-control-input" id="e_2_7_2" v-model="fm_l.e_2_7" :value="2">
                  <label class="custom-control-label" for="e_2_7_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>소계 II (최대 14점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3">{{ fm_l.e_2_1 + fm_l.e_2_2 + fm_l.e_2_3 + fm_l.e_2_4 + fm_l.e_2_5 + fm_l.e_2_6 + fm_l.e_2_7 }}</td>
            </tr>

            <tr>
              <td class="paper-item" colspan="9">Ⅲ. 혼합 협동군 내에서의 수의적 움직임<br/>(앉은 자세, 무릎은 침대/의자 끝에서 10cm)
              </td>
              <td class="td-answer td-bk">수행<br>못함 (0)</td>
              <td class="td-answer td-bk">부분적<br>수행(1)</td>
              <td class="td-answer td-bk">완전한<br>수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="3" rowspan="3"><strong>무릎 굽힘</strong><br/> 능동 또는 수동적으로 펴진 무릎에서</td>
              <td colspan="6">능동적 움직임 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_3_1" class="custom-control-input" id="e_3_1_0" v-model="fm_l.e_3_1" :value="0">
                  <label class="custom-control-label" for="e_3_1_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">90° 미만의 능동적 굽힘, 넙다리뒤인대 촉지</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_3_1" class="custom-control-input" id="e_3_1_1" v-model="fm_l.e_3_1" :value="1">
                  <label class="custom-control-label" for="e_3_1_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">90° 이상의 능동적 굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_3_1" class="custom-control-input" id="e_3_1_2" v-model="fm_l.e_3_1" :value="2">
                  <label class="custom-control-label" for="e_3_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="3" rowspan="3"><strong>발목 발등굽힘</strong><br/>건측과 비교</td>
              <td colspan="6">능동적 움직임 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_3_2" class="custom-control-input" id="e_3_2_0" v-model="fm_l.e_3_2" :value="0">
                  <label class="custom-control-label" for="e_3_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">제한된 발등굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_3_2" class="custom-control-input" id="e_3_2_1" v-model="fm_l.e_3_2" :value="1">
                  <label class="custom-control-label" for="e_3_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">완전한 발등굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_3_2" class="custom-control-input" id="e_3_2_2" v-model="fm_l.e_3_2" :value="2">
                  <label class="custom-control-label" for="e_3_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9">소계 Ⅲ (최대 4점)</td>
              <td class="td-answer paper-total-score" colspan="3">{{ fm_l.e_3_1 + fm_l.e_3_2 }}</td>
            </tr>

            <tr>
              <td class="paper-item" colspan="9">IV. 협동작용이 거의 없거나 없는 수의적 움직임<br/>(선 자세, 엉덩관절 0°)</td>
              <td class="td-answer td-bk">수행<br>못함 (0)</td>
              <td class="td-answer td-bk">부분적<br>수행(1)</td>
              <td class="td-answer td-bk">완전한<br>수행(2)</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="3" rowspan="3"><strong>무릎 90° 굽힘</strong><br/>엉덩관절 0°,<br/>균형 지지 허용됨</td>
              <td colspan="6">능동적 움직임 없음 또는 즉각적, 동시적 엉덩관절 굽혀짐</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_4_1" class="custom-control-input" id="e_4_1_0" v-model="fm_l.e_4_1" :value="0">
                  <label class="custom-control-label" for="e_4_1_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">90° 미만의 무릎 굽힘 그리고/또는 움직임 동안에 엉덩관절 굽혀짐</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_4_1" class="custom-control-input" id="e_4_1_1" v-model="fm_l.e_4_1" :value="1">
                  <label class="custom-control-label" for="e_4_1_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">동시적 엉덩관절의 굽혀짐 없는 최소 90° 무릎 굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_4_1" class="custom-control-input" id="e_4_1_2" v-model="fm_l.e_4_1" :value="2">
                  <label class="custom-control-label" for="e_4_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="3" rowspan="3"><strong>발목 발등굽힘</strong><br/>건측과 비교</td>
              <td colspan="6">능동적 움직임 없음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_4_2" class="custom-control-input" id="e_4_2_0" v-model="fm_l.e_4_2" :value="0">
                  <label class="custom-control-label" for="e_4_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">제한된 발등굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_4_2" class="custom-control-input" id="e_4_2_1" v-model="fm_l.e_4_2" :value="1">
                  <label class="custom-control-label" for="e_4_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">완전한 발등굽힘</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_4_2" class="custom-control-input" id="e_4_2_2" v-model="fm_l.e_4_2" :value="2">
                  <label class="custom-control-label" for="e_4_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9">소계 IV (최대 4점)</td>
              <td class="td-answer paper-total-score" colspan="3">{{ fm_l.e_4_1 + fm_l.e_4_2 }}</td>
            </tr>

            <tr>
              <td class="paper-item" colspan="9"><strong>V. 정상 운동 반사</strong>
                <br/>(바로누운자세, 항목 Ⅳ에서 4점 만점을 획득한 경우에만 평가한다. 건측과 비교)
              </td>
              <td class="td-answer td-bk">과항진(0)</td>
              <td class="td-answer td-bk">항진(1)<br/>(나타남)</td>
              <td class="td-answer td-bk">정상(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="3" rowspan="3"><strong>운동 반사</strong>
                <br/>무릎굽힘근, 무릎힘줄, 아킬레스힘줄
              </td>
              <td colspan="6">세 개 중 두 개의 반사가 현저히 과항진 되어있음</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_5_1" class="custom-control-input" id="e_5_3_0" v-model="fm_l.e_5_1" :value="0" disabled v-if="fm_l.e_4_1 + fm_l.e_4_2 !== 4">
                  <input type="radio" name="e_5_1" class="custom-control-input" id="e_5_3_0" v-model="fm_l.e_5_1" :value="0" v-else>
                  <label class="custom-control-label" for="e_5_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">하나의 반사가 현저히 과항진 되거나 또는 최소 두 개의 반사가 나타남</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_5_1" class="custom-control-input" id="e_5_3_1" v-model="fm_l.e_5_1" :value="0" disabled v-if="fm_l.e_4_1 + fm_l.e_4_2 !== 4">
                  <input type="radio" name="e_5_1" class="custom-control-input" id="e_5_3_1" v-model="fm_l.e_5_1" :value="1" v-else>
                  <label class="custom-control-label" for="e_5_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="6">최대 하나의 반사가 나타남, 과항진 반사 없음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="e_5_1" class="custom-control-input" id="e_5_3_2" v-model="fm_l.e_5_1" :value="0" disabled v-if="fm_l.e_4_1 + fm_l.e_4_2 !== 4">
                  <input type="radio" name="e_5_1" class="custom-control-input" id="e_5_3_2" v-model="fm_l.e_5_1" :value="2" v-else>
                  <label class="custom-control-label" for="e_5_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>소계 V (최대 2점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-if="fm_l.e_4_1 + fm_l.e_4_2 !== 4"><strong>0</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-else><strong>{{ fm_l.e_5_1 + 0 }}</strong></td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 E (최대 28점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-if="fm_l.e_4_1 + fm_l.e_4_2 !== 4"><strong>{{
                  fm_l.e_1_1 + fm_l.e_1_2 +
                  fm_l.e_2_1 + fm_l.e_2_2 + fm_l.e_2_3 + fm_l.e_2_4 + fm_l.e_2_5 + fm_l.e_2_6 + fm_l.e_2_7 +
                  fm_l.e_3_1 + fm_l.e_3_2 +
                  fm_l.e_4_1 + fm_l.e_4_2
                }}</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-else><strong>{{
                  fm_l.e_1_1 + fm_l.e_1_2 +
                  fm_l.e_2_1 + fm_l.e_2_2 + fm_l.e_2_3 + fm_l.e_2_4 + fm_l.e_2_5 + fm_l.e_2_6 + fm_l.e_2_7 +
                  fm_l.e_3_1 + fm_l.e_3_2 +
                  fm_l.e_4_1 + fm_l.e_4_2 +
                  fm_l.e_5_1
                }}</strong></td>
            </tr>

            <tr>
              <td class="paper-item td-bk" colspan="9">
                F.협응/속도<br/>
                (바로누운자세, 양다리로 한번의 시도 후, 눈은 감은 상태, 발뒤꿈치를 반대쪽 다리의 무릎뼈로, 가능한 빠르게 5회)
              </td>
              <td class="td-answer td-bk">현저함(0)</td>
              <td class="td-answer td-bk">약간(1)</td>
              <td class="td-answer td-bk">없음(2)</td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4"><strong>떨림</strong></td>
              <td colspan="5">최소 한번의 완전한 움직임</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_1" class="custom-control-input" id="f_1_1_0" v-model="fm_l.f_1_1" :value="0">
                  <label class="custom-control-label" for="f_1_1_0"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_1" class="custom-control-input" id="f_1_1_1" v-model="fm_l.f_1_1" :value="1">
                  <label class="custom-control-label" for="f_1_1_1"></label>
                </div>
              </td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_1" class="custom-control-input" id="f_1_1_2" v-model="fm_l.f_1_1" :value="2">
                  <label class="custom-control-label" for="f_1_1_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-question" colspan="4" rowspan="3"><strong>겨냥이상</strong></td>
              <td colspan="5">현저하거나 비체계적</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_2" class="custom-control-input" id="f_1_2_0" v-model="fm_l.f_1_2" :value="0">
                  <label class="custom-control-label" for="f_1_2_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5">약하거나 체계적</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_2" class="custom-control-input" id="f_1_2_1" v-model="fm_l.f_1_2" :value="1">
                  <label class="custom-control-label" for="f_1_2_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5">겨냥이상 없음</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_2" class="custom-control-input" id="f_1_2_2" v-model="fm_l.f_1_2" :value="2">
                  <label class="custom-control-label" for="f_1_2_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-item" colspan="4"></td>
              <td class="paper-item" colspan="5"></td>
              <td class="td-answer td-bk">≥ 6s</td>
              <td class="td-answer td-bk">2-5s</td>
              <td class="td-answer td-bk">＜ 2s</td>
            </tr>
            <tr>
              <td class="paper-question paper-padding" colspan="4" rowspan="3"><strong>시간</strong><br/>발뒤꿈치가 발목에서 시작해서 끝냄</td>
              <td colspan="5">건측보다 최소 6초 이상 느림</td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_3" class="custom-control-input" id="f_1_3_0" v-model="fm_l.f_1_3" :value="0">
                  <label class="custom-control-label" for="f_1_3_0"></label>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5">건측보다 2-5초 느림</td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_3" class="custom-control-input" id="f_1_3_1" v-model="fm_l.f_1_3" :value="1">
                  <label class="custom-control-label" for="f_1_3_1"></label>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="5">건측과의 차이가 2초 미만</td>
              <td class="td-answer"></td>
              <td class="td-answer"></td>
              <td class="td-answer">
                <div class="custom-control">
                  <input type="radio" name="f_1_3" class="custom-control-input" id="f_1_3_2" v-model="fm_l.f_1_3" :value="2">
                  <label class="custom-control-label" for="f_1_3_2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 F (최대 6점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-html="fm_l.f_1_1 + fm_l.f_1_2 + fm_l.f_1_3"></td>
            </tr>
            <tr>
              <td class="paper-total-score" colspan="9"><strong>총점 E-F(운동 기능) (최대 34점)</strong></td>
              <td class="td-answer paper-total-score" colspan="3" v-html="fm_l.e_1_1 + fm_l.e_1_2 +
                  fm_l.e_2_1 + fm_l.e_2_2 + fm_l.e_2_3 + fm_l.e_2_4 + fm_l.e_2_5 + fm_l.e_2_6 + fm_l.e_2_7 +
                  fm_l.e_3_1 + fm_l.e_3_2 +
                  fm_l.e_4_1 + fm_l.e_4_2 +
                  fm_l.e_5_1
                      +  fm_l.f_1_1 + fm_l.f_1_2 + fm_l.f_1_3"></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mdb-scrollbar>

    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <FuglMyerLowerResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :fm_l="fm_l"/>
  </div>

</template>

<script>
import {mdbScrollbar} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import FuglMyerLowerResultDialog from "@/views/therapist/menu/doEvaluation/paper/fm_l/FuglMyerLowerResultDialog";

export default {
  name: "FuglMyerLower",
  components: {
    FuglMyerLowerResultDialog,
    mdbScrollbar,
  },
  props: {
    fbDocId: String,
    fm_l: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: {
        totalE: 0,
        totalF: 0,
        totalAll: 0,
      },
      tools: {
        fm_l: {}
      },
      isShowModal: false,
    }
  },
  methods: {
    async tempSave() {
      const self = this;
      await self.calculator();

      self.tools.fm_l = self.fm_l;
      self.tools.fm_l['state'] = 1;
      self.tools.fm_l['totalE'] = null
      self.tools.fm_l['totalF'] = null
      self.tools.fm_l['totalAll'] = null
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'fm_l', 1)
          });
    },
    checkNullData() {
      const self = this;
      const tempData = {...self.fm_l}
      delete tempData.totalAll
      delete tempData.totalE
      delete tempData.totalF

      if (tempData.e_4_1 + tempData.e_4_2 < 4) {
        delete tempData.e_5_1
      }

      if (JSON.stringify(tempData).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;

      self.total.totalE = self.fm_l.e_1_1 + self.fm_l.e_1_2 +
          self.fm_l.e_2_1 + self.fm_l.e_2_2 + self.fm_l.e_2_3 + self.fm_l.e_2_4 + self.fm_l.e_2_5 + self.fm_l.e_2_6 + self.fm_l.e_2_7 +
          self.fm_l.e_3_1 + self.fm_l.e_3_2 +
          self.fm_l.e_4_1 + self.fm_l.e_4_2 +
          self.fm_l.e_5_1;
      self.total.totalF = self.fm_l.f_1_1 + self.fm_l.f_1_2 + self.fm_l.f_1_3;
      self.total.totalAll = self.total.totalE + self.total.totalF;

      if (self.fm_l.e_4_1 + self.fm_l.e_4_2 < 4) {
        self.fm_l.e_5_1 = 0;
      }

    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      await self.calculator();
      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'fm_l', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    },
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 48px;
  font-size: 16px;
}

.td-answer {
  text-align: center;
  font-weight: 500;
}

.td-bk {
  background: #F8F8FA;
  padding: 6px 0;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  height: 570px;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
}

.paper-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}

.paper-item {
  padding-left: 20px;
  font-weight: 500;
  background: #F8F8FA;
}

.paper-question {
  padding-left: 20px;
}

.paper-total-score {
  text-align: center;
  font-weight: 500;
  color: #0069CA;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}

label.custom-control-label {
  cursor: pointer;
}

label.custom-control-label::before {
  border: 2px solid grey;
  width: 20px;
  height: 20px;
}


</style>
