<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        환자가 신규 생성되었습니다.
      </h5>
    </div>
    <div class="modal-center">
      <div class="user-info">
        <h5>환자명&nbsp;&nbsp;&nbsp;&nbsp;</h5>
        <p>{{patientInfo.name}}</p>
      </div>
      <div class="user-info">
        <h5>아이디&nbsp;&nbsp;&nbsp;&nbsp;</h5>
        <p>{{patientInfo.id}}</p>
      </div>
      <div class="user-info">
        <h5>생년월일</h5>
        <p>{{patientInfo.birth}}</p>
      </div>
      <strong> 환자가 로그인시 최초 비밀번호는 생년월일로 지정됩니다.<br/>예: 생년월일이 1989년 1월 1일일 경우 비밀번호는 19890101 입니다.</strong>
    </div>
    <div class="modal-footer">
      <button class="custom-btn-box-white btn-short primary-blue" @click="btnCancel">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientsCreateSuccessDialog",
  props: {
    patientInfo: Object,
  },
  data(){
    return{
    }
  },
  methods: {
    btnCancel() {
      const self = this;
      self.$emit('callBackModalCreateUser', 0, false)
    },
  }
}
</script>

<style scoped>

strong {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #DC2626;
}

.modal-container {
  position: absolute;
  width: 580px;
  height: 270px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 60px;
  background: #F8F8FA;
}
.user-info {
  display: flex;
  margin-bottom: 3px;
}
.modal-center h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin-right: 39px;
}

.modal-center p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #272833;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  padding: 16px;
}

.modal-footer button {
  margin: 0;
}

.btn-short {
  width: 84px;
  font-size: 16px;
  line-height: 36px;
  background-color: white;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.0125em;
  color: rgba(24, 24, 25, 0.9);
}


</style>
