<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        평가도구 변경
      </h5>
    </div>
    <div class="modal-center" v-if="evaluationTools">
      <p>평가 결과 저장이 완료된 평가는 변경할 수 없으며 임시저장된 평가는 이전데이터가 모두 삭제됩니다. </p>
      <div class="tools-title">
        <div class="tools-sub-title">Basic Measure</div>
      </div>
      <div class="tools">
        <mdb-input v-if="evaluationTools.balance.state === 2 && evaluationTools.rom_mmt.state === 2 && evaluationTools.mas.state === 2" type="checkbox" id="checkbox7" name="BM"
                   v-model="evaluationTools.bm.checked" class="tools-item bm-box" label=" " checked disabled>
          <div class="bm box-disabled">
            <div>ROM, MMT</div>
            <div>MAS</div>
            <div>Balance</div>
          </div>
        </mdb-input>
        <mdb-input v-else type="checkbox" id="checkbox7" name="BM" v-model="evaluationTools.bm.checked" class="tools-item bm-box" label=" ">
          <div class="bm" @click="evaluationTools.bm.checked = !evaluationTools.bm.checked">
            <div>ROM, MMT</div>
            <div>MAS</div>
            <div>Balance</div>
          </div>
        </mdb-input>
      </div>
      <div class="tools-bottom-container">
        <div class="left">
          <div class="tools-title">
            <div class="tools-sub-title">Upper Extremity</div>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_1 = !active.tool_1">Body Function & Structure
              <img v-if="active.tool_1" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_1" :duration="500">
              <mdb-input v-if="evaluationTools.fm.state === 2" type="checkbox" id="checkbox1" name="FM"
                         v-model="evaluationTools.fm.checked" class="tools-item" label="Fugl-Meyer" checked disabled/>

              <mdb-input v-else type="checkbox" id="checkbox1" name="FM" v-model="evaluationTools.fm.checked" class="tools-item" label="Fugl-Meyer"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_2 = !active.tool_2">Activity
              <img v-if="active.tool_2" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_2" :duration="500">
              <mdb-input v-if="evaluationTools.bb.state === 2" type="checkbox" id="checkbox2" name="BB"
                         v-model="evaluationTools.bb.checked" class="tools-item" label="BB" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox2" name="BB" v-model="evaluationTools.bb.checked" class="tools-item" label="BB"/>
              <mdb-input v-if="evaluationTools.wmft.state === 2" type="checkbox" id="checkbox3" name="WMFT"
                         v-model="evaluationTools.wmft.checked" class="tools-item" label="WMFT" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox3" name="WMFT" v-model="evaluationTools.wmft.checked" class="tools-item" label="WMFT"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_3 = !active.tool_3">Participation
              <img v-if="active.tool_3" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_3" :duration="500">
              <mdb-input v-if="evaluationTools.sis.state === 2" type="checkbox" id="checkbox4" name="SIS"
                         v-model="evaluationTools.sis.checked" class="tools-item" label="SIS" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox4" name="SIS" v-model="evaluationTools.sis.checked" class="tools-item" label="SIS"/>
              <mdb-input v-if="evaluationTools.aaut.state === 2" type="checkbox" id="checkbox5" name="AAUT"
                         v-model="evaluationTools.aaut.checked" class="tools-item" label="AAUT" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox5" name="AAUT" v-model="evaluationTools.aaut.checked" class="tools-item" label="AAUT"/>
              <mdb-input v-if="evaluationTools.mal.state === 2" type="checkbox" id="checkbox6" name="MAL"
                         v-model="evaluationTools.mal.checked" class="tools-item" label="MAL" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox6" name="MAL" v-model="evaluationTools.mal.checked" class="tools-item" label="MAL"/>
            </slide-up-down>
          </div>
        </div>
        <div class="right">
          <div class="tools-title">
            <div class="tools-sub-title">Lower Extremity</div>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_4 = !active.tool_4">Body Function & Structure
              <img v-if="active.tool_4" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_4" :duration="500">
              <mdb-input v-if="evaluationTools.bbs.state === 2" type="checkbox" id="checkbox8" name="BBS"
                         v-model="evaluationTools.bbs.checked" class="tools-item" label="BBS" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox8" name="BBS" v-model="evaluationTools.bbs.checked" class="tools-item" label="BBS"/>
              <mdb-input v-if="evaluationTools.tug.state === 2" type="checkbox" id="checkbox9" name="TUG"
                         v-model="evaluationTools.tug.checked" class="tools-item" label="TUG" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox9" name="TUG" v-model="evaluationTools.tug.checked" class="tools-item" label="TUG"/>
              <mdb-input v-if="evaluationTools.ftsts.state === 2" type="checkbox" id="checkbox10" name="FTSTS"
                         v-model="evaluationTools.ftsts.checked" class="tools-item" label="FTSTS" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox10" name="FTSTS" v-model="evaluationTools.ftsts.checked" class="tools-item" label="FTSTS"/>
              <mdb-input v-if="evaluationTools.fm_l.state === 2" type="checkbox" id="checkbox11" name="FM_L"
                         v-model="evaluationTools.fm_l.checked" class="tools-item" label="Fugl-Meyer" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox11" name="FM_L" v-model="evaluationTools.fm_l.checked" class="tools-item" label="Fugl-Meyer"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_5 = !active.tool_5">Activity
              <img v-if="active.tool_5" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_5" :duration="500">
              <mdb-input v-if="evaluationTools.tmwt.state === 2" type="checkbox" id="checkbox12" name="TMWT"
                         v-model="evaluationTools.tmwt.checked" class="tools-item" label="10MWT" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox12" name="TMWT" v-model="evaluationTools.tmwt.checked" class="tools-item" label="10MWT"/>
              <mdb-input v-if="evaluationTools.smwt.state === 2" type="checkbox" id="checkbox13" name="SMWT"
                         v-model="evaluationTools.smwt.checked" class="tools-item" label="6MWT" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox13" name="SMWT" v-model="evaluationTools.smwt.checked" class="tools-item" label="6MWT"/>
              <mdb-input v-if="evaluationTools.dgi.state === 2" type="checkbox" id="checkbox14" name="DGI"
                         v-model="evaluationTools.dgi.checked" class="tools-item" label="DGI" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox14" name="DGI" v-model="evaluationTools.dgi.checked" class="tools-item" label="DGI"/>
            </slide-up-down>
          </div>
          <div class="tools">
            <button class="tools-sub-title" @click="active.tool_6 = !active.tool_6">Participation
              <img v-if="active.tool_6" src="@/assets/images/IA/icon_up.png" alt="arrow">
              <img v-else src="@/assets/images/IA/icon_down.png" alt="arrow">
            </button>
            <slide-up-down :active="active.tool_6" :duration="500">
              <mdb-input v-if="evaluationTools.abc.state === 2" type="checkbox" id="checkbox15" name="ABCscale"
                         v-model="evaluationTools.abc.checked"
                         class="tools-item" label="ABCscale" checked disabled/>
              <mdb-input v-else type="checkbox" id="checkbox15" name="ABCscale" v-model="evaluationTools.abc.checked"
                         class="tools-item" label="ABCscale"/>
            </slide-up-down>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class=" custom-btn-box-white btn-short" @click="btnCancel">취소</button>
      <button class="primary-blue custom-btn-box btn-short" @click="btnModify">수정</button>
    </div>
  </div>

</template>

<script>
import SlideUpDown from "vue-slide-up-down";
import {mdbInput,} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "EvaluationToolsReviseDialog",
  components: {
    SlideUpDown,
    mdbInput,
  },
  props: {
    tools: Object,
  },
  data() {
    return {
      checkData: false,
      fbCollection: 'evaluations',
      fbDocId: this.$route.params.id.toString(),
      active: {tool_1: true, tool_2: true, tool_3: true, tool_4: true, tool_5: true, tool_6: true,},
      evaluationTools: null
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getStateEndCheck()
    },
    getStateEndCheck() {
      const self = this;
      self.evaluationTools = JSON.parse(JSON.stringify(self.tools));
    },
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 0, false)
    },
    async btnModify() {
      const self = this;
      if (!self.evaluationTools.fm.checked && !self.evaluationTools.bb.checked && !self.evaluationTools.wmft.checked && !self.evaluationTools.sis.checked &&
          !self.evaluationTools.aaut.checked && !self.evaluationTools.mal.checked && !self.evaluationTools.bm.checked && !self.evaluationTools.bbs.checked &&
          !self.evaluationTools.tug.checked && !self.evaluationTools.ftsts.checked && !self.evaluationTools.fm_l.checked && !self.evaluationTools.tmwt.checked &&
          !self.evaluationTools.smwt.checked && !self.evaluationTools.dgi.checked && !self.evaluationTools.abc.checked) {
        alert('평가도구는 최소 1개 이상 필수 선택입니다.');
        return
      }
      await self.bmChecked()
      await self.updateDataTools()
    },
    bmChecked() {
      const self = this;
      self.evaluationTools.rom_mmt.checked = self.evaluationTools.bm.checked
      self.evaluationTools.mas.checked = self.evaluationTools.bm.checked
      self.evaluationTools.balance.checked = self.evaluationTools.bm.checked
    },
    async updateDataTools() {
      const self = this;

      const tools = self.evaluationTools

      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.fbDocId)
          .set({tools: tools}, {merge: true})
          .then(async () => {
            self.$emit('callBackModal', 2, false, self.evaluationTools)
          });
    },
  }
}
</script>

<style scoped>

button {
  font-size: 16px !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 3;
}


.modal-container {
  position: fixed;
  width: 580px;
  height: 657px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}


.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 23px 20px;
  background: #F8F8FA;
  max-height: 507px;
  overflow: overlay;
}

.modal-center p {

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #DC2626;
}

.tools-bottom-container {
  display: flex;
  justify-content: space-between;
}

.tools-bottom-container > div {
  width: 265px;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
}

.custom-btn-box-white {
  background: #ffffff;
  border: 1px solid #E1E3E6;
}


.search-result-item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.tools-sub-title {
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E1E3E6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #0069CA;
  text-align: left;
  padding: 0 8px 0 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tools-title {
  margin-top: 20px;
}

.tools-title .tools-sub-title {
  background: #00498A;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  color: #FFFFFF;
}

.tools-sub-title img {
  width: 18px;
  height: 18px;
  margin-left: auto;
}

.tools-item {
  height: 40px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.tools img {
  width: 18px;
  height: 18px;
}

/deep/ .form-check {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  padding-left: 8px;
}

.bm-box {
  display: flex;
  height: 132px;
  padding: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}

/deep/ .bm-box label {
  margin: 0 !important;
  padding-left: 30px;
}

/deep/ .bm-box label::before {
  margin-left: 2px !important;
}

.bm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.bm div {
  padding: 6px 10px;
  width: 494px;
  height: 34px;
  border: 1px solid #E1E3E6;
}

.box-disabled {
  color: #8D8D8D;
}


</style>
