<template>
  <div class="nav-frame">
    <router-link to="/therapist/patientsManage" class="nav-item " :class="{active : $route.params.menu === 'patientsManage'}">
      환자관리
    </router-link>
    <router-link to="/therapist/doEvaluation" class="nav-item" :class="{active : $route.params.menu === 'doEvaluation'}">
      평가하기
    </router-link>
    <router-link to="/therapist/evaluationToolsInfo/fm" class="nav-item" :class="{active : $route.params.menu === 'evaluationToolsInfo'}">
      평가 도구 정보
    </router-link>
    <router-link to="/therapist/dataReferenceRoom/all" class="nav-item" :class="{active : $route.params.menu === 'dataReferenceRoom'}">
      데이터 다운로드
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Navigator",
  props: {
    index: Number,
  },
  methods: {
  }
}
</script>

<style scoped>
.nav-frame {
  background-color: #16476E;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-item {
  width: 173px;
  height: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  line-height: 50px;
  margin-left: 15px;
  text-align: center;
}

.nav-item:first-child {
  margin-left: 0;
}


.active {
  background-color: #0078AF;
}
</style>
