export default {
    diagnosis: [
        {text: "뇌졸중", value: "뇌졸중"},
        {text: "파킨슨", value: "파킨슨"},
        {text: "외상성 뇌손상", value: "외상성 뇌손상"},
        {text: "척수 손상", value: "척수 손상"},
        {text: "치매", value: "치매"},
        {text: "암", value: "암"},
        {text: "다발성 경화증", value: "다발성 경화증"},
        {text: "뇌정증(간질)", value: "뇌정증(간질)"},
        {text: "말초신경병증", value: "말초신경병증"},
    ],

    monthly: [
        {text: "1", value: "1"},
        {text: "2", value: "2"},
        {text: "3", value: "3"},
        {text: "4", value: "4"},
        {text: "5", value: "5"},
        {text: "6", value: "6"},
        {text: "7", value: "7"},
        {text: "8", value: "8"},
        {text: "9", value: "9"},
        {text: "10", value: "10"},
        {text: "11", value: "11"},
        {text: "12", value: "12"},
    ],

    day: [
        {text: "1", value: "1"},
        {text: "2", value: "2"},
        {text: "3", value: "3"},
        {text: "4", value: "4"},
        {text: "5", value: "5"},
        {text: "6", value: "6"},
        {text: "7", value: "7"},
        {text: "8", value: "8"},
        {text: "9", value: "9"},
        {text: "10", value: "10"},
        {text: "11", value: "11"},
        {text: "12", value: "12"},
        {text: "13", value: "13"},
        {text: "14", value: "14"},
        {text: "15", value: "15"},
        {text: "16", value: "16"},
        {text: "17", value: "17"},
        {text: "18", value: "18"},
        {text: "19", value: "19"},
        {text: "20", value: "20"},
        {text: "21", value: "21"},
        {text: "22", value: "22"},
        {text: "23", value: "23"},
        {text: "24", value: "24"},
        {text: "25", value: "25"},
        {text: "26", value: "26"},
        {text: "27", value: "27"},
        {text: "28", value: "28"},
        {text: "29", value: "29"},
        {text: "30", value: "30"},
        {text: "31", value: "31"},
    ],

    hands: [
        {text: "오른쪽", value: "오른쪽"},
        {text: "왼쪽", value: "왼쪽"},
        {text: "양쪽 모두", value: "양쪽 모두"},
    ],

    side: [
        {text: "오른쪽", value: "오른쪽"},
        {text: "왼쪽", value: "왼쪽"},
        {text: "양쪽 모두", value: "양쪽 모두"},
    ],

    parts: [
        {text: "상지", value: "상지"},
        {text: "하지", value: "하지"},
        {text: "상,하지 모두", value: "상,하지 모두"},
    ],

    hospitalType: [
        {text: "1차 병원(의원급)", value: "1차 병원(의원급)"},
        {text: "2차 병원(재활전문병원 및 요양병원급)", value: "2차 병원(재활전문병원 및 요양병원급)"},
        {text: "3차 병원(종합병원급)", value: "3차 병원(종합병원급)"},
    ],

    hospitalLocation: [
        {text: "서울 및 경기도", value: "서울 및 경기도"},
        {text: "광역시", value: "광역시"},
        {text: "강원도", value: "강원도"},
        {text: "경상도", value: "경상도"},
        {text: "전라도", value: "전라도"},
        {text: "충청도", value: "충청도"},
        {text: "제주도", value: "제주도"},
    ],

    job: [
        {text: "물리치료사", value: "물리치료사"},
        {text: "작업치료사", value: "작업치료사"},
    ],

    workingYear: [
        {text: "1년 미만", value: "1년 미만"},
        {text: "1~3년", value: "1~3년"},
        {text: "3~5년", value: "3~5년"},
        {text: "5~10년", value: "5~10년"},
        {text: "10년 이상", value: "10년 이상"},
    ],

    tools: [
        {text: "Fugl-Meyer", value: "Fugl-Meyer"},
        {text: "WMFT", value: "WMFT"},
        {text: "BB", value: "BB"},
        {text: "AAUT", value: "AAUT"},
        {text: "MAL", value: "MAL"},
        {text: "SIS", value: "SIS"},
    ],

    basicOptions: [
        {text: "환자 번호", value: "patientNo"},
        {text: "환자 아이디", value: "id"},
        {text: "환자 이름", value: "name"},
        {text: "진단명", value: "diagnosis"},
    ],

    basicOptionsNoDiagnosis: [
        {text: "환자 번호", value: "patientNo"},
        {text: "환자 아이디", value: "id"},
        {text: "환자 이름", value: "name"},
    ],


};
