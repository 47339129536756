<template>
  <div class="modal-container">
    <div v-show="!isShowStoreDialog">
      <div class="modal-header">
        <h5 class="title">
          <span style="color: #0069CA">SIS</span> 평가 결과 저장
        </h5>
      </div>
      <div class="modal-center">
        <div class="data-group">
          <p class="data-label">총 점수 합계</p>
          <div class="data-disabled">
            <p class="result-p result-d">{{ total.sum }}</p>
          </div>
        </div>
        <div class="data-group total-group">
          <p class="data-label black-label">총 평균</p>
          <div class="data-disabled data-total">
            <p class="result-p result-total">{{ total.avg }}</p>
          </div>
        </div>
        <div class="p-guide">
          <p>※ 환자 평가 기록에는 총 평균 값이 반영됩니다.</p>
          <p>※ 엑셀로 데이터 다운로드시에는 평가한 모든 내용 확인이 가능합니다.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button class="custom-btn-box-white btn-short" @click="btnCancel">닫기</button>
        <button class="primary-blue custom-btn-box btn-confirm" @click="isShowStoreDialog = true">확인</button>
      </div>
    </div>
    <EvaluationStoreDialog v-show="isShowStoreDialog" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import EvaluationStoreDialog from "@/views/therapist/menu/doEvaluation/EvaluationStoreDialog";

export default {
  name: "SisResultDialog",
  components: {EvaluationStoreDialog},
  props: {
    total: Object,
    fbDocId: String,
    sis: Object,
  },
  data() {
    return {
      fbCollection: 'evaluations',
      tools: {
        sis: {}
      },
      isShowStoreDialog: false,
    }
  },
  methods: {
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 0, false)
    },
    async btnConfirm() {
      const self = this;
      self.tools.sis = self.sis;
      self.tools.sis['state'] = 2;
      self.tools.sis['totalSum'] = self.total.sum;
      self.tools.sis['totalAvg'] = parseFloat(self.total.avg);
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('callBackModal', 0, true)
          });
    },
    callBackModal: function (state) {
      const self = this;
      if (state) {
        self.btnConfirm();
      } else {
        self.btnCancel();
      }
    }
  }
}
</script>

<style scoped>

button, strong {
  font-size: 16px !important;
}

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 60px;
  background: #F8F8FA;
}

.data-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.total-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.total-aou, .total-aou {
  width: 205px;
}

.data-total {
  border: 2px solid #0069CA;
  background: #fff !important;
}

.data-group > p:nth-child(1) {
  width: 120px;
}

.data-label {
  width: 115px;
  margin-right: 10px;
  color: #8d8d8d;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.black-label {
  color: #000;
}

.label-margin {
  margin: 0 10px;
}

.data-disabled {
  width: 290px;
  height: 40px;
  color: #0069CA;
  font-weight: 400;
  background: #EDEDF1;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.result-p {
  width: 274px;
  font-size: 16px;
  text-align: right;
}

.p-guide {
  margin-top: 10px;
}

.p-guide > p {
  color: #E14F4E;
  font-size: 14px;
  font-weight: 400;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 14px;
  line-height: 36px;
  background-color: white;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.0125em;
  color: rgba(24, 24, 25, 0.9);
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #E14F4E;
  margin-bottom: 20px;
  margin-top: 8px;
}

.btn-confirm {
  width: 84px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: white;
}

hr {
  width: 205px;
  height: 1px;
  background: #E1E3E6;
  margin: 10px 0;
  border: 0;
}


</style>
