export default {
    partA: [
        [
            {part: 'partA', id: '0', title: '•\t대상자가 최대한 주의를 기울일 수 있는 조용한 곳에서 평가를 진행하였습니다.'},
            {part: 'partA', id: '1', title: '•\t구두 지시사항으로 참가자에게 원하는 움직임을 분명하게 전달하였습니다.'},
            {part: 'partA', id: '2', title: '•\t대상자가 움직임 수행에 어려움을 겪을 때는 격려의 말을 해주었고, 움직임을 재촉하지 않았습니다.'},
            {part: 'partA', id: '3', title: '•\t평가자(나)는 반사 망치와 스톱워치를 준비했습니다.'},
            {part: 'partA', id: '4', title: '•\t평가자(나)는 FMA-UE의 목적을 대상자에게 설명했습니다.'},
            {part: 'partA', id: '5', title: '•\t모든 항목을 평가하고 각 항목에 대해 올바른 점수를 주었습니다.'}
        ],
    ],
    partB: [
        [
            {part: 'partB', id: '0', title: '•\t자세: 대상자는 편히 앉은 자세입니다.'},
            {part: 'partB', id: '1', title: '•\t반사 평가: 위팔두갈래근(biceps brachii) 반사 평가를 진행했습니다.'},
            {part: 'partB', id: '2', title: '•\t점수: 대상자의 위팔두갈래근 반사에 대해 정확한 점수를 메겼습니다.'},
            {part: 'partB', id: '3', title: '•\t반사 평가: 위팔세갈래근(triceps brachii) 반사 평가를 진행했습니다.'},
            {part: 'partB', id: '4', title: '•\t점수: 대상자의 위팔세갈래근 반사에 대해 정확한 점수를 메겼습니다.'}
        ],
        [
            {part: 'partB', id: '5', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '6', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '7', title: '•\t시작자세:  완전한 폄 시너지 자세(팔꿈치를 펴고 손등이 반대쪽 무릎의 안쪽에 위치)를 취하였습니다. 환자가 시작 자세를 수행할 수 없는 경우, 평가자가 대상자의 움직임을 수동적으로 도와주었습니다.'},
            {part: 'partB', id: '8', title: '•\t구두지시: "손을 귀쪽으로 들어 올려 팔꿈치를 어깨 높이까지 올려보세요. 손등을 보여주시고, 저를 보고 저와 같이 이렇게 하세요."라고 지시하였습니다. 어깨는 최소 90도 이상 외전(abduction)되도록 지시하였습니다.'},
            {part: 'partB', id: '9', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'}
        ],
        [
            {part: 'partB', id: '10', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '11', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '12', title: '•\t시작자세: 대상자는 어깨를 중립으로 하고, 팔꿈치는 구부러지고 전완은 뒤집어진 상태(elbow flexion + forearm supination)로 동측 허벅지 위에 올려놓았습니다. 이때 평가자는 대상자가 몸통을 앞으로 구부리거나 돌리지 않도록 주의시켰습니다.'},
            {part: 'partB', id: '13', title: '•\t구두지시: "팔꿈치를 펴고 손등이 반대쪽 무릎의 안쪽에 위치 하세요"라고 지시하였습니다.'},
            {part: 'partB', id: '14', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'}
        ],
        [
            {part: 'partB', id: '15', title: '•\t 비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '16', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '17', title: '•\t시작자세: 대상자는 앉은 자세로 손을 무릎 위에 놓았습니다.'},
            {part: 'partB', id: '18', title: '•\t구두지시: “뒷짐을 져주세요”라고 지시하였습니다.'},
            {part: 'partB', id: '19', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '20', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '21', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '22', title: '•\t시작자세: 대상자는 앉은 자세로 손을 무릎 위에 놓았습니다.'},
            {part: 'partB', id: '23', title: '•\t구두지시: "팔꿈치를 편 자세를 유지하면서 팔을 어깨 높이까지 올리세요"라는 지시를 하였습니다. 수행 시 전완(Forearm)은 중립자세를 유지시켰습니다.'},
            {part: 'partB', id: '24', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '25', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '26', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '27', title: '•\t시작자세: 대상자는 팔꿈치를 90° 구부린 자세에서 팔을 겨드랑이에 붙였습니다.'},
            {part: 'partB', id: '28', title: '•\t구두지시: “팔꿈치를 굽히고 팔을 겨드랑이에 붙힌 자세에서 손바닥을 앞뒤로 뒤집으세요”라는 지시를 하였습니다.'},
            {part: 'partB', id: '29', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '30', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '31', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '32', title: '•\t시작자세: 대상자는 앉은 상태로 팔과 손을 편 상태로 몸통의 옆에 위치하였습니다.'},
            {part: 'partB', id: '33', title: '•\t구두지시: "팔꿈치 폄을 유지하면서 팔을 어깨 높이까지 벌려주세요."라고 지시하였습니다.'},
            {part: 'partB', id: '34', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '35', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '36', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '37', title: '•\t시작자세: 대상자는 바르게 앉아 어깨를 90도 구부린 (shoulder flexion) 자세를 취하였습니다.'},
            {part: 'partB', id: '38', title: '•\t구두지시:  "팔꿈치 폄을 유지하면서 귀 옆으로 팔을 들어 머리 위로 올려주세요."라고 지시하였습니다.'},
            {part: 'partB', id: '39', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '40', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '41', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '42', title: '•\t시작자세: 대상자는 손을 무릎 위에 위치하고 앉았습니다.'},
            {part: 'partB', id: '43', title: '•\t구두지시: "팔을 앞으로 약간 들어 올리고, 팔꿈치를 편 자세에서, 손바닥을 앞뒤로 돌리세요." 라고 지시하였습니다.'},
            {part: 'partB', id: '44', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '45', title: '•\t이 항목은 환자가 위의 항목 (5. Movement out of synergies)에서 최대 점수 만점을 받은 경우에만 진행하였습니다.'},
            {part: 'partB', id: '46', title: '•\t평가자는 세 가지 반사 신경(biceps, triceps, and finger flexor)을 평가하였습니다.'},
            {part: 'partB', id: '47', title: '•\t평가자는 반사에 대해 정확하게 평가하여 점수를 부여했습니다.'}
        ],
        [
            {part: 'partB', id: '48', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '49', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '50', title: '•\t시작자세: 대상자는 앉은 자세에서 팔꿈치를 90° 구부리고 손등을 위로 향하게 유지하였습니다.'},
            {part: 'partB', id: '51', title: '•\t구두지시: "팔꿈치를 구부린 자세를 유지하고 손목(손등)을 최대한 위로 올리세요."라고 지시하였습니다. 필요한 경우 대상자의 어깨를 지지 해 주었습니다.'},
            {part: 'partB', id: '52', title: '•\t저항: 대상자가 배측 굴곡의 전체 범위에 도달할 시, 약간의 저항을 주었습니다.'},
            {part: 'partB', id: '53', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},

        ],
        [
            {part: 'partB', id: '54', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '55', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '56', title: '•\t시작자세: 대상자는 앉은 자세에서 팔꿈지를 90° 구부리고 유지하였습니다.'},
            {part: 'partB', id: '57', title: '•\t구두지시:  "팔꿈치를 구부린 자세를 유지하고 손목을 최대한 위, 아래로 움직여보세요." 라고 지시하였습니다. 필요한 경우 대상자의 어깨를 지지 해 주었습니다.'},
            {part: 'partB', id: '58', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '59', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '60', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '61', title: '•\t시작자세: 대상자는 팔꿈치를 편상태에서 손을 무릎 위에 놓고 앉았습니다.'},
            {part: 'partB', id: '62', title: '•\t구두지시: "팔 전체를 약간 앞으로 들어 올리고 팔꿈치 폄을 유지하고 손목(손등)을 최대한 위로 올리세요"라고 지시하였습니다. 필요한 경우 대상자의 어깨를 지지 해 주었습니다.'},
            {part: 'partB', id: '63', title: '•\t저항: 대상자가 배측 굴곡의 전체 범위에 도달할 시, 약간의 저항을 주었습니다.'},
            {part: 'partB', id: '64', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '65', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '66', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '67', title: '•\t시작자세: 대상자는 팔꿈치를 편상태에서 손을 무릎 위에 놓고 앉았습니다.'},
            {part: 'partB', id: '68', title: '•\t구두지시:"팔 전체를 약간 앞으로 들어 올리고 팔꿈치 폄을 유지하고 손목을 위, 아래로 움직이세요"라고 지시하였습니다. 필요한 경우 대상자의 어깨를 지지 해 주었습니다.'},
            {part: 'partB', id: '69', title: '•\t저항: 대상자가 배측 굴곡의 전체 범위에 도달할 시, 약간의 저항을 주었습니다.'},
            {part: 'partB', id: '70', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '71', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '72', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '73', title: '•\t시작자세: 대상자는 앉은 자세에서 팔꿈치를 90 ° 구부리고 팔을 겨드랑이에 붙이고, 손등이 위를 향하게 하였습니다.'},
            {part: 'partB', id: '74', title: '•\t구두지시: "팔의 움직임 없이 손목을 원을 그리며 움직이세요"라고 지시하였습니다. 필요한 경우 대상자의 어깨를 지지 해 주었습니다.'},
            {part: 'partB', id: '75', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '76', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '77', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '78', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 놓고 손을 펴고 앉았습니다. 필요한 경우 평가자가 수동적으로 손을 펴 주었습니다.'},
            {part: 'partB', id: '79', title: '•\t구두지시:  “주먹을 쥐세요"라고 지시하였습니다.'},
            {part: 'partB', id: '80', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '81', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '82', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '83', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 놓고 손가락을 구부리고 앉았습니다. 필요한 경우 수동적으로 손을 구부려 주었습니다.'},
            {part: 'partB', id: '84', title: '•\t구두지시:  “주먹을 펴세요"라고 지시하였습니다.'},
            {part: 'partB', id: '85', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '86', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '87', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '88', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 손을 놓고 앉았습니다. 필요 시 전완(forearm)의 자세를 바로잡는 것을 도와주었으나, 손 움직임에 대해서는 도와주지 않았습니다.'},
            {part: 'partB', id: '89', title: '•\t구두지시:  "손을 갈퀴처럼 만들어보세요"라고 지시하였습니다.'},
            {part: 'partB', id: '90', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '91', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '92', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '93', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 손을 놓고 앉았습니다. 필요 시 전완(forearm)의 자세를 바로잡는 것을 도와주었으나, 손 움직임에 대해서는 도와주지 않았습니다.'},
            {part: 'partB', id: '94', title: '•\t구두지시:  평가자는 대상자의 엄지와 검지 사이에 종이를 위치시킨 후, 대상자에게 "엄지와 검지로 종이를 잡아보세요" 라고 지시하였습니다.'},
            {part: 'partB', id: '95', title: '•\t저항: 대상자에게 “제가 종이를 빼려고 힘을 줄 때 빠지지 않게 유지하세요” 라고 지시하고, 종이를 빼 내는 저항을 주었습니다.'},
            {part: 'partB', id: '96', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록하였습니다.'},
        ],
        [
            {part: 'partB', id: '97', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '98', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '99', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 손을 놓고 앉았습니다. 필요 시 전완(forearm)의 자세를 바로잡는 것을 도와주었으나, 손 움직임에 대해서는 도와주지 않았습니다.'},
            {part: 'partB', id: '100', title: '•\t구두지시:  평가자는 펜을 테이블에 놓은 후  "엄지와 검지 손가락을 이용해 펜을 잡으십시오" 고 지시하였습니다. 이때 반대측 손의 도움을 통해 과제를 진행하는 것을 하지 못하게 하였습니다.'},
            {part: 'partB', id: '101', title: '•\t저항:  대상자에게 “제가 펜을 빼려고 힘을 줄 때 빠지지 않게 유지하세요” 라고 지시합니다.'},
            {part: 'partB', id: '102', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록합니다.'},
        ],
        [
            {part: 'partB', id: '103', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '104', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '105', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 손을 놓고 앉았습니다. 필요 시 전완(forearm)의 자세를 바로잡는 것을 도와주었으나, 손 움직임에 대해서는 도와주지 않았습니다.'},
            {part: 'partB', id: '106', title: '•\t구두지시: 평가자는 테이블에 캔을 놓은 후,  대상자에게 "캔을 잡아보세요" 고 지시하였습니다. 이때 반대측 손의 도움을 통해 과제를 진행하는 것을 하지 못하게 하였습니다.'},
            {part: 'partB', id: '107', title: '•\t저항:  대상자에게 “제가 캔을 빼려고 힘을 줄 때 빠지지 않게 유지하세요” 라고 지시하였습니다.'},
            {part: 'partB', id: '108', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록합니다.'},
        ],
        [
            {part: 'partB', id: '109', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '110', title: '•\tPROM확인: 손상측을 검사를 할 시 각 관절에서 대상자의 사용 가능한 PROM을 확인하였습니다.'},
            {part: 'partB', id: '111', title: '•\t시작자세: 대상자는 손을 테이블이나 무릎에 손을 놓고 앉았습니다. 필요 시 전완(forearm)의 자세를 바로잡는 것을 도와주었으나, 손 움직임에 대해서는 도와주지 않았습니다.'},
            {part: 'partB', id: '112', title: '•\t구두지시: 평가자는 테이블에 테니스 공을 놓은 후,  대상자에게 "공을 잡아보세요" 고 지시하였습니다. 이때 반대측 손의 도움을 통해 과제를 진행하는 것을 하지 못하게 하였습니다.'},
            {part: 'partB', id: '113', title: '•\t저항:  대상자에게 “제가 공을 빼려고 힘을 줄 때 빠지지 않게 유지하세요” 라고 지시하였습니다.'},
            {part: 'partB', id: '114', title: '•\t점수: 손상측의 움직임을 3번 시도하고, 가장 잘 수행한 움직임을 통해 대상자의 점수를 정확하게 기록합니다.'},
        ],
        [
            {part: 'partB', id: '115', title: '•\t비손상측 측정: 대상자의 비손상측부터 평가를 진행하였습니다.'},
            {part: 'partB', id: '116', title: '•\t시작자세: 눈을 감고 앉은 자세에서 검지 손가락을 동측의 무릎에 놓았습니다.'},
            {part: 'partB', id: '117', title: '•\t구두지시 1: "검지 손가락을 무릎에서 코까지 가능한 한 빨리 5 번 왔다 갔다 움직이세요. 제가 움직이는 시간을 측정하니 최대한 빠르게 움직임을 진행해주세요"라고 지시하였습니다.'},
            {part: 'partB', id: '118', title: '•\t비손상측 시간: 평가자는 비손상측 팔을 움직이는 시간을 기록하였습니다'},
            {part: 'partB', id: '119', title: '•\t구두지시 2: 대상자에게 "이제 반대의 팔로 할 수 있는 한 가장 빠르게 5 번 동일한 동작을 수행해주세요" 라고 지시한다.'},
            {part: 'partB', id: '120', title: '•\t손상측 시간: 평가자는 손상측 팔을 움직이는 시간을 기록하였습니다.'},
            {part: 'partB', id: '121', title: '•\t점수: 평가자는 떨림, 운동조절이상, 시간에 대해 정확하게 기록하였습니다.'},
        ],
    ]
}
