<template>
  <div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <table class="paper-table">
          <thead class="fixedHeader">
          <tr>
            <td colspan="4" class="td-task td-weight">Task</td>
            <td colspan="8" class="td-answer td-weight">AOU</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="4" class="td-task">1. 스위치로 전등을 켠다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_0" v-model="aou.aou_1" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_1" v-model="aou.aou_1" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_1" v-model="aou.aou_1" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">2. 서랍을 연다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_2" v-model="aou.aou_2" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_2" v-model="aou.aou_2" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">3. 서랍에서 옷을 꺼낸다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_3" v-model="aou.aou_3" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_3" v-model="aou.aou_3" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">4. 전화기를 잡는다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_4" v-model="aou.aou_4" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_4" v-model="aou.aou_4" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">5. 조리대나 다른 상판을 닦는다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_5" v-model="aou.aou_5" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_5" v-model="aou.aou_5" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">6. 차에서 내린다<br/>
              <span class="sub-txt">(문이 열리면, 앉은자세에서 차 밖에 선 자세를<br/>
                취하는데 필요한 움직임만을 본다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_6" v-model="aou.aou_6" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_6" v-model="aou.aou_6" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">7. 냉장고를 연다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_7" v-model="aou.aou_7" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_7" v-model="aou.aou_7" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">8. 문고리를 잡고 문을 연다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_8" v-model="aou.aou_8" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_8" v-model="aou.aou_8" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">9. TV리모컨을 사용한다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_9" v-model="aou.aou_9" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_9" v-model="aou.aou_9" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">10. 손을 씻는다<br/>
              <span class="sub-txt">(비누거품을 내고 손을 헹구는 것을 포함하지만,<br/>
                수도꼭지 손잡이로 물을 틀고 잠그는 것은 포함하지<br/>않는다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_10" v-model="aou.aou_10" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_10" v-model="aou.aou_10" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">11. 수도꼭지 손잡이로 물을 틀고 잠근다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_11" v-model="aou.aou_11" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_11" v-model="aou.aou_11" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">12. 손을 말린다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_12" v-model="aou.aou_12" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_12" v-model="aou.aou_12" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">13. 양말을 신는다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_13" v-model="aou.aou_13" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_13" v-model="aou.aou_13" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">14. 양말을 벗는다<br/>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_14" v-model="aou.aou_14" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_14" v-model="aou.aou_14" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">15. 신발을 신는다<br/>
              <span class="sub-txt">(신발끈 묶기와 스트랩 고정을 포함한다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_15" v-model="aou.aou_15" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_15" v-model="aou.aou_15" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">16. 신발을 벗는다<br/>
              <span class="sub-txt">(신발끈 풀기와 스트랩 풀기를 포함한다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_16" v-model="aou.aou_16" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_16" v-model="aou.aou_16" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">17. 팔걸이가 있는 의자에서 일어난다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_17" v-model="aou.aou_17" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_17" v-model="aou.aou_17" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">18. 앉기 전에 탁자에서 의자를 당긴다<br/></td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_18" v-model="aou.aou_18" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_18" v-model="aou.aou_18" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">19. 앉은 후에 탁자 쪽으로 의자를 당긴다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_19" v-model="aou.aou_19" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_19" v-model="aou.aou_19" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">20. 유리컵, 병, 음료수컵 혹은 깡통을 잡는다<br/>
              <span class="sub-txt">(마실 필요는 없다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_20" v-model="aou.aou_20" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_20" v-model="aou.aou_20" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">21. 이를 닦는다<br/>
              <span class="sub-txt">(의치를 하고 솔질을 하는 것이 아니면, 의치 솔질은<br/>
                포함되지 않고 칫솔 준비도 포함되지 않는다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_21" v-model="aou.aou_21" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_21" v-model="aou.aou_21" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">22. 열쇠를 사용하여 문을 연다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_22" v-model="aou.aou_22" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_22" v-model="aou.aou_22" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">23. 물건을 손에 든다<br/>
              <span class="sub-txt">(팔에 물건을 걸치는 것은 허용되지 않는다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_23" v-model="aou.aou_23" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_23" v-model="aou.aou_23" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">24. 포크나 수저를 사용하여 먹는다<br/>
              <span class="sub-txt">(포크나 수저로 음식을 입으로<br/>
                가져가는 행위를 말한다)</span>
            </td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_24" v-model="aou.aou_24" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_24" v-model="aou.aou_24" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">25. 머리를 빗는다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_25" v-model="aou.aou_25" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_25" v-model="aou.aou_25" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">26. 손잡이로 컵을 잡는다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_26" v-model="aou.aou_26" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_26" v-model="aou.aou_26" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">27. 셔츠 단추를 잠근다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_27" v-model="aou.aou_27" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_27" v-model="aou.aou_27" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="td-task">28. 샌드위치 반쪽이나 과자를 집어먹는다</td>
            <td colspan="8">
              <div class="radio-flex">
                <div class="radio-group">
                  <label class="container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="0">
                    <span class="checkmark">0</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="0.5">
                    <span class="checkmark xs-mark">0.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="1">
                    <span class="checkmark">1</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="1.5">
                    <span class="checkmark xs-mark">1.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="2">
                    <span class="checkmark">2</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="2.5">
                    <span class="checkmark xs-mark">2.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="3">
                    <span class="checkmark">3</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="3.5">
                    <span class="checkmark xs-mark">3.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="4">
                    <span class="checkmark">4</span>
                  </label>
                  <label class="container xs-container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="4.5">
                    <span class="checkmark xs-mark">4.5</span>
                  </label>
                  <label class="container">
                    <input type="radio" name="aou_28" v-model="aou.aou_28" :value="5">
                    <span class="checkmark">5</span>
                  </label>
                </div>
                <hr class="divided-line">
                <label class="container">
                  <input type="radio" name="aou_28" v-model="aou.aou_28" :value="'NT'">
                  <span class="checkmark nt-mark">NT, code</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </mdb-scrollbar>
  </div>
</template>

<script>
import {mdbScrollbar} from "mdbvue";

export default {
  name: "MalAou",
  components: {
    mdbScrollbar
  },
  props: {
    aou: Object,
  }
}
</script>

<style scoped>

.paper-frame {
  overflow: auto;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 62px;
  font-size: 16px;
}

thead {
  background: #F8F8FA;
  color: #272833;
  font-weight: 500 !important;
}

.fixedHeader {
  position: sticky;
  top: 0;
}

.td-task {
  height: 48px !important;
  text-align: left;
  padding-left: 20px;
  font-weight: 400;
  font-size: 15px;
}

.sub-txt {
  font-size: 15px;
  color: #0069CA;
  font-weight: 400;
  line-height: 20px;
}

.td-total {
  text-align: center !important;
  color: #0069CA;
  font-weight: 500;
}

.td-answer {
  height: 48px !important;
  text-align: center;
}

.td-weight {
  font-weight: 500;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 5px 10px;
}

.info-container > input {
  text-align: right;
  font-size: 18px;
  color: #000;
}

.info-container > input::placeholder {
  color: #B1B1B1;
  font-size: 18px;
}

.info-container > p {
  font-size: 16px;
  font-weight: 400;
  color: #8D8D8D;
  margin-left: 5px;
  margin-right: 3px;
}

.radio-flex {
  display: flex;
  align-items: center;
}

/*radio custom*/

.radio-group {
  width: 576px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: url('../../../../../../assets/images/IA/img_bk_line.png') no-repeat center;
}

.container {
  cursor: pointer;
  width: 46px;
  font-size: 18px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0 !important;
}

.container > label:last-child {
  margin-right: 0;
}

.xs-container {
  width: 40px;
  margin: 0 10px !important;
}

/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #F8F8FA;
  border-radius: 100px;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
}

.xs-mark {
  width: 40px !important;
  height: 40px !important;
  font-size: 16px !important;
  background: #fff !important;
}

.nt-mark {
  width: 86px !important;
  height: 38px !important;
  background-color: #fff;
  border-radius: 4px !important;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
  font-size: 16px !important;
  font-weight: 400;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0069CA;
  color: #fff;
  border: 2px solid #0069CA;
}

.container input:checked ~ .xs-mark {
  background-color: #fff;
  color: #0069CA;
  border: 2px solid #0069CA;
}

.divided-line {
  background: #E1E3E6;
  width: 2px;
  height: 46px;
  margin: 0 20px 0 10px;
}


</style>