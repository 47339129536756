<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Activities-Specific Balance Confidence Scale (ABC scale)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="question">
      <img src="@/assets/images/IA/icon_question.png" alt="">
      <h5>질문 : 균형을 잃거나 뒤뚱거리지 않고 다음과 같은 동작을 할 수 있는 자신감은 어느정도 입니까?</h5>
    </div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <table class="paper-table">
          <tbody>
          <tr class="paper-topic">
            <td colspan="5" class="td-task td-weight">Task</td>
            <td colspan="7" class="td-answer td-weight">점수</td>
          </tr>
          <tr>
            <td colspan="5" class="td-task td-weight"></td>
            <td colspan="3" class="text-red td-weight">회복되지 않음</td>
            <td colspan="4" class="text-blue td-weight">완전히 회복됨</td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">1. 집 주변을 걸어 다닌다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_0" v-model="abc.abc_1" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_1" v-model="abc.abc_1" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">2. 층계를 오르내린다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_2" v-model="abc.abc_2" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">3. 땅에서 신발을 집어 든다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_3" v-model="abc.abc_3" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">4. 물건에 닿기 위해 눈높이로 손을 뻗친다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_4" v-model="abc.abc_4" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">5. 물건에 닿기 위해 발뒤꿈치를 든다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_5" v-model="abc.abc_5" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">6. 물건에 닿기 위해 의자에 올라선다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_6" v-model="abc.abc_6" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">7. 바닥을 빗자루로 쓴다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_7" v-model="abc.abc_7" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">8. 가까이 있는 차까지 걸어 나간다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_8" v-model="abc.abc_8" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">9. 차에 타고 내린다</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_9" v-model="abc.abc_9" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">10. 주차장을 건너 지나간다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_10" v-model="abc.abc_10" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">11. 경사진 곳을 오르고 내린다</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_11" v-model="abc.abc_11" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">12. 붐비는 시장(쇼핑몰)을 걸어 다닌다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_12" v-model="abc.abc_12" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">13. 사람들로 붐비고 부대끼는 곳을 걸어간다.</td>
            <td colspan="7">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_13" v-model="abc.abc_13" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">14. 에스컬레이터를 난간을 잡고 탄다.</td>
            <td colspan="8">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_14" v-model="abc.abc_14" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">15. 에스컬레이터를 난간을 잡지 않고 탄다.</td>
            <td colspan="8">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_15" v-model="abc.abc_15" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="td-task">16. 빙판으로 미끄러운 길을 걷는다.</td>
            <td colspan="8">
              <div class="radio-group">
                <label class="container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="0">
                  <span class="checkmark">0</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="10">
                  <span class="checkmark xs-mark">10</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="20">
                  <span class="checkmark">20</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="30">
                  <span class="checkmark xs-mark">30</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="40">
                  <span class="checkmark">40</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="50">
                  <span class="checkmark xs-mark">50</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="60">
                  <span class="checkmark">60</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="70">
                  <span class="checkmark xs-mark">70</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="80">
                  <span class="checkmark">80</span>
                </label>
                <label class="container xs-container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="90">
                  <span class="checkmark xs-mark">90</span>
                </label>
                <label class="container">
                  <input type="radio" name="abc_16" v-model="abc.abc_16" :value="100">
                  <span class="checkmark">100</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <ABCscaleResultDialog v-if="isShowModal" @callBackModal="callBackModal" :total="total" :fbDocId="fbDocId" :abc="abc"/>
  </div>
</template>

<script>
import {mdbScrollbar} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import ABCscaleResultDialog from "@/views/therapist/menu/doEvaluation/paper/abc/ABCscaleResultDialog";

export default {
  name: "ABCscale",
  components: {mdbScrollbar, ABCscaleResultDialog},
  props: {
    fbDocId: String,
    abc: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: null,
      tools: {
        abc: {}
      },
      isShowModal: false,
    };
  },
  methods: {
    async tempSave() {
      const self = this;
      await self.calculator();
      self.tools.abc = self.abc;
      self.tools.abc['state'] = 1;
      self.tools.abc['total'] = null;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'abc', 1)
          });
    },
    checkNullData() {
      const self = this;
      const tempAbc = {...self.abc}
      delete tempAbc.total
      if (JSON.stringify(tempAbc).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    calculator() {
      const self = this;
      self.total = (self.abc.abc_1 + self.abc.abc_2 + self.abc.abc_3 + self.abc.abc_4 + self.abc.abc_5 + self.abc.abc_6 + self.abc.abc_7 + self.abc.abc_8 +
          self.abc.abc_9 + self.abc.abc_10 + self.abc.abc_11 + self.abc.abc_12 + self.abc.abc_13 + self.abc.abc_14 + self.abc.abc_15 + self.abc.abc_16) / 16
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      await self.calculator();
      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'abc', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

.question {
  width: 100%;
  height: 48px;
  background: #F1F6FD;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.question img {
  width: 20px;
  height: 20px;
  margin: 0 6px 0 20px;
}

.question h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0069CA;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 62px;
  font-size: 16px;
}

.td-answer {
  height: 48px !important;
  text-align: center;
}


.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}

.paper-frame {
  width: 100%;
  height: 570px;
  margin: 0;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

.paper-topic {
  padding-left: 20px;
  font-weight: 700;
  background: #F1F6FD;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}


input::placeholder {
  color: #B1B1B1;
}

.text-red {
  color: #E14F4E;
  padding-left: 10px;
}

.text-blue {
  color: #0069CA;
  text-align: right;
  padding-right: 10px;
}

.paper-frame {
  overflow: auto;
}

.td-task {
  text-align: left;
  padding-left: 20px;
  font-weight: 400;
}

.td-weight {
  height: 48px;
  font-weight: 500;
  background: #F8F8FA;
}

/*radio custom*/

.radio-group {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container {
  cursor: pointer;
  width: 46px;
  font-size: 18px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 10px 0 0;
  padding: 0 !important;
}

.container:last-child {
  margin-right: 0;
}


/* Hide the browser's default radio button */
.container input {
  /*position: absolute;*/
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: #F8F8FA;
  border-radius: 100px;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
}

.nt-mark {
  width: 86px !important;
  height: 38px !important;
  background-color: #fff;
  border-radius: 4px !important;
  border: 2px solid #E1E3E6;
  color: #B1B1B1;
  font-size: 16px !important;
  font-weight: 400;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0069CA;
  color: #fff;
  border: 2px solid #0069CA;
}

.divided-line {
  background: #E1E3E6;
  width: 2px;
  height: 46px;
  margin: 0 20px 0 10px;
}


</style>
