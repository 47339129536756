<template>
  <div>
    <div class="pdp-third-container">
      <img src="@/assets/images/IA/label_patients_sel.png" alt="label">
      <div class="guide-container">
        <img src="@/assets/images/IA/check-circle.png" alt="circle">
        <p>평가 항목을 지정하고 조회하면 최근까지 이뤄진 모든 날짜의 평가 기록을 확인할 수 있습니다.</p>
      </div>
      <div class="select-container">
        <!--        <mdb-select-->
        <!--            outline-->
        <!--            v-model="diagnosisOptions"-->
        <!--            placeholder="받은 진단명 선택"-->
        <!--            @getValue="setSelectDiagnosis"-->
        <!--            class="search-select "/>-->
        <!--        <mdb-select-->
        <!--            outline-->
        <!--            v-model="handsOptions"-->
        <!--            placeholder="손상측"-->
        <!--            @getValue="setSelectHands"-->
        <!--            class="search-select sel-hands"/>-->
        <!--        <mdb-select-->
        <!--            outline-->
        <!--            v-model="partsOptions"-->
        <!--            placeholder="손상 부위"-->
        <!--            @getValue="setSelectParts"-->
        <!--            class="search-select sel-parts"/>-->
        <mdb-date-picker-2 far datePickerIcon="fas fa-calendar" v-model="selectStartDate" class="data-pick" title="날짜선택" :options="options"/>
        <p style="margin: 0 5px">-</p>
        <mdb-date-picker-2 far datePickerIcon="fas fa-calendar" v-model="selectEndDate" class="data-pick" title="날짜선택" :options="options"/>
        <mdb-select
            style="margin-left: auto; margin-right: 10px"
            outline
            v-model="toolsOptions"
            placeholder="평가도구 선택"
            @getValue="setSelectTools"
            class="search-select"/>

        <button class="btn-search" @click="searchStatistics">검색</button>
      </div>
      <div class="pdf-container">

        <img src="@/assets/images/IA/pdf_download.png" alt="download" class="pdf_download" @click="goPdf" v-show="isShowChart">
      </div>
      <div class="search-container" :class="{active : isShowChart === true}">
        <h5 v-html="statistics" v-show="!isShowChart"></h5>
        <mdb-line-chart
            :data="lineChartData"
            :options="lineChartOptions"
            :width="1070"
            :height="174"
            v-if="isShowChart"
        />
      </div>
      <StatisticsFm :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart && selectTool==='fm'"/>
      <StatisticsBb :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='bb'"/>
      <StatisticsSis :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='sis'"/>
      <StatisticsAaut :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='aaut'"/>
      <StatisticsMal :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='mal'"/>
      <StatisticsWmft :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='wmft'"/>
      <StatisticsBbs :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='bbs'"/>
      <StatisticsTug :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='tug'"/>
      <StatisticsFtsts :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='ftsts'"/>
      <StatisticsFm_l :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='fm_l'"/>
      <StatisticsTmwt :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='tmwt'"/>
      <StatisticsSmwt :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='smwt'"/>
      <StatisticsDgi :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='dgi'"/>
      <StatisticsAbc :selectTool="selectTool" :dataTableList="dataTableList" :isShowChart="isShowChart" v-if="isShowChart&& selectTool==='abc'"/>
    </div>
  </div>

</template>

<script>
import {firebase} from "@/firebase/firebaseConfig";
import {mdbDatePicker2, mdbSelect, mdbLineChart} from "mdbvue";
import {getDate} from "@/lib/prettyDate";
import StatisticsFm from "@/views/therapist/menu/patientsManage/detail/StatisticsFm";
import StatisticsBb from "@/views/therapist/menu/patientsManage/detail/StatisticsBb";
import StatisticsSis from "@/views/therapist/menu/patientsManage/detail/StatisticsSis";
import StatisticsAaut from "@/views/therapist/menu/patientsManage/detail/StatisticsAaut";
import StatisticsMal from "@/views/therapist/menu/patientsManage/detail/StatisticsMal";
import StatisticsWmft from "@/views/therapist/menu/patientsManage/detail/StatisticsWmft";
import StatisticsBbs from "@/views/therapist/menu/patientsManage/detail/StatisticsBbs";
import StatisticsTug from "@/views/therapist/menu/patientsManage/detail/StatisticsTug";
import StatisticsFtsts from "@/views/therapist/menu/patientsManage/detail/StatisticsFtsts";
import StatisticsFm_l from "@/views/therapist/menu/patientsManage/detail/StatisticsFm_l";
import StatisticsSmwt from "@/views/therapist/menu/patientsManage/detail/StatisticsSmwt";
import StatisticsTmwt from "@/views/therapist/menu/patientsManage/detail/StatisticsTmwt";
import StatisticsDgi from "@/views/therapist/menu/patientsManage/detail/StatisticsDgi";
import StatisticsAbc from "@/views/therapist/menu/patientsManage/detail/StatisticsAbc";

export default {
  name: "StatisticsView",
  components: {
    StatisticsWmft,
    StatisticsMal,
    StatisticsAaut,
    StatisticsSis,
    StatisticsBb,
    StatisticsFm,
    StatisticsBbs,
    StatisticsTug,
    StatisticsFtsts,
    StatisticsFm_l,
    StatisticsTmwt,
    StatisticsSmwt,
    StatisticsDgi,
    StatisticsAbc,
    mdbDatePicker2,
    mdbSelect,
    mdbLineChart,
  },
  props: {
    fbDataPatientsInfo: Object
  },
  data() {
    return {
      fbCollection: 'patients',
      fbDocId: this.$route.params.id,
      selectDiagnosis: '뇌졸중',
      selectPart: '상지',
      selectHand: '오른쪽',
      selectTool: 'fm',
      selectStartDate: '',
      selectEndDate: '',
      statistics: '조회할 항목을 선택해주세요.',
      statisticsList: [],
      diagnosisOptions: [
        {text: "뇌졸중", value: "뇌졸중", selected: true},
        {text: "파킨슨", value: "파킨슨"},
        {text: "외상성 뇌손상", value: "외상성 뇌손상"},
        {text: "척수 손상", value: "척수 손상"},
        {text: "치매", value: "치매"},
        {text: "암", value: "암"},
        {text: "다발성 경화증", value: "다발성 경화증"},
        {text: "뇌정증(간질)", value: "뇌정증(간질)"},
        {text: "말초신경병증", value: "말초신경병증"},
      ],
      handsOptions: [
        {text: "오른쪽", value: "오른쪽", selected: true},
        {text: "왼쪽", value: "왼쪽"},
        {text: "양쪽 모두", value: "양쪽 모두"},
      ],
      partsOptions: [
        {text: "상지", value: "상지", selected: true},
        {text: "하지", value: "하지"},
        {text: "상,하지 모두", value: "상,하지 모두"},
      ],
      toolsOptions: [
        {text: "Fugl-Meyer(상)", value: "fm", selected: true},
        {text: "BB", value: "bb"},
        {text: "WMFT", value: "wmft"},
        {text: "SIS", value: "sis"},
        {text: "AAUT", value: "aaut"},
        {text: "MAL", value: "mal"},
        {text: "BBS", value: "bbs"},
        {text: "TUG", value: "tug"},
        {text: "FTSTS", value: "ftsts"},
        {text: "Fugl-Meyer(하)", value: "fm_l"},
        {text: "10MWT", value: "tmwt"},
        {text: "6MWT", value: "smwt"},
        {text: "DGI", value: "dgi"},
        {text: "ABCscale", value: "abc"},
      ],
      options: {
        month: ['1 /', '2 /', '3 /', '4 /', '5 /', '6 /', '7 /', '8 /', '9 /', '10 /', '11 /', '12 /'],
        week: ['일', '월', '화', '수', '목', '금', '토'],
        color: {
          header: 'info',
          headerText: 'white',
          checkedDay: 'info'
        },
        buttons: {
          ok: '확인',
          cancel: '취소',
          clear: '초기화'
        }
      },
      search: '',
      fieldName: '',
      isShowChart: false,
      dataTableList: [],
      lineChartData: {
        labels: [],
        datasets: []
      },
      lineChartOptions: {
        legend: {
          fullWidth: false,
          position: 'bottom'
        },
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    setSelectDiagnosis(value) {
      const self = this;
      self.selectDiagnosis = value;
    },
    setSelectParts(value) {
      const self = this;
      self.selectPart = value;
    },
    setSelectHands(value) {
      const self = this;
      self.selectHand = value;
    },
    setSelectTools(value) {
      const self = this;
      self.selectTool = value;
      self.isShowChart = false;
    },
//통계자료
    searchStatistics() {
      const self = this;
      self.resetChartData();
      self.isShowChart = false;
      if (self.selectDiagnosis === '' || self.selectPart === '' || self.selectHand === '' || self.selectTool === '') {
        return alert('모든 항목을 선택해 주세요.');
      }

      self.statisticsList = [];
      let docBasic = firebase.firestore().collection('evaluations')
          .where('patientInfo.uid', '==', self.fbDataPatientsInfo.userInfo.uid)
          .where('state', '==', 1);

      // if (self.selectDiagnosis !== '') {
      //   docBasic = docBasic
      //       .where('diseaseInfo.diagnosis', '==', self.selectDiagnosis)
      // }
      // if (self.selectPart !== '') {
      //   docBasic = docBasic
      //       .where('diseaseInfo.injuredPart', '==', self.selectPart)
      // }
      // if (self.selectHand !== '') {
      //   docBasic = docBasic
      //       .where('diseaseInfo.usedHand', '==', self.selectHand)
      // }

      if (self.selectTool !== '') {
        if (self.selectTool === 'fm') {
          docBasic = docBasic
              .where('tools.fm.state', '==', 2)
        } else if (self.selectTool === 'wmft') {
          docBasic = docBasic
              .where('tools.wmft.state', '==', 2)
        } else if (self.selectTool === 'sis') {
          docBasic = docBasic
              .where('tools.sis.state', '==', 2)
        } else if (self.selectTool === 'mal') {
          docBasic = docBasic
              .where('tools.mal.state', '==', 2)
        } else if (self.selectTool === 'bb') {
          docBasic = docBasic
              .where('tools.bb.state', '==', 2)
        } else if (self.selectTool === 'aaut') {
          docBasic = docBasic
              .where('tools.aaut.state', '==', 2)
        } else if (self.selectTool === 'bbs') {
          docBasic = docBasic
              .where('tools.bbs.state', '==', 2)
        } else if (self.selectTool === 'tug') {
          docBasic = docBasic
              .where('tools.tug.state', '==', 2)
        } else if (self.selectTool === 'ftsts') {
          docBasic = docBasic
              .where('tools.ftsts.state', '==', 2)
        } else if (self.selectTool === 'fm_l') {
          docBasic = docBasic
              .where('tools.fm_l.state', '==', 2)
        } else if (self.selectTool === 'tmwt') {
          docBasic = docBasic
              .where('tools.tmwt.state', '==', 2)
        } else if (self.selectTool === 'smwt') {
          docBasic = docBasic
              .where('tools.smwt.state', '==', 2)
        } else if (self.selectTool === 'dgi') {
          docBasic = docBasic
              .where('tools.dgi.state', '==', 2)
        } else if (self.selectTool === 'abc') {
          docBasic = docBasic
              .where('tools.abc.state', '==', 2)
        }
      }

      if (self.selectStartDate !== '') {
        let start = new Date(self.selectStartDate);
        start.setHours(0, 0, 0, 0)
        docBasic = docBasic
            .where('regDate', '>=', start)
      }

      if (self.selectEndDate !== '') {
        let end = new Date(self.selectEndDate);
        end.setHours(23, 59, 59, 999)
        docBasic = docBasic
            .where('regDate', '<=', end)
      }

      self.realQuery(docBasic)
    },
    realQuery(doc) {
      const self = this;
      self.dataTableList.splice(0)
      let fmLineData = {
        label: self.selectTool,
        backgroundColor: "rgba(36, 194, 153, 0.1)",
        borderColor: "rgba(36, 194, 153, 0.6)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData2 = {
        label: self.selectTool,
        backgroundColor: "rgba(255, 99, 132, 0.1)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData3 = {
        label: self.selectTool,
        backgroundColor: "rgba(151,187,205,0.2)",
        borderColor: "rgba(151,187,205,1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }

      let fmLineData4 = {
        label: self.selectTool,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 3,
        lineTension: 0,
        data: []
      }


      doc.orderBy('regDate', 'asc')
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          self.statistics = '데이터가 없습니다.'
          return
        }
        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDate(date);
          // 챠트라벨넣기
          self.lineChartData.labels.push(getDate(date));

          if (self.selectTool === 'fm') {
            fmLineData.data.push(_data.tools.fm.totalAll);
          } else if (self.selectTool === 'bb') {
            fmLineData.data.push(_data.tools.bb.la)
            fmLineData2.data.push(_data.tools.bb.ma)
          } else if (self.selectTool === 'sis') {
            fmLineData.data.push(_data.tools.sis.totalAvg)
            fmLineData2.data.push(_data.tools.sis.totalSum)
          } else if (self.selectTool === 'aaut') {
            fmLineData.data.push(_data.tools.aaut.totalAouScore)
            fmLineData2.data.push(_data.tools.aaut.totalQomScore)
            // fmLineData3.data.push(_data.tools.aaut.totalAouAvg)
            // fmLineData4.data.push(_data.tools.aaut.totalQomAvg)
          } else if (self.selectTool === 'mal') {
            fmLineData.data.push(_data.tools.mal.totalAouScore)
            fmLineData2.data.push(_data.tools.mal.totalQomScore)
            // fmLineData3.data.push(_data.tools.mal.totalAouAvg)
            // fmLineData4.data.push(_data.tools.mal.totalQomAvg)
          } else if (self.selectTool === 'wmft') {
            fmLineData.data.push(_data.tools.wmft.totalAvg)
          } else if (self.selectTool === 'bbs') {
            fmLineData.data.push(_data.tools.bbs.total)
          } else if (self.selectTool === 'tug') {
            fmLineData.data.push(_data.tools.tug.time)
          } else if (self.selectTool === 'ftsts') {
            fmLineData.data.push(_data.tools.ftsts.time)
          } else if (self.selectTool === 'fm_l') {
            fmLineData.data.push(_data.tools.fm_l.totalAll)
          } else if (self.selectTool === 'tmwt') {

            _data.tools.tmwt['average_self_selected_speed'] = _data.tools.tmwt.self_selected_speed.average_self_selected
            _data.tools.tmwt['average_fast_speed'] = _data.tools.tmwt.fast_speed.average_self_selected
            fmLineData.data.push(_data.tools.tmwt.average_self_selected_speed)
            fmLineData2.data.push(_data.tools.tmwt.average_fast_speed)
          } else if (self.selectTool === 'smwt') {

            _data.tools.smwt['total'] =
                _data.tools.smwt.distance_1 + _data.tools.smwt.distance_2 + _data.tools.smwt.distance_3 +
                _data.tools.smwt.distance_4 + _data.tools.smwt.distance_5 + _data.tools.smwt.distance_6
            fmLineData.data.push(_data.tools.smwt.total)
          } else if (self.selectTool === 'dgi') {
            fmLineData.data.push(_data.tools.dgi.total)
          } else if (self.selectTool === 'abc') {
            fmLineData.data.push(_data.tools.abc.total)
          }

          self.statisticsList.push(_data);
          self.dataTableList.push(_data);


          // console.log('statisticsList', self.statisticsList)
          // console.log('dataTableList', self.dataTableList)
        });

        self.setLabel(fmLineData, fmLineData2, fmLineData3, fmLineData4);
        self.isShowChart = true;
      });
    },

    setLabel(fmLineData, fmLineData2, fmLineData3, fmLineData4) {
      const self = this;
      switch (self.selectTool) {
        case 'fm':
          fmLineData.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'bb':
          fmLineData.label = 'Less-Affected Hand'
          fmLineData2.label = 'More-Affected Hand'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'sis':
          fmLineData.label = '평균'
          fmLineData2.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'aaut':
        case 'mal':
          fmLineData.label = 'AOU Score'
          fmLineData2.label = 'QOM Score'
          fmLineData3.label = 'AOU AVG'
          fmLineData4.label = 'QOM AVG'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          // self.lineChartData.datasets.push(fmLineData3)
          // self.lineChartData.datasets.push(fmLineData4)
          return
        case 'wmft':
          fmLineData.label = '평균'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'bbs':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'tug':
          fmLineData.label = '시간(초)'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'ftsts':
          fmLineData.label = '시간(초)'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'fm_l':
          fmLineData.label = '합계'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'tmwt':
          fmLineData.label = 'self selected speed'
          fmLineData2.label = 'fast speed'
          self.lineChartData.datasets.push(fmLineData)
          self.lineChartData.datasets.push(fmLineData2)
          return
        case 'smwt':
          fmLineData.label = '총 거리'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'dgi':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
        case 'abc':
          fmLineData.label = '총점'
          self.lineChartData.datasets.push(fmLineData)
          return
      }
    },

    resetChartData() {
      const self = this;
      self.lineChartData.datasets = [];
      self.lineChartData.labels = [];
    },

    goPdf() {
      const self = this;
      if (!self.isShowChart) {
        alert('평가항목을 선택해 주세요.')
        return
      }
      self.$router.push({
        name: 'pdf',
        query: {
          selectDiagnosis: self.selectDiagnosis,
          selectPart: self.selectPart,
          selectHand: self.selectHand,
          selectTool: self.selectTool,
          selectStartDate: self.selectStartDate,
          selectEndDate: self.selectEndDate,
        }
      });
    },
  }
}
</script>

<style scoped>
.pdp-third-container {
  width: 100%;
  min-height: 448px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.pdp-third-container img {
  width: 124px;
  height: 28px;
}

.guide-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.guide-container img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.guide-container p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #0069CA;
  margin: 0;
}

.select-container {
  width: 1070px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.pdf-container {
  display: flex;
  width: 1070px;
  align-items: center;
}

.search-container {
  width: 1070px;
  min-height: 218px;
  background: #F8F8FA;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.search-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #B1B1B1;
}

.pdf-container p {
  margin: 0 5px;
}

.pdf_download {
  width: 185px !important;
  height: 42px !important;
  margin-left: auto;
  cursor: pointer;
}

.btn-search {
  width: 101px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: none;

}

.btn-reset {
  margin-left: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: red;
  cursor: pointer;
}


/deep/ .md-form {
  margin: 0;
}

/deep/ .form-control {
  margin: 0;
}


/deep/ .search-select .md-form {
  width: 288px;
  background: url('../../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
  height: 40px;
}

/deep/ .sel-hands .md-form {
  width: 197px !important;
}

/deep/ .sel-parts .md-form {
  width: 184px !important;
}

.active {
  background: #ffffff;
}
</style>
