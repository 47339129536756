<template>
  <div class="sub-frame">
    <div class="modal-cover" v-show="isShowModal||isShowCompleteModal||isCreatePatient||isCreatePatientSuccess"></div>
    <h1 class="title">환자관리</h1>
    <div class="search-frame">
      <p class="search-count">총 환자({{ patientCount }})</p>
      <div class="search-box">
        <mdb-select
            :scroll=false
            outline
            v-model="basicOptions"
            placeholder="환자 번호"
            @getValue="getSelectMainValue"
            class="search-select "/>
        <div class="basic-search" v-if="!isDiagnosis">
          <div class="icon-input">
            <img src="@/assets/images/IA/icon_search.png" alt="search">
            <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
          </div>
          <button class="custom-btn-box search-btn">검색</button>
        </div>
        <div class="diagnosis-search" v-else>
          <mdb-select
              :scroll=false
              outline
              v-model="diagnosisOptions"
              placeholder="뇌졸중"
              @getValue="getSelectDiagnosisValue"
              class="search-select diagnosis-select"/>
          <button class="custom-btn-box search-btn">조회</button>
        </div>
        <div class="patient-btn-box">
          <div class="custom-btn-box create-patient" @click="isCreatePatient = true">
            <img src="@/assets/images/IA/icon_user_create.png" alt="add">
            환자 신규 생성
          </div>
          <div class="custom-btn-box add-patient" @click="showModal">
            <img src="@/assets/images/IA/icon_add_patient.png" alt="add">
            환자 차트 등록
          </div>
        </div>
      </div>
    </div>
    <div class="table-frame">
      <mdb-datatable-2
          v-model="data"
          class="text-center custom-table"
          borderless
          hover
          fullPagination
          :searching="{value: search, field: fieldName} "
          noFoundMessage="조회된 환자가 없습니다."/>
      <div class="data-null" v-show="!isDataNull">
        <img src="@/assets/images/IA/img_null.png" alt="null">
        <p>등록된 환자가 없습니다.</p>
      </div>

    </div>
    <PatientsCreateSuccessDialog v-if="isCreatePatientSuccess" @callBackModalCreateUser="callBackModalCreateUser" :patientInfo="patientInfo"/>
    <PatientsCreateDialog v-if="isCreatePatient" @callBackModalCreateUser="callBackModalCreateUser"/>
    <PatientAddDialog v-if="isShowModal" @callBackModal="callBackModal" :patientCount="patientCount"/>
    <PatientAddCompleteDialog v-if="isShowCompleteModal" @callBackModal="callBackModal" :patientInfo="patientInfo"/>
  </div>
</template>

<script>
import {mdbDatatable2, mdbSelect} from "mdbvue";
import patient_columns from "@/assets/data/patient_columns";
import PatientAddDialog from "@/views/therapist/menu/patientsManage/PatientsAddDialog";
import PatientAddCompleteDialog from "@/views/therapist/menu/patientsManage/PatientsAddCompleteDialog";
import selectOption_data from "@/assets/data/selectOption_data";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import PatientsCreateDialog from "@/views/therapist/menu/patientsManage/PatientsCreateDialog";
import PatientsCreateSuccessDialog from "@/views/therapist/menu/patientsManage/PatientsCreateSuccessDialog";

export default {
  name: "PatientsManagePage",
  components: {
    PatientsCreateDialog,
    PatientAddDialog,
    PatientAddCompleteDialog,
    PatientsCreateSuccessDialog,
    mdbDatatable2,
    mdbSelect,
  },
  data() {
    return {
      fbCollection: 'patients',
      fbTherapistUid: this.$store.state.user.uid,
      basicOptions: selectOption_data.basicOptions,
      diagnosisOptions: [
        {text: "뇌졸중", value: "뇌졸중", selected: true},
        {text: "파킨슨", value: "파킨슨"},
        {text: "외상성 뇌손상", value: "외상성 뇌손상"},
        {text: "척수 손상", value: "척수 손상"},
        {text: "치매", value: "치매"},
        {text: "암", value: "암"},
        {text: "다발성 경화증", value: "다발성 경화증"},
        {text: "뇌정증(간질)", value: "뇌정증(간질)"},
        {text: "말초신경병증", value: "말초신경병증"},
      ],
      data: {
        columns: patient_columns,
        rows: []
      },
      search: '',
      fieldName: 'patientNo',
      isShowModal: false,
      isCreatePatient: false,
      isCreatePatientSuccess: false,
      isShowCompleteModal: false,
      isDataNull: false,
      isDiagnosis: false,
      patientCount: 0,
      patientInfo: {
        userInfo: {},
      }
    }
  },
  created() {
    window.goDetail = this.goDetail;
  },
  mounted() {
    this.storageReset()
    this.dataInit();
  },
  methods: {
    storageReset() {
      delete localStorage.evaluationId
      delete localStorage.isEvaluationEdit
    },
    async dataInit() {
      await this.getDataPatientTable()
      this.setNullDataDesign()
    },
    setNullDataDesign() {
      const self = this;
      if (!self.isDataNull) {
        const _td = document.getElementsByClassName('dataTable').item(0)
            .children.item(1).childNodes.item(0).childNodes.item(0)
        if (self.data.rows.length === 0) {
          _td.style.height = '384px'
          _td.style.opacity = 0
        }
      }
    },
    getDataPatientTable() {
      const self = this;
      const db = firebase.firestore();
      db.collection(self.fbCollection).where('therapistUid', '==', self.fbTherapistUid).orderBy("regDate", "desc")
          // db.collection(self.fbCollection).orderBy("patientNo", "desc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          self.isDataNull = false
          return
        }
        self.patientCount = querySnapshot.size;
        self.isDataNull = true
        querySnapshot.forEach((doc) => {
          const _data = doc.data();
          _data["docId"] = doc.id;
          const date = new Date(_data.regDate.seconds * 1000);
          _data.regDate = getDate(date);
          _data.name = _data.userInfo.name
          _data.visitCount = '<p class="blue-color">' + _data.visitCount + "회차" + '</p>'
          _data.id = _data.userInfo.id
          // _data.action = '<img src="/images/btn_manage_search_patients.png" alt="img" class="action-btn" onclick="goDetail(\'' + doc.id + '\')">'
          _data.action = '<button class="btn-manage" onclick="goDetail(\'' + doc.id + '\')"><img src="../images/icon_search.png" alt="img"/>환자 조회</button>'
          self.data.rows.push(_data);
        });
      })
    },


    callBackModal: function (index, isShowModal, data) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
          self.isCreatePatient = isShowModal
          self.isCreatePatientSuccess = isShowModal
        } else {
          self.patientInfo = data;
          self.isShowModal = !isShowModal
          self.isCreatePatient = !isShowModal
          self.isCreatePatientSuccess = !isShowModal
          self.showCompleteModal()
        }
      } else {
        if (!isShowModal) {
          self.isShowCompleteModal = isShowModal
          self.data.rows.splice(0)
          this.dataInit();
        } else {
          self.$router.push('/therapist/doEvaluation').catch((err) => {
            console.log(err)
          })
        }
      }
    },

    callBackModalCreateUser: function (index, data) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
        self.isCreatePatient = false
        self.isCreatePatientSuccess = false
      } else {
        self.patientInfo = data;
        self.isShowModal = false
        self.isCreatePatient = false
        self.isCreatePatientSuccess = true
      }
    },

    showModal() {
      const self = this;
      // const _body = document.getElementsByTagName('body').item(0)
      self.isShowModal = !self.isShowModal
      if (self.isShowModal) {
        // _body.style.overflow = 'hidden';
        window.scrollTo(0, 0);
      } else {
        // _body.style.overflow = 'unset';
      }
    },
    showCompleteModal() {
      const self = this;
      // const _body = document.getElementsByTagName('body').item(0)
      self.isShowCompleteModal = !self.isShowCompleteModal
      if (self.isShowCompleteModal) {
        // _body.style.overflow = 'hidden';
        window.scrollTo(0, 0);
      } else {
        // _body.style.overflow = 'unset';
      }
    },
    getSelectMainValue(value) {
      const self = this;
      this.fieldName = value
      self.isDiagnosis = value === 'diagnosis';
    },
    getSelectDiagnosisValue(value) {
      const self = this;
      self.search = value;
    },
    goDetail(value) {
      const self = this;
      self.$router.push('/therapist/patientsManage/' + value).catch((err) => {
        console.log(err)
      });
    }
  }
}
</script>

<style scoped>
.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.sub-frame {
  padding: 40px 15px;
}

.search-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px 20px;
  width: 100%;
  height: 116px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 12px;
}

.table-frame {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  background: #fff !important;
}

/deep/ .mdb-datatable thead th {
  font-size: 16px !important;
}

.search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.basic-search {
  display: flex;
  flex-direction: row;
}

.diagnosis-search {
  display: flex;
  flex-direction: row;
}

/deep/ .diagnosis-search .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
}

/deep/ .diagnosis-search .form-control::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #B1B1B1 !important;
}


.search-count {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 10px;
}

/deep/ .search-select .md-form {
  width: 145px;
  margin: 0 10px 0 0;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .diagnosis-select .md-form {
  width: 268px;
  margin: 0;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

/deep/ .dropdown-content li > span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.search-input {
  width: 282px;
  padding-left: 30px;
  background: #EDEDF1;
  font-size: 16px;
}

.icon-input {
  position: relative;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-btn {
  width: 78px;
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
}

.patient-btn-box {
  display: flex;
  margin-left: auto;
}

.add-patient, .create-patient {
  width: 145px;
  background-color: #24C299;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-patient {
  margin-right: 10px;
  background: #ffffff;
  color: #24C299;
  border: 1px #24C299 solid;
}

.add-patient img,
.create-patient img {
  width: 14px;
  height: 14px;
  margin-right: 6px;
  margin-top: 2px;
}

.data-null {
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}

.data-null img {
  width: 131px;
  height: 105px;
  margin-bottom: 20px;
}

.data-null p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}


/deep/ .mdb-datatable thead th {
  font-size: 16px;
  font-weight: 700;
}

/deep/ .mdb-datatable tbody td:nth-child(3n) {
  color: #8D8D8D;
  font-weight: 400;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F1F6FD;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(227, 238, 252, 1);
}


/deep/ .action-btn {
  width: 118px;
  height: 40px;
  cursor: pointer;
  z-index: 10;
}

/deep/ .blue-color {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 0;
}

/deep/ td {
  vertical-align: middle;
  font-size: 16px !important;
}

/deep/ .mdb-datatable .btn-manage {
  width: 118px;
  height: 40px;
  border: 1px solid #0169ca;
  background: #0169ca;
  border-radius: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  color: #FFFFFF;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

/deep/ .mdb-datatable .btn-manage img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-top: 2px;
}

</style>
