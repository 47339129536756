<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        환자를 삭제하시겠습니까?
      </h5>
    </div>
    <div class="modal-center">
      <p>환자를 삭제하게되면 평가 기록 모두 삭제됩니다.<br/>
        삭제된 데이터는 복구할 수 없습니다.
      </p>
    </div>
    <div class="modal-footer">
      <button class="custom-btn-box-white" @click="btnCancel">닫기</button>
      <button class="primary-blue custom-btn-box btn-delete" @click="btnDelete">삭제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientsDeleteDialog",
  methods: {
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 1, false)
    },
    async btnDelete() {
      const self = this;
      const delConfirm = confirm('정말로 삭제하시겠습니까?\n' + '\n삭제 시 모든 데이터가 사라집니다.\n' + '정말 삭제하시겠습니까?');
      if (delConfirm) {
        self.$emit('callBackModal', 1, true);
      } else {
        self.$emit('callBackModal', 1, false)
      }
    },
  },
}
</script>

<style scoped>
.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 30px;
  background: #F8F8FA;
}

.modal-center p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #000000;
}

.modal-footer {
  background: #FFFFFF;
  padding: 16px;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

button {
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.0125em;
  width: 84px !important;
}

.btn-delete {
  text-transform: uppercase;
  background: #E14F4E !important;
}

</style>
