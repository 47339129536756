<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Five Times Sit to Stand Test(FTSTS)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <mdb-scrollbar class="scroll-frame" width="100%">
      <div class="paper-frame">
        <div class="paper-guide-frame">
          <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
          <p class="paper-guide">의자에서 일어났다가 앉는 동작을 5번 수행할 때의 시간을 측정하는 평가로 하지의 힘으로만 일어나도록 합니다.</p>
        </div>
        <div class="form-container">
          <div class="form-group" style="margin-right: 10px">
            <label for="moreAffectedHand">시간(초) Time(second)</label>
            <input type="number" id="moreAffectedHand" class="form-control" placeholder="시간입력" v-model.number="ftsts.time" min="0">
            <p class="time-unit">s</p>
          </div>
        </div>
      </div>
    </mdb-scrollbar>

    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
  </div>

</template>

<script>
import {mdbScrollbar} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "FiveTimesSitToStandTest",
  components: {
    mdbScrollbar,
  },
  props: {
    fbDocId: String,
    ftsts: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      tools: {
        ftsts: {
          time: ''
        }
      },
      isShowModal: false,
    };
  },
  methods: {
    async tempSave() {
      const self = this;
      self.tools.ftsts = self.ftsts;
      if (self.tools.ftsts.time < 0) {
        alert('0 미만으로 내려갈 수 없습니다.')
        return
      } else if (self.tools.ftsts.time === null || self.tools.ftsts.time === '') {
        alert('시간을 입력하지 않았습니다.')
        return
      }

      self.tools.ftsts['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'ftsts', 1)
          });
    },
    async resultSave() {
      const self = this;
      self.tools.ftsts = self.ftsts;

      if (self.tools.ftsts.time < 0) {
        alert('0 미만으로 내려갈 수 없습니다.')
        return
      } else if (self.tools.ftsts.time === null || self.tools.ftsts.time === '') {
        alert('시간을 입력하지 않았습니다.')
        return
      }
      self.btnConfirm()
    },
    btnConfirm() {
      const self = this;
      self.tools.ftsts = self.ftsts;
      self.tools.ftsts['state'] = 2;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'ftsts', 2)
          });
    },
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.form-container {
  display: flex;
  align-items: center;
}

.form-group {
  width: 570px;
  position: relative;
}

.form-group > input {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E1E3E6;
  height: 40px;
  padding-right: 26px;
  line-height: 22px;
}

.time-unit {
  position: absolute;
  bottom: 9px;
  right: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #A0B0C6;
}

input::placeholder {
  color: #B1B1B1;
}

.form-group > label {
  color: #0069CA;
  font-weight: 500;
  margin-bottom: 10px;
}

.paper-main-frame {
  background-color: #ffffff !important;
  position: relative;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  margin: 0;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}

input {
  text-align: right;
  padding-right: 14px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>
