import { render, staticRenderFns } from "./PatientsCreateSuccessDialog.vue?vue&type=template&id=4936c25a&scoped=true&"
import script from "./PatientsCreateSuccessDialog.vue?vue&type=script&lang=js&"
export * from "./PatientsCreateSuccessDialog.vue?vue&type=script&lang=js&"
import style0 from "./PatientsCreateSuccessDialog.vue?vue&type=style&index=0&id=4936c25a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4936c25a",
  null
  
)

export default component.exports