import { render, staticRenderFns } from "./BergBalanceScaleResultDialog.vue?vue&type=template&id=0ff4cb20&scoped=true&"
import script from "./BergBalanceScaleResultDialog.vue?vue&type=script&lang=js&"
export * from "./BergBalanceScaleResultDialog.vue?vue&type=script&lang=js&"
import style0 from "./BergBalanceScaleResultDialog.vue?vue&type=style&index=0&id=0ff4cb20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff4cb20",
  null
  
)

export default component.exports