export default {
    tools: {
        bm: {name: 'Basic Measure', checked: false,},
        rom_mmt: {
            name: 'rom_mmt', checked: false, state: 0,
            rom: {
                lt: {
                    shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    elbow: {flexion: null, extension: null,},
                    forearm: {supination: null, pronation: null,},
                    wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                    hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                    ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
                },
                rt: {
                    shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    elbow: {flexion: null, extension: null,},
                    forearm: {supination: null, pronation: null,},
                    wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                    hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                    ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
                }
            },
            mmt: {
                lt: {
                    shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    elbow: {flexion: null, extension: null,},
                    forearm: {supination: null, pronation: null,},
                    wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                    hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                    ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
                },
                rt: {
                    shoulder: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    elbow: {flexion: null, extension: null,},
                    forearm: {supination: null, pronation: null,},
                    wrist: {flexion: null, extension: null, radial_deviation: null, ulnar_deviation: null},
                    hip: {flexion: null, extension: null, abduction: null, adduction: null, internal_rotation: null, external_rotation: null},
                    knee: {flexion: null, extension: null, internal_rotation: null, external_rotation: null},
                    ankle: {dorsi_flexion: null, plantar_flexion: null, inversion: null, eversion: null}
                }
            }
        },
        mas: {
            name: 'mas', checked: false, state: 0,
            lt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null},
                elbow: {flexion: null, extension: null,},
                wrist: {flexion: null, extension: null,},
                hip: {flexion: null, extension: null, abduction: null, adduction: null},
                knee: {flexion: null,},
                ankle: {dorsi_flexion: null, plantar_flexion: null}
            },
            rt: {
                shoulder: {flexion: null, extension: null, abduction: null, adduction: null},
                elbow: {flexion: null, extension: null,},
                wrist: {flexion: null, extension: null,},
                hip: {flexion: null, extension: null, abduction: null, adduction: null},
                knee: {flexion: null,},
                ankle: {dorsi_flexion: null, plantar_flexion: null}
            }
        },
        balance: {
            name: 'balance', checked: false, state: 0,
            sitting: {static: null, dynamic: null},
            standing: {static: null, dynamic: null}
        },
        fm: {
            name: 'Fugl-Meyer(상)', checked: false, state: 0,
            a_1_1: null, a_1_2: null, a_2_1: null, a_2_2: null, a_2_3: null, a_2_4: null, a_2_5: null, a_2_6: null, a_2_7: null,
            a_2_8: null, a_2_9: null, a_3_1: null, a_3_2: null, a_3_3: null, a_4_1: null, a_4_2: null, a_4_3: null, a_5_1: null,
            b_1_1: null, b_1_2: null, b_1_3: null, b_1_4: null, b_1_5: null,
            c_1_1: null, c_1_2: null, c_1_3: null, c_1_4: null, c_1_5: null, c_1_6: null, c_1_7: null,
            d_1_1: null, d_1_2: null, d_1_3: null,
        },
        bb: {
            name: 'BB', checked: false, state: 0,
            ma: null, la: null
        },
        wmft: {
            name: 'WMFT', checked: false, state: 0,
            ma: {
                ma_1: null, ma_2: null, ma_3: null, ma_4: null, ma_5: null, ma_6: null, ma_7: null, ma_8: null, ma_9: null, ma_10: null,
                ma_11: null, ma_12: null, ma_13: null, ma_14: null, ma_15: null,
                kg: {kg_1: null, kg_2: null, kg_3: null,},
                pw: {pw_1: null, pw_2: null, pw_3: null,},
            },
            la: {
                la_1: null, la_2: null, la_3: null, la_4: null, la_5: null, la_6: null, la_7: null, la_8: null, la_9: null, la_10: null,
                la_11: null, la_12: null, la_13: null, la_14: null, la_15: null,
                kg: {kg_1: null, kg_2: null, kg_3: null,},
                pw: {pw_1: null, pw_2: null, pw_3: null,},
            },
        },
        sis: {
            name: 'SIS', checked: false, state: 0,
            a_1_1: null, a_1_2: null, a_1_3: null, a_1_4: null,
            a_2_1: null, a_2_2: null, a_2_3: null, a_2_4: null, a_2_5: null, a_2_6: null, a_2_7: null,
            a_3_1: null, a_3_2: null, a_3_3: null, a_3_4: null, a_3_5: null, a_3_6: null, a_3_7: null, a_3_8: null, a_3_9: null,
            a_4_1: null, a_4_2: null, a_4_3: null, a_4_4: null, a_4_5: null, a_4_6: null, a_4_7: null,
            a_5_1: null, a_5_2: null, a_5_3: null, a_5_4: null, a_5_5: null, a_5_6: null, a_5_7: null, a_5_8: null, a_5_9: null, a_5_10: null,
            a_6_1: null, a_6_2: null, a_6_3: null, a_6_4: null, a_6_5: null, a_6_6: null, a_6_7: null, a_6_8: null, a_6_9: null,
            a_7_1: null, a_7_2: null, a_7_3: null, a_7_4: null, a_7_5: null,
            a_8_1: null, a_8_2: null, a_8_3: null, a_8_4: null, a_8_5: null, a_8_6: null, a_8_7: null, a_8_8: null,
            a_9_1: null,
        },
        aaut: {
            name: 'AAUT', checked: false, state: 0,
            aou: {
                aou_1: null, aou_2: null, aou_3: null, aou_4: null, aou_5: null, aou_6: null, aou_7: null,
                aou_8: null, aou_9: null, aou_10: null, aou_11: null, aou_12: null, aou_13: null, aou_14: null,
            },
            qom: {
                qom_1: null, qom_2: null, qom_3: null, qom_4: null, qom_5: null, qom_6: null, qom_7: null,
                qom_8: null, qom_9: null, qom_10: null, qom_11: null, qom_12: null, qom_13: null, qom_14: null,
            },
        },
        mal: {
            name: 'MAL', checked: false, state: 0,
            aou: {
                aou_1: null, aou_2: null, aou_3: null, aou_4: null, aou_5: null, aou_6: null, aou_7: null, aou_8: null, aou_9: null, aou_10: null,
                aou_11: null, aou_12: null, aou_13: null, aou_14: null, aou_15: null, aou_16: null, aou_17: null, aou_18: null, aou_19: null,
                aou_20: null, aou_21: null, aou_22: null, aou_23: null, aou_24: null, aou_25: null, aou_26: null, aou_27: null, aou_28: null,
            },
            qom: {
                qom_1: null, qom_2: null, qom_3: null, qom_4: null, qom_5: null, qom_6: null, qom_7: null, qom_8: null, qom_9: null, qom_10: null,
                qom_11: null, qom_12: null, qom_13: null, qom_14: null, qom_15: null, qom_16: null, qom_17: null, qom_18: null, qom_19: null,
                qom_20: null, qom_21: null, qom_22: null, qom_23: null, qom_24: null, qom_25: null, qom_26: null, qom_27: null, qom_28: null,
            },
        },
        bbs: {
            name: 'BBS', checked: false, state: 0,
            bbs_1: null, bbs_2: null, bbs_3: null, bbs_4: null, bbs_5: null, bbs_6: null, bbs_7: null,
            bbs_8: null, bbs_9: null, bbs_10: null, bbs_11: null, bbs_12: null, bbs_13: null, bbs_14: null,
        },
        tug: {
            name: 'TUG', checked: false, state: 0,
            time: null
        },
        ftsts: {
            name: 'FTSTS', checked: false, state: 0,
            time: null
        },
        fm_l: {
            name: 'Fugl-Meyer(하)', checked: false, state: 0,
            e_1_1: null, e_1_2: null,
            e_2_1: null, e_2_2: null, e_2_3: null, e_2_4: null, e_2_5: null, e_2_6: null, e_2_7: null,
            e_3_1: null, e_3_2: null,
            e_4_1: null, e_4_2: null,
            e_5_1: null,
            f_1_1: null, f_1_2: null, f_1_3: null,
        },
        tmwt: {
            name: '10MWT', checked: false, state: 0,
            assistive_device: null, assist: null,
            self_selected_speed: {trial_1: null, trial_2: null, trial_3: null, average_time: null, average_self_selected: null},
            fast_speed: {trial_1: null, trial_2: null, trial_3: null, average_time: null, average_self_selected: null}
        },
        smwt: {
            name: '6MWT', checked: false, state: 0, assist: null,
            pre_rest: null, pre_1: null, pre_2: null, pre_3: null, pre_4: null, pre_5: null, pre_6: null,
            distance_rest: null, distance_1: null, distance_2: null, distance_3: null, distance_4: null, distance_5: null, distance_6: null,
            comments_rest: null, comments_1: null, comments_2: null, comments_3: null, comments_4: null, comments_5: null, comments_6: null,
        },
        dgi: {
            name: 'DGI', checked: false, state: 0,
            dgi_1: null, dgi_2: null, dgi_3: null, dgi_4: null, dgi_5: null, dgi_6: null, dgi_7: null, dgi_8: null,
        },
        abc: {
            name: 'ABCscale', checked: false, state: 0,
            abc_1: null, abc_2: null, abc_3: null, abc_4: null,
            abc_5: null, abc_6: null, abc_7: null, abc_8: null,
            abc_9: null, abc_10: null, abc_11: null, abc_12: null,
            abc_13: null, abc_14: null, abc_15: null, abc_16: null
        }
    }
}
