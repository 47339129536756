export default [
    {
        label: '환자 번호',
        field: 'patientNo',
        sort: true
    },
    {
        label: '아이디',
        field: 'id',
        sort: true
    },
    {
        label: '성명',
        field: 'name',
        sort: false
    },
    {
        label: '평가 회차',
        field: 'visitCount',
        sort: false
    },
    {
        label: '마지막 평가일',
        field: 'lastDate',
        sort: false
    },
    {
        label: '마지막 진단명',
        field: 'diagnosis',
        sort: false
    },
    {
        label: '',
        field: 'action',
        sort: false
    },


];
