<template>
  <div>
    <div class="modal-container">
      <div class="modal-header">
        <h5 class="title">
          환자 정보 수정
        </h5>
      </div>
      <div class="modal-center">
        <div class="info-container">
          <h5>환자번호<strong>*</strong></h5>
          <div class="input-box">
            <input type="text" class="custom-input-box" disabled v-model="patientsInfo.patientNo">
          </div>
        </div>
        <div class="info-container">
          <h5>아이디<strong>*</strong></h5>
          <div class="input-box">
            <input type="text" class="custom-input-box" disabled v-model="patientsInfo.userInfo.id">
          </div>
        </div>
        <div class="info-container">
          <h5>휴대폰번호<strong>*</strong></h5>
          <input type="tel" v-model="userInfo.mobile" placeholder="‘-’없이 번호 입력" maxlength="11" class="custom-input-box input-box" autocomplete="off"
                 oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>
        </div>
        <div class="info-container">
          <h5>이름<strong>*</strong></h5>
          <input type="text" class="custom-input-box input-box" placeholder="12자리 이내 입력" v-model="userInfo.name" maxlength="12" autocomplete="off"/>
        </div>
        <div class="info-container">
          <h5>생년월일<strong>*</strong></h5>
          <div class="input-box">
            <mdb-select outline v-model="yearOptions" placeholder="생년" @getValue="getSelectYearValue" class="search-select "/>
            <mdb-select outline v-model="monthOptions" placeholder="월" @getValue="getSelectMonthValue" class="search-select "/>
            <mdb-select outline v-model="dayOptions" placeholder="일" @getValue="getSelectDayValue" class="search-select "/>
          </div>
        </div>
        <div class="info-container">
          <h5>성별<strong>*</strong></h5>
          <mdb-form-inline class="input-box">
            <mdb-input type="radio" id="gender-1" name="groupOfMaterialRadios2" radioValue="남성" v-model="userInfo.gender" label="남성"/>
            <mdb-input type="radio" id="gender-2" name="groupOfMaterialRadios2" radioValue="여성" v-model="userInfo.gender" label="여성"/>
          </mdb-form-inline>
        </div>
        <div class="info-container">
          <h5>이메일</h5>
          <input type="email" class="custom-input-box input-box" placeholder="이메일 주소 입력" v-model="userInfo.email" autocomplete="off"/>
        </div>
        <p><strong>치료사가 직접 생성한 환자의 경우 환자 메뉴 사이트 로그인시 최초 비밀번호는 생년월일로 지정됩니다.<br/>
          예: 생년월일이 1989년 1월 1일일 경우 비밀번호는 19890101 입니다.</strong></p>
      </div>
      <div class="modal-footer">
        <button class="custom-btn-box-white w70" @click="btnCancel">취소</button>
        <button class="primary-blue custom-btn-box btn-short" @click="btnConfirm">수정</button>
      </div>
    </div>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>
import {mdbSelect, mdbInput, mdbFormInline} from "mdbvue";
// import {firebase} from "@/firebase/firebaseConfig";
import Progress from "@/components/Progress.vue";

export default {
  name: "PatientsEditDialog",
  components: {
    mdbSelect,
    mdbInput,
    mdbFormInline,
    Progress
  },
  props: {
    patientsInfo: Object,
    fbDataUserInfo: Object,
  },
  data() {
    return {
      fbCollection: 'users',
      yearOptions: [],
      monthOptions: [
        {text: "01", value: "01"}, {text: "02", value: "02"}, {text: "03", value: "03"}, {text: "04", value: "04"},
        {text: "05", value: "05"}, {text: "06", value: "06"}, {text: "07", value: "07"}, {text: "08", value: "08"},
        {text: "09", value: "09"}, {text: "10", value: "10"}, {text: "11", value: "11"}, {text: "12", value: "12"},
      ],
      dayOptions: [
        {text: "01", value: "01"}, {text: "02", value: "02"}, {text: "03", value: "03"}, {text: "04", value: "04"},
        {text: "05", value: "05"}, {text: "06", value: "06"}, {text: "07", value: "07"}, {text: "08", value: "08"},
        {text: "09", value: "09"}, {text: "10", value: "10"}, {text: "11", value: "11"}, {text: "12", value: "12"},
        {text: "13", value: "13"}, {text: "14", value: "14"}, {text: "15", value: "15"}, {text: "16", value: "16"},
        {text: "17", value: "17"}, {text: "18", value: "18"}, {text: "19", value: "19"}, {text: "20", value: "20"},
        {text: "21", value: "21"}, {text: "22", value: "22"}, {text: "23", value: "23"}, {text: "24", value: "24"},
        {text: "25", value: "25"}, {text: "26", value: "26"}, {text: "27", value: "27"}, {text: "28", value: "28"},
        {text: "29", value: "29"}, {text: "30", value: "30"}, {text: "31", value: "31"},
      ],
      birthYear: '',
      birthMonth: '',
      birthday: '',
      userInfo: {
        birth: '',
        email: '',
        gender: '',
        mobile: '',
        name: '',
      },
      isProgress: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this;
      self.userInfo.name = self.fbDataUserInfo.name
      self.userInfo.email = self.fbDataUserInfo.email
      self.userInfo.mobile = self.fbDataUserInfo.mobile
      self.userInfo.gender = self.fbDataUserInfo.gender
      self.getBirthYear()
      self.compareSelect()
    },
    getBirthYear() {
      const self = this;
      const now = new Date();	// 현재 날짜 및 시간
      const year = now.getFullYear();	// 연도
      const dateArray = self.fbDataUserInfo.birth.split("-")

      self.birthYear = parseInt(dateArray[0])
      self.birthMonth = dateArray[1]
      self.birthday = dateArray[2]
      for (let i = year; i >= 1920; i--) {
        let yearData = {}
        yearData['value'] = i;
        yearData['text'] = i
        if (i === self.birthYear) yearData['selected'] = true
        self.yearOptions.push(yearData);
      }
    },
    compareSelect() {
      const self = this
      let index
      index = self.monthOptions.findIndex((element) => element.value === self.birthMonth);
      self.monthOptions[index].selected = true
      self.monthOptions.push()
      index = self.dayOptions.findIndex((element) => element.value === self.birthday);
      self.dayOptions[index].selected = true
      self.dayOptions.push()
    },
    checkMobile() {
      const self = this;
      const exptext = /^\d{3}\d{3,4}\d{4}$/;
      const exptext2 = /^\d{3}-\d{3,4}-\d{4}$/;
      if (exptext.test(self.userInfo.mobile) === false && exptext2.test(self.userInfo.mobile) === false) {
        alert('전화번호 형식이 올바르지 않습니다.')
        return false;
      }
      self.userInfo.mobile = self.userInfo.mobile.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1$2$3");
      return true;
    },
    checkEmail() {
      const self = this;
      const exptext = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (exptext.test(self.userInfo.email) === false) {
        //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우
        alert("이메일형식이 올바르지 않습니다.");
        return false;
      }
      return true;
    },
    checkName() {
      const self = this;
      const special_regx = /[~!@#$%^&*()_+|<>?:{}]/;

      if (special_regx.test(self.userInfo.name)) {
        alert('이름에 특수문자를 넣을 수 없습니다.')
        return false;
      }
      return true;
    },
    checkValidate() {
      const self = this;

      //휴대폰번호 확인
      if (self.userInfo.mobile.trim().length === 0) {
        alert('휴대폰번호를 입력하세요.')
        return false
      }
      if (!self.checkMobile()) {
        return false
      }
      //이름 확인 (특수문자 입력불가)
      if (self.userInfo.name.trim().length === 0) {
        alert('이름을 입력하세요.')
        return false
      }
      if (!self.checkName()) {
        return false
      }
      //이메일이 있는 경우 확인
      if (self.userInfo.email !== '') {
        if (!self.checkEmail()) {
          return false
        }
      }
      self.userInfo.birth = this.birthYear + '-' + this.birthMonth + '-' + this.birthday
      return true
    },
    getSelectYearValue(value) {
      const self = this;
      if (value !== null) {
        self.birthYear = value
      }
    },
    getSelectMonthValue(value) {
      const self = this;
      if (value !== null) {
        self.birthMonth = value
      }
    },
    getSelectDayValue(value) {
      const self = this;
      if (value !== null) {
        self.birthday = value
      }
    },
    btnCancel() {
      const self = this;
      self.$emit('callBackModal', 2, false)
    },
    btnConfirm() {
      const self = this;
      if (self.checkValidate()) {
        self.isProgress = true;
        self.$emit('callBackModal', 2, true, self.userInfo)
      }
    },
  }
}
</script>

<style scoped>

p, button {
  font-size: 16px !important;
}

.modal-container {
  position: absolute;
  width: 580px;
  height: 502px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 23px 20px;
  background: #F8F8FA;
}

.info-container {
  display: flex;
  width: 100%;
  align-items: center;
  height: 70px;
}

.info-container h5 {
  width: 160px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  color: #272833;
}

.input-box {
  display: flex;
  width: 380px;
}

.custom-input-box[disabled] {
  background: none;
  font-weight: 400;
  line-height: 22px;
  color: #b1b1b1;
}

.modal-center p {
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 20px;
  letter-spacing: 0.0025em;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-weight: 500;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  background: #FFFFFF;
  border: 1px solid #000000;
  color: #000000;
}

.search-select {
  margin: 0 !important;
  flex-grow: 1;
  /*background: #FFFFFF;*/
}

.search-select:first-child {
  margin-right: 9px;
}

/deep/ .search-select .md-form {
  width: 120px !important;
}

/deep/ .search-select .form-control {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #000000 !important;
  background: #ffffff !important;
  height: 40px;
}

/deep/ .search-select .form-control::placeholder {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.0025em !important;
  color: #B1B1B1 !important;
}

input,
input::-webkit-input-placeholder {
  font-size: 16px;
}

strong {
  white-space: nowrap;
  font-weight: 400;
  color: #E14F4E;
}

.w70 {
  width: 84px;
  font-weight: 500;
}
</style>
