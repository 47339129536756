<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">Range of Motion(ROM), Manual Muscle Test(MMT)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="paper-guide-frame">
      <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
      <p class="paper-guide">ROM, MMT에 대한 설명 <span class="blue-btn" @click="isShowPopUp = true">더보기</span></p>
      <div class="pop-up" v-if="isShowPopUp">
        <div class="text">
          <h5>MMT 등급</h5>
          <div>
            <div class="gray">
              <h5 class="grade">Grade</h5>
              <h5 class="number">%</h5>
              <h5>Modified Ashworth Scale</h5>
            </div>
            <div>
              <p class="grade">5(Normal)</p>
              <p class="number">100</p>
              <p>중력과 강한(maximal) 저항에 대하여 완전한 ROM [정상상태]</p>
            </div>
            <div>
              <p class="grade">4(Good)</p>
              <p class="number">75</p>
              <p>중력과 중증도(moderate)의 저항에 대하여 완전한 ROM</p>
            </div>
            <div>
              <p class="grade">3(Fair)</p>
              <p class="number">50</p>
              <p>중력에 대하여 완전한(Full) ROM</p>
            </div>
            <div>
              <p class="grade">2(Poor)</p>
              <p class="number">25</p>
              <p>중력이 작용하지 않을 때 완전한(Full) ROM</p>
            </div>
            <div>
              <p class="grade">1(Trace)</p>
              <p class="number">10</p>
              <p>약한 근수축이 있으나 관절운동은 없는 상태</p>
            </div>
            <div>
              <p class="grade">0(Zero)</p>
              <p class="number">0</p>
              <p>근수축이 없는 상태</p>
            </div>
          </div>
        </div>
        <img src="@/assets/images/IA/btn_close.png" alt="" @click="isShowPopUp= false">
      </div>
      <div class="btn-box">
        <button class="full" @click="isFull('Full')">Full</button>
<!--        <button @click="isFull('Z')">Z</button>-->
<!--        <button @click="isFull('T')">T</button>-->
<!--        <button @click="isFull('P')">P</button>-->
<!--        <button @click="isFull('F')">F</button>-->
<!--        <button @click="isFull('G')">G</button>-->
<!--        <button @click="isFull('N')">N</button>-->
      </div>
    </div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-table">
        <div class="paper-topic">
          <div class="header-line first ">Joint/Segment</div>
          <div class="header-line second">Movement</div>
          <div class="header-line last">ROM</div>
          <div class="header-line last">MMT</div>
        </div>
        <div class="times">
          <div class="first"></div>
          <div class="second"></div>
          <div class="last">
            <div>Lt</div>
            <div class="line"/>
            <div>Rt</div>
          </div>
          <div class="last">
            <div>Lt</div>
            <div class="line"/>
            <div>Rt</div>
          </div>
        </div>
        <div class="times">
          <div class="first">Shoulder</div>
          <div class="second">Flexion (180˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="1" v-model="rom_mmt.rom.lt.shoulder.flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="101" v-model="rom_mmt.rom.rt.shoulder.flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="201" @click="active.lt.tool_1 = !active.lt.tool_1">{{ rom_mmt.mmt.lt.shoulder.flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="301" @click="active.rt.tool_1 = !active.rt.tool_1">{{ rom_mmt.mmt.rt.shoulder.flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_1" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'-T'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'T'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'T+'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'Z'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'-P'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'P'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'P+'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'-F'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'F'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'F+'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'-G'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'G'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'G+'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'-N'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'N'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.flexion" :value="'N+'"
                       @click="active.lt.tool_1 = !active.lt.tool_1">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_1" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'-T'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'T'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'T+'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'Z'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'-P'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'P'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'P+'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'-F'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'F'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'F+'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'-G'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'G'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'G+'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'-N'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'N'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.flexion" :value="'N+'"
                       @click="active.rt.tool_1 = !active.rt.tool_1">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension (50˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="2" v-model="rom_mmt.rom.lt.shoulder.extension"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="102" v-model="rom_mmt.rom.rt.shoulder.extension"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="202" @click="active.lt.tool_2 = !active.lt.tool_2">{{ rom_mmt.mmt.lt.shoulder.extension }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="302" @click="active.rt.tool_2 = !active.rt.tool_2">{{ rom_mmt.mmt.rt.shoulder.extension }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_2" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'-T'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'T'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'T+'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'Z'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'-P'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'P'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'P+'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'-F'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'F'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'F+'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'-G'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'G'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'G+'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'-N'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'N'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.extension" :value="'N+'"
                       @click="active.lt.tool_2 = !active.lt.tool_2">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_2" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'-T'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'T'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'T+'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'Z'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'-P'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'P'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'P+'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'-F'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'F'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'F+'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'-G'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'G'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'G+'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'-N'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'N'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.extension" :value="'N+'"
                       @click="active.rt.tool_2 = !active.rt.tool_2">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Abduction (180˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="3" v-model="rom_mmt.rom.lt.shoulder.abduction"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="103" v-model="rom_mmt.rom.rt.shoulder.abduction"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="203" @click="active.lt.tool_3 = !active.lt.tool_3">{{ rom_mmt.mmt.lt.shoulder.abduction }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="303" @click="active.rt.tool_3 = !active.rt.tool_3">{{ rom_mmt.mmt.rt.shoulder.abduction }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_3" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'-T'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'T'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'T+'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'Z'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'-P'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'P'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'P+'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'-F'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'F'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'F+'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'-G'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'G'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'G+'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'-N'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'N'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.abduction" :value="'N+'"
                       @click="active.lt.tool_3 = !active.lt.tool_3">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_3" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'-T'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'T'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'T+'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'Z'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'-P'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'P'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'P+'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'-F'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'F'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'F+'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'-G'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'G'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'G+'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'-N'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'N'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.abduction" :value="'N+'"
                       @click="active.rt.tool_3 = !active.rt.tool_3">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Adduction (40˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="4" v-model="rom_mmt.rom.lt.shoulder.adduction"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="104" v-model="rom_mmt.rom.rt.shoulder.adduction"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="204" @click="active.lt.tool_4 = !active.lt.tool_4">{{ rom_mmt.mmt.lt.shoulder.adduction }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="304" @click="active.rt.tool_4 = !active.rt.tool_4">{{ rom_mmt.mmt.rt.shoulder.adduction }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_4" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'-T'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'T'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'T+'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'Z'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'-P'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'P'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'P+'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'-F'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'F'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'F+'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'-G'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'G'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'G+'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'-N'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'N'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.adduction" :value="'N+'"
                       @click="active.lt.tool_4 = !active.lt.tool_4">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_4" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'-T'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'T'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'T+'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'Z'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'-P'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'P'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'P+'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'-F'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'F'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'F+'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'-G'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'G'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'G+'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'-N'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'N'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.adduction" :value="'N+'"
                       @click="active.rt.tool_4 = !active.rt.tool_4">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Internal Rotation (70˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="5" v-model="rom_mmt.rom.lt.shoulder.internal_rotation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="105" v-model="rom_mmt.rom.rt.shoulder.internal_rotation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="205" @click="active.lt.tool_5 = !active.lt.tool_5">{{ rom_mmt.mmt.lt.shoulder.internal_rotation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="305" @click="active.rt.tool_5 = !active.rt.tool_5">{{ rom_mmt.mmt.rt.shoulder.internal_rotation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_5" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'-T'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'T'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'T+'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'Z'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'-P'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'P'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'P+'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'-F'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'F'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'F+'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'-G'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'G'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'G+'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'-N'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'N'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.internal_rotation" :value="'N+'"
                       @click="active.lt.tool_5 = !active.lt.tool_5">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_5" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'-T'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'T'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'T+'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'Z'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'-P'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'P'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'P+'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'-F'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'F'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'F+'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'-G'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'G'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'G+'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'-N'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'N'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.internal_rotation" :value="'N+'"
                       @click="active.rt.tool_5 = !active.rt.tool_5">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">External Rotation (90˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="6" v-model="rom_mmt.rom.lt.shoulder.external_rotation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="106" v-model="rom_mmt.rom.rt.shoulder.external_rotation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="206" @click="active.lt.tool_6 = !active.lt.tool_6">{{ rom_mmt.mmt.lt.shoulder.external_rotation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="306" @click="active.rt.tool_6 = !active.rt.tool_6">{{ rom_mmt.mmt.rt.shoulder.external_rotation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_6" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'-T'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'T'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'T+'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'Z'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'-P'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'P'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'P+'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'-F'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'F'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'F+'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'-G'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'G'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'G+'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'-N'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'N'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.shoulder.external_rotation" :value="'N+'"
                       @click="active.lt.tool_6 = !active.lt.tool_6">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_6" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'-T'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'T'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'T+'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'Z'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'-P'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'P'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'P+'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'-F'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'F'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'F+'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'-G'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'G'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'G+'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'-N'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'N'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.shoulder.external_rotation" :value="'N+'"
                       @click="active.rt.tool_6 = !active.rt.tool_6">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Elbow</div>
          <div class="second">Flexion (145˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="7" v-model="rom_mmt.rom.lt.elbow.flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="107" v-model="rom_mmt.rom.rt.elbow.flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="207" @click="active.lt.tool_7 = !active.lt.tool_7">{{ rom_mmt.mmt.lt.elbow.flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="307" @click="active.rt.tool_7 = !active.rt.tool_7">{{ rom_mmt.mmt.rt.elbow.flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_7" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'-T'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'T'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'T+'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'Z'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'-P'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'P'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'P+'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'-F'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'F'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'F+'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'-G'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'G'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'G+'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'-N'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'N'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.flexion" :value="'N+'"
                       @click="active.lt.tool_7 = !active.lt.tool_7">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_7" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'-T'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'T'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'T+'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'Z'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'-P'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'P'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'P+'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'-F'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'F'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'F+'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'-G'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'G'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'G+'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'-N'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'N'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.flexion" :value="'N+'"
                       @click="active.rt.tool_7 = !active.rt.tool_7">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension (0˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="8" v-model="rom_mmt.rom.lt.elbow.extension"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="108" v-model="rom_mmt.rom.rt.elbow.extension"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="208" @click="active.lt.tool_8 = !active.lt.tool_8">{{ rom_mmt.mmt.lt.elbow.extension }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="308" @click="active.rt.tool_8 = !active.rt.tool_8">{{ rom_mmt.mmt.rt.elbow.extension }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_8" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'-T'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'T'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'T+'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'Z'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'-P'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'P'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'P+'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'-F'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'F'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'F+'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'-G'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'G'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'G+'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'-N'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'N'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.elbow.extension" :value="'N+'"
                       @click="active.lt.tool_8 = !active.lt.tool_8">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_8" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'-T'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'T'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'T+'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'Z'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'-P'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'P'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'P+'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'-F'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'F'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'F+'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'-G'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'G'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'G+'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'-N'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'N'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.elbow.extension" :value="'N+'"
                       @click="active.rt.tool_8 = !active.rt.tool_8">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Forearm</div>
          <div class="second">Supination (90˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="9" v-model="rom_mmt.rom.lt.forearm.supination"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="109" v-model="rom_mmt.rom.rt.forearm.supination"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="209" @click="active.lt.tool_9 = !active.lt.tool_9">{{ rom_mmt.mmt.lt.forearm.supination }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="309" @click="active.rt.tool_9 = !active.rt.tool_9">{{ rom_mmt.mmt.rt.forearm.supination }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_9" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'-T'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'T'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'T+'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'Z'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'-P'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'P'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'P+'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'-F'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'F'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'F+'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'-G'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'G'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'G+'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'-N'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'N'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.supination" :value="'N+'"
                       @click="active.lt.tool_9 = !active.lt.tool_9">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_9" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'-T'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'T'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'T+'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'Z'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'-P'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'P'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'P+'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'-F'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'F'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'F+'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'-G'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'G'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'G+'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'-N'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'N'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.supination" :value="'N+'"
                       @click="active.rt.tool_9 = !active.rt.tool_9">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Pronation (80˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="10" v-model="rom_mmt.rom.lt.forearm.pronation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="110" v-model="rom_mmt.rom.rt.forearm.pronation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="210" @click="active.lt.tool_10 = !active.lt.tool_10">{{ rom_mmt.mmt.lt.forearm.pronation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="310" @click="active.rt.tool_10 = !active.rt.tool_10">{{ rom_mmt.mmt.rt.forearm.pronation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_10" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'-T'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'T'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'T+'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'Z'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'-P'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'P'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'P+'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'-F'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'F'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'F+'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'-G'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'G'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'G+'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'-N'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'N'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.forearm.pronation" :value="'N+'"
                       @click="active.lt.tool_10 = !active.lt.tool_10">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_10" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'-T'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'T'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'T+'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'Z'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'-P'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'P'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'P+'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'-F'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'F'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'F+'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'-G'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'G'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'G+'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'-N'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'N'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.forearm.pronation" :value="'N+'"
                       @click="active.rt.tool_10 = !active.rt.tool_10">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Wrist</div>
          <div class="second">Flexion (80˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="11" v-model="rom_mmt.rom.lt.wrist.flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="111" v-model="rom_mmt.rom.rt.wrist.flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="211" @click="active.lt.tool_11 = !active.lt.tool_11">{{ rom_mmt.mmt.lt.wrist.flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="311" @click="active.rt.tool_11 = !active.rt.tool_11">{{ rom_mmt.mmt.rt.wrist.flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_11" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'-T'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'T'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'T+'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'Z'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'-P'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'P'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'P+'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'-F'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'F'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'F+'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'-G'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'G'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'G+'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'-N'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'N'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.flexion" :value="'N+'"
                       @click="active.lt.tool_11 = !active.lt.tool_11">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_11" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'-T'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'T'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'T+'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'Z'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'-P'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'P'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'P+'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'-F'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'F'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'F+'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'-G'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'G'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'G+'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'-N'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'N'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.flexion" :value="'N+'"
                       @click="active.rt.tool_11 = !active.rt.tool_11">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension (70˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="12" v-model="rom_mmt.rom.lt.wrist.extension"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="112" v-model="rom_mmt.rom.rt.wrist.extension"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="212" @click="active.lt.tool_12 = !active.lt.tool_12">{{ rom_mmt.mmt.lt.wrist.extension }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="312" @click="active.rt.tool_12 = !active.rt.tool_12">{{ rom_mmt.mmt.rt.wrist.extension }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_12" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'-T'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'T'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'T+'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'Z'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'-P'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'P'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'P+'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'-F'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'F'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'F+'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'-G'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'G'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'G+'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'-N'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'N'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.extension" :value="'N+'"
                       @click="active.lt.tool_12 = !active.lt.tool_12">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_12" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'-T'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'T'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'T+'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'Z'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'-P'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'P'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'P+'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'-F'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'F'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'F+'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'-G'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'G'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'G+'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'-N'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'N'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.extension" :value="'N+'"
                       @click="active.rt.tool_12 = !active.rt.tool_12">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Radial Deviation (40˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="13" v-model="rom_mmt.rom.lt.wrist.radial_deviation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="113" v-model="rom_mmt.rom.rt.wrist.radial_deviation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="213" @click="active.lt.tool_13 = !active.lt.tool_13">{{ rom_mmt.mmt.lt.wrist.radial_deviation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="313" @click="active.rt.tool_13 = !active.rt.tool_13">{{ rom_mmt.mmt.rt.wrist.radial_deviation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_13" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'-T'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'T'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'T+'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'Z'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'-P'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'P'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'P+'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'-F'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'F'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'F+'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'-G'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'G'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'G+'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'-N'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'N'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.radial_deviation" :value="'N+'"
                       @click="active.lt.tool_13 = !active.lt.tool_13">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_13" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'-T'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'T'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'T+'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'Z'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'-P'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'P'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'P+'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'-F'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'F'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'F+'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'-G'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'G'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'G+'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'-N'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'N'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.radial_deviation" :value="'N+'"
                       @click="active.rt.tool_13 = !active.rt.tool_13">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Ulnar Deviation (45˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="14" v-model="rom_mmt.rom.lt.wrist.ulnar_deviation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="114" v-model="rom_mmt.rom.rt.wrist.ulnar_deviation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="214" @click="active.lt.tool_14 = !active.lt.tool_14">{{ rom_mmt.mmt.lt.wrist.ulnar_deviation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="314" @click="active.rt.tool_14 = !active.rt.tool_14">{{ rom_mmt.mmt.rt.wrist.ulnar_deviation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_14" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'-T'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'T'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'T+'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'Z'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'-P'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'P'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'P+'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'-F'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'F'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'F+'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'-G'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'G'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'G+'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'-N'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'N'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.wrist.ulnar_deviation" :value="'N+'"
                       @click="active.lt.tool_14 = !active.lt.tool_14">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_14" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'-T'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'T'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'T+'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'Z'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'-P'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'P'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'P+'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'-F'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'F'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'F+'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'-G'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'G'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'G+'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'-N'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'N'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.wrist.ulnar_deviation" :value="'N+'"
                       @click="active.rt.tool_14 = !active.rt.tool_14">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Hip</div>
          <div class="second">Flexion (125˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="15" v-model="rom_mmt.rom.lt.hip.flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="115" v-model="rom_mmt.rom.rt.hip.flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="215" @click="active.lt.tool_15 = !active.lt.tool_15">{{ rom_mmt.mmt.lt.hip.flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="315" @click="active.rt.tool_15 = !active.rt.tool_15">{{ rom_mmt.mmt.rt.hip.flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_15" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'-T'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'T'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'T+'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'Z'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'-P'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'P'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'P+'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'-F'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'F'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'F+'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'-G'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'G'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'G+'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'-N'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'N'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.flexion" :value="'N+'"
                       @click="active.lt.tool_15 = !active.lt.tool_15">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_15" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'-T'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'T'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'T+'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'Z'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'-P'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'P'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'P+'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'-F'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'F'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'F+'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'-G'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'G'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'G+'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'-N'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'N'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.flexion" :value="'N+'"
                       @click="active.rt.tool_15 = !active.rt.tool_15">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension (10˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="16" v-model="rom_mmt.rom.lt.hip.extension"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="116" v-model="rom_mmt.rom.rt.hip.extension"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="216" @click="active.lt.tool_16 = !active.lt.tool_16">{{ rom_mmt.mmt.lt.hip.extension }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="316" @click="active.rt.tool_16 = !active.rt.tool_16">{{ rom_mmt.mmt.rt.hip.extension }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_16" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'-T'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'T'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'T+'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'Z'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'-P'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'P'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'P+'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'-F'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'F'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'F+'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'-G'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'G'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'G+'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'-N'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'N'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.extension" :value="'N+'"
                       @click="active.lt.tool_16 = !active.lt.tool_16">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_16" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'-T'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'T'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'T+'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'Z'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'-P'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'P'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'P+'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'-F'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'F'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'F+'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'-G'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'G'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'G+'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'-N'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'N'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.extension" :value="'N+'"
                       @click="active.rt.tool_16 = !active.rt.tool_16">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Abduction (45˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="17" v-model="rom_mmt.rom.lt.hip.abduction"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="117" v-model="rom_mmt.rom.rt.hip.abduction"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="217" @click="active.lt.tool_17 = !active.lt.tool_17">{{ rom_mmt.mmt.lt.hip.abduction }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="317" @click="active.rt.tool_17 = !active.rt.tool_17">{{ rom_mmt.mmt.rt.hip.abduction }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_17" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'-T'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'T'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'T+'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'Z'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'-P'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'P'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'P+'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'-F'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'F'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'F+'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'-G'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'G'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'G+'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'-N'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'N'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.abduction" :value="'N+'"
                       @click="active.lt.tool_17 = !active.lt.tool_17">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_17" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'-T'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'T'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'T+'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'Z'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'-P'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'P'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'P+'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'-F'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'F'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'F+'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'-G'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'G'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'G+'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'-N'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'N'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.abduction" :value="'N+'"
                       @click="active.rt.tool_17 = !active.rt.tool_17">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Adduction (40˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="18" v-model="rom_mmt.rom.lt.hip.adduction"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="118" v-model="rom_mmt.rom.rt.hip.adduction"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="218" @click="active.lt.tool_18 = !active.lt.tool_18">{{ rom_mmt.mmt.lt.hip.adduction }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="318" @click="active.rt.tool_18 = !active.rt.tool_18">{{ rom_mmt.mmt.rt.hip.adduction }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_18" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'-T'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'T'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'T+'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'Z'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'-P'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'P'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'P+'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'-F'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'F'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'F+'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'-G'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'G'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'G+'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'-N'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'N'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.adduction" :value="'N+'"
                       @click="active.lt.tool_18 = !active.lt.tool_18">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_18" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'-T'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'T'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'T+'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'Z'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'-P'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'P'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'P+'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'-F'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'F'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'F+'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'-G'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'G'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'G+'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'-N'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'N'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.adduction" :value="'N+'"
                       @click="active.rt.tool_18 = !active.rt.tool_18">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Internal Rotation (45˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="19" v-model="rom_mmt.rom.lt.hip.internal_rotation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="119" v-model="rom_mmt.rom.rt.hip.internal_rotation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="219" @click="active.lt.tool_19 = !active.lt.tool_19">{{ rom_mmt.mmt.lt.hip.internal_rotation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="319" @click="active.rt.tool_19 = !active.rt.tool_19">{{ rom_mmt.mmt.rt.hip.internal_rotation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_19" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'-T'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'T'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'T+'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'Z'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'-P'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'P'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'P+'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'-F'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'F'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'F+'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'-G'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'G'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'G+'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'-N'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'N'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.internal_rotation" :value="'N+'"
                       @click="active.lt.tool_19 = !active.lt.tool_19">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_19" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'-T'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'T'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'T+'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'Z'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'-P'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'P'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'P+'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'-F'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'F'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'F+'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'-G'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'G'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'G+'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'-N'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'N'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.internal_rotation" :value="'N+'"
                       @click="active.rt.tool_19 = !active.rt.tool_19">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">External Rotation (45˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="20" v-model="rom_mmt.rom.lt.hip.external_rotation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="120" v-model="rom_mmt.rom.rt.hip.external_rotation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="220" @click="active.lt.tool_20 = !active.lt.tool_20">{{ rom_mmt.mmt.lt.hip.external_rotation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="320" @click="active.rt.tool_20 = !active.rt.tool_20">{{ rom_mmt.mmt.rt.hip.external_rotation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_20" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'-T'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'T'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'T+'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'Z'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'-P'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'P'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'P+'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'-F'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'F'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'F+'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'-G'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'G'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'G+'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'-N'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'N'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.hip.external_rotation" :value="'N+'"
                       @click="active.lt.tool_20 = !active.lt.tool_20">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_20" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'-T'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'T'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'T+'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'Z'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'-P'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'P'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'P+'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'-F'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'F'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'F+'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'-G'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'G'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'G+'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'-N'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'N'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.hip.external_rotation" :value="'N+'"
                       @click="active.rt.tool_20 = !active.rt.tool_20">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Knee</div>
          <div class="second">Flexion (135˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="21" v-model="rom_mmt.rom.lt.knee.flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="121" v-model="rom_mmt.rom.rt.knee.flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="221" @click="active.lt.tool_21 = !active.lt.tool_21">{{ rom_mmt.mmt.lt.knee.flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="321" @click="active.rt.tool_21 = !active.rt.tool_21">{{ rom_mmt.mmt.rt.knee.flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_21" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'-T'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'T'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'T+'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'Z'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'-P'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'P'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'P+'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'-F'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'F'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'F+'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'-G'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'G'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'G+'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'-N'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'N'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.flexion" :value="'N+'"
                       @click="active.lt.tool_21 = !active.lt.tool_21">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_21" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'-T'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'T'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'T+'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'Z'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'-P'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'P'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'P+'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'-F'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'F'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'F+'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'-G'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'G'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'G+'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'-N'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'N'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.flexion" :value="'N+'"
                       @click="active.rt.tool_21 = !active.rt.tool_21">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Extension (0°)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="22" v-model="rom_mmt.rom.lt.knee.extension"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="122" v-model="rom_mmt.rom.rt.knee.extension"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="222" @click="active.lt.tool_22 = !active.lt.tool_22">{{ rom_mmt.mmt.lt.knee.extension }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="322" @click="active.rt.tool_22 = !active.rt.tool_22">{{ rom_mmt.mmt.rt.knee.extension }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_22" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'-T'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'T'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'T+'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'Z'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'-P'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'P'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'P+'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'-F'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'F'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'F+'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'-G'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'G'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'G+'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'-N'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'N'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.extension" :value="'N+'"
                       @click="active.lt.tool_22 = !active.lt.tool_22">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_22" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'-T'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'T'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'T+'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'Z'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'-P'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'P'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'P+'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'-F'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'F'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'F+'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'-G'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'G'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'G+'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'-N'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'N'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.extension" :value="'N+'"
                       @click="active.rt.tool_22 = !active.rt.tool_22">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Internal Rotation (15˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="23" v-model="rom_mmt.rom.lt.knee.internal_rotation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="123" v-model="rom_mmt.rom.rt.knee.internal_rotation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="223" @click="active.lt.tool_23 = !active.lt.tool_23">{{ rom_mmt.mmt.lt.knee.internal_rotation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="323" @click="active.rt.tool_23 = !active.rt.tool_23">{{ rom_mmt.mmt.rt.knee.internal_rotation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_23" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'-T'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'T'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'T+'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'Z'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'-P'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'P'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'P+'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'-F'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'F'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'F+'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'-G'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'G'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'G+'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'-N'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'N'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.internal_rotation" :value="'N+'"
                       @click="active.lt.tool_23 = !active.lt.tool_23">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_23" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'-T'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'T'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'T+'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'Z'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'-P'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'P'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'P+'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'-F'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'F'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'F+'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'-G'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'G'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'G+'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'-N'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'N'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.internal_rotation" :value="'N+'"
                       @click="active.rt.tool_23 = !active.rt.tool_23">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">External Rotation (30˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="24" v-model="rom_mmt.rom.lt.knee.external_rotation"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="124" v-model="rom_mmt.rom.rt.knee.external_rotation"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="224" @click="active.lt.tool_24 = !active.lt.tool_24">{{ rom_mmt.mmt.lt.knee.external_rotation }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="324" @click="active.rt.tool_24 = !active.rt.tool_24">{{ rom_mmt.mmt.rt.knee.external_rotation }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_24" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'-T'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'T'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'T+'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'Z'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'-P'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'P'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'P+'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'-F'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'F'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'F+'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'-G'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'G'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'G+'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'-N'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'N'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.knee.external_rotation" :value="'N+'"
                       @click="active.lt.tool_24 = !active.lt.tool_24">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_24" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'-T'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'T'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'T+'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'Z'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'-P'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'P'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'P+'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'-F'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'F'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'F+'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'-G'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'G'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'G+'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'-N'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'N'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.knee.external_rotation" :value="'N+'"
                       @click="active.rt.tool_24 = !active.rt.tool_24">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first">Ankle</div>
          <div class="second">Dorsi flexion (20˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="25" v-model="rom_mmt.rom.lt.ankle.dorsi_flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="125" v-model="rom_mmt.rom.rt.ankle.dorsi_flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="225" @click="active.lt.tool_25 = !active.lt.tool_25">{{ rom_mmt.mmt.lt.ankle.dorsi_flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="325" @click="active.rt.tool_25 = !active.rt.tool_25">{{ rom_mmt.mmt.rt.ankle.dorsi_flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_25" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'-T'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'T'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'T+'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'Z'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'-P'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'P'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'P+'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'-F'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'F'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'F+'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'-G'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'G'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'G+'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'-N'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'N'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.dorsi_flexion" :value="'N+'"
                       @click="active.lt.tool_25 = !active.lt.tool_25">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_25" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'-T'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'T'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'T+'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'Z'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'-P'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'P'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'P+'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'-F'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'F'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'F+'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'-G'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'G'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'G+'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'-N'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'N'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.dorsi_flexion" :value="'N+'"
                       @click="active.rt.tool_25 = !active.rt.tool_25">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Plantar flexion (55˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="26" v-model="rom_mmt.rom.lt.ankle.plantar_flexion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="126" v-model="rom_mmt.rom.rt.ankle.plantar_flexion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="226" @click="active.lt.tool_26 = !active.lt.tool_26">{{ rom_mmt.mmt.lt.ankle.plantar_flexion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="326" @click="active.rt.tool_26 = !active.rt.tool_26">{{ rom_mmt.mmt.rt.ankle.plantar_flexion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_26" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'-T'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'T'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'T+'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'Z'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'-P'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'P'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'P+'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'-F'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'F'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'F+'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'-G'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'G'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'G+'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'-N'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'N'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.plantar_flexion" :value="'N+'"
                       @click="active.lt.tool_26 = !active.lt.tool_26">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_26" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'-T'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'T'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'T+'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'Z'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'-P'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'P'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'P+'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'-F'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'F'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'F+'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'-G'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'G'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'G+'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'-N'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'N'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.plantar_flexion" :value="'N+'"
                       @click="active.rt.tool_26 = !active.rt.tool_26">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Inversion (40˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="27" v-model="rom_mmt.rom.lt.ankle.inversion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="127" v-model="rom_mmt.rom.rt.ankle.inversion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="227" @click="active.lt.tool_27 = !active.lt.tool_27">{{ rom_mmt.mmt.lt.ankle.inversion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="327" @click="active.rt.tool_27 = !active.rt.tool_27">{{ rom_mmt.mmt.rt.ankle.inversion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_27" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'-T'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'T'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'T+'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'Z'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'-P'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'P'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'P+'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'-F'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'F'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'F+'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'-G'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'G'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'G+'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'-N'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'N'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.inversion" :value="'N+'"
                       @click="active.lt.tool_27 = !active.lt.tool_27">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_27" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'-T'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'T'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'T+'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'Z'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'-P'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'P'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'P+'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'-F'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'F'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'F+'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'-G'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'G'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'G+'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'-N'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'N'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.inversion" :value="'N+'"
                       @click="active.rt.tool_27 = !active.rt.tool_27">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"></div>
          <div class="second">Eversion (20˚)</div>
          <div class="last">
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="28" v-model="rom_mmt.rom.lt.ankle.eversion"/>
            </div>
            <div>
              <input type="text" maxlength="4" class="input-box" tabindex="128" v-model="rom_mmt.rom.rt.ankle.eversion"/>
            </div>
          </div>
          <div class="last">
            <div>
              <button class="input-box" tabindex="228" @click="active.lt.tool_28 = !active.lt.tool_28">{{ rom_mmt.mmt.lt.ankle.eversion }}</button>
            </div>
            <div>
              <button class="input-box" tabindex="328" @click="active.rt.tool_28 = !active.rt.tool_28">{{ rom_mmt.mmt.rt.ankle.eversion }}</button>
            </div>
          </div>
        </div>
        <slide-up-down class="dropdown-lime" :active="active.lt.tool_28" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'-T'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'T'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'T+'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'Z'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'-P'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'P'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'P+'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'-F'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'F'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'F+'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'-G'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'G'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'G+'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'-N'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'N'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.lt.ankle.eversion" :value="'N+'"
                       @click="active.lt.tool_28 = !active.lt.tool_28">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <slide-up-down class="dropdown-lime" :active="active.rt.tool_28" :duration="200">
          <div class="dropdown-grid">
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'-T'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">-T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'T'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">T</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'T+'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">T+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'Z'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">Z</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'-P'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">-P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'P'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">P</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'P+'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">P+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'-F'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">-F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'F'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">F</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'F+'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">F+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'-G'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">-G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'G'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">G</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'G+'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">G+</span>
              </label>
            </div>
            <div class="grid-group">
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'-N'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">-N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'N'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">N</span>
              </label>
              <label class="container">
                <input type="radio" name="test" v-model="rom_mmt.mmt.rt.ankle.eversion" :value="'N+'"
                       @click="active.rt.tool_28 = !active.rt.tool_28">
                <span class="checkmark">N+</span>
              </label>
            </div>
          </div>
        </slide-up-down>
        <div class="times">
          <div class="first"/>
          <div class="second"/>
          <div class="last"/>
          <div class="last"/>
        </div>
      </div>
    </mdb-scrollbar>
    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" tabindex="400" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" tabindex="401" @click="resultSave">평가 결과 저장</button>
    </div>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";
import {mdbScrollbar} from "mdbvue";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "RangeOfMotion_ManualMuscleTest",
  components: {
    mdbScrollbar,
    SlideUpDown,
  },
  props: {
    fbDocId: String,
    rom_mmt: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      total: 0,
      tools: {
        rom_mmt: {
          rom: {},
          mmt: {}
        }
      },
      active: {
        lt: {
          tool_1: false, tool_2: false, tool_3: false, tool_4: false, tool_5: false, tool_6: false, tool_7: false,
          tool_8: false, tool_9: false, tool_10: false, tool_11: false, tool_12: false, tool_13: false, tool_14: false,
          tool_15: false, tool_16: false, tool_17: false, tool_18: false, tool_19: false, tool_20: false, tool_21: false,
          tool_22: false, tool_23: false, tool_24: false, tool_25: false, tool_26: false, tool_27: false, tool_28: false,
        },
        rt: {
          tool_1: false, tool_2: false, tool_3: false, tool_4: false, tool_5: false, tool_6: false, tool_7: false,
          tool_8: false, tool_9: false, tool_10: false, tool_11: false, tool_12: false, tool_13: false, tool_14: false,
          tool_15: false, tool_16: false, tool_17: false, tool_18: false, tool_19: false, tool_20: false, tool_21: false,
          tool_22: false, tool_23: false, tool_24: false, tool_25: false, tool_26: false, tool_27: false, tool_28: false,
        }
      },
      isShowPopUp: false,
      isShowModal: false,
    };
  },
  methods: {
    isFull(value) {
      const self = this;
      if (value === 'Full') {
        self.ltFull('rom',value);
        self.rtFull('rom',value);
      } else {
        self.ltFull('mmt',value);
        self.rtFull('mmt',value);
      }

    },
    ltFull(index,value) {
      const self = this;
      const _lt = self.rom_mmt[index].lt
      _lt.shoulder.flexion = value
      _lt.shoulder.extension = value
      _lt.shoulder.abduction = value
      _lt.shoulder.adduction = value
      _lt.shoulder.internal_rotation = value
      _lt.shoulder.external_rotation = value
      _lt.elbow.flexion = value
      _lt.elbow.extension = value
      _lt.forearm.supination = value
      _lt.forearm.pronation = value
      _lt.wrist.flexion = value
      _lt.wrist.extension = value
      _lt.wrist.radial_deviation = value
      _lt.wrist.ulnar_deviation = value
      _lt.hip.flexion = value
      _lt.hip.extension = value
      _lt.hip.abduction = value
      _lt.hip.adduction = value
      _lt.hip.internal_rotation = value
      _lt.hip.external_rotation = value
      _lt.knee.flexion = value
      _lt.knee.extension = value
      _lt.knee.internal_rotation = value
      _lt.knee.external_rotation = value
      _lt.ankle.dorsi_flexion = value
      _lt.ankle.plantar_flexion = value
      _lt.ankle.inversion = value
      _lt.ankle.eversion = value
    },
    rtFull(index,value) {
      const self = this;
      const _rt = self.rom_mmt[index].rt
      _rt.shoulder.flexion = value
      _rt.shoulder.extension = value
      _rt.shoulder.abduction = value
      _rt.shoulder.adduction = value
      _rt.shoulder.internal_rotation = value
      _rt.shoulder.external_rotation = value
      _rt.elbow.flexion = value
      _rt.elbow.extension = value
      _rt.forearm.supination = value
      _rt.forearm.pronation = value
      _rt.wrist.flexion = value
      _rt.wrist.extension = value
      _rt.wrist.radial_deviation = value
      _rt.wrist.ulnar_deviation = value
      _rt.hip.flexion = value
      _rt.hip.extension = value
      _rt.hip.abduction = value
      _rt.hip.adduction = value
      _rt.hip.internal_rotation = value
      _rt.hip.external_rotation = value
      _rt.knee.flexion = value
      _rt.knee.extension = value
      _rt.knee.internal_rotation = value
      _rt.knee.external_rotation = value
      _rt.ankle.dorsi_flexion = value
      _rt.ankle.plantar_flexion = value
      _rt.ankle.inversion = value
      _rt.ankle.eversion = value
    },
    tempSave() {
      const self = this;
      self.tools.rom_mmt = self.rom_mmt;
      self.tools.rom_mmt['state'] = 1;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'rom_mmt', 1)
          });
    },
    checkNullData() {
      const self = this;
      if (JSON.stringify(self.rom_mmt).includes('null')) {
        alert('아직 완료되지 않은 항목이 있습니다.')
        return true;
      }
      return false;
    },
    async resultSave() {
      const self = this;
      if (self.checkNullData()) {
        return
      }
      self.btnConfirm()
    },
    btnConfirm() {
      const self = this;
      self.tools.rom_mmt = self.rom_mmt;
      self.tools.rom_mmt['state'] = 2;
      const db = firebase.firestore();
      db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(() => {
            self.$emit('refresh', 'rom_mmt', 2)
          });
    },
  }
}
</script>

<style scoped>
button, strong {
  font-size: 16px !important;
}

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.blue-btn {
  color: #0069CA;
  cursor: pointer;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide-frame .btn-box {
  margin-left: auto;
  display: flex;
}

.paper-guide-frame .btn-box button {
  margin-left: 8px;
  width: 50px;
  height: 40px;
  background: #F1F6FD;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #0069CA;
}

.paper-guide-frame .btn-box .full {
  width: 70px;
  /*margin-right: 15px;*/
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}

.scroll-frame {
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

.paper-table > div {
  display: flex;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
  /*height: 100%;*/
  font-size: 16px;
  text-align: center;
}

.paper-topic {
  height: 49px !important;
  font-weight: 700;
  background: #F1F6FD;
  font-size: 14px;
  text-align: center !important;
  justify-content: center;
}

.header-line {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 700 !important;
  font-size: 14px;
}

.times {
  height: 49px !important;
  margin: 0 10px;
  display: flex;
  justify-content: center;
}

.first {
  display: flex;
  align-items: center;
  text-align: left;
  width: 374px !important;
  font-weight: 700;
  line-height: 28px;
  color: #272833;
}

.second {
  display: flex;
  align-items: center;
  text-align: left;
  width: 296px !important;
  font-weight: 400;
}

.last {
  width: 190px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  font-weight: 500;
  height: 100%;
}

.last div {
  width: 195px;
  padding: 10px;
}

.last .input-box {
  width: 100%;
  height: 28px;
  background: #F1F6FD;
  border-radius: 4px;
  border: 0;
  text-align: center;
  font-size: 14px !important;
  font-weight: 500;
  color: #0069CA;
}

.line {
  width: 2px !important;
  height: 28px !important;
  padding: 0 !important;
  background: #D4D4D8;
}

.dropdown-lime {
  margin: 0 10px;
  display: flex;
  justify-content: center;
}

.dropdown-grid {
  margin-left: auto;
  width: 380px !important;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: space-between;*/
  display: grid;
  grid-template-columns: 285px 95px;
  grid-template-rows: repeat(5, 1fr);
  padding: 0 !important;
  font-weight: 500;
  height: 100%;
}

.grid-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-group:nth-child(1) {
  grid-column: 1/2
}

.grid-group:nth-child(2) {
  grid-column: 2/3;
  grid-row: 1 / span 5;
}

.grid-group:nth-child(2) .checkmark {
  height: 220px;
}

.container {
  cursor: pointer;
  /*width: 380px;*/
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px;
  margin: 0;
  display: flex;
  max-height: 100%;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #A0B0C6;
  width: 75px;
  height: 28px;
  background: #F8F8FA;
  border-radius: 4px;
  margin-right: 20px;
}

.checkmark:last-child {
  margin-right: 0;
}

/*bottom btn*/

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}

.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}


/*pop-up*/
.pop-up {
  padding: 23px 20px;
  position: absolute;
  width: 732px;
  height: 287px;
  left: 167px;
  bottom: 68%;
  transform: translateY(50%);
  background: #FFFFFF;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 50;
  display: flex;
}

.pop-up .text {
  width: 658px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  height: 214px;
}

.pop-up .text h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.pop-up .text .gray {
  color: #8D8D8D;
  margin-top: 5px;
}

.pop-up .text div > div {
  display: flex;
}

.pop-up .text div div {
  margin-bottom: 10px;
}

.pop-up .text .grade {
  width: 134px;
}

.pop-up img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.pop-up .text .number {
  width: 93px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scroll-frame button:focus {
  border: 2px solid #0069CA;
}


</style>
