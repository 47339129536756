<template>
  <div class="home-frame">
    <Navigator/>
    <PatientsManage v-if="$route.params.menu === 'patientsManage'&& $route.params.id == null" class="view-layout"/>
    <PatientsDetailPage v-else-if="$route.params.menu === 'patientsManage'&& $route.params.id != null" class="view-layout"/>
    <EvaluationPage v-else-if="$route.params.menu === 'doEvaluation'&& $route.params.id == null" class="view-layout"/>
    <EvaluationDetailPage v-else-if="$route.params.menu === 'doEvaluation' && $route.params.id != null" class="view-layout"/>
    <EvaluationToolsInfo v-else-if="$route.params.menu === 'evaluationToolsInfo'" />
    <DataReferanceRoom v-else-if="$route.params.menu === 'dataReferenceRoom'" />
    <TherapistInfoEditPage v-else-if="$route.params.menu === 'myPage'" />
    <TherapistWithdrawal v-else-if="$route.params.menu === 'withdrawal'" class="view-layout"/>
    <TherapistRePassword v-else-if="$route.params.menu === 'rePassword'"/>
    <Error404 v-else/>
  </div>
</template>

<script>
import PatientsManage from "@/views/therapist/menu/patientsManage/PatientsManagePage";
import Navigator from "@/components/Navigator";
import EvaluationPage from "@/views/therapist/menu/doEvaluation/EvaluationPage";
import EvaluationDetailPage from "@/views/therapist/menu/doEvaluation/EvaluationDetailPage";
import Error404 from "@/views/Error404";
import EvaluationToolsInfo from "@/views/therapist/menu/evaluationToolsInfo/EvaluationToolsInfo";
import PatientsDetailPage from "@/views/therapist/menu/patientsManage/PatientsDetailPage";
import DataReferanceRoom from "@/views/therapist/menu/dataDownload/DataReferenceRoom";
import TherapistInfoEditPage from "./myPage/TherapistInfoEditPage";
import TherapistWithdrawal from "./myPage/TherapistWithdrawal";
import TherapistRePassword from "../common/auth/therapist/TherapistRePassword";

export default {
  name: "TherapistHomePage",
  components: {
    TherapistRePassword,
    TherapistWithdrawal,
    TherapistInfoEditPage,
    DataReferanceRoom,
    PatientsDetailPage, EvaluationToolsInfo, Error404, EvaluationDetailPage, EvaluationPage, Navigator, PatientsManage},
  date() {
    return {}
  },

}
</script>

<style>
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 30px;
}

.view-layout {
  width: 1140px;
  margin: 0 auto;
}
</style>
