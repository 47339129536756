export default [
    {
        label: 'name',
        field: 'name',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'a_1_1',
        field: 'a_1_1',
    },
    {
        label: 'a_1_2',
        field: 'a_1_2',
    },
    {
        label: 'a_2_1',
        field: 'a_2_1',
    },
    {
        label: 'a_2_2',
        field: 'a_2_2',
    },
    {
        label: 'a_2_3',
        field: 'a_2_3',
    },
    {
        label: 'a_2_4',
        field: 'a_2_4',
    },
    {
        label: 'a_2_5',
        field: 'a_2_5',
    },
    {
        label: 'a_2_6',
        field: 'a_2_6',
    },
    {
        label: 'a_2_7',
        field: 'a_2_7',
    },
    {
        label: 'a_2_8',
        field: 'a_2_8',
    },
    {
        label: 'a_2_9',
        field: 'a_2_9',
    },
    {
        label: 'a_3_1',
        field: 'a_3_1',
    },
    {
        label: 'a_3_2',
        field: 'a_3_2',
    },
    {
        label: 'a_3_3',
        field: 'a_3_3',
    },
    {
        label: 'a_4_1',
        field: 'a_4_1',
    },
    {
        label: 'a_4_2',
        field: 'a_4_2',
    },
    {
        label: 'a_4_3',
        field: 'a_4_3',
    },
    {
        label: 'a_5_1',
        field: 'a_5_1',
    },
    {
        label: 'b_1_1',
        field: 'b_1_1',
    },
    {
        label: 'b_1_2',
        field: 'b_1_2',
    },
    {
        label: 'b_1_3',
        field: 'b_1_3',
    },
    {
        label: 'b_1_4',
        field: 'b_1_4',
    },
    {
        label: 'b_1_5',
        field: 'b_1_5',
    },
    {
        label: 'c_1_1',
        field: 'c_1_1',
    },
    {
        label: 'c_1_2',
        field: 'c_1_2',
    },
    {
        label: 'c_1_3',
        field: 'c_1_3',
    },
    {
        label: 'c_1_4',
        field: 'c_1_4',
    },
    {
        label: 'c_1_5',
        field: 'c_1_5',
    },
    {
        label: 'c_1_6',
        field: 'c_1_6',
    },
    {
        label: 'c_1_7',
        field: 'c_1_7',
    },
    {
        label: 'd_1_1',
        field: 'd_1_1',
    },
    {
        label: 'd_1_2',
        field: 'd_1_2',
    },
    {
        label: 'd_1_3',
        field: 'd_1_3',
    },

];
