<template>
  <div>
    <mdb-scrollbar class="scroll-frame" height="570px" width="100%">
      <div class="paper-frame">
        <table class="paper-table">
          <thead class="fixedHeader">
          <tr>
            <td colspan="9" class="td-task td-weight">Task</td>
            <td colspan="3" class="td-answer td-weight">Time / kg / N</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="9" class="td-task">1. (측면에서) 상완 테이블에 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_1" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">2. (측면에서) 상완 박스위에 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_2" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">3. (측면에서) 팔꿈치 펴기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_3" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">4. (측면에서) 팔꿈치 펴면서 무게추 밀기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_4" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">5. (정면에서) 손 테이블에 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_5" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">6. (정면에서) 손 박스위에 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_6" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">7. (정면에서) 무게추 달고 손 박스 위로 올리기(단위:kg)</td>
            <td colspan="3" class="td-answer">
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task td-total">1차 시도</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.kg.kg_1" :placeholder="0" class="custom-input-box w444"/>
                <p>kg</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task td-total">2차 시도</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.kg.kg_2" :placeholder="0" class="custom-input-box w444"/>
                <p>kg</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task td-total">3차 시도</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.kg.kg_3" :placeholder="0" class="custom-input-box w444"/>
                <p>kg</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">8. 팔 뻗어서 무게추 밀기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_7" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">9. 캔 들어 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_8" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">10. 연필 들어 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_9" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">11. 클립 들어 올리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_10" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">12. 코인 쌓기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_11" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">13. 카드 뒤집기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_12" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">14. 손 악력 재기(단위:N)</td>
            <td colspan="3" class="td-answer">
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task td-total">1차 시도</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.pw.pw_1" :placeholder="0" class="custom-input-box w444"/>
                <p>N</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task td-total">2차 시도</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.pw.pw_2" :placeholder="0" class="custom-input-box w444"/>
                <p>N</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task td-total">3차 시도</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.pw.pw_3" :placeholder="0" class="custom-input-box w444"/>
                <p>N</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">15. 열쇠 꽂아 돌리기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_13" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">16. 타올 집기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_14" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="9" class="td-task">17. 무게추 들어있는 바스켓 옮기기</td>
            <td colspan="3" class="td-answer">
              <div class="info-container">
                <input type="number" v-model.number="la.la_15" :placeholder="0.00" class="custom-input-box w444"/>
                <p>초</p>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </mdb-scrollbar>
  </div>
</template>

<script>
import {mdbScrollbar} from "mdbvue";

export default {
  name: "WmftLessAffected",
  components: {
    mdbScrollbar
  },
  props: {
    la: Object,
  }
}
</script>

<style scoped>

.paper-frame {
  overflow: auto;
}

.paper-table {
  width: 100%;
  font-size: 16px;
}

table, tbody, tr, td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E1E3E6;
}

td {
  max-width: 80px;
  min-width: 80px;
  height: 48px;
  font-size: 16px;
}

thead {
  background: #F8F8FA;
  color: #272833;
  font-weight: 500 !important;
}

.fixedHeader {
  position: sticky;
  top: 0;
}

.td-task {
  text-align: left;
  padding-left: 20px;
  font-weight: 400;
}

.td-total {
  text-align: center !important;
  color: #0069CA;
  font-weight: 500;
}

.td-answer {
  text-align: center;
}

.td-weight {
  font-weight: 500;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 5px 10px;
}

.info-container > input {
  text-align: right;
  font-size: 18px;
  color: #000;
}

.info-container > input::placeholder {
  color: #B1B1B1;
  font-size: 18px;
}

.info-container > p {
  font-size: 16px;
  font-weight: 400;
  color: #8D8D8D;
  margin-left: 5px;
  margin-right: 3px;
  width: 15px !important;
}


</style>