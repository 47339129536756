<template>
  <div class="pdp-frame">
    <div class="modal-cover" v-show="isShowModal"></div>
    <h1 class="pdp-title" v-if="!noDocIdData">환자 상세보기</h1>
    <h1 class="pdp-title error" v-else>{{ warningText }}</h1>
    <h5 class="pdp-sub-tile">환자 차트</h5>
    <div class="pdp-first-container">
      <div class="title-line">
        <img src="@/assets/images/IA/label_patients_info.png" alt="label">
        <div class="patients-btn">
          <button v-if="!noDocIdData" class="patients-del" @click="showModal(1)">삭제</button>
          <button class="patients-edit" @click="showModal(2)">수정</button>
        </div>
      </div>
      <div class="info-container" v-if="!noDocIdData">
        <div class="info-item">
          <div class="chart-container">
            <p class="chart-title-1">환자번호</p>
            <p class="chart-value-1 user-name">{{ fbDataPatientsInfo.patientNo }}</p>
          </div>
          <div class="chart-container">
            <p class="chart-title-1">환자명</p>
            <p class="chart-value-1">{{ fbDataUserInfo.name }}</p>
          </div>
          <div class="chart-container">
            <p class="chart-title-1 ">첫 등록 날짜</p>
            <p class="chart-value-1">{{ fbDataPatientsInfo.regDate }}</p>
          </div>
          <div class="chart-container">
            <p class="chart-title-1 ">방문회차</p>
            <p class="chart-value-1">{{ fbDataPatientsInfo.visitCount }}회차</p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="info-item">
          <div class="chart-container">
            <p class="chart-title-1 ">아이디</p>
            <p class="chart-value-1 ">{{ fbDataUserInfo.id }}</p>
          </div>
          <div class="chart-container">
            <p class="chart-title-1 ">이메일</p>
            <p class="chart-value-1 w-106">{{ fbDataUserInfo.email }}</p>
          </div>
          <div class="chart-container">
            <p class="chart-title-1 ">휴대폰번호</p>
            <p class="chart-value-1 ">{{ fbDataUserInfo.mobile }}</p>
          </div>
          <div class="chart-container">
            <p class="chart-title-1 ">성별</p>
            <p class="chart-value-1 ">{{ fbDataUserInfo.gender }}</p>
          </div>
        </div>
      </div>
    </div>
    <h5 class="pdp-sub-tile">진단 및 평가 내역</h5>
    <div class="pdp-second-container">
      <div class="pdp-data-table">
        <mdb-datatable-2
            id="table2"
            v-model="data"
            fixedHeader
            class="text-center evaluation-table"
            hover
            responsive
            noFoundMessage="진단 및 평가 내역이 없습니다."/>
        <p v-show="isDataNull">진단 및 평가 내역이 없습니다.</p>
      </div>
    </div>
    <h5 class="pdp-sub-tile">평가 항목별 점수 통계</h5>
    <StatisticsView :fbDataPatientsInfo="fbDataPatientsInfo"/>
    <PatientsDeleteDialog v-if="isShowModal && modalNum === 1" @callBackModal="callBackModal"/>
    <PatientsEditDialog v-if="isShowModal && modalNum === 2" @callBackModal="callBackModal"
                        :patientsInfo="fbDataPatientsInfo" :fbDataUserInfo="fbDataUserInfo"/>
    <EvaluationDeleteDialog v-if="isShowModal && modalNum === 3" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import {mdbDatatable2,} from "mdbvue";
import patient_detail_columns from "@/assets/data/patient_detail_columns";

import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import PatientsDeleteDialog from "@/views/therapist/menu/patientsManage/PatientsDeleteDialog";
import StatisticsView from "@/views/therapist/menu/patientsManage/statistics/StatisticsView";
import EvaluationDeleteDialog from "@/views/therapist/menu/patientsManage/EvaluationDeleteDialog.vue";
import PatientsEditDialog from "@/views/therapist/menu/patientsManage/PatientsEditDialog.vue";


export default {
  name: "PatientsDetailPage",
  components: {
    StatisticsView,
    PatientsDeleteDialog,
    EvaluationDeleteDialog,
    PatientsEditDialog,
    mdbDatatable2,

  },
  data() {
    return {
      fbCollection: 'patients',
      fbDocId: this.$route.params.id,
      noDocIdData: false,
      fbDataPatientsInfo: {
        userInfo: {}
      },
      fbDataUserInfo: {},
      warningText: '환자 정보를 찾을 수 없습니다.',
      data: {
        columns: patient_detail_columns,
        rows: [],
      },
      deleteEvaluation: '',
      editData: {},
      modalNum: 0,
      deleteRound: 0,
      deleteEvaluationId: '',
      isShowModal: false,
      isDataNull: true,
    }
  },
  created() {
    window.tryEdit = this.tryEdit;
    window.showModal = this.showModal;
  },
  mounted() {
    this.dataInit()
  },
  methods: {
    async dataInit() {
      const self = this;
      self.setNullDataDesign()
      await self.getDataPatientInfo()
      await self.getDataUserInfo()
      await self.getEvaluationList()
    },
    async getEvaluationList() {
      const self = this;
      await firebase.firestore().collection('evaluations')
          .where('patientInfo.uid', '==', self.fbDataPatientsInfo.userInfo.uid)
          .where('state', '==', 1)
          .orderBy('regDate', 'desc')
          .get().then(async (querySnapshot) => {
            if (querySnapshot.size === 0) {
              self.isDataNull = true
              return
            }
            self.isDataNull = false
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              const date = new Date(_data.regDate.seconds * 1000);
              _data.regDate = getDate(date).substring(2);

              _data.diagnosis = _data.diseaseInfo.diagnosis;
              _data.injuredDate = _data.diseaseInfo.injuredDate;
              _data.usedHand = _data.diseaseInfo.usedHand;
              _data.injuredPart = _data.diseaseInfo.injuredPart;
              _data.injuredSide = _data.diseaseInfo.injuredSide;
              _data.tools = self.checkTheUseTools(_data.tools);
              _data.active =
                  `<button class="btn-delete" onclick="showModal(3, '${doc.id}','${doc.data().round}')">삭제</button>
                    <button class="btn-edit" onclick="tryEdit(event,'${doc.id}')">점수 확인 및 수정</button>`

              self.data.rows.push(_data);
            });
          });
      const th = document.getElementById('table2').getElementsByClassName('dataTable')[0].getElementsByTagName('thead')[0].getElementsByTagName('th');
      th[th.length - 1].style.display = 'none'
      th[th.length - 2].colSpan = '2'
    },
    setNullDataDesign() {
      const self = this;
      if (self.isDataNull) {
        const _td = document.getElementsByClassName('dataTable').item(0)
            .children.item(1).childNodes.item(0).childNodes.item(0)
        if (self.data.rows.length === 0) {
          _td.style.opacity = 0
          _td.style.border = 0
        }
      }
    },
    checkTheUseTools(tools) {
      let toolsList = [];
      let bmCount = 0
      const tooltools = ['Fugl-Meyer(상)', 'WMFT', 'BB', 'SIS', 'AAUT', 'MAL', 'BBS', 'Basic Measure', 'TUG', 'FTSTS', 'Fugl-Meyer(하)', '10MWT', '6MWT', 'DGI', 'ABC scale'];
      for (let i = 0; i < Object.keys(tools).length; i++) {
        const tm = Object.keys(tools)[i];
        if (tools[tm].state === 2) {
          if (tm === 'fm') {
            toolsList.push('Fugl-Meyer(상)')
          } else if (tm === 'wmft') {
            toolsList.push('WMFT')
          } else if (tm === 'bbs') {
            toolsList.push('BBS')
          } else if (tm === 'sis') {
            toolsList.push('SIS')
          } else if (tm === 'bb') {
            toolsList.push('BB')
          } else if (tm === 'aaut') {
            toolsList.push('AAUT')
          } else if (tm === 'mal') {
            toolsList.push('MAL')
          } else if (tm === 'tug') {
            toolsList.push('TUG')
          } else if (tm === 'ftsts') {
            toolsList.push('FTSTS')
          } else if (tm === 'fm_l') {
            toolsList.push('Fugl-Meyer(하)')
          } else if (tm === 'tmwt') {
            toolsList.push('10MWT')
          } else if (tm === 'smwt') {
            toolsList.push('6MWT')
          } else if (tm === 'dgi') {
            toolsList.push('DGI')
          } else if (tm === 'abc') {
            toolsList.push('ABC scale')
          } else if (tm === 'rom_mmt') {
            bmCount++
          } else if (tm === 'mas') {
            bmCount++
          } else if (tm === 'balance') {
            bmCount++
          }
        }
      }
      if (bmCount === 3) {
        toolsList.push('Basic Measure')
      }

      let intersection = tooltools.filter(x => toolsList.includes(x))
      return intersection.toString().replaceAll(',', ', ');
    },
    async getDataPatientInfo() {
      const self = this;
      await firebase.firestore().collection(self.fbCollection)
          .doc(self.fbDocId).get().then(async (querySnapshot) => {
            const _data = querySnapshot.data();
            if (_data == null) {
              self.noDocIdData = true;
              return
            }
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            if (_data.lastDate != null) {
              const lastDate = new Date(_data.lastDate.seconds * 1000);
              _data.lastDate = getDate(lastDate);
            }
            self.fbDataPatientsInfo = _data
          });
    },
    async getDataUserInfo() {
      const self = this;
      await firebase.firestore().collection('users')
          .doc(self.fbDataPatientsInfo.userInfo.uid).get().then(async (querySnapshot) => {
            const _data = querySnapshot.data();
            if (_data == null) {
              self.noDocIdData = true;
              return
            }
            self.fbDataUserInfo = _data
          });
    },
    showModal(index, bocId, round) {
      const self = this;
      self.deleteEvaluationId = bocId
      self.modalNum = index
      self.isShowModal = true;
      self.deleteRound = parseInt(round);
    },
    callBackModal: function (index, isDelete, info) {
      const self = this;
      self.modalNum = 0
      self.isShowModal = false
      if (isDelete) {
        if (index === 1) self.patientDelete()
        else if (index === 2) self.editPatient(info)
        else if (index === 3) self.evaluationDeleteBtn()
      } else self.deleteEvaluationId = ''
    },
    tryEdit(event, docId) {
      const self = this;
      event.stopPropagation()
      localStorage.isEvaluationEdit = true;
      localStorage.evaluationId = self.fbDocId;
      self.$router.push(`/therapist/doEvaluation/${docId}`);
    },
    patientDelete() {
      const self = this;
      const db = firebase.firestore();
      const batch = db.batch();
      const ref1 = db.collection(self.fbCollection).doc(self.fbDocId)
      batch.delete(ref1)
      const ref2 = db.collection('users').doc(self.fbDataPatientsInfo.userInfo.uid)
      batch.set(ref2, {patientsId: ''}, {merge: true})
      batch.commit().then(() => {
        alert('삭제되었습니다.')
        self.$router.push({path: '/therapist/patientsManage'});
      })
    },
    editPatient(info) {
      const self = this;
      const db = firebase.firestore();
      const batch = db.batch();

      const userRef = db.collection('users')
          .doc(self.fbDataPatientsInfo.userInfo.uid)
      batch.set(userRef, info, {merge: true})
      if (self.fbDataUserInfo.name !== info.name) {
        const patientsRef = db.collection(self.fbCollection)
            .doc(self.fbDataUserInfo.patientsId)
        batch.set(patientsRef, {userInfo: {name: info.name}}, {merge: true})
        const evaluationsRef = db.collection('evaluations')
        evaluationsRef.where('patientInfo.uid', '==', self.fbDataPatientsInfo.userInfo.uid)
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const evaluationRef = evaluationsRef.doc(doc.id);
                batch.set(evaluationRef, {patientInfo: {name: info.name}}, {merge: true});
              });
              batch.commit().then(() => {
                alert('수정이 완료되었습니다.')
                self.$router.go(0);
              });
            }, (error) => {
              console.error('오류가 발생했습니다:', error);
            });
      } else {
        batch.commit().then(() => {
          alert('수정이 완료되었습니다.')
          self.$router.go(0);
        });
      }
    },
    evaluationDeleteBtn() {
      const self = this;
      const db = firebase.firestore();
      self.isProgress = true
      db.collection('evaluations')
          .where('round', '>', self.deleteRound)
          .where('patientInfo.uid', '==', self.fbDataPatientsInfo.userInfo.uid)
          .where('state', '==', 1)
          .get().then(async (querySnapshot) => {
        await querySnapshot.forEach((doc) => {
          const docId = doc.id
          db.collection('evaluations')
              .doc(docId)
              .set({round: firebase.firestore.FieldValue.increment(-1)}, {merge: true})
        })
        self.evaluationDelete()
      })
    },
    evaluationDelete() {
      const self = this;
      const db = firebase.firestore();
      const batch = db.batch();

      const evaluationsRef = db.collection('evaluations')
          .doc(self.deleteEvaluationId)
      batch.delete(evaluationsRef)
      const patientsRef = db.collection(self.fbCollection)
          .doc(self.fbDocId)
      batch.set(patientsRef, {visitCount: firebase.firestore.FieldValue.increment(-1)}, {merge: true})
          .commit().then(() => {
        self.isProgress = false
        self.deleteEvaluationId = ''
        alert('평가 데이터가 삭제되었습니다.')
        self.$router.go(0);
      })
          .catch((e) => {
            console.error(e)
          })
    },
  },
}
</script>

<style scoped>
.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.pdp-frame {
  padding: 40px 22px 40px 8px;
}

.pdp-title {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #272833;
}

.pdp-sub-tile {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 30px 0 11px 0;
}

.pdp-first-container {
  width: 100%;
  height: 196px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.pdp-second-container {
  width: 100%;
  height: 238px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
  overflow: auto;
}


.title-line {
  display: flex;
}

.title-line img {
  width: 124px;
  height: 28px;
}

.title-line .patients-btn {
  height: 28px;
  margin-left: auto;
}

.title-line .patients-btn button {
  width: 55px;
  height: 28px;
  padding: 0 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
}

.title-line .patients-btn .patients-del {
  background: #B1B1B1;
  margin-right: 8px;
}

.title-line .patients-btn .patients-edit {
  background: #0069CA;
}

.divider {
  width: 1px;
  height: 112px;
  background: #E1E3E6;
  margin: 0 40px;
}

.info-container {
  display: flex;
  align-items: flex-end;
}

.info-item {
  margin-top: 10px;
}

.chart-container {
  display: flex;
  align-items: center;
}

.chart-title-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  width: 100px;
  margin: 0 20px 0 0;
}

.user-name {
  color: #0069CA !important;
}

.chart-value-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.001em;
  color: #000000;
  width: 175px;
}

.error {
  color: red;
  text-align: center;
}

.deleteConfirm {
  cursor: pointer;
}

/deep/ .mdb-datatable-footer {
  display: none;
}

.pdp-data-table {
  position: relative;
}

.pdp-data-table p {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #B1B1B1;
}

/deep/ .text-center th {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 13px !important;
  padding-top: 13px !important;
  height: 48px !important;
}

/deep/ .text-center td {
  vertical-align: middle;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 13px !important;
  padding-top: 13px !important;
  height: 48px !important;
}

/deep/ .text-center tr td:first-child {
  color: #0069CA;
}

/deep/ .text-center tr td {
  padding: 0 12px;
}

/deep/ .text-center tr td:last-child {
  width: 201px !important;
  padding: 0 16px 0 0;
}

/deep/ .text-center tr td:nth-child(7) {
  width: 225px;
  padding: 0 12px;
}

/deep/ .text-center tr:last-child td {
  border-bottom: 1px solid #E7E7ED;
}

/deep/ .chart-table th {
  border-top: 2px solid #B1B1B1;
}

/deep/ .chart-table tr:last-child td {
  border-bottom: 2px solid #B1B1B1;
}

/deep/ .mdb-datatable .btn-edit {
  width: 140px;
  height: 38px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin-left: 6px;
}

/deep/ .mdb-datatable .btn-box {
  background: #00ABEB !important;
}

/deep/ .mdb-datatable .btn-delete {
  width: 55px;
  height: 38px;
  background: #B1B1B1;
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

/deep/ .evaluation-table th {
  background: #F8F8FA !important;
}

/deep/ .evaluation-table tbody td:nth-child(7) {
  word-break: break-all;
  text-align: left;
  white-space: pre-wrap;
}

/deep/ .evaluation-table thead th:last-child {
  visibility: hidden;
}


</style>
