<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        {{ selected.title }}
      </h5>
      <p>{{selected.time}}</p>
    </div>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe :src="selected.url" width="1280" height="720" frameborder="0" allow="autoplay; fullscreen" loading="lazy"></iframe>
    </div>
    <div class="modal-footer">
      <button class="primary-blue custom-btn-box btn-short" @click="btnClose">나가기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VimeoStandardDialog",
  props: {
    selected: Object,
  },
  methods: {
    btnClose() {
      const self = this;
      self.$emit('callBackModal', 1, true)
    }
  }
}
</script>

<style scoped>
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 912px;
  background: #FFFFFF;
  border-radius: 8px;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
  display: flex;
}

.modal-header p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 14px;
  line-height: 36px;
}
</style>
