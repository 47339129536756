<template>
  <div class="paper-main-frame">
    <h1 class="paper-title">BB(Box and Blocks Testing)</h1>
    <div class="modal-cover" v-show="isShowModal"></div>
    <mdb-scrollbar class="scroll-frame" width="100%">
      <div class="paper-frame">
        <div class="paper-guide-frame">
          <img style="width: 20px; margin-right: 5px;" src="@/assets/images/IA/icon_solid_info.png" alt="null">
          <p class="paper-guide">1분에 이동시킬 수 있는 블록 개수를 측정할 수 있습니다.</p>
        </div>
        <div class="form-container">
          <div class="form-group" style="margin-right: 10px">
            <label for="moreAffectedHand">More-Affected Hand</label>
            <input type="number" id="moreAffectedHand" class="form-control" placeholder="손상측 옮긴 블록 개수 입력" v-model.number="bb.ma">
          </div>
          <div class="form-group">
            <label for="moreAffectedHand">Less-Affected Hand</label>
            <input type="number" id="lessAffectedHand" class="form-control" placeholder="비손상측 옮긴 블록 개수 입력" v-model.number="bb.la">
          </div>
        </div>
      </div>
    </mdb-scrollbar>

    <div class="paper-btn-box">
      <button class="paper-btn-quick-save" @click="tempSave" v-if="!isEdit">임시저장</button>
      <button class="paper-btn-result-save" @click="resultSave">평가 결과 저장</button>
    </div>
    <BoxAndBlocksResultDialog v-if="isShowModal" @callBackModal="callBackModal" :fbDocId="fbDocId" :bb="bb"/>

  </div>

</template>

<script>
import {mdbScrollbar} from "mdbvue";
import BoxAndBlocksResultDialog from "./BoxAndBlocksResultDialog";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "BoxAndBlocks",
  components: {
    BoxAndBlocksResultDialog,
    mdbScrollbar,
  },
  props: {
    fbDocId: String,
    bb: Object,
    isEdit: Boolean
  },
  data() {
    return {
      fbCollection: 'evaluations',
      tools: {
        bb: {}
      },
      isShowModal: false,
    };
  },
  methods: {
    commonCheck() {
      const self = this;
      self.tools.bb = self.bb;

      if (self.tools.bb.la === '' || self.tools.bb.ma === '') {
        alert('완료되지 않은 항목이 있습니다.')
        return true
      }

      if (self.tools.bb.la < 0 || self.tools.bb.ma < 0) {
        alert('0 미만으로 내려갈 수 없습니다.')
        return true
      }
      if (self.tools.bb.la > 100 || self.tools.bb.ma > 100) {
        alert('100 이상을 넘을 수 없습니다.')
        return true
      }

      return false
    },
    async tempSave() {
      const self = this
      if (self.commonCheck()) {
        return
      }

      self.tools.bb['state'] = 1;
      const db = firebase.firestore();
      await db.collection(self.fbCollection).doc(self.fbDocId)
          .set({
            tools: self.tools
          }, {merge: true})
          .then(async () => {
            self.$emit('refresh', 'bb', 1)
          });
    },
    async resultSave() {
      const self = this
      if (self.commonCheck()) {
        return
      }

      self.showModal()
    },
    callBackModal: function (index, isShowModal) {
      const self = this;
      if (index === 0) {
        if (!isShowModal) {
          self.isShowModal = isShowModal
        } else {
          self.isShowModal = !isShowModal
          self.$emit('refresh', 'bb', 2)
        }
      }
    },
    showModal() {
      const self = this;
      self.isShowModal = !self.isShowModal
    }
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.paper-guide-frame {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.paper-guide {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.form-container {
  display: flex;
  align-items: center;
}

.form-group {
  width: 570px;
}

.form-group > input {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #E1E3E6;
}

input::placeholder {
  color: #B1B1B1;
}

.form-group > label {
  color: #0069CA;
  font-weight: 500;
  font-size: 16px;
}

.paper-main-frame {
  background-color: #ffffff !important;
}

.paper-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}


.paper-frame {
  width: 100%;
  margin: 0;
}

.paper-btn-box {
  text-align: right;
  margin-top: 20px;
}


.paper-btn-quick-save {
  width: 126px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.paper-btn-result-save {
  margin-left: 10px;
  height: 40px;
  padding: 0 14px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
}

.row-align-right div {
  margin-left: auto;
}

hr {
  border-color: #E1E3E6;
  width: 100%;
  height: 1px !important;
  margin: 0;
}


</style>
